import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ReCAPTCHA from "react-google-recaptcha";
import {CaptchaKey} from '../Config';

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [recapchaError, setRecapchaError] = useState("false");
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  let { FromDataError1 } = props.formErr1;

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    setProcessing(true);
    stripe.createToken(elements.getElement(CardElement)).then((result) => {
      console.log("ffffffffffffffffffffffffsubtttt", result);
      props.changeCardValue(result);
    });
  };

  return (
    <>
      <CardElement
      className="col-md-8"
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />

      <Form.Row className="mt-3">
        <Form.Group controlId="formBasicEmail" as={Col}>
          <ReCAPTCHA
            sitekey={CaptchaKey}
            onChange={props.capchaChange}
          />

          <p style={{ color: "red" }}>
            {FromDataError1.recapcha == "false" ? "" : FromDataError1.recapcha}
          </p>
          <Form.Group controlId="formBasicCheckbox">
              <Form.Check onChange={props.handaleChange} name="save_card" type="checkbox" label="Save This Card" />
          </Form.Group>
        </Form.Group>
      </Form.Row>

      {/* <Button
        variant="primary"
        type="button"
        className="btn-main tabformbutton px-5"
        onClick={handleSubmit}
      >
        Submit
      </Button> */}
            <Form.Row className="justify-content-between mt-5"> 

                    <Form.Group as={Col} md={3}>
                        <Button
                        variant="primary"
                        type="button"
                        className="btn-main tabformbutton px-5"
                        onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                    </Form.Group>
                    <Form.Group as={Col} md={3}>
                        <Button type="button" onClick={props.prevStep}>BACK</Button>
                    </Form.Group>
                  </Form.Row>
    </>
  );
}
