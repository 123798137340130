import React, { Component, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Dropdown, Modal } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';

import './css/dashboard.css';
import DataTable from "react-data-table-component";
import AddTempleteModal from './Section/AddTempleteModal'
import { Redirect } from 'react-router-dom'
import EditTempleteModal from './Section/EditTempleteModal'
import moment from 'moment';
import Threedots_img from '../images/dotsss.png'



export default class AffiliateTempleteDetails extends Component {

  constructor(props) {
    super(props)
    this.wrapper = React.createRef();
    this.state = {

      ContactData: [],
      ContactDataError: [],
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/templete-details',
      TempleteModal: false,
      TempleteEditModal: false,
      help: '',
      templateViewModal: false,
      template_content: '',



    };

    //Binding




  }
  handleClose() {

    if (this.state.TempleteModal) {
      this.setState({ TempleteModal: false })
    }
    else {
      this.setState({ TempleteModal: true })

    }
  }

  handleViewModal = (id) => {
    this.setState({ templateViewModal: true });
    let request = {
      end_point: '/template-view/' + id,
      token: localStorage.getItem("gs_token")

    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        this.setState({ template_content: resp.data.content })


      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });

  }
  handaleSwitchModal = () => {
    this.setState({ templateViewModal: false });
  }

  handleCloseEdit(id = 0) {
    console.log(id)

    if (this.state.TempleteEditModal) {
      this.setState({ TempleteEditModal: false })
    }
    else {
      this.setState({ id: id, TempleteEditModal: true })


    }
  }

  copyToClipBoard(content, id) {
    var clipboard = navigator.clipboard;
    if (clipboard == undefined) {
      console.log('copy undefined');
    } else {
      clipboard.writeText(content.replace(/(<([^>]+)>)/ig, ''));
      document.execCommand("copy");
      this.setState({ Msg: 'Copied!', Msgtype: 'succcess' })
    }
  }
  columns = [
    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
      wrap: true,
    },

    {
      name: "Template Name",
      selector: row => row.templete_name,
      sortable: true,
      width: '200px',
      wrap: true,
    },
    {
      name: "Template Type",
      selector: row => row.template_type,
      sortable: true
    },
    {
      name: "Type",
      selector: row => row.section_type == 'non_customize' ? 'Non-personalised' : 'Personalised',
      sortable: true
    },
    {
      name: "Description",
      selector: row => row.content.replace(/(<([^>]+)>)/ig, '')
      // {
      //     return(
      //         <div className="post__content" dangerouslySetInnerHTML={{__html: row.content}}></div>
      //         )
      //         } 
      ,
      width: '350px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Created At",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: '150px',
      sortable: true,
      wrap: true
    },

    // {
    //   name: "Created At",
    //   selector: row => row.created_at ,
    //   width:'150px',
    //   sortable: true,
    //   wrap:true,
    // },
    {
      name: "Actions",
      cell: row => {
        return (
          // <div><a href="javascript:"><Button variant="primary mr-1"onClick={()=>this.handleCloseEdit(row.id)} >Edit</Button></a>

          //        <Button disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Template?')){this.handaleDelete(row.id)}}}>Delete</Button> 
          //       </div>




          // <div className="three_dotss">
          //   <Dropdown className="drop_partts">
          //     <Dropdown.Toggle variant="" id="dropdown-basic">
          //       <img src={Threedots_img} />
          //     </Dropdown.Toggle>

          //     <Dropdown.Menu>
          //       <Dropdown.Item>
          //         <Button
          //         onClick={()=>this.handleCloseEdit(row.id)} > <i className="fa fa-pencil"></i> Edit</Button>
          //       </Dropdown.Item>
          //       <Dropdown.Item >
          //         <Button disabled={false}
          //         onClick={()=>{if(window.confirm('Delete the Contact Group?')){this.handaleDelete(row.id)}}}> <i className="fa fa-trash"></i> Delete</Button> 
          //       </Dropdown.Item>
          //       <Dropdown.Item >
          //         <a href={'#/contact-details/'+row.id} >
          //           <Button> <i className="fa fa-address-book"></i> Manage Contact</Button></a>
          //       </Dropdown.Item>
          //     </Dropdown.Menu>
          //   </Dropdown>
          // </div>



          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>

                <Dropdown.Item>
                  <a href="javascript:"><Button variant="primary mr-1" onClick={() => this.handleViewModal(row.id)} > <i className="fa fa-eye"></i> View</Button></a>
                </Dropdown.Item>
                {!['8', '9'].includes(localStorage.getItem("roleID")) ?
                  (<>
                    <Dropdown.Item>
                      <a href="javascript:"><Button variant="primary mr-1" onClick={() => this.handleCloseEdit(row.id)} > <i className="fa fa-pencil"></i> Edit</Button></a>
                    </Dropdown.Item>
                    <Dropdown.Item >
                      <Button disabled={false} variant="danger" onClick={() => { if (window.confirm('Delete the Template?')) { this.handaleDelete(row.id) } }}> <i className="fa fa-trash"></i> Delete</Button>
                    </Dropdown.Item>
                  </>) : (<></>)}
                {row.section_type == 'non_customize' ? (<>
                  <Dropdown.Item>
                    <a href="javascript:"><Button variant="primary mr-1" onClick={() => this.copyToClipBoard(row.content, row.id)} > <i className="fa fa-clone"></i> Copy</Button></a>
                  </Dropdown.Item>
                </>) : (<></>)}
              </Dropdown.Menu>
            </Dropdown>
          </div>

        )
      }
      ,
      // sortable: true,
      width: '100px'
    },


  ];

  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let ContactData = this.state.ContactData
    let ContactDataError = this.state.ContactDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    this.getTempleteList()

    //help api
    let requestHelp = {
      end_point: '/help-details?key=affiliate_template_list',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })



  }

  searchChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;


    let sta = this;
    let ContactData = this.state.ContactData
    let ContactDataError = this.state.ContactDataError
    let request = {
      end_point: '/list-campaign-templete?search_key=' + value,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        ContactData = resp.data

        sta.setState({ ContactData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });

  }

  handaleDelete(id) {
    let sta = this

    var formdata = new FormData();
    formdata.append("id", id)

    let request = {
      end_point: '/delete-campaign-templete',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };


    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then((resp) => {

      if (resp.status == 'success') {


        sta.setState({ loader: 'hidden' })///loader off
        sta.setState({ Msg: 'Delete Successfully!', Msgtype: 'succcess' })

        // window.location.reload()
        this.getTempleteList()
        // setTimeout(() => {


        // }, 3000);



      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: "faild", MsgType: 'danger' })
      }

    }).catch(() => {

    })

  }

  getTempleteList() {

    let sta = this;
    let ContactData = this.state.ContactData
    let ContactDataError = this.state.ContactDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: '/list-campaign-templete',
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        ContactData = resp.data

        sta.setState({ ContactData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });





  }

  render() {
    let ContactData = this.state.ContactData

    return (
      <div>
        {ContactData != null ? (<>


          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12}>
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm pt-3">



                  <Row>

                    {this.state.Msg !== '' ? (<><Col md={12}><div><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></div></Col></>) : (<></>)}

                    <Col md={9}>

                      <h3>Template List</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.help
                        }}></p>
                    </Col>
                    <Col md={3} className="d-flex justify-content-end align-items-start">

                      <AddTempleteModal {...this.props} show={this.state.TempleteModal} close={() => this.handleClose()} />

                      {(this.state.id != 0) ? (<>
                        <EditTempleteModal {...this.props} id={this.state.id} show={this.state.TempleteEditModal} close={() => this.handleCloseEdit()} />
                      </>) : (<></>)}
                      {!['8', '9'].includes(localStorage.getItem("roleID")) ?
                        (<>
                          <a href="javascript:" onClick={() => this.handleClose()} className="btn btn-primary ghost_button">Add Template</a>
                        </>) : (<></>)}

                    </Col>
                  </Row>



                  {/*<div className="tooltip-block-redeem-list" style={{left: "200px", top:"20px"}}>   
                  <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2"><div
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></div></Tooltip>}
              >
                     <Image
                      roundedCircle
                      src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1774ca3ddcc%20text%20%7B%20fill%3A%23FFF%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1774ca3ddcc%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%2328a745%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%227.1171875%22%20y%3D%2215.100000000000001%22%3EJ%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                    /> 
                    
            
               </OverlayTrigger>
              </div>*/}



                  <div className="table_search" style={{ float: "right", margin: '10px' }}>
                    Search : <input type='text' placeholder='Search' name="search_val" onChange={this.searchChange} /></div>
                  <DataTable
                    title=""
                    columns={this.columns}
                    data={ContactData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                  />
                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}

        <Modal
          show={this.state.templateViewModal}
          onHide={() => { this.handaleSwitchModal() }}
          dialogClassName=""
          aria-labelledby="example-custom-modal-styling-title"
          className='jobseeker_modal'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Template View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row >
                <Col sm="12" dangerouslySetInnerHTML={{ __html: this.state.template_content }}>
                  {/* {this.state.template_content} */}
                </Col>
              </Row>
            </div>
          </Modal.Body>

        </Modal>
      </div>




    );
  }
}




