import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";

import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import DataTable from "react-data-table-component";
import moment from "moment";

export default class MemberJobseeker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: [
        { sl_no: 1, referral_code: "gjfjhhfuahbj", member_type: "manager" },
      ],
      FromDataError: {},
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/member-jobseeker-list",
      member_id: 0,
      memberDashModal: false,
      totalRows: 0,
      offset: 1,
      limit: 10,
    };

    //Binding
  }

//   handlePageChange = async (page) => {
//     //this.setState({ offset: page });
//     console.log(page);
//     let request = {
//       end_point: `/#?offset=${page}&limit=${this.state.limit}`,
//       token: localStorage.getItem("gs_token"),
//     };

//     this.setState({ loader: "visible" }); ///loader on

//     GET_API(request)
//       .then((resp) => {
//         this.setState({ loader: "hidden" }); ///loader off

//         if (resp.status == "success") {
//           let FromData = resp.data;
//           console.log("response data", resp.data);
//           //this.setState({ totalRows: resp.data.total });
//           this.setState({ FromData });
//         } else {
//         }
//       })
//       .catch((ee) => {
//         this.setState({ loader: "hidden" }); ///loader off

//         console.log("/code list ERR", ee);
//       });
//   };

//   handlePerRowsChange = async (page) => {
//     //this.setState({ offset: page });
//     console.log(page);
//     let request = {
//       end_point: `/#?offset=${this.state.offset}&limit=${page}`,
//       token: localStorage.getItem("gs_token"),
//     };

//     this.setState({ loader: "visible" }); ///loader on

//     GET_API(request)
//       .then((resp) => {
//         this.setState({ loader: "hidden" }); ///loader off

//         if (resp.status == "success") {
//           let FromData = resp.data;
//           console.log("response data", resp.data);
//           this.setState({ limit: page });
//           this.setState({ FromData });
//         } else {
//         }
//       })
//       .catch((ee) => {
//         this.setState({ loader: "hidden" }); ///loader off

//         console.log("/code list ERR", ee);
//       });
//   };

//   handleSearchChange = async (searchValue) => {
//     //this.setState({ offset: page });
//     console.log(searchValue);
//     let request = {
//       end_point: `/#?searchValue=${searchValue}&offset=${this.state.offset}&limit=10`,
//       token: localStorage.getItem("gs_token"),
//     };

//     this.setState({ loader: "visible" }); ///loader on

//     GET_API(request)
//       .then((resp) => {
//         this.setState({ loader: "hidden" }); ///loader off

//         if (resp.status == "success") {
//           let FromData = resp.data;
//           console.log("response data", resp.data);
//           //this.setState({ totalRows: resp.data.total });
//           this.setState({ FromData });
//         } else {
//         }
//       })
//       .catch((ee) => {
//         this.setState({ loader: "hidden" }); ///loader off

//         console.log("/code list ERR", ee);
//       });
//   };

//   componentDidMount() {
//     window.scrollTo(0, 0);
//     let sta = this;
//     let FromData = this.state.FromData;
//     let FromDataError = this.state.FromDataError;

//     //for redirectin message show
//     if (sta.props.location.state != undefined) {
//       sta.setState({ Msg: sta.props.location.state.Msg });
//       sta.setState({ MsgType: sta.props.location.state.MsgType });
//     }

//     let request = {
//       end_point: `/#?offset=${this.state.offset}&limit=${this.state.limit}`,
//       token: localStorage.getItem("gs_token"),
//     };

//     sta.setState({ loader: "visible" }); ///loader on

//     GET_API(request)
//       .then((resp) => {
//         sta.setState({ loader: "hidden" }); ///loader off

//         if (resp.status == "success") {
//           FromData = resp.data;
//           console.log(resp.total);
//           console.log("response data", resp.data);
//           sta.setState({ totalRows: resp.total });
//           sta.setState({ FromData });
//         } else {
//         }
//       })
//       .catch((ee) => {
//         sta.setState({ loader: "hidden" }); ///loader off

//         console.log("/code list ERR", ee);
//       });
//   }

  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: "100px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Member Type",
        cell: row => row.roleID == 3 ? <span className="badge badge-primary">Employee</span>
         : <span className="badge badge-info">Manager</span>,
        // sortable: true
      },
    {
      name: "Referral Code",
      selector: "referral_code",
      width: "150px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => (row.firstname || "") + " " + (row.lastname || ""),
      width: "200px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "200px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Register At",
      // selector: (row) => row.created_at,
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: "150px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Last Active",
      // selector: (row) => row.last_activity,
      selector: row => moment(row.last_activity).format('MM-DD-YYYY'),
      width: "150px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div>
            <Button
              style={{ marginLeft: "20px" }}
              variant="primary mr-1"
              onClick={() => this.handleClose(row.userID)}
            >
              View
            </Button>
          </div>
        );
      },
      width: "150px",
    },
  ];

  render() {
    let FromData = this.state.FromData;

    return (
      <div>
        {FromData != null ? (
          <>
            <Container fluid className="dash_container">
              <Row>
                {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
                <Col md={12} className="pt-4 pb-4">
                  <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />
                  <div className="tabpanelOneForm">
                    <h3>Member Jobseeker List</h3>

                    {this.state.Msg !== "" ? (
                      <>
                        <FlashMess
                          m={this.state.Msg}
                          mtype={
                            this.state.MsgType ? this.state.MsgType : "success"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <input
                      className="form-control float-right"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchChange(e.target.value)}
                      style={{ maxWidth: "15rem" }}
                    />
                    <DataTable
                      className="table__main"
                      title=""
                      columns={this.columns}
                      data={FromData}
                      expandableRows={true}
                      defaultSortField="title"
                      pagination={true}
                      NoDataComponent={"No data"}
                      noHeader={true}
                      paginationServer
                      paginationTotalRows={this.state.totalRows}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}
