import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Table, Card, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import Switch from "react-switch";



export default class SponserSettings extends Component {

  constructor(props) {
    super(props)
    this.state = {
      settings: {
        anynomous: { key: 'Make Company Profile Anonymous', value: "0" },
        autopay: { key: 'Auto Payment', value: "0" },
        
      },
      FromData: {},
      FromDataError: {},

      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/settings',
      admin_allow_redeemcode:"0",
      dashboardInfo: {},
      user_name:"",
      total_sponsor_amount:0,
      total_sponsored:0,
      userDetails: {},

    };

    //Binding

    //  this.togglechange =this.togglechange.bind(this)


  }

  togglechange(key, value) {
    let sta = this

    if (value == "1") {
      value = "0";
    }
    else {
      value = "1";

    }

    var formdata = new FormData();
    formdata.append("key", key);
    formdata.append("value", value);

    let request = {
      end_point: '/create-user-settings',
      formdata: formdata,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on
    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        window.location.reload()

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });






  }








  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let { FromData, settings, FromDataError } = this.state




    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: '/user-settings-details',
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        
        this.setState({ admin_allow_redeemcode:resp.userDetails.admin_referal_consent,user_name:resp.userDetails.firstname,userDetails:resp.userDetails });

        for (const [key, v] of Object.entries(resp.data)) {

          if (v.key == 'anynomous') {

            settings.anynomous.value = v.value
            this.setState({ settings }, () => { console.log(settings) })
          } else if (v.key == 'autopay') {
            settings.autopay.value = v.value
            this.setState({ settings }, () => { console.log(settings) })
          }




        }



      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });


    //help api
    let requestHelp = {
      end_point: '/help-details?key=sponsor_setting',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })

    this.getProfileDetails();
  }
  getProfileDetails() {
    let sta=this;
    let request = {
      end_point: '/get-company-dashboard',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(request).then((resp) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resp.status == 'success') {
        let dashboardInfo = resp.data
        this.setState({ dashboardInfo })
        let left_balance=0
      if(resp.data.total!=0){
        left_balance=(resp.data.total-resp.data.sponser)
      }
        this.setState({ total_sponsor_amount: resp.data.total})
        this.setState({ total_sponsored: resp.data.sponser})
        this.setState({ left_balance: left_balance})
       
       
        


       
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })


  

  }

  softDelete() {
    let sta=this;
    let text='';
  //  if(this.state.userDetails.roleID==13 || this.state.userDetails.roleID==14){
      if(this.state.admin_allow_redeemcode=='1'){
          if(this.state.left_balance!=0){
            text=`Hi ${this.state.user_name} you have a balance of ${this.state.left_balance} left in your account. Your remaining balance will be used by Administrator. Do you want to delete your account?`;
          }else{
            text=`Hi ${this.state.user_name} Are you sure you want to delete your account?`;
          }
      }else if(this.state.admin_allow_redeemcode=='0'){
          if(this.state.left_balance!=0){
            text=`Hi ${this.state.user_name} You have a balance of ${this.state.left_balance} left in your account.  Your account balance will be discarded if you delete your account. You can allow Admin to utilize your remaining balance by giving your consent from dashboard, Do you want to proceed and delete your account?`;
          }else{
            text=`Hi ${this.state.user_name} Are you sure you want to delete your account?`;
          }
      }
    // }else{
    //   text=`Hi ${this.state.user_name} Are you sure you want to delete your account?`;
    // }
    console.log("ttttttttttt",text)
    
    if (window.confirm(text) == true) {
            let requestDel = {
              end_point: '/account-delete',
        
              token: localStorage.getItem("gs_token")
            }
        
            sta.setState({ loader: 'visible' })///loader on
        
            GET_API(requestDel).then((resph) => {
              sta.setState({ loader: 'hidden' })///loader off
        
              if (resph.status == 'success') {
                sta.setState({ Msg: resph.message, MsgType: 'success' })
               
                this.props.history.push({
                  pathname: '/logout',
                });
              }else{
                sta.setState({ Msg: resph.message, MsgType: 'danger' })
              }
        
            }).catch(() => {
              sta.setState({ loader: 'hidden' })///loader off
        
              
            })
    } else {
      console.log("false")

    }

  }

  render() {
    let { FromData, settings } = this.state

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12} className="pt-4 pb-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />



                <h3>Settings</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.help
                  }}></p>

                {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}


                <Card className="p-5 body_card">

                  <Table striped bordered hover>
                    <tr>
                      <th>Key</th>

                      <th>Action</th>
                    </tr>
                    {this.state.userDetails.roleID !=16 &&(<>
                    {/* <tr>
                      <td>{settings.anynomous.key}</td>
                      <td><Switch onChange={() => { this.togglechange("anynomous", settings.anynomous.value) }} checked={settings.anynomous.value == "0" ? false : true} /></td>
                    </tr> */}

                    <tr>
                      <td>{settings.autopay.key}</td>
                      <td><Switch onChange={() => { this.togglechange("autopay", settings.autopay.value) }} checked={settings.autopay.value == "0" ? false : true} /></td>
                    </tr>
                    </>)}
                    {this.state.userDetails.roleID !=undefined && this.state.userDetails.roleID !=16 ? (<>
                    
                    {/* <tr>
                      <td>Disable My Account</td>
                      <td><button type="button" onClick={() => {this.softDelete() }} className="btn btn-danger">Delete</button></td>
                    </tr> */}
                    </>) : (<></>)}



                  </Table>

                </Card>




              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

