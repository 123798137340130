import React, { Component } from 'react';
import {  Route  } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

class Employe extends Component {
    render() {
        return (
            <div>
            <Header/>
            Employe Frontend
            {this.props.children}
            <Footer/>
        </div>
        );
    }
}


const EmployeRoute = ({component: Component, ...rest}) => {  
    return (  
      <Route {...rest} render={matchProps => (  
        <Employe>  
            <Component {...matchProps} />  
        </Employe>  
      )} />  
    )  
  };  

export default EmployeRoute;