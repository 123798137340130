import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    ListGroup,
    ListGroupItem,
    Tab,
    Nav,
    Form,
    Button,
    Image,
    OverlayTrigger,
    Tooltip,

} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import Leftpanel from "./Section/LeftPanel";
import "./css/dashboard.css";
import ReactPaginate from "react-paginate";

export default class ResumeTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FromData: [],
            loader: "hidden",
            urlPath: "/resume-templates",
            all_ebook_category: [],
            perpage: 20,
            currentPage: 0,
            offset: 1,
            pageCount: 1,
            totalData: 0,
            searchData: false,
            searchValue: "",
            nowPage: 1,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let sta = this;
        let FromData = this.state.FromData;

        try {
            if (localStorage.getItem("gs_user") != null) {
                sta.setState({ loader: "visible" }); ///loader on

                GET_API({
                    token: localStorage.getItem("gs_token"),
                    end_point: `/get-resume-template?offset=${this.state.offset}&limit=${this.state.perpage}`,
                })
                    .then((resp) => {
                        if (resp.status == "success") {
                            FromData = resp.data;
                            this.setState({ FromData });
                            let countOfPage = Math.ceil(resp.totalCount / this.state.perpage);
                            console.log(resp.totalCount)
                            this.setState({ pageCount: countOfPage })
                            this.setState({ totalData: resp.totalCount })

                            let offsetAttr = this.state.currentPage * this.state.perpage;
                            this.setState({ offset: offsetAttr });
                            this.setState({ searchData: false });
                            console.log(this.state);
                        }
                        sta.setState({ loader: "hidden" }); ///loader off
                    })
                    .catch((ee) => {
                        sta.setState({ loader: "hidden" }); ///loader off

                        console.log("/resumeTemplateLoadERR", ee);
                    });
            }
        } catch (error) { }

        sta.setState({ loader: "visible" }); ///loader on
        //this.getall_ebook_category();
    }

    handlePageClick = (e) => {
        this.setState({ loader: "visible" });
        this.setState({ currentPage: e.selected });
        this.setState({ nowPage: e.selected + 1 })

        if (this.state.searchData) {
            let FromData = this.state.FromData;
            let request = {
                end_point: `/get-resume-template?category_id=${this.state.searchValue}`,
                token: localStorage.getItem("gs_token"),
            };

            this.setState({ loader: "visible" }); ///loader on

            GET_API(request)
                .then((resp) => {
                    //this.setState({ loader: "hidden" }); ///loader off

                    if (resp.status == "success") {
                        let FromData = resp.data;

                        this.setState({ FromData });
                        let countOfPage = Math.ceil(resp.totalCount / this.state.perpage);
                        this.setState({ pageCount: countOfPage })
                        this.setState({ totalData: resp.totalCount })

                        let offsetAttr = this.state.currentPage * this.state.perpage;
                        this.setState({ offset: offsetAttr });
                        this.setState({ searchData: true });
                    } else {
                    }
                })
                .catch((ee) => {
                    //this.setState({ loader: "hidden" }); ///loader off

                    console.log("/code list ERR", ee);
                });
        } else {
            let FromData = this.state.FromData;
            // let FromData = [];


            // this.setState({ FromData });
            GET_API({
                token: localStorage.getItem("gs_token"),
                end_point: `/get-resume-template?offset=${e.selected + 1}&limit=${this.state.perpage}`,
            })
                .then((resp) => {
                    if (resp.status == "success") {
                        FromData = resp.data;
                        this.setState({ FromData });
                        let countOfPage = Math.ceil(resp.totalCount / this.state.perpage);
                        this.setState({ pageCount: countOfPage })

                        let offsetAttr = this.state.currentPage * this.state.perpage;
                        this.setState({ offset: offsetAttr });
                        this.setState({ searchData: false });
                    }


                })
                .catch((ee) => {
                    //this.setState({ loader: "hidden" }); ///loader off

                    console.log("/resumeTemplateLoadERR", ee);
                });
        }
        this.setState({ loader: "hidden" }); ///loader off
        // setTimeout(() => {
        //     this.setState({ loader: "hidden" }); ///loader off
        // }, 8000);


        console.log(this.state);

    }

    getall_ebook_category() {
        let sta = this;
        let all_ebook_category = this.state.all_ebook_category;
        try {
            if (localStorage.getItem("gs_user") != null) {


                GET_API({
                    token: localStorage.getItem("gs_token"),
                    end_point: "/get-resume-template-category",
                })
                    .then((resp) => {
                        if (resp.status == "success") {
                            all_ebook_category = resp.data;
                            this.setState({ all_ebook_category });
                        }

                    })
                    .catch((ee) => {

                    });
            }
        } catch (error) { }

    }

    handleSearchChange = async (searchValue) => {
        this.setState({ currentPage: 0 });
        this.setState({ offset: 1 })
        this.setState({ pageCount: 1 })
        this.setState({ totalData: 0 })
        let FromData = this.state.FromData;
        let request = {
            end_point: `/get-resume-template?category_id=${searchValue}`,
            token: localStorage.getItem("gs_token"),
        };

        this.setState({ loader: "visible" }); ///loader on

        GET_API(request)
            .then((resp) => {
                this.setState({ loader: "hidden" }); ///loader off

                if (resp.status == "success") {
                    let FromData = resp.data;
                    this.setState({ searchValue: searchValue })
                    this.setState({ FromData });
                    let countOfPage = Math.ceil(resp.totalCount / this.state.perpage);
                    this.setState({ pageCount: countOfPage })
                    this.setState({ totalData: resp.totalCount })

                    let offsetAttr = this.state.currentPage * this.state.perpage;
                    this.setState({ offset: offsetAttr });
                    this.setState({ searchData: true });
                } else {
                }
            })
            .catch((ee) => {
                this.setState({ loader: "hidden" }); ///loader off

                console.log("/code list ERR", ee);
            });
        console.log(this.state);
    };

    render() {
        let FromData = this.state.FromData;

        return (
            <div className="pt-3 pb-5">
                <div className="d-flex justify-content-between">
                    <div>
                        <h2 className="ebooks_heading">Resume Templates</h2>
                        <p className="ebooks_desc">Download Resume Templates here...</p>
                    </div>
                    {/* <Form.Group style={{ 'width': "225px" }}>
                        <Form.Control as="select" onChange={(e) => this.handleSearchChange(e.target.value)}>
                            <option value="">Search By Category</option>
                            {this.state.all_ebook_category.map((c_val, index) => (
                                <>
                                    <option value={c_val.id}>{c_val.category_name}</option>
                                </>

                            ))}

                        </Form.Control>
                    </Form.Group> */}
                </div>

                <Row className="mt-4">
                    <Loader
                        loadmsg="please wait"
                        visibility={this.state.loader}
                    />

                    {this.state.pageCount > 0 ? (<>

                        {/* {this.state.FromData.slice(this.state.offset, this.state.offset + this.state.perpage).map((resume, index) => ( */}
                        {this.state.FromData.map((resume, index) => (
                            <>



                                <Col sm={12} md={6} lg={3} className="mb-5 mt-3">
                                    <div className="eBook_block">
                                        <div className="eBook_image">
                                            <Card.Img variant="top" src={resume.image} />
                                        </div>
                                        <div className="eBook_desc text-center">
                                            {resume.title}
                                            <a href={resume.template} className="eBook_download" target="_blank">Download</a>
                                        </div>
                                    </div>

                                </Col>

                            </>

                        ))}

                    </>) : (<>
                        <Col sm={12}>
                            <h5 className="text-center">No Record Found</h5>
                        </Col>
                    </>)}
                    {this.state.pageCount > 0 ? (<>
                        <Col md={12}>
                            <ReactPaginate
                                previousLabel={"← Previous"}
                                nextLabel={"Next →"}
                                pageCount={this.state.pageCount}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination_ebooks"}
                                // previousLinkClassName={"pagination__link"}
                                // nextLinkClassName={"pagination__link"}
                                // disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination_ebooks_active"}
                            />
                        </Col>
                    </>) : (<></>)}
                </Row>
            </div>
        );
    }
}
