import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PayemntDashboardViewModal from './Section/PayemntDashboardViewModal';


export default class PaymentHistory extends Component {

  constructor(props) {
    super(props)
    this.state = {

      FromData: {},
      FromDataError:
        {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/donate-history',
      help: '',
      paymentDashModal: false,
      payment_userID: 0,
      totalRows: 0,
      offset: 1,
      limit: 10,
      JobseekerTotal: [],
      msgShow: false,
      refMsg: '',

    };

    //Binding




  }
  handlepaymentmodal(payment_id = 0,) {


    if (this.state.paymentDashModal) {
      this.setState({ paymentDashModal: false })
    }
    else {
      this.setState({ paymentDashModal: true, payment_userID: payment_id })
      console.log("payment state", this.state.payment_userID);
    }
  }



  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment Date",
      selector: row => row.payment_date,
      width: '300px',
      sortable: true
    },
    {
      name: "Amount",
      selector: row => '$' + row.amount,
      width: '199px',
      sortable: true
    },

    {
      name: "Payment Status",
      selector: row => row.payment_status == 1 ? <span className="badge badge-success">Success</span> : <span className="badge badge-danger">Failed</span>,
      width: '199px',
      sortable: true
    },
    // {
    //   name: "Payment From",
    //   selector: row => row.payment_gateway,
    //   sortable: true
    // },
    {
      name: "Action",
      cell: row => {
        console.log('payment id', row.users_userID);
        return (
          <div>
            <Button variant="primary" onClick={() => this.handlepaymentmodal(row.paymentID)}>View</Button>
          </div>
        )
      }
      ,
      // sortable: true
    }

  ];


  handlePageChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/payment-history?offset=${page}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        console.log("payment history", resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };


  handlePerRowsChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/payment-history?offset=${this.state.offset}&limit=${page}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        this.setState({ limit: page });
        console.log("payment history", resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };

  handleSearchChange = async (searchValue) => {
    let request = {
      end_point: `/payment-history?offset=${this.state.offset}&limit=${this.state.limit}&searchValue=${searchValue}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        console.log("payment history", resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: `/payment-history?offset=${this.state.offset}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        FromData = resp.data
        console.log("payment history", resp.data)
        sta.setState({ totalRows: resp.total });

        sta.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });

    //help api
    let requestHelp = {
      end_point: '/help-details?key=sponsor_payment_history',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })

    let requestJobseeker = {
      end_point: `/jobseeker-list`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on

    GET_API(requestJobseeker)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let JobseekerTotal = resp.data;
          // console.log("response data", JobseekerTotal.length);
          //this.setState({ totalRows: resp.data.total });
          this.setState({ JobseekerTotal });
        } else {
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });

    if (localStorage.getItem("msg_for_payment_history") != null) {
      this.setState({ msgShow: true })
      this.setState({ refMsg: localStorage.getItem("msg_for_payment_history") })
      setTimeout(() => {
        this.setState({ msgShow: false })
        this.setState({ refMsg: '' })
        localStorage.removeItem("msg_for_payment_history");
      }, 8000);
    }


  }


  render() {
    let FromData = this.state.FromData

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row>

              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12} className="pt-4 pb-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />
                <div className="tabpanelOneForm pt-3">
                  {(this.state.payment_userID != 0) ? (<>
                    <PayemntDashboardViewModal {...this.props} payment_userID={this.state.payment_userID} show={this.state.paymentDashModal} close={() => this.handlepaymentmodal()} />
                  </>) : (<></>)}  </div>

                <div className="tabpanelOneForm">


                  <Row className='align-items-start'>
                    <Col md={12}>
                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                    </Col>
                    <Col md={12}>
                      <h3>Payment History</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.help,
                        }}
                      ></p>
                    </Col>
                    <Col md={12} className="d-flex justify-content-end">
                      <a href='#/jobseeker-list' style={{ textDecoration: 'none' }}><h3>Total Sponsorship Links Sent</h3></a>
                      <strong style={{ fontSize: "15px" }} className="referral_made">({this.state.JobseekerTotal.length ? this.state.JobseekerTotal.length : 0})</strong>
                    </Col>
                    {this.state.msgShow == true ? (<>
                      <Col md={12}>
                        <p className='text-center' style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}>{this.state.refMsg}</p>
                      </Col>
                    </>) : (<></>)}
                  </Row>





                  <div className="table_search" style={{ float: "right", margin: '10px' }}>
                    Search :
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchChange(e.target.value)}
                    />
                  </div>



                  <DataTable
                    title=""
                    columns={this.columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

