import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import { BASE_URL } from '../Config';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class RefferFriend extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromData: {
        recipients: '',
        template: '',

      },
      FromDataError:
      {
        recipients: 'false',

      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/invitations',
      help: '',
      template: '',
      reddemCode: '',
      shereLink: '',
      referLink: '',


    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)
    this.genarateCode = this.genarateCode.bind(this)


  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = data.target.value;
    let FromData = this.state.FromData;




    FromData[name] = value;

    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }
  // ref code gengarate
  genarateCode(e) {
    e.preventDefault();
    let sta = this;

    let request = {
      end_point: '/create-referral-code',
      formdata: {},
      token: localStorage.getItem("gs_token")

    };
    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        sta.setState({ Text: resp.message, TextType: 'text-success' })

        sta.setState({ reddemCode: resp.data.referral_code })
        sta.setState({ shereLink: `<a href='` + BASE_URL + '#/becomejobseeker/' + this.state.reddemCode + "'>Click here</a>" })


        setTimeout(() => {
          this.setState({ Msg: '' })

        }, 3000);
      }
      else {


        sta.setState({ Text: resp.message, TextType: 'text-danger' })
      }


    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off


      sta.setState({ Text: 'Something Went Wrong,Please try again later.', TextType: 'text-danger' })
      console.log('/codeGEN_ERR', ee)


    });


  }

  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    console.log(this.state)
    // return 0;




    if (this.validateForm(FromDataError)) {

      sta.setState({ loader: 'visible' })///loader on

      let t_key = localStorage.getItem("roleID")==16? 'jobAssist_reffer_frnd' : 'sponser_reffer_frnd'
      var formdata = new FormData();

      formdata.append("email", FromData.recipients || '');
      //formdata.append("key", 'sh_link');
      formdata.append("key", t_key);
      //formdata.append("link", this.state.reddemCode);
      formdata.append("template", this.state.template);
      //formdata.append("link", BASE_URL);
      formdata.append("link", this.state.referLink);

      let request = {
        end_point: '/refer-friend',
        formdata: formdata,
        token: localStorage.getItem("gs_token")

      };

      console.log('......request', formdata)


      POST_API(request).then(resp => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp.status == 'success') {
          sta.setState({ Msg: 'Mail sent successfully', MsgType: 'success' })
          FromData.recipients = ''
          // FromData.template = ''
          sta.setState({ FromData });
          // console.log('recipients',this.state.FormData.recipients);

        }
        else {


          sta.setState({ Msg: 'Mail sent failed', MsgType: 'danger' })
        }
      }).catch((ee) => {
        sta.setState({ loader: 'hidden' })///loader on
        //  sta.setState({Msg:'Something Went Wrong. Try After Some Time.',MsgType:'danger'})
        sta.setState({ Msg: 'Please enter a valid Email ID.', MsgType: 'danger' })
        console.log('/InvittationERR', ee)
      });



    } else {
      sta.setState({ Msg: 'Fill the form correctly!' })
      sta.setState({ MsgType: 'danger' })
    }


  }


  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError


    if (localStorage.getItem("roleID") != undefined && localStorage.getItem("roleID")==16) {
      sta.setState({referLink:BASE_URL+'#/jobassist-signup?pagesource=JAS'})
    }else{
      sta.setState({referLink:BASE_URL+'#/becomesponsor'})
    }
    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }
    //help api
    let requestHelp = {
      end_point: '/help-details?key=sponsor_reffer_friend',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })
    let t_key = localStorage.getItem("roleID")==16? 'jobAssist_reffer_frnd' : 'sponser_reffer_frnd'
    let request = {
      end_point: '/share-template-fetch?t_key='+t_key,

      token: localStorage.getItem("gs_token")
    }

    GET_API(request).then((resp) => {
      if (resp.status == 'success') {
        let dataResult = resp.data;
       
        let user = JSON.parse(localStorage.getItem("gs_user"))
        dataResult = dataResult.replace('{{referrer_name}}', user.firstname+' '+user.lastname)
        dataResult = dataResult.replace('{{link}}', this.state.referLink)
        this.setState({ template: dataResult })
        console.log('template', dataResult);
      }

    }).catch(() => {


    })

  }

  render() {
    let { FromData, FromDataError } = this.state
    console.log("..//...//", FromData)
    return (
      <div>
        <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12} className="pt-4 pb-4">
              <Loader loadmsg="please wait" visibility={this.state.loader} />

              <div className="tabpanelOneForm card p-5">

              <h3>Refer A Friend</h3>
                {localStorage.getItem("roleID") != undefined && localStorage.getItem("roleID") == 16 ? (<>
                  <p>Know someone who is willing to sponsor outplaced candidates and help them find a job? Refer your friend now with one easy step by providing his or her email address below.</p>
                </>) : (<>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.help
                    }}></p>
                </>)}
               
                    

                <Form
                  className="tabform"
                  onSubmit={this.handaleSubmit}
                >
                  <Row className="m-0 mt-4 mb-4">

                    <Col md={8} className="pl-0 ">
                      {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}



                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Please enter your friends email id
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="recipients"
                          onChange={this.handaleChange}
                          placeholder="You can enter multiple email id's, separated by comma"
                          value={FromData.recipients || ''}
                          validaterule={['required']}
                          validatemsg={['This field is required']}

                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.recipients == 'false' ? '' : this.state.FromDataError.recipients}</p>
                      </Form.Group>

                      {/* <Button className="btn btn-primary ghost_button" disabled={this.state.loader != 'hidden' ? true : false} onClick={this.genarateCode}>Generate Code</Button>
                      <div className=" test1">{this.state.reddemCode}</div>
                      <p className={this.state.TextType}>{this.state.Text}</p>


                      <Form.Group controlId="formBasicTexteditor">
                    <Form.Label>Email template</Form.Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.template.replace('{{link}}', this.state.shereLink) || ''}
                      onInit={editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                        const data = editor.getData();
                        FromData.template = data;
                        this.setState({ FromData })
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        FromData.template = data;
                        this.setState({ FromData })
                      }}
                      onBlur={(event, editor) => {
                        //console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        //console.log( 'Focus.', editor );
                      }}
                    />

                  </Form.Group> */}




                      {/* company_description */}

                    </Col>
                    <Col md={4} className="pl-0 tabformRight">

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> &nbsp;
                        </Form.Label>
                        <Button
                        variant="primary"
                        type="submit"
                        className="btn-main tabformbutton"
                        >
                        Submit
                      </Button>
                    </Form.Group>
                      
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    );
  }
}

export default RefferFriend;