import React, { Component } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { job_assist_logo } from "../images";
import TokenVerify, {
  FlashMess,
  POST_API,
  Loader,
  GET_API,
} from "../Helpers/Helpers";
import validation from "../Validate/validator";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./jobassist.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import 'react-phone-number-input/style.css'
import recurator from '../images/recruiters.png';
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import {CaptchaKey} from "../Config";
export default class JobOutPlacement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        firstname: "",
        lastname: "",
        email: "",
        contact_no: "",
        //otptext: "",
        linkedin_url: "",
        recapcha: "",
        cv: "",
        cvname: "",
        job_title: "",
        score: "",
        id: "",
        confirm_email: "",
        confirm_password: ""
      },
      FromData1: {
        otptext: "",
      },
      FromDataError: {
        firstname: "false",
        lastname: "false",
        email: "false",
        contact_no: "false",
        //otptext: "false",
        linkedin_url: "false",
        recapcha: "false",
        cv: "false",
        confirm_email: "false",
        confirm_password: "false"
      },
      FromDataError1: {
        otptext: "false",
      },
      isValid: false,
      Msg: "",
      MsgType: "",
      loader: "hidden",
      pagesource: "",
      servicetype: "",
      isOtpSend: false,
      isOtpVerified: false,

      jobassist_code: "",
      isValidReferalCode: false,
      company_details: {},
      inputType: 'password',
      inputTypeConfirm: 'password',
      showOtpModal: false,
      MsgPromo: "",
      MsgTypePromo: "",
      isPasswordValid: false,
      isMailValid: false
    };

    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.handaleChange = this.handaleChange.bind(this);
    this.toggleEye = this.toggleEye.bind(this);
    this.handaleChangeOtp = this.handaleChangeOtp.bind(this);
  }

  onshowModal() {
    if (this.state.showOtpModal) {
      this.setState({ showOtpModal: false })

    } else {
      this.setState({ showOtpModal: true })
    }
  }
  // password hide and show
  toggleEye() {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      this.setState({ inputType: 'text' });

    }
    else {
      this.setState({ inputType: 'password' });

    }
  }

  handlePhoneChange = (value) => {
    let { FromDataError, FromData } = this.state;
    if (value !== undefined) {
      FromData.contact_no = value;
      this.setState({ FromData });

      //let number = parsePhoneNumber(value);
      // if (number) {
      //   let country_code = "+" + number.countryCallingCode;
      //   let mobile = number.nationalNumber;
      //   this.setState({ country_code, mobile });
      // }
      if (isValidPhoneNumber(value)) {
        FromDataError.contact_no = "";

      } else {
        FromDataError.contact_no = "Enter Valid Mobile Number";

      }
      this.setState({ FromDataError });
    } else {
      FromDataError.contact_no = "Enter Valid Mobile Number";

      this.setState({ FromDataError });
    }
  };

  handaleChangeOtp(data) {
    let sta = this;
    let FromDataError1 = this.state.FromDataError1;

    let name = data.target.name;
    let value = "";
    let FromData1 = this.state.FromData1;

    if (name == "file") {
      let file = data.target.files[0];
      value = file;

      this.toBase64(data.target.files[0]).then((resp) => {
        FromData1[name] = resp.split(",")[1];
        FromData1.filename = file.name;
        FromData1.format = file.type;
      });
    } else {
      value = data.target.value;
      FromData1[name] = value;
    }
    this.setState({ FromData1 }, () => {

    });
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      //console.log(error)
      FromDataError1[name] = error;
      this.setState({ FromDataError1 });

      setTimeout(() => {

      }, 100);
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { params } = this.props.match;
    if (params) {
      let { jobassist_code } = params;
      this.setState({ jobassist_code });

      var formdata = new FormData();
      formdata.append("referral_code", jobassist_code);

      let request2 = {
        end_point: "/validate-referral-code",
        formdata: formdata,
      };

      POST_API(request2)
        .then((resp) => {
          if (resp.status === "success") {
            this.setState({
              // MsgPromo: 'Promo "' + jobassist_code + '" Applied!',
              MsgPromo: 'Sponsorship Code "' + jobassist_code + '" Applied!',
              MsgTypePromo: "success",
              isValidReferalCode: true,
            });
          } else {
            this.setState({
              // MsgPromo: 'Promo "' + jobassist_code + '" Invalid',
              MsgPromo: 'Sponsorship Code "' + jobassist_code + '" Invalid',
              MsgTypePromo: "danger",
            });
          }
        })
        .catch(() => {
          this.setState({
            MsgPromo: "Something Went Wrong,Code Not Applied!",
            MsgTypePromo: "danger",
          });
        });

      let request = {
        end_point: `/get-jobassist-details?referral_code=${jobassist_code}`,
      };
      GET_API(request)
        .then((res) => {
          if (res.status === "success") {
            this.setState({ company_details: res.data });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  closeMsg = (duration = 2000) => {
    setTimeout(() => {
      this.setState({ Msg: "", MsgType: "" });
    }, duration);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handaleChange = async (data) => {
    let { FromDataError, FromData } = this.state;

    let { name, value } = data.target;



    if (name === "cv") {
      value = data.target.files[0];
      FromData.cvname = value?.name;
      if (value) {
        this.toBase64(value).then((resp) => {
          FromData[name] = resp.split(",")[1];
        });
      } else {
        FromData[name] = value;
      }
    } else if (name == "password" || name == "confirm_password") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.password == FromData.confirm_password) {
        FromDataError['confirm_password'] = "";
        this.setState({ isPasswordValid: true, FromDataError });
      } else {
        if (FromData.confirm_password.length < 6) {
          FromDataError['confirm_password'] = "password should be of at least 6 characters";
        } else {
          FromDataError['confirm_password'] = "Confirmed Password should be same as password";
        }
        this.setState({ isPasswordValid: false, FromDataError });

      }

    } else if (name == "email" || name == "confirm_email") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.email == FromData.confirm_email) {
        FromDataError['confirm_email'] = "";
        this.setState({ isMailValid: true, FromDataError });
      } else {

        FromDataError['confirm_email'] = "Confirmed e-mail don't match";

        this.setState({ isMailValid: false, FromDataError });

      }

    }

    else {
      FromData[name] = value;
    }

    if (name === "email") {
      this.setState({
        isOtpSend: false,
        isOtpVerified: false,
        VerifyapiStatus: "",
      });
    }

    var valid_obj = {
      value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      setTimeout(() => {
        this.setState({ FromDataError }, () => {
          this.setState({ FromData }, () => {
            // UPLOAD CV
            if (name === "cv") {
              if (!error) {
                this.uploadCv();
              } else {
                FromDataError[name] = "";
                this.setState({ FromDataError });
              }
            }
          });
        });
      }, 100);
    });
  };

  uploadCv = () => {
    let sta = this;
    let { FromData, jobassist_code } = this.state;

    let formdata = new FormData();
    formdata.append("filename", FromData.cvname);
    formdata.append("format", ".pdf");
    formdata.append("base64", FromData.cv);
    formdata.append("referral_code", jobassist_code || "");
    formdata.append("category", "");
    formdata.append("affiliate_code", "");

    let request = {
      end_point: "/jobseeker-registration",
      formdata: formdata,
    };

    sta.setState({ loader: "visible" });

    POST_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" });

        if (resp.status === "success") {
          let userDetails = resp.data;
          FromData.id = userDetails.id;
          FromData.score = userDetails.score;
          FromData.job_title = userDetails.job_title;

          sta.setState(FromData);
        } else if (resp.status == 'failed_resume') {
          sta.setState({ Msg: "Please! upload a valid Resume", MsgType: "danger" });
          this.closeMsg(5000);
        }
        else {
          sta.setState({ Msg: "Some thing went wrong", MsgType: "danger" });
          this.closeMsg(5000);
        }
      })
      .catch((err) => {
        sta.setState({ loader: "hidden" });
        sta.setState({
          Msg: "Something Went Wrong,Please try again later.",
          MsgType: "danger",
        });
        console.log(err);
        this.closeMsg(5000);
      });
  };

  capchaChange = (value) => {
    let { FromData, FromDataError } = this.state;
    FromData.recapcha = value;
    FromDataError.recapcha = "";
    this.setState({ FromData, FromDataError }, () => {
      this.validateForm(FromDataError);
    });
  };

  validateForm(errors) {
    let valid = true;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value === "false") {
          FromDataError[key] = "This field is required";
          this.setState({ FromDataError });
        }
        valid = false;
      }
    }

    return valid;
  }
  validateForm1(errors) {
    let valid = true;
    let sta = this;

    let FromDataError1 = this.state.FromDataError1;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {

          FromDataError1[key] = "This field is required";
          sta.setState({ FromDataError1 });
        }

        valid = false;
      }
    }

    return valid;
  }

  sendOTP = () => {
    let sta = this;
    let { FromDataError, FromData } = this.state;

    if (this.validateForm(FromDataError)) {
      let formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.servicetype || "");

      let request = {
        end_point: "/email-otp-send",
        formdata: formdata,
      };

      sta.setState({ loader: "visible" });

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" });

          if (resp.status === "success") {
            sta.setState({
              Msg: "One Time Passcode sent successfully. Please check your email",
              MsgType: "success",
              isOtpSend: true,
            });
            this.setState({ showOtpModal: true })
            this.closeMsg(5000);
          } else {
            sta.setState({
              Msg: resp.message.email_id.join(),
              MsgType: "danger",
              isOtpSend: false,
            });
            this.closeMsg(5000);
          }
        })
        .catch((err) => {
          console.log(err);
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
            loader: "hidden",
          });
          this.closeMsg(5000);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!", MsgType: "danger" });
      this.closeMsg();
    }
  };

  verifyOTP = () => {
    let sta = this;
    let { FromDataError, FromDataError1, FromData, FromData1 } = this.state;

    if (this.validateForm1(FromDataError1)) {
      let formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.servicetype || "");
      formdata.append("otp", FromData1.otptext);

      sta.setState({ loader: "visible" });

      let request = {
        end_point: "/email-otp-verify",
        formdata: formdata,
      };

      POST_API(request)
        .then((resp) => {
          //sta.setState({ loader: "hidden" });

          let respData = resp.status;
          sta.setState({ VerifyapiStatus: respData });

          if (resp.status === "success") {
            sta.setState({
              Msg: "One Time Passcode Verified",
              MsgType: "success",
              isOtpVerified: true,
            });
            this.finalhandaleSubmit();
            // this.closeMsg();

          } else {
            sta.setState({
              Msg: "One Time Passcode not valid!",
              MsgType: "danger",
              isOtpVerified: false,
            });
            this.closeMsg(5000);
          }
        })
        .catch((err) => {
          console.log(err);
          sta.setState({ loader: "hidden" });
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
            isOtpVerified: false,
          });

          this.closeMsg(5000);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!", MsgType: "danger" });
      this.closeMsg();
    }
  };

  handaleSubmit = (e) => {
    e.preventDefault();

    let { FromDataError, FromData } = this.state;
    let sta = this;

    if (!FromData.recapcha) {
      FromDataError.recapcha = "This field is required";
      this.setState({ FromDataError });
    }
    this.sendOTP();
  };

  finalhandaleSubmit = () => {

    let { FromDataError, FromData, jobassist_code } = this.state;
    let sta = this;

    // if (!FromData.recapcha) {
    //   FromDataError.recapcha = "This field is required";
    //   this.setState({ FromDataError });
    // }

    if (this.validateForm(FromDataError)) {
      if (!this.state.isOtpVerified) {
        sta.setState({
          Msg: "Please Verify your OTP",
          MsgType: "danger",
          VerifyapiStatus: "failed",
        });
        this.closeMsg(5000);
        return;
      }

      let data = FromData.job_title;
      let job_title;
      if (data) {
        let mapdata = data.map((x) => ({ label: x, value: x }));
        job_title = JSON.stringify(mapdata);
      }

      let formdata = new FormData();

      formdata.append("firstname", FromData.firstname || "");
      formdata.append("lastname", FromData.lastname || "");
      formdata.append("phone", FromData.contact_no || "");
      formdata.append("email", FromData.email || "");
      formdata.append("linkedin_url", FromData.linkedin_url || "");

      formdata.append("role_id", 4);
      formdata.append("servicetype", this.props.servicetype || "");
      formdata.append("id", FromData.id || "");
      formdata.append("category", "");
      formdata.append("score", FromData.score || "");
      formdata.append("job_title", job_title || "");
      formdata.append("referral_code", jobassist_code || "");
      formdata.append("password", FromData.password);
      sta.setState({ loader: "visible" });

      let request = {
        end_point: "/registration",
        formdata: formdata,
      };

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" });
          if (resp.status === "success") {
            let email = resp.data.email;
            let password = resp.code;

            let loginformdata = new FormData();
            loginformdata.append("email", email);
            loginformdata.append("password", password);

            let request2 = {
              end_point: "/login",
              formdata: loginformdata,
            };

            POST_API(request2)
              .then((resp) => {
                if (resp.status == "success") {
                  localStorage.setItem("gs_token", resp.user.token);
                  localStorage.setItem("roleID", resp.user.roleID);

                  if (resp.user.resume_score != null) {
                    localStorage.setItem("resumeScore", resp.user.resume_score);
                  }

                  localStorage.setItem("gs_user", JSON.stringify(resp.user));

                  if (localStorage.getItem("gs_token") != null) {
                    localStorage.setItem("profile_image", resp.image);

                    localStorage.setItem("dashboardLink", "#/jobseeker-dash");
                    TokenVerify();

                    localStorage.setItem("gs_auth_code", resp.user.auth_code);
                    localStorage.setItem("roleID", resp.user.roleID);
                    localStorage.setItem(
                      "get_category",
                      resp.user.job_category
                    );
                    localStorage.setItem("profile_image", resp.image);
                    this.props.history.push("/jobseeker-dash");
                  }
                } else {
                  sta.props.history.push({
                    pathname: "/login",
                    state: {
                      Msg: "Registration Successfully Done, Check your mail to login.",
                      MsgType: "success",
                    },
                  });
                }
              })
              .catch((ee) => {
                console.log("/AutologinERR", ee);
                sta.props.history.push({
                  pathname: "/login",
                  state: {
                    Msg: "Registration Successfully Done, Check your mail to login.",
                    MsgType: "success",
                  },
                });
              });
          } else {
            sta.setState({ Msg: resp.message.email.join(), MsgType: "danger" });
            this.closeMsg(5000);
          }
        })
        .catch(() => {
          sta.setState({ loader: "hidden" });

          sta.setState({
            Msg: "Something Went Wrong,Please try again later.",
            MsgType: "danger",
          });
          this.closeMsg(5000);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
      this.closeMsg();
    }
  };


  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      A one time code will be sent to your email. Please use the code to validate you email ID.
    </Tooltip>
  );

  render() {
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
    let { FromData, FromDataError, company_details } = this.state;
    return (

      <div>
        <div className="Becomesponsorfromarea" style={{ "padding-top": "20px", "padding-bottom": "20px" }}>
          <Container>
            <div
              className="bundle_package_inner" style={{ "margin-top": "30px" }}>
              <div className="logo_box_inner_img">
                <img
                  src={company_details?.company_logo || job_assist_logo}
                  alt="GWI"
                />
              </div>
              <div className="logobox_inner_text">
                <h2>{company_details?.company_name}</h2>
                {/* <p>{company_details?.company_tagline}</p> */}
              </div>
            </div>

            <div style={{ margin: "50px 0px 39px -10px" }}>
              {/* <h2>Job Outplacement Sign Up</h2> */}
              <h2>Employee Sign Up for Get Back To Work Jobseeker Bundle</h2>
              <p>Please fill in your details and upload your resume</p>
            </div>

            {/* <div className="form_block"> */}

            <Loader loadmsg="please wait" visibility={this.state.loader} />
            {this.state.MsgPromo && (
              <FlashMess
                m={this.state.MsgPromo}
                mtype={this.state.MsgTypePromo ? this.state.MsgTypePromo : "success"}
              />
            )}
            {this.state.Msg && (
              <FlashMess
                m={this.state.Msg}
                mtype={this.state.MsgType ? this.state.MsgType : "success"}
              />
            )}

            {/* <div className="tabpanelOneForm"> */}
            <Form onSubmit={this.handaleSubmit} className="tabform">

              <Row className="sponsorfrom p-4">
                <Col lg={7}>

                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        First Name
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        value={FromData.firstname}
                        onChange={this.handaleChange}
                        placeholder="Enter  first name"
                        validaterule={["required"]}
                        validatemsg={["This field is required"]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.firstname === "false"
                          ? ""
                          : FromDataError.firstname}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        Last Name
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastname"
                        value={FromData.lastname}
                        onChange={this.handaleChange}
                        placeholder="Enter  last name"
                        validaterule={["required"]}
                        validatemsg={["This field is required"]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.lastname === "false"
                          ? ""
                          : FromDataError.lastname}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>

                      <Form.Label>
                        Email
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={FromData.email}
                        onChange={this.handaleChange}
                        placeholder="Enter  Email"
                        validaterule={["required", "isEmail"]}
                        validatemsg={[
                          "This field is required",
                          "Invalid Email",
                        ]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.email === "false"
                          ? ""
                          : FromDataError.email}
                      </p>

                      {/* 
                                <Button type="button" 
                              variant="otp_btn"
                              onClick={this.sendOTP} className="otp_btn">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={this.renderTooltip}>
                                <i className="fa-solid fa-circle-question"></i>
                            </OverlayTrigger>  Confirm Email</Button> */}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col} >
                      <Form.Label>
                        Confirm Email
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <div className="d-flex w-100 verify_field">
                        <Form.Control
                          type="email"
                          name="confirm_email"
                          value={FromData.confirm_email}
                          onChange={this.handaleChange}
                          placeholder="Enter Confirm Email"
                          validaterule={["required", "isEmail", 'same|' + FromData.email]}
                          validatemsg={["This field is required", "Invalid Email", "Confirmed e-mail don't match"]}
                        />
                        {this.state.isMailValid &&
                          <div className="verify_icon d-flex align-items-center">
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                          </div>

                        }
                      </div>
                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.confirm_email == "false"
                          ? ""
                          : this.state.FromDataError.confirm_email}
                      </p>

                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        Phone
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        value={FromData.contact_no}
                        onChange={this.handlePhoneChange}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.contact_no === "false"
                          ? ""
                          : FromDataError.contact_no}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        Password
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type={this.state.inputType}
                        id='password'
                        name="password"
                        onChange={this.handaleChange}
                        value={FromData.password}
                        placeholder="Enter Password"
                        validaterule={["required", "password"]}
                        validatemsg={[
                          "This field is required",
                          "password should be of at least 6 characters",
                        ]}
                      />
                      <i style={{ float: 'right', marginTop: '-30px', marginRight: '8px', position: 'relative', zIndex: 1, cursor: 'pointer' }} onClick={this.toggleEye}>{this.state.inputType == 'text' ? eyeSlash : eye}</i>
                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.password == "false"
                          ? ""
                          : this.state.FromDataError.password}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>Confirm Password</Form.Label>

                      <div className="d-flex w-100 verify_field">

                        <Form.Control
                          id='confirm_password'
                          type={this.state.inputTypeConfirm}
                          name="confirm_password"
                          onChange={this.handaleChange}
                          placeholder="Confirm Password"
                          value={FromData.confirm_password}
                          validaterule={[
                            "required",
                            "password",
                            "same|" + FromData.password,
                          ]}
                          validatemsg={[
                            "This field is required",
                            "password should be of at least 6 characters",
                            "Confirmed Password should be same as password",
                          ]}
                        />

                        {this.state.isPasswordValid &&
                          <div className="verify_icon d-flex align-items-center">
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                          </div>

                        }
                      </div>


                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.confirm_password == "false"
                          ? ""
                          : this.state.FromDataError.confirm_password}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>Linkedin Profile URL</Form.Label>
                      <span className="text-danger"> *</span>
                      <Form.Control
                        type="text"
                        validaterule={["required", "linkedinUrlCheck"]}
                        validatemsg={["This field is required", "Invalid URL"]}
                        name="linkedin_url"
                        placeholder="Enter Linkedin URL"
                        onChange={this.handaleChange}
                        value={FromData.linkedin_url}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.linkedin_url === "false"
                          ? ""
                          : FromDataError.linkedin_url}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>Upload Resume (.pdf)</Form.Label>
                      <span className="text-danger"> *</span>
                      <Form.File
                        id="custom-file-translate-scss"
                        validaterule={["mime|pdf"]}
                        validatemsg={["Select valid file"]}
                        name="cv"
                        onChange={this.handaleChange}
                        label={FromData.cvname || "Select file"}
                        lang="en"
                        custom
                        accept=".pdf"
                      />
                      <p style={{ color: "green", marginTop: "5px" }}>
                        {FromData.cvname != ""
                          ? FromData.cvname
                          : ""}
                      </p>
                      <p style={{ color: "red" }}>
                        {FromDataError.cv === "false" ? "" : FromDataError.cv}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <ReCAPTCHA
                        sitekey={CaptchaKey}
                        onChange={this.capchaChange}
                      />

                      <p style={{ color: "red" }}>
                        {FromDataError.recapcha === "false"
                          ? ""
                          : FromDataError.recapcha}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className="justify-content-end mt-5">
                    <Form.Group as={Col} md={3}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-main tabformbutton"
                        disabled={
                          this.state.loader !== "hidden" ||
                          !this.state.isValidReferalCode
                        }
                      >
                        Submit
                      </Button>
                    </Form.Group>
                  </Form.Row>

                </Col>
                <Col lg={5}>
                  <img src={recurator} className="img-fluid" />
                </Col>
              </Row>


            </Form>
            {/* </div> */}

            {/* </div> */}
            <Modal show={this.state.showOtpModal}
              backdrop="static"
              keyboard={false}
              onHide={() => { this.onshowModal() }}
              id="modal"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>OTP Verification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <Form
                  className="tabform"

                >
                  <Row className="m-0 mt-4 mb-4">
                    <Col md={12} className="pr-5 ">

                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                      <Form.Group
                        controlId="formBasicEmail"
                        as={Col}
                        className="field_box"
                      >
                        <div className="otp_field">
                          <Form.Label>
                            One Time Passcode
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="otptext"
                            value={this.state.FromData1.otptext}
                            onChange={this.handaleChangeOtp}
                            placeholder="Enter one time passcode"
                            validaterule={["required"]}
                            validatemsg={["This field is required", "Invalid one time passcode"]}
                          />
                          <p style={{ color: "red" }}>
                            {this.state.FromDataError1.otptext == "false"
                              ? ""
                              : this.state.FromDataError1.otptext}
                          </p>
                        </div>

                      </Form.Group>

                    </Col>

                    <Col md={3} className="">
                      <Button

                        variant="primary"
                        type="button"
                        className="btn btn-primary btn-md tabformbutton"
                        onClick={this.verifyOTP}
                      >
                        Submit
                      </Button>
                    </Col>

                  </Row>
                </Form>
              </Modal.Body>

            </Modal>
          </Container>
        </div>
      </div>
    );
  }
}
