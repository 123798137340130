// import './css/dashboard.css';
import Leftpanel from './Section/LeftPanel';
import React, { Component } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Modal, Dropdown } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import profileimage from "../images/dashboard_profile_image.jpg";
import DataTable from "react-data-table-component";
import Threedots_img from '../images/dotsss.png'




class MyHrContactsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Msg: '',
            MsgType: '',
            urlPath: '/jobseeker-hrcontacts-list',
            help: '',
            hrcontacts: {},
            group_name: "",
            searchInput: "",
            filteredData: [],

            loading: false,
            loader: 'hidden',
            contact_count: 0,
            viewModal: false,
            viewData: [],
            unlockModal: false,
            unlockId: '',
            selectedRows: [],
            creditCount: 1,
            dataIds: [],
            debitForUnlock: 0,
            singleUnlock: true,

        };

    }


    getDebitRules = () => {
        GET_API({ end_point: '/get-credit-debit-rules/unlock_my_hr_contacts', token: localStorage.getItem("gs_token") })
            .then((resp) => {
                if (resp.status == "success") {
                    this.setState({ debitForUnlock: resp.data.debit_value })

                }
            })
            .catch((err) => {
                console.log("getDebitRules", err)
            })
    }



    componentDidMount() {
        let id = this.props.match.params.id

        // let request = {
        //     end_point: '/get-my-hrContacts?hr_group_id=' + id,
        //     token: localStorage.getItem("gs_token")

        // };

        // this.setState({ loader: 'visible' })///loader on

        // GET_API(request).then(resp => {


        //     this.setState({ hrcontacts: resp.contacts, group_name: resp.group_data.company_name })
        //     this.setState({ loader: 'hidden' })

        // }).catch((ee) => {

        //     this.setState({ loader: 'hidden' })
        // });

        this.getGroupContactList();
        this.getDebitRules();





    }

    getGroupContactList = () => {

        let id = this.props.match.params.id
        let request = {
            end_point: '/get-my-hrContacts?hr_group_id=' + id,
            token: localStorage.getItem("gs_token")

        };

        this.setState({ loader: 'visible' })///loader on

        GET_API(request).then(resp => {


            this.setState({ hrcontacts: resp.contacts, group_name: resp.group_data.company_name })
            this.setState({ loader: 'hidden' })

        }).catch((ee) => {

            this.setState({ loader: 'hidden' })
        });
    }

    handleChange = (event) => {
        this.setState({ searchInput: event.target.value })
        //this.globalSearch()
    };



    globalSearch = () => {
        console.log("searchInput")
        let { searchInput, hrcontacts } = this.state;
        let filteredData = hrcontacts.filter((value) => {
            if (value.Company_Name != null && value.Email != null && value.Phone != null && value.Country != null && value.Full_Name != null && value.City != null && value.State != null) {
                return (
                    value.Company_Name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.Phone.includes(searchInput) ||
                    value.Country.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.Full_Name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.City.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.State.toLowerCase().includes(searchInput.toLowerCase())
                );
            }
        });
        this.setState({ filteredData });
    };


    handaleSwitchModal = (type) => {
        if (type == 'viewModal') {
            if (this.state.viewModal) {
                this.setState({ viewModal: false });
            } else {
                this.setState({ viewModal: true });
            }
        } else if (type == 'unlockModal') {
            if (this.state.unlockModal) {
                this.setState({ unlockModal: false });
            } else {
                this.setState({ unlockModal: true });
            }
        }
    }

    unlockContactModal = (id) => {
        this.setState({ singleUnlock: true });
        this.setState({ creditCount: 1 });
        this.setState({ unlockId: id });
        this.handaleSwitchModal('unlockModal');
    }


    modalopenForView = (id) => {
        var formdata = new FormData();
        formdata.append("hr_id", id);
        let request_api_type = {
            end_point: '/get-hr-details',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            this.setState({ viewData: resp.contacts_details });
            this.setState({ viewModal: true });
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
    }

    unlockContact = (id) => {
        this.setState({ loader: 'visible' })///loader on
        var formdata = new FormData();
        formdata.append("hr_id", id);
        formdata.append("type", 'my_contact');
        let request_api_type = {
            end_point: '/unlock-contact',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            if (resp.status == 'success') {
                if (this.state.selectedRows.length > 0) {
                    let indexArr = this.state.selectedRows.indexOf(id);
                    if (indexArr >= 0) {
                        this.state.selectedRows.splice(indexArr, 1);
                    }
                }
                this.props.dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })
                this.setState({ unlockModal: false });
                this.setState({ Msg: "Contact Successfully Unlocked", MsgType: "success" });
                this.getGroupContactList();
            }
            else {
                if (this.state.selectedRows.length > 0) {
                    let indexArr = this.state.selectedRows.indexOf(id);
                    if (indexArr >= 0) {
                        this.state.selectedRows.splice(indexArr, 1);
                    }
                }
                this.setState({ unlockModal: false });
                this.setState({ Msg: resp.message, MsgType: "danger" });
                this.getGroupContactList();

            }
        }).catch((ee) => {
            this.setState({ unlockModal: false });
            this.setState({ Msg: "Low Credit Balance", MsgType: "danger" });
            this.getGroupContactList();

            // this.setState({loader:'hidden'})
        });
    }

    mailTosend = (mail) => {
        window.location.href = 'mailto:' + mail;
    }


    handleChangeRow = (event) => {

        let selectedRows = [];
        console.log(event);

        event.selectedRows.map((items, key2) => {
            if (!this.state.dataIds.includes(items.id)) {
                selectedRows.push(items.id);
            }
        })
        this.setState({ selectedRows });

    }

    unlockContactModalMultiple = () => {
        console.log(this.state.selectedRows);
        if (this.state.selectedRows.length > 0) {
            this.setState({ singleUnlock: false });
            this.setState({ creditCount: this.state.selectedRows.length });
            this.handaleSwitchModal('unlockModal');
        } else {

        }
    }

    unlockBulkContact = () => {
        this.setState({ loader: 'visible' })///loader on
        var formdata = new FormData();
        formdata.append("hr_ids", this.state.selectedRows);
        formdata.append("type", 'my_contact');
        let request_api_type = {
            end_point: '/unlock-contact',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            this.setState({ singleUnlock: true });
            console.log(resp)
            if (resp.status == 'success') {
                this.props.dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })
                let dataIds = this.state.dataIds;
                this.state.selectedRows.map((items, key2) => {
                    dataIds.push(items);
                })
                this.setState({ dataIds });
                this.setState({ unlockModal: false });
                this.setState({ Msg: resp.message, MsgType: "success" });
                let selectedRows = [];
                this.setState({ selectedRows });
                this.getGroupContactList();
            }
            else {
                this.setState({ unlockModal: false });
                this.setState({ Msg: resp.message, MsgType: "danger" });
                this.getGroupContactList();
            }
        }).catch((ee) => {
            this.setState({ unlockModal: false });
            this.setState({ Msg: "Low Credit Balance", MsgType: "danger" });
            this.getGroupContactList();
            // this.setState({loader:'hidden'})
        });
    }








    render() {
        let FromData = this.state.FromData
        let data = this.state.hrcontacts
        //console.log(",,,,,,,,,",this.state.filteredData)
        let conditionalRowStyles = [
            {
                when: row => (row.unlock_flag > 0),
                style: {
                    backgroundColor: "#93ddea",
                    'input[type="checkbox"]': {
                        display: "none",
                    },
                }
            }
        ];

        const rowDisabledCriteria = row => (row.unlock_flag > 0 || row.unlock_requested == 1) ? true : false;
        const customStyles = {
            header: {
                style: {
                    display: 'none',
                },
            },
        };





        let columns = [
            {
                name: "Company",
                selector: "Company_Name",
                sortable: true,
                width: "15%",
            },
            {
                name: "Title",
                selector: "Title",
                sortable: true,
                width: "15%",
            },
            {
                name: "Name",
                // selector: row => {
                //     let fName = row.Full_Name.split(' ')
                //     return (
                //         <span>
                //             {row.unlock_flag == 0 ? (
                //                 <>
                //                     <span>{fName[0]} *****</span>
                //                 </>
                //             ) : (<>
                //                 <span>{row.Full_Name}</span>
                //             </>)}
                //         </span>
                //     )

                // },
                selector: "Full_Name",
                sortable: true,
                width: "13%",
            },
            // {
            //     name: "Title",
            //     selector: "Title",
            //     sortable: true,
            // },
            {
                name: "Email",
                //selector: row => (row.unlock_flag == 0) ? (row.Email.length > 2) ? row.Email.substring(0, 3) + '*'.repeat(row.Email.length - 2) : '' : <a href={'mailto:' + row.Email}>{row.Email}</a>,
                sortable: true,
                width: "15%",
                selector: row => (row.unlock_flag == 0) ? row.Email : <a href={'mailto:' + row.Email}>{row.Email}</a>,

            },
            {
                name: "Phone",
                //selector: row => (row.unlock_flag == 0) ? (row.Phone != null) ? (row.Phone.length > 2) ? row.Phone.substring(0, 3) + '*'.repeat(row.Phone.length - 2) : '' : '' : <a href={'tel:' + row.Phone}>{row.Phone}</a>,
                sortable: true,
                width: "13%",
                selector: row => (row.unlock_flag == 0) ? row.Phone : <a href={'tel:' + row.Phone}>{row.Phone}</a>,

            },
            {
                name: "Linkedin Profile",
                //selector: row => (row.unlock_flag == 0) ? (row.Company_Url != null) ? (row.Company_Url.length > 2) ? row.Company_Url.substring(0, 3) + '*'.repeat(row.Company_Url.length - 2) : '' : '' : <a target="_blank" href={row.Company_Url}>Click Here</a>,
                sortable: true,
                width: "17%",
                selector: row => (row.unlock_flag == 0) ? row.Company_Url : <a target="_blank" href={row.Company_Url}>Click Here</a>,

            },
            // {
            //     name: "Industry",
            //     selector: "Industry",
            //     sortable: true,
            // },
            // {
            //     name: "Address",
            //     selector: "address",
            //     sortable: true,
            // },
            // {
            //     name: "City",
            //     selector: "City",
            //     sortable: true,
            //     width: "10%",
            // },
            // {
            //     name: "State",
            //     selector: "State",
            //     sortable: true,
            //     width: "10%",
            // },
            // {
            //     name: "Zip",
            //     selector: "Zip",
            //     sortable: true,
            // },
            // {
            //     name: "Country",
            //     selector: "Country",
            //     sortable: true,
            // },





            // {
            //     name: "Sales",
            //     selector: "Sales",
            //     sortable: true,
            // },
            // {
            //     name: "Employees",
            //     selector: "Company_Size",
            //     sortable: true,
            // },
            // {
            //     name: "SIC Code",
            //     selector: "Sic_Code",
            //     sortable: true,
            // },
            {
                name: "Action",
                selector: row => {
                    return (


                        <div>
                            {row.unlock_flag > 0 ? (
                                <>
                                    <div className="three_dotss">
                                        <Dropdown className="drop_partts">
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <img src={Threedots_img} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Button variant="danger" onClick={() => this.modalopenForView(row.id)}> <i className="fa fa-eye" aria-hidden="true"></i> View</Button>
                                                </Dropdown.Item>
                                                {/* <Dropdown.Item >
                                                            <Button variant="danger" onClick={() => this.mailTosend(row.Email)}> <i className="fa fa-envelope" aria-hidden="true"></i> Send Email</Button>
                                                        </Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                            ) : (<>
                                {row.unlock_requested != 1 && (


                                    <Button title='Unlock contact information' className='btn btn-primary tabformbutton btn_total' onClick={() => { this.unlockContactModal(row.id) }}> <i className="fa fa-unlock" aria-hidden="true"></i></Button>
                                )}
                            </>)}
                        </div>







                    )
                },
                sortable: true,
                width: "7%",
            },


        ]
        return (
            <div className='pt-5 pb-5 data_table_gwi'>
                <Container >

                    <Row>
                        <Col md={12}>
                        <p style={{ fontWeight: 600 }}><span className='text-danger'>*</span> One "Contact" is equivalent to either one or three valid details consisting of email address, phone number or Linkedin profile. 1 Credit will be deducted if any of the 3 (email address, phone number, linkedin URL) is found valid</p>
                            <Row>
                                <h2 className='jobseeker_heading_hr'>HR Contacts of {this.state.group_name}</h2>

                                <div className='w-100 mb-2'>&nbsp;</div>
                                <p>Your unlocked contents are highlighted with teal <span style={{ backgroundColor: '#93ddea' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> colour.</p>
                            </Row>

                            <Loader loadmsg="please wait" visibility={this.state.loader} />
                            {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                            <div>
                                {/* <Row>
                                    <Col sm="8">

                                    </Col>
                                    <Col sm="4">
                                        <Form.Control
                                            size="large"
                                            name="searchInput"
                                            value={this.state.searchInput || ""}
                                            onChange={this.handleChange}
                                            placeholder="Search State, Country and HR contacts"
                                        />
                                    </Col>
                                </Row> */}
                            </div>




                            <div className='clearfix'></div>
                            <div className='d-flex align-items-start'>
                                <a
                                    href={'#/jobseeker-hrcontacts'}
                                    className="btn btn-primary tabformbutton ghost_button float-left btn_total"
                                >
                                    Back
                                </a>
                                <a
                                    href={'#/jobseeker-bulk-unlock-quee'}
                                    className="btn btn-primary tabformbutton ghost_button ml-2 float-left btn_total"
                                >
                                    Data Validation List
                                </a>

                                <div className='d-flex ml-auto flex-column'>
                                    <button
                                        variant="primary"
                                        type="submit"
                                        className={this.state.selectedRows.length > 0 ? "btn btn-primary btn-md tabformbutton float-right  mt-1 mb-3 btn_total" : "btn btn-primary btn-md tabformbutton float-right  mt-1 mb-3 d-none btn_total"}
                                        disabled={this.state.selectedRows.length > 0 ? false : true}
                                        onClick={() => { this.unlockContactModalMultiple() }}><i className="fa fa-unlock" aria-hidden="true"></i> Unlock</button>
                                    {this.state.selectedRows.length > 0 ? (<>
                                        <small className='float-right text-primary mr-2 mt-1'>(Maximum 10 rows selectable)</small>
                                    </>) : (<></>)}
                                </div>
                            </div>
                            <DataTable
                                title=""
                                columns={columns}
                                data={data}
                                defaultSortField="title"
                                pagination={true}
                                conditionalRowStyles={conditionalRowStyles}
                                selectableRows
                                selectableRowDisabled={rowDisabledCriteria}
                                onSelectedRowsChange={this.handleChangeRow}
                                noContextMenu
                                customStyles={customStyles}
                            />
                            {/* <p style={{ fontWeight: 600 }}><span className='text-danger'>*</span> One "Contact" is equivalent to either one or three valid details consisting of email address, phone number or Linkedin profile. 1 Credit will be deducted if any of the 3 (email address, phone number, linkedin URL) is found valid</p> */}



                        </Col>
                    </Row>


                </Container>



                <Modal
                    show={this.state.viewModal}
                    onHide={() => { this.handaleSwitchModal('viewModal') }}
                    // dialogClassName="modal-90w"
                    // aria-labelledby="example-custom-modal-styling-title"
                    // className='jobseeker_modal'
                    centered
                >

                    <Modal.Header closeButton>
                        <Modal.Title>HR Details</Modal.Title>
                        {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
                    </Modal.Header>

                    <Modal.Body>
                        {/* <div className='close_btn' onClick={() => { handaleSwitchModal('viewModal') }}>x</div> */}
                        <div>

                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Company :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Company_Name}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Name :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Full_Name}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Title :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Title}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Email :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Email}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Phone :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Phone}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Linkedin Profile :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Company_Url}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Industry :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Industry}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Address :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.address}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        City :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.City}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        State :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.State}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Zip :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Zip}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Country :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Country}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Sales :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Sales}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Employees :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Company_Size}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        SIC Code :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Sic_Code}</p>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>

                </Modal>



                <Modal show={this.state.unlockModal}
                    backdrop="static"
                    className='modal_upload'
                    keyboard={false}
                    onHide={() => { this.handaleSwitchModal('unlockModal') }}
                    id="modal"
                >

                    <Modal.Body style={{ minHeight: "250px" }}>
                        <div className="upload_file_area text-center">
                            <div className="upload_info">
                                <h4>Unlock Contact <span className='text-danger'>*</span></h4>
                                {(this.state.creditCount * this.state.debitForUnlock) <= this.props.creditBalance ? (<>
                                    {this.state.singleUnlock == false ? (<>
                                        {this.state.selectedRows.length > 10 ? (<>

                                        </>) : (<>
                                            <p className="mb-0" style={{ fontSize: '15px' }}>{this.state.creditCount * this.state.debitForUnlock} credit{this.state.selectedRows.length > 1 ? '(s)' : ''} will be deducted to <br /> unlock {this.state.selectedRows.length > 1 ? 'these contacts' : 'this contact'}  <span className='text-danger'>*</span></p>
                                        </>)}
                                    </>) : (<>
                                        <p className="mb-0" style={{ fontSize: '15px' }}>{this.state.creditCount * this.state.debitForUnlock} credit{this.state.selectedRows.length > 1 ? '(s)' : ''} will be deducted to <br /> unlock {this.state.selectedRows.length > 1 ? 'these contacts' : 'this contact'}  <span className='text-danger'>*</span></p>

                                    </>)}
                                    {/* <p className="mb-0" style={{ fontSize: '15px' }}>{this.state.creditCount * this.state.debitForUnlock} credit{this.state.selectedRows.length > 1 ? '(s)' : ''} will be deducted to <br /> unlock this contact <span className='text-danger'>*</span></p> */}
                                </>) : (<>
                                    <p className="mb-0" style={{ fontSize: '15px', color: "red" }}>Low credit balance</p>

                                </>)}
                            </div>
                            <div className="upload_action row">

                                {(this.state.creditCount * this.state.debitForUnlock) <= this.props.creditBalance && (<>
                                    {/* {this.state.selectedRows.length > 0 ? ( */}
                                    {this.state.singleUnlock == false ? (

                                        <>
                                            {this.state.selectedRows.length > 10 ? (<>
                                                <div className='col-12'>
                                                    <p className='text-danger mt-2'>At a time maximum 10 rows selectable</p>
                                                </div>
                                                <div className="col pr-2">
                                                    <Button variant="primary" onClick={() => { this.handaleSwitchModal('unlockModal') }} className="btn btn-danger tabformbutton btn_total">Cancel</Button>
                                                </div>
                                            </>) : (<>
                                                <div className="col pr-2">
                                                    <Button variant="primary" onClick={() => { this.handaleSwitchModal('unlockModal') }} className="btn btn-danger tabformbutton btn_total">Cancel</Button>
                                                </div>
                                                <div className="col pl-2">
                                                    <Button onClick={() => { this.unlockBulkContact() }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn_total">Accept</Button>
                                                </div>
                                            </>)}
                                        </>) : (<>
                                            <div className="col pr-2">
                                                <Button variant="primary" onClick={() => { this.handaleSwitchModal('unlockModal') }} className="btn btn-danger tabformbutton btn_total">Cancel</Button>
                                            </div>
                                            <div className="col pl-2">
                                                <Button onClick={() => { this.unlockContact(this.state.unlockId) }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn_total">Accept</Button>
                                            </div>
                                        </>)}
                                </>)}

                            </div>
                        </div>
                    </Modal.Body>

                </Modal>



            </div >
        )
    }
}


const WithUseReducer = (props) => {

    let dispatch = useDispatch();
    const creditBalance = useSelector((state) => state.creditBalance);




    return <MyHrContactsList {...props} {...{ dispatch, creditBalance }} />;
}

export default WithUseReducer
