import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image,Modal } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import DataTable from "react-data-table-component";
import moment from 'moment';

const FeedBackNotifications = (props) => {
  const [showModal, setShowModal] = useState(false);
  
  const [genCategory, setgenCategory] = useState({});
  const [communicationCategory, setcommunicationCategory] = useState({});
  const [serviceCategory, setserviceCategory] = useState({});
  const [unknownCategory, setunknownCategory] = useState({});

  const [notifications, setNotifications] = useState({});
  const [FromData, setFromData] = useState({});
  const [Msg, setMsg] = useState('');
  const [MsgType, setMsgType] = useState('');
  const [loader, setLoader] = useState('hidden');
  const [help, setHelp] = useState('');
  const [rowid, setRowid] = useState(0);
  const [readless, setReadless] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
   
    // Help API
    let requestHelp = {
      end_point: '/help-details?key=sponsor_notification',
      token: localStorage.getItem("gs_token")
    };

    setLoader('visible'); // Loader on

    GET_API(requestHelp)
      .then((resph) => {
        setLoader('hidden'); // Loader off
        if (resph.status === 'success') {
          let help = resph.data.value;
          setHelp(help);
        }
      })
      .catch(() => {
        setLoader('hidden'); // Loader off
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    let sta = props;
    // For redirecting message show
    if (sta.location.state != undefined) {
      setMsg(sta.location.state.Msg);
      setMsgType(sta.location.state.MsgType);
    }

    let request1 = {
      end_point: `/type-notification?type=all&offset=${offset}&limit=${limit}&searchValue=${search}&category=${category}`,
      token: localStorage.getItem("gs_token")
    };

    GET_API(request1)
      .then((resp) => {
        if (resp.status === 'success') {
          setNotifications(resp.data);
          setTotalRows(resp.row_count);
        }
      })
      .catch(() => {});
  }, [props, offset, limit,search,category]);

  const handledata = async (id) => {
    await setRowid(id);
    await setReadless(id);
  };

  const handlelessdata = async (id) => {
    await setRowid(0);
    await setReadless(0);
  };

  const handlePageChange = async (page) => {
    setOffset(page);
  };

  const handlePerRowsChange = async (page) => {
    setLimit(page)
  };

  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  const columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '90px',
      sortable: true,
      wrap: true
    },
    {
      name: "Name",
      cell: (row) => <table cellPadding="10px"><td>{(row.name || "") + " " + (row.lastname || "")}</td></table>
    },
    {
      name: "Email",
      cell: (row) => <table cellPadding="10px"><td>{row.email}</td></table>
    },
    {
      name: "Subject",
      selector: (row) => row.feedback_subject,
      sortable: true
    },
    {
      name: "Message",
      cell: (row) => (
        <div>
          {row.percentage ? (
            <>
              <ul>
                <li>Satisfaction %age : {row.percentage}</li>
                <li>Satisfaction Level : {row.level}</li>
                
              </ul>
              <button className='btn btn-primary' onClick={()=>{
                  setShowModal(!showModal);
                  let feedback_body = JSON.parse(row.feedback_body)
                  setgenCategory(Object.keys(feedback_body).reduce((object, key) => {
                    if (['Ease of Use','Value for Money',"Recommendation"].includes(key)) {
                      object[key] = feedback_body[key];
                    }
                    return object;
                  }, {}))
              setcommunicationCategory(Object.keys(feedback_body).reduce((object, key) => {
                if (['Customer Support','User Interface',"Timely Updates","Clear Communication"].includes(key)) {
                  object[key] = feedback_body[key];
                }
                return object;
              }, {}))
              setserviceCategory(Object.keys(feedback_body).reduce((object, key) => {
                if (['Resume Boost','Scoring Tools',"Direct Contacts","Diverse Offerings","Personalized Experience","Resource Availability"].includes(key)) {
                  object[key] = feedback_body[key];
                }
                return object;
              }, {}))
              setunknownCategory(Object.keys(feedback_body).reduce((object, key) => {
                if (['Secure and Safe','Satisfaction Level'].includes(key)) {
                  object[key] = feedback_body[key];
                }
                return object;
              }, {}))
                  console.log("ajay",genCategory,communicationCategory)
                }}>View</button>
            </>
          ) : (
            <>
              {rowid !== row.id ? renderHTML(row.feedback_body.substring(0, 100)) : renderHTML(row.feedback_body)}
              {readless !== row.id ? (
                <Nav.Link onClick={() => handledata(row.id)}>
                  <b>...Read more</b>
                </Nav.Link>
              ) : (
                <Nav.Link onClick={() => handlelessdata(row.id)}>
                  <b>...Read less</b>
                </Nav.Link>
              )}
            </>
          )}
        </div>
      ),
      sortable: true
    },
    
    {
      name: "Time",
      selector: (row) => moment(row.created_at).format('MM-DD-YYYY'),
      sortable: true
    }
  ];

  return (
    <div>
      {FromData != null ? (
        <Container fluid className="dash_container">
          <Row>
            <Col md={12} className="pt-4 pb-4">
              <Loader loadmsg="please wait" visibility={loader} />
              <div className="tabpanelOneForm">
                <h3>Notifications</h3>
                <p dangerouslySetInnerHTML={{ __html: help }}></p>
                {Msg !== '' ? <FlashMess m={Msg} mtype={MsgType ? MsgType : 'success'} /> : null}
                <Row>
                  <Col>
                    <select className="form-control" onChange={(e)=>{
                      setCategory(e.target.value);
                    }}>
                      <option value="">All</option>
                      <option value="THANKYOU_NOTE">Thank You Note</option>
                      <option value="30DAYSFEEDBACK">After 30 Days</option>
                      <option value="60DAYSFEEDBACK">After 60 Days</option>
                      <option value="90DAYSFEEDBACK">After 90 Days</option>
                    </select>
                  </Col>
                  <Col>
                    <input
                      className="form-control float-right"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      style={{ maxWidth: '15rem' }}
                    />
                  </Col>
                </Row>
                <DataTable
                  title=""
                  columns={columns}
                  data={notifications}
                  defaultSortField="title"
                  pagination={true}
                  NoDataComponent={"No data"}
                  noHeader={true}
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </div>

              <Modal
					show={showModal}
					onHide={()=>setShowModal(!showModal)}
					dialogClassName=""
					aria-labelledby="example-custom-modal-styling-title"
					className='jobseeker_modal tabform'
					size='lg'
					centered
				>

					<Modal.Header closeButton>
						<Modal.Title>Feedback Detail</Modal.Title>
					</Modal.Header>


					<Modal.Body>
						<div>
							<Row >
              <Col md="6" >
              <h4>General</h4>
									{
                     Object.keys(genCategory).map((key)=>{
                        return <>
                                  <li key={key}>{key}: {genCategory[key]}/5</li>
                               
                        </>
                     })
                  }
                </Col>

                <Col md="6" >
                <h4>Communication</h4>
									{
                     Object.keys(communicationCategory).map((key)=>{
                        return <>
                                  <li key={key}>{key}: {communicationCategory[key]}/5</li>
                               
                        </>
                     })
                  }
                </Col>

                <Col md="6" >
                <h4>Service</h4>
									{
                     Object.keys(serviceCategory).map((key)=>{
                        return <>
                                  <li key={key}>{key}: {serviceCategory[key]}/5</li>
                               
                        </>
                     })
                  }
                </Col>

                <Col md="6" >
                  <br/>
                <h4>Security & Assurance</h4>
									{
                     Object.keys(unknownCategory).map((key)=>{
                        return <>
                                  <li key={key}>{key}: {unknownCategory[key]}/5</li>
                               
                        </>
                     })
                  }
                </Col>
								
							</Row>
							
						</div>
					</Modal.Body>

				</Modal>

            </Col>
          </Row>
        </Container>
      ) : (
        <p>....Loading</p>
      )}
    </div>
  );
};

export default FeedBackNotifications;
