import React, { Component } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import arrower from "../images/arrower.png";
import logo10 from "../images/getback_work.png";
import { job_assist_logo } from "../images";
import { FlashMess, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import "./jobassist.css"
import ReCAPTCHA from "react-google-recaptcha";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import 'react-phone-number-input/style.css'
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import recurator from '../images/recruiters.png';
import TokenVerify from "../Helpers/Helpers";
import {CaptchaKey} from "../Config";



export default class JobAssistSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_logo: "",
      FromData: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        co_name: "",
        co_tagline: "",
        company_weburl: "",
        company_logo: "",
        recapcha: "",
        //otptext: "",
        contact_jobtitle: "",
        confirm_email: "",
        confirm_password: "",
        password: "",
      },
      FromData1: {
        otptext: "",
      },
      FromDataError: {
        firstname: "false",
        lastname: "false",
        email: "false",
        phone: "false",
        co_name: "false",
        co_tagline: "",
        company_weburl: "false",
        company_logo: "false",
        recapcha: "false",
        //otptext: "false",
        contact_jobtitle: "false",
        confirm_email: "false",
        confirm_password: "false",
        password: "false",
      },
      FromDataError1: {
        otptext: "false",
      },
      isValid: false,
      Msg: "",
      MsgType: "",
      loader: "hidden",
      msgAffilicate: "",
      MsgAffiliateType: "",

      apiStatus: "",
      VerifyapiStatus: "",
      liveUrl: "",
      pagesource: "",
      servicetype: "",
      showOtpModal: false,
      inputType: 'password',
      inputTypeConfirm: "password",
      isPasswordValid: false,
      isMailValid: false
    };

    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.handaleChange = this.handaleChange.bind(this);
    this.toggleEye = this.toggleEye.bind(this);
    this.handaleChangeOtp = this.handaleChangeOtp.bind(this);
  }

  // password hide and show
  toggleEye() {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      this.setState({ inputType: 'text' });

    }
    else {
      this.setState({ inputType: 'password' });

    }
  }
  validateForm1(errors) {
    let valid = true;
    let sta = this;

    let FromDataError1 = this.state.FromDataError1;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {

          FromDataError1[key] = "This field is required";
          sta.setState({ FromDataError1 });
        }

        valid = false;
      }
    }

    return valid;
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value === "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
      }
    }

    return valid;
  }

  handaleChangeOtp(data) {
    let sta = this;
    let FromDataError1 = this.state.FromDataError1;

    let name = data.target.name;
    let value = "";
    let FromData1 = this.state.FromData1;

    if (name == "file") {
      let file = data.target.files[0];
      value = file;

      this.toBase64(data.target.files[0]).then((resp) => {
        FromData1[name] = resp.split(",")[1];
        FromData1.filename = file.name;
        FromData1.format = file.type;
      });
    } else {
      value = data.target.value;
      FromData1[name] = value;
    }
    this.setState({ FromData1 }, () => {

    });
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      //console.log(error)
      FromDataError1[name] = error;
      this.setState({ FromDataError1 });

      setTimeout(() => {

      }, 100);
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    const { match, location, history } = this.props;
    this.setState({ liveUrl: location.search });

    console.log(location.search);
    const queryParams = new URLSearchParams(location.search);
    this.setState({
      pagesource: queryParams.get("pagesource")
        ? queryParams.get("pagesource")
        : "",
    });
    this.setState({
      servicetype: queryParams.get("servicetype")
        ? queryParams.get("servicetype")
        : "",
    });
  }

  handaleSubmit(e) {
    e.preventDefault();

    this.sendOTP();
  }


  finalhandaleSubmit() {


    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    let role_id = "16";

    if (this.validateForm(FromDataError)) {
      let formdata = new FormData();
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("email", FromData.email);
      formdata.append("phone", FromData.phone);
      formdata.append("co_name", FromData.co_name);
      // formdata.append("co_tagline", FromData.co_tagline);
      formdata.append("company_weburl", FromData.company_weburl);
      formdata.append("company_logo", FromData.company_logo);
      formdata.append("role_id", role_id);
      formdata.append("contact_jobtitle", FromData.contact_jobtitle);
      formdata.append("password", FromData.password);
      sta.setState({ loader: "visible" });

      let request = {
        end_point: "/registration",
        formdata,
      };
      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" });
          // if (resp.status === "success") {
          //   sta.props.history.push({
          //     pathname: "/login",
          //     state: {
          //       Msg: "Congratulations, you have registered successfully. Please check your mail for more details.",
          //       Msgtype: "succcess",
          //     },
          //   });
          // } else {
          //   sta.setState({ Msg: resp.message.email.join(), MsgType: "danger" });
          // }

          //////////////////////// NEW AUTO LOGIN ////////////////////



          if (resp.status === "success") {
            let email = resp.data.email;
            let password = resp.code;

            //Auto login code
            var loginformdata = new FormData();
            loginformdata.append("email", email);
            loginformdata.append("password", password);

            let request2 = {
              end_point: '/login',
              formdata: loginformdata

            };

            POST_API(request2).then(resp => {

              localStorage.setItem('gs_token', resp.user.token)
              localStorage.setItem('roleID', resp.user.roleID)
              localStorage.setItem('gs_user', JSON.stringify(resp.user))

              if (resp.user.resume_score != null) {
                localStorage.setItem('resumeScore', resp.user.resume_score)
              }

              if (localStorage.getItem("gs_token") != null) {
                localStorage.setItem('profile_image', resp.image)

                localStorage.setItem('dashboardLink', '#/sponser-dash')
                TokenVerify()

                localStorage.setItem('gs_auth_code', resp.user.auth_code)
                localStorage.setItem('roleID', resp.user.roleID)
                localStorage.setItem('get_category', resp.user.job_category)
                localStorage.setItem('profile_image', resp.image)
                this.props.history.push('/sponser-dash')



              }

            }).catch((ee) => {

              console.log('/AutologinERR', ee)
              sta.props.history.push({
                pathname: "/login",
                state: {
                  Msg: "Congratulations, you have registered successfully. Please check your mail for more details.",
                  Msgtype: "succcess",
                },
              });


            });


          } else {
            sta.setState({ Msg: resp.message.email.join(), MsgType: "danger" });
          }








          //////////////////// END AUTO LOGIN ///////////////////////////


        })
        .catch(() => {
          sta.setState({ loader: "hidden" });

          sta.setState({
            Msg: "Something Went Wrong,Please try again later.",
            MsgType: "danger",
          });
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
      this.closeMsg();
    }
  }

  handaleChange(data) {
    let FromDataError = this.state.FromDataError;

    let { name, value } = data.target;

    let FromData = this.state.FromData;

    if (name === "company_logo") {
      value = data.target.files[0];
      this.setState({ company_logo: value });
    }
    else if (name == "password" || name == "confirm_password") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.password == FromData.confirm_password) {
        FromDataError['confirm_password'] = "";
        this.setState({ isPasswordValid: true, FromDataError });
      } else {
        if (FromData.confirm_password.length < 6) {
          FromDataError['confirm_password'] = "password should be of at least 6 characters";
        } else {
          FromDataError['confirm_password'] = "Confirmed Password should be same as password";
        }
        this.setState({ isPasswordValid: false, FromDataError });

      }

    } else if (name == "email" || name == "confirm_email") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.email == FromData.confirm_email) {
        FromDataError['confirm_email'] = "";
        this.setState({ isMailValid: true, FromDataError });
      } else {

        // FromDataError['confirm_email'] = "Confirm email don't match";
        FromDataError['confirm_email'] = "Confirmed e-mail don't match";

        this.setState({ isMailValid: false, FromDataError });

      }

    }

    FromData[name] = value;

    this.setState({ FromData });

    var valid_obj = {
      value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError });
    });
  }


  handlePhoneChange = (value) => {
    let { FromDataError, FromData } = this.state;
    if (value !== undefined) {
      FromData.phone = value;
      this.setState({ FromData });

      //let number = parsePhoneNumber(value);
      // if (number) {
      //   let country_code = "+" + number.countryCallingCode;
      //   let mobile = number.nationalNumber;
      //   this.setState({ country_code, mobile });
      // }
      if (isValidPhoneNumber(value)) {
        FromDataError.phone = "";

      } else {
        FromDataError.phone = "Enter Valid Mobile Number";

      }
      this.setState({ FromDataError });
    } else {
      FromDataError.phone = "Enter Valid Mobile Number";

      this.setState({ FromDataError });
    }
  };

  sendOTP = () => {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on
      var formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.pagesource || "");

      let request2 = {
        end_point: "/email-otp-send",
        formdata: formdata,
      };

      POST_API(request2)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let respData = resp.status;
            console.log("respData", respData);
            sta.setState({ apiStatus: respData });
            sta.setState({ Msg: "One Time Passcode sent successfully. Please check your email", MsgType: "success" });
            this.setState({ showOtpModal: true })
          } else {
            let respData = resp.status;
            sta.setState({ apiStatus: respData });
            sta.setState({ Msg: resp.message.email_id.join(), MsgType: "danger" });
            this.closeMsg();
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
        });
    } else {
      sta.setState({ Msg: "Please enter valid email", MsgType: "danger" });
      this.closeMsg()
    }
  };

  verifyOTP = () => {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromDataError1 = this.state.FromDataError1;
    let FromData = this.state.FromData;
    let FromData1 = this.state.FromData1;

    if (this.validateForm1(FromDataError1)) {
      sta.setState({ loader: "visible" }); ///loader on
      var formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.pagesource);
      formdata.append("otp", FromData1.otptext);

      let request2 = {
        end_point: "/email-otp-verify",
        formdata: formdata,
      };

      POST_API(request2)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let respData = resp.status;
            sta.setState({ VerifyapiStatus: respData });
            sta.setState({ Msg: "One Time Passcode Verified", MsgType: "success" });
            this.finalhandaleSubmit();
          } else {
            let respData = resp.status;
            sta.setState({ VerifyapiStatus: respData });
            sta.setState({ Msg: "One Time Passcode not valid!", MsgType: "danger" });
            this.closeMsg();
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
          this.closeMsg();
        });
    } else {
      sta.setState({ Msg: "Please enter a valid Passcode", MsgType: "danger" });
      this.closeMsg();
    }
  };

  capchaChange = (value) => {
    console.log("Captcha value:", value);
    let { FromData, FromDataError } = this.state;
    FromData.recapcha = value;
    FromDataError.recapcha = "";
    this.setState({ FromData, FromDataError }, () => {
      this.validateForm(FromDataError);
    });
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      A one time code will be sent to your email. Please use the code to validate you email ID.
    </Tooltip>
  );
  closeMsg = (duration = 2000) => {
    setTimeout(() => {
      this.setState({ Msg: "", MsgType: "", step1Msg: "", step1MsgType: "" });
    }, duration);
  };


  onshowModal() {
    if (this.state.showOtpModal) {
      this.setState({ showOtpModal: false })

    } else {
      this.setState({ showOtpModal: true })
    }
  }
  render() {
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
    let { FromData, FromDataError } = this.state;
    return (
      <div>
        <div className="Becomesponsorfromarea" style={{ "padding-top": "20px", "padding-bottom": "20px" }}>
          <Container>
            <div>
              <span className="d-flex justify-content-center container-max align-items-center">
                <img src={job_assist_logo} className="fix_logo_align" />
                <img src={arrower} className="arrow_fix mx-4" />
                <img src={logo10} className="fix_logo_align" />
              </span>
            </div>

            {/* <div className="pr-8" style={{ margin: "50px 0px 39px 0px" }}> */}
            <h2>Company Administrator Registration</h2>
            <p>Please fill in your details</p>
            {/* </div> */}

            {/* <div className="form_block"> */}
            <Row>
              <Col lg={12} className="sponsorfrom p-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                {this.state.msgAffilicate && (
                  <>
                    <FlashMess
                      m={this.state.msgAffilicate}
                      mtype={
                        this.state.MsgAffiliateType
                          ? this.state.MsgAffiliateType
                          : "success"
                      }
                    />
                  </>
                )}
                {this.state.Msg && (
                  <>
                    <FlashMess
                      m={this.state.Msg}
                      mtype={this.state.MsgType ? this.state.MsgType : "success"}
                    />
                  </>
                )}

                {/* <div className="tabpanelOneForm"> */}
                <Form className="tabform" onSubmit={this.handaleSubmit}>
                  <Row className="m-0 mt-4 mb-4">
                    <Col md={7}>
                      <Row>
                        <Col md={12} className="tabformLeft">
                          <h5>Personal Info</h5>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>First Name <span className="text-danger">*</span> </Form.Label>
                            <Form.Control
                              type="text"
                              name="firstname"
                              value={FromData.firstname}
                              onChange={this.handaleChange}
                              placeholder="Enter your first name"
                              validaterule={["required", "isString"]}
                              validatemsg={[
                                "This field is required",
                                "First name must contain alphabets",
                              ]}
                            />
                            <p style={{ color: "red" }}>
                              {FromDataError.firstname === "false"
                                ? ""
                                : FromDataError.firstname}
                            </p>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              type="text"
                              name="lastname"
                              value={FromData.lastname}
                              onChange={this.handaleChange}
                              placeholder="Enter your last name"
                              validaterule={["required", "isString"]}
                              validatemsg={[
                                "This field is required",
                                "Last name must contain alphabets",
                              ]}
                            />
                            <p style={{ color: "red" }}>
                              {FromDataError.lastname === "false"
                                ? ""
                                : FromDataError.lastname}
                            </p>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail" className="d-flex flex-wrap justify-content-between">
                            <div className="email_box_set">
                              <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={FromData.email}
                                onChange={this.handaleChange}
                                placeholder="Enter your email"
                                validaterule={["required", "isEmail"]}
                                validatemsg={[
                                  "This field is required",
                                  "Input a valid Email",
                                ]}
                              />
                              <p style={{ color: "red" }}>
                                {FromDataError.email === "false"
                                  ? ""
                                  : FromDataError.email}
                              </p>
                            </div>
                            {/* <Button type="button" 
                                              variant="otp_btn"
                                              onClick={this.sendOTP} className="otp_btn">
                                            <OverlayTrigger
                                              placement="top"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={this.renderTooltip}>
                                                <i className="fa-solid fa-circle-question"></i>
                                            </OverlayTrigger>  Confirm Email</Button> */}
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              Confirm Email
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="d-flex w-100 verify_field">
                              <Form.Control
                                type="email"
                                name="confirm_email"
                                value={FromData.confirm_email}
                                onChange={this.handaleChange}
                                placeholder="Enter confirm email"
                                validaterule={["required", "isEmail", 'same|' + FromData.email]}
                                validatemsg={["This field is required", "Invalid Email", "Confirmed e-mail don't match"]}
                              />
                              {this.state.isMailValid &&
                                <div className="verify_icon d-flex align-items-center">
                                  <i className="fa fa-check-circle" aria-hidden="true"></i>
                                </div>

                              }
                            </div>
                            <p style={{ color: "red" }}>
                              {this.state.FromDataError.confirm_email == "false"
                                ? ""
                                : this.state.FromDataError.confirm_email}
                            </p>

                          </Form.Group>

                          {/* <Form.Group
                                  controlId="formBasicEmail"
                                  className="d-flex flex-wrap justify-content-between">
                                  <div className="email_box_set">
                                    <Form.Label>
                                    One Time Code
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="otptext"
                                      value={FromData.otptext}
                                      onChange={this.handaleChange}
                                      placeholder="Enter one time code"
                                      validaterule={["required"]}
                                      validatemsg={[
                                        "This field is required",
                                        "Invalid One Time Code",
                                      ]}
                                    />
                                    <p style={{ color: "red" }}>
                                      {this.state.FromDataError.otptext == "false"
                                        ? ""
                                        : this.state.FromDataError.otptext}
                                    </p>
                                  </div>
                                  <Button
                                            type="button"
                                            onClick={this.verifyOTP}
                                            variant="valid_otp_btn"
                                            className={
                                              this.state.VerifyapiStatus == "success"
                                                ? "verified_otp_btn"
                                                : this.state.VerifyapiStatus == "failed"
                                                ? "error_btn_fix"
                                                : "valid_otp_btn"
                                            }
                                            disabled={
                                              this.state.VerifyapiStatus == "success"
                                            }
                                          >
                                            {this.state.VerifyapiStatus == "success"
                                              ? "Email Verified"
                                              : "Verify Email"}
                                          </Button>
                                </Form.Group> */}

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Phone <span className="text-danger">*</span></Form.Label>
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              value={FromData.phone}
                              onChange={this.handlePhoneChange}
                            />

                            <p style={{ color: "red" }}>
                              {FromDataError.phone === "false"
                                ? ""
                                : FromDataError.phone}
                            </p>
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              Password
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type={this.state.inputType}
                              id='password'
                              name="password"
                              onChange={this.handaleChange}
                              value={FromData.password}
                              placeholder="Enter Password"
                              validaterule={["required", "password"]}
                              validatemsg={[
                                "This field is required",
                                "password should be of at least 6 characters",
                              ]}
                            />
                            <i style={{ float: 'right', marginTop: '-30px', marginRight: '8px', position: 'relative', zIndex: 1, cursor: 'pointer' }} onClick={this.toggleEye}>{this.state.inputType == 'text' ? eyeSlash : eye}</i>

                            <p style={{ color: "red" }}>
                              {this.state.FromDataError.password == "false"
                                ? ""
                                : this.state.FromDataError.password}
                            </p>
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>

                            <div className="d-flex w-100 verify_field">

                              <Form.Control
                                id='confirm_password'
                                type={this.state.inputTypeConfirm}
                                name="confirm_password"
                                onChange={this.handaleChange}
                                placeholder="Confirm Password"
                                value={FromData.confirm_password}
                                validaterule={[
                                  "required",
                                  "password",
                                  "same|" + FromData.password,
                                ]}
                                validatemsg={[
                                  "This field is required",
                                  "password should be of at least 6 characters",
                                  "Confirmed Password should be same as password",
                                ]}
                              />

                              {this.state.isPasswordValid &&
                                <div className="verify_icon d-flex align-items-center">
                                  <i className="fa fa-check-circle" aria-hidden="true"></i>
                                </div>

                              }
                            </div>


                            <p style={{ color: "red" }}>
                              {this.state.FromDataError.confirm_password == "false"
                                ? ""
                                : this.state.FromDataError.confirm_password}
                            </p>
                          </Form.Group>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>HR Manager Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              type="text"
                              name="contact_jobtitle"
                              value={FromData.contact_jobtitle}
                              onChange={this.handaleChange}
                              placeholder="Enter Full Name"
                              validaterule={["required", "isString"]}
                              validatemsg={["This field is required"]}
                            />
                            <p style={{ color: "red" }}>
                              {FromDataError.contact_jobtitle === "false"
                                ? ""
                                : FromDataError.contact_jobtitle}
                            </p>
                          </Form.Group>


                        </Col>

                        <Col md={12} className="tabformRight">
                          <hr />
                          <h5>Company Info</h5>

                          {/* <Form.Row> */}
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              type="text"
                              name="co_name"
                              value={FromData.co_name}
                              onChange={this.handaleChange}
                              placeholder="Enter company name"
                              validaterule={["required"]}
                              validatemsg={["This field is required"]}
                            />
                            <p style={{ color: "red" }}>
                              {FromDataError.co_name === "false"
                                ? ""
                                : FromDataError.co_name}
                            </p>
                          </Form.Group>

                          {/* <Form.Group as={Col} controlId="formGridState">
                                  <Form.Label>Company Tagline</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="co_tagline"
                                    value={FromData.co_tagline}
                                    onChange={this.handaleChange}
                                    placeholder="Enter Company Tagline"
                                    validaterule={["required"]}
                                    validatemsg={["This field is required"]}
                                  />

                                  <p style={{ color: "red" }}>
                                    {FromDataError.co_tagline === "false"
                                      ? ""
                                      : FromDataError.co_tagline}
                                  </p>
                                </Form.Group> */}
                          {/* </Form.Row>
                                <Form.Row> */}


                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Website URL <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              type="text"
                              name="company_weburl"
                              value={FromData.company_weburl}
                              onChange={this.handaleChange}
                              placeholder="Enter Website Url"
                              validaterule={["required", "org_web_url_check"]}
                              validatemsg={[
                                "This field is required",
                                "Invalid URL",
                              ]}
                            />
                            <p style={{ color: "red" }}>
                              {FromDataError.company_weburl === "false"
                                ? ""
                                : FromDataError.company_weburl}
                            </p>
                          </Form.Group>

                          <Form.Group controlId="formGridState">
                            <Form.Label>Company Logo <span className="text-danger">*</span></Form.Label>
                            <Form.File
                              id="custom-file-translate-scss"
                              validaterule={[
                                "required",
                                "filesize|250000",
                                "mime|jpeg/png/jpg",
                              ]}
                              accept="image/png, image/jpg, image/jpeg"
                              validatemsg={["This field is required"]}
                              name="company_logo"
                              onChange={this.handaleChange}
                              label={this.state.company_logo.name || "Choose File"}
                              lang="en"
                              custom
                            />

                            <p style={{ color: "red" }}>
                              {FromDataError.company_logo === "false"
                                ? ""
                                : FromDataError.company_logo}
                            </p>
                          </Form.Group>
                          {/* </Form.Row> */}
                          <Form.Group controlId="formBasicEmail">
                            <ReCAPTCHA
                              sitekey={CaptchaKey}
                              onChange={this.capchaChange}
                            />

                            <p style={{ color: "red" }}>
                              {this.state.FromDataError.recapcha == "false"
                                ? ""
                                : this.state.FromDataError.recapcha}
                            </p>
                          </Form.Group>


                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-main tabformbutton"
                          >
                            Submit
                          </Button>

                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <img src={recurator} className="img-fluid" />
                    </Col>
                  </Row>
                </Form>
                {/* </div> */}
              </Col>
            </Row>
            {/* </div> */}
            <Modal show={this.state.showOtpModal}
              backdrop="static"
              keyboard={false}
              onHide={() => { this.onshowModal() }}
              id="modal"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>OTP Verification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <Form
                  className="tabform"

                >
                  <Row className="m-0 mt-4 mb-4">
                    <Col md={12} className="pr-5 ">

                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                      <Form.Group
                        controlId="formBasicEmail"
                        as={Col}
                        className="field_box"
                      >
                        <div className="otp_field">
                          <Form.Label>
                            One Time Passcode
                            <span className="text-danger"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="otptext"
                            value={this.state.FromData1.otptext}
                            onChange={this.handaleChangeOtp}
                            placeholder="Enter one time passcode"
                            validaterule={["required"]}
                            validatemsg={["This field is required", "Invalid one time passcode"]}
                          />
                          <p style={{ color: "red" }}>
                            {this.state.FromDataError1.otptext == "false"
                              ? ""
                              : this.state.FromDataError1.otptext}
                          </p>
                        </div>

                      </Form.Group>

                    </Col>

                    <Col md={3} className="">
                      <Button

                        variant="primary"
                        type="button"
                        className="btn btn-primary btn-md tabformbutton"
                        onClick={this.verifyOTP}
                      >
                        Submit
                      </Button>
                    </Col>

                  </Row>
                </Form>
              </Modal.Body>

            </Modal>
          </Container>
        </div>
      </div>
    );
  }
}
