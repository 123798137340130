import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import Leftpanel from "./Section/LeftPanel";
import DataTable from "react-data-table-component";
import { BASE_URL } from "../Config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SponsorDashboardViewModal from './Section/SponsorDashboardViewModal'
import logo1 from "../Jobseeker/images/logo1.png";
import logo2 from "../Jobseeker/images/logo2.png";
import logo3 from "../Jobseeker/images/logo3.png";
import logo4 from "../Jobseeker/images/logo4.png";
import logo5 from "../Jobseeker/images/logo5.png";
import logo7 from "../Jobseeker/images/logo7.png";
import logo9 from "../Jobseeker/images/logo9.png";
import logo10 from "../Jobseeker/images/logo10.png";
import logo11 from "../Jobseeker/images/logo11.png";
import logo12 from "../Jobseeker/images/logo12.png";
import logo13 from "../Jobseeker/images/logo13.png";
import logo14 from "../Jobseeker/images/logo14.png";
import moment from "moment";
export default class SponsershipList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {},
      FromDataError: {},
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/jobseeker-list",
      member_id: 0,
      memberDashModal: false,
      totalRows: 0,
      offset: 1,
      limit: 10,
      searchValue: '',
    };

    //Binding
  }
  handleClose(member_id = 0) {


    if (this.state.memberDashModal) {
      this.setState({ memberDashModal: false })
    }
    else {
      this.setState({ member_id: member_id, memberDashModal: true })
    }
  }

  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '70px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Sponsor Code",
      selector: 'referral_code',
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => (row.firstname || "") + " " + (row.lastname || ""),
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Register At",
      // selector: (row) => row.created_at,
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Last Active",
      // selector: (row) => row.last_activity,
      selector: row => moment(row.last_activity).format('MM-DD-YYYY'),
      width: '120px',
      sortable: true,
      wrap: true,
    },
    // {
    //   name: "Site Registered",
    //   cell: row => {

    //     return (
    //       <h2 data-tag="allowRowEvents"></h2>
    //     )
    //   }, 
    // },
    
    {
      name: (<div title='Success Percentage is calculated based on Total number of services Activated and Used by the Jobseeker.'>Success (%) <i className="fa fa-info-circle" aria-hidden="true"></i></div>),
      cell: row => {
        let countServices = 4;
        if(row?.select_all_web_tab?.resume_sending == '1') countServices ++;
        if(row?.select_all_web_tab?.resume_certified == '1') countServices ++;
        if(row?.select_all_web_tab?.resume_scoring == '1') countServices ++;
        if(row?.select_all_web_tab?.job_alerts == '1') countServices ++;
        if(row?.select_all_web_tab?.hr_contacts == '1') countServices ++;
        if(row?.select_all_web_tab?.lifeguru_coach == '1') countServices ++;
        if(row?.select_all_web_tab?.free_ebooks == '1') countServices ++;
        if(row?.select_all_web_tab?.social_profile_view == '1') countServices ++;
        if(row?.select_all_web_tab?.reqruiter_list == '1') countServices ++;
        if(row?.select_all_web_tab?.career_master == '1') countServices ++;
        if(row?.select_all_web_tab?.job_seeker_news == '1') countServices ++;
        if(row?.select_all_web_tab?.job_assist == '1') countServices ++;

        return (
          <div className="badge badge-info"> {((countServices * 100) / 15).toFixed(2) }%</div>
        )
      },
    },
    {
      name: "Action",
      cell: row => {
        // console.log("row",row)
        return (
          <div>
            <Button style={{ marginLeft: "5px" }} variant="primary mr-1" onClick={() => this.handleClose(row.userID)}>View</Button>
          </div>
        )
      },
      // width: '130px',

    }
  ];

  handlePageChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/jobseeker-list?offset=${page}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let FromData = resp.data;
          console.log("response data", resp.data);
          //this.setState({ totalRows: resp.data.total });
          this.setState({ FromData });
        } else {
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };


  handlePerRowsChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/jobseeker-list?offset=${this.state.offset}&limit=${page}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let FromData = resp.data;
          console.log("response data", resp.data);
          this.setState({ limit: page });
          this.setState({ FromData });
        } else {
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };

  handleSearchChange = async (searchValue) => {
    //this.setState({ offset: page });
    console.log(searchValue);
    let request = {
      end_point: `/jobseeker-list?searchValue=${searchValue}&offset=${this.state.offset}&limit=10`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let FromData = resp.data;
          console.log("response data", resp.data);
          //this.setState({ totalRows: resp.data.total });
          this.setState({ FromData });
        } else {
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    let request = {
      end_point: `/jobseeker-list?offset=${this.state.offset}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          FromData = resp.data;
          console.log(resp.total);
          console.log("response data", resp.data);
          sta.setState({ totalRows: resp.total });
          sta.setState({ FromData });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });

    //help api
    let requestHelp = {
      end_point: "/help-details?key=sponsor_jobseeker_list",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });




  }


  //     ExpandedComponent = () => {
  //     console.log('ggggggggg')
  //     return (
  //       <>
  //         <pre>{JSON.stringify(, null, 2)}</pre>


  //       </>
  //     );
  //  };
  // ExpandedComponent = ( data ) => 
  // <pre>{JSON.stringify(data, null, 2)}</pre>;

  render() {
    let FromData = this.state.FromData;



    return (
      <div>
        {FromData != null ? (
          <>
            <Container fluid className="dash_container">
              <Row>
                {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
                <Col md={12} className="pt-4 pb-4">
                  <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />
                  <div className="tabpanelOneForm pt-3">
                    {(this.state.member_id != 0) ? (<>
                      <SponsorDashboardViewModal {...this.props} member_id={this.state.member_id} show={this.state.memberDashModal} close={() => this.handleClose()} />
                    </>) : (<></>)}  </div>
                  <div className="tabpanelOneForm">
                    {localStorage.getItem("roleID") != undefined && localStorage.getItem("roleID") == 16 ? (<>

                      <h3>Job Outplacement List</h3>
                      {/* <p>Find below the names of all the sponsored job outplaced candidates along with their Sponsor codes and other details. This list will help you keep track of the total number of jobseekers you have sponsored.</p> */}
                      <p>Please find below the list of all the sponsored outplaced employees. With our Jobseeker list, you can track the total number of jobseekers sponsored.</p>
                    </>) : (<><h3>Jobseeker List</h3></>)}
                    <p dangerouslySetInnerHTML={{ __html: this.state.help, }}></p>

                    {this.state.Msg !== "" ? (
                      <>
                        <FlashMess
                          m={this.state.Msg}
                          mtype={
                            this.state.MsgType ? this.state.MsgType : "success"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}


                    <div className="table_search" style={{ float: "right", margin: '10px' }}>
                      Search :
                      <input
                        className="form-control float-right"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => this.handleSearchChange(e.target.value)}
                        style={{ maxWidth: '15rem' }}
                      />
                    </div>



                    <DataTable className="table__main"
                      title=""
                      columns={this.columns}
                      data={FromData}
                      expandableRows={true}
                      expandableRowsComponent={<ExpandedComponent />}
                      defaultSortField="title"
                      pagination={true}
                      NoDataComponent={"No data"}
                      noHeader={true}
                      paginationServer
                      paginationTotalRows={this.state.totalRows}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>

    );
  }
}
const ExpandedComponent = props => <div className="badge_area">

  {props?.data?.select_all_web_tab?.resume_sending == '1' ?
    <div className="logo_item" title="Resume Sending"><div className="sponsor_logo"><img src={logo4} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.resume_sending_date}</p>
    </div> :
    <div className="logo_item" title="Resume Sending"><div className="logo_inactive sponsor_logo"><img src={logo4} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.resume_certified == '1' ?
    <div className="logo_item" title="Resume Certified"><div className="sponsor_logo"><img src={logo2} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.resume_certified_date}</p>
    </div> :
    <div className="logo_item" title="Resume Certified"><div className="logo_inactive sponsor_logo"><img src={logo2} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.resume_scoring == '1' ?
    <div className="logo_item" title="Resume Scoring"><div className="sponsor_logo"><img src={logo1} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.resume_scoring_date}</p>
    </div> :
    <div className="logo_item" title="Resume Scoring"><div className="logo_inactive sponsor_logo"><img src={logo1} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.job_alerts == '1' ?
    <div className="logo_item" title="Job Alerts"><div className="sponsor_logo"><img src={logo3} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.job_alerts_date}</p>
    </div> :
    <div className="logo_item" title="Job Alerts"><div className="logo_inactive sponsor_logo"><img src={logo3} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.hr_contacts == '1' ?
    <div className="logo_item" title="HR Contacts"><div className="sponsor_logo"><img src={logo5} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.hr_contacts_date}</p>
    </div> :
    <div className="logo_item" title="HR Contacts"><div className="logo_inactive sponsor_logo"><img src={logo5} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.lifeguru_coach == '1' ?
    <div className="logo_item" title="Coach Master"><div className="sponsor_logo"><img src={logo9} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.lifeguru_coach_date}</p>
    </div> :
    <div className="logo_item" title="Coach Master"><div className="logo_inactive sponsor_logo"><img src={logo9} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.free_ebooks == '1' ?
    <div className="logo_item" title="FreeEbooks"><div className="sponsor_logo"><img src={logo7} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.free_ebooks_date}</p>
    </div> :
    <div className="logo_item" title="FreeEbooks"><div className="logo_inactive sponsor_logo"><img src={logo7} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.social_profile_view == '1' ?
    <div className="logo_item" title="Social Profile Views"><div className="sponsor_logo"><img src={logo11} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.social_profile_view_date}</p>
    </div> :
    <div className="logo_item" title="Social Profile Views"><div className="logo_inactive sponsor_logo"><img src={logo11} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.reqruiter_list == '1' ?
    <div className="logo_item" title="Requiter's List"><div className="sponsor_logo"><img src={logo10} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.reqruiter_list_date}</p>
    </div> :
    <div className="logo_item" title="Requiter's List"><div className="logo_inactive sponsor_logo"><img src={logo10} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.career_master == '1' ?
    <div className="logo_item" title="Career Master"><div className="sponsor_logo"><img src={logo12} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.career_master_date}</p>
    </div> :
    <div className="logo_item" title="Career Master"><div className="logo_inactive sponsor_logo"><img src={logo12} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.job_seeker_news == '1' ?
    <div className="logo_item" title="Jobseeker News Hub"><div className="sponsor_logo"><img src={logo13} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.job_seeker_news_date}</p>
    </div> :
    <div className="logo_item" title="Jobseeker News Hub"><div className="logo_inactive sponsor_logo"><img src={logo13} className="img-fluid" /></div></div>
  }
  {props?.data?.select_all_web_tab?.job_assist == '1' ?
    <div className="logo_item" title="Job Assist"><div className="sponsor_logo"><img src={logo14} className="img-fluid" /></div>
      <p style={{ fontSize: "10px" }}>{props?.data?.select_all_web_tab?.job_assist_date}</p>
    </div> :
    <div className="logo_item" title="Job Assist"><div className="logo_inactive sponsor_logo"><img src={logo14} className="img-fluid" /></div></div>
  }
  {/* <div style={{fontWeight: "bold"}}>Active Date and Time: {props?.data?.last_activity}</div> */}

</div>;


