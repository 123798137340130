import React, { Component } from 'react';

import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';

import { API_URL } from '../Config';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";






class PasswordChangeJobseeker extends Component {

  constructor(props) {
    super(props)
    this.state = {
      FromData: {
        password: '',
        confirm_password: ''

      },
      FromDataError:
      {
        password: 'false',
        confirm_password: 'false'


      },
      isValid: false,
      Msg: '',
      MsgType: '',
      token: '',
      loadingMsg: '....Loading',
      loader: 'hidden',
      urlPath: '/jobseeker-password-change',
      help: '',
      inputType: 'password',



    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)

  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  toggleEye = () => {

    if (this.state.inputType === "password") {
      this.setState({ inputType: 'text' });

    }
    else {
      this.setState({ inputType: 'password' });

    }
  }


  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    //from data update state
    let name = data.target.name;
    let value = data.target.value;
    let FromData = this.state.FromData;
    FromData[name] = value;


    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }



    validation(valid_obj).then((error) => {



      FromDataError[name] = error
      this.setState({ FromDataError })


      this.setState({ FromData })
    })
    // console.log("password", FromData.password);
    // console.log("confirm password", FromData.confirm_password);



  }
  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let formBody = [];


    if (this.validateForm(FromDataError)) {
      var formdata = new FormData();

      formdata.append("password", FromData.password);
      formdata.append("password_confirmation", FromData.confirm_password);

      let request = {
        end_point: '/change-password',
        formdata: formdata,
        token: localStorage.getItem("gs_token")
      };


      POST_API(request).then((resp) => {

        if (resp.status == 'success') {
          console.log('change_password', resp)

          sta.setState({ Msg: 'Password Changed Successfully!', MsgType: 'success' })

          setTimeout(() => {
            sta.setState({ Msg: '', MsgType: 'success' })
            window.location.reload()

          }, 3000);


        }
        else {
          //console.log(resp.message.password.join())
          sta.setState({ Msg: resp.message, MsgType: 'danger' })
        }

      }).catch(() => {

      })


    }
    else {
      sta.setState({ Msg: 'Fill the form correctly!' })
      sta.setState({ MsgType: 'danger' })
    }




  }

  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;


    let token = 'a'




    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }

    //help api
    let requestHelp = {
      end_point: '/help-details?key=jobseeker_change_password',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })


  }



  render() {

    let { token, loadingMsg } = this.state;
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

    return (





      <Container fluid className="dash_container">
        <Row>
          {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}

          <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />


            <div className="tabpanelOneForm pt-3">
              <Card className="p-5 body_card">
                <h3>Change Password </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.help
                  }}></p>



                <Row className="m-0 mt-4 mb-4">


                  <Col md={12}>
                    {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                    {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}



                    <Form onSubmit={this.handaleSubmit} className="tabform">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>

                        <Form.Control
                          type={this.state.inputType}
                          className="password"
                          name="password"
                          onChange={this.handaleChange}
                          placeholder="New Password"
                          validaterule={['required', 'password']}
                          validatemsg={['This field is required', 'password should be of at least 6 characters']}
                        />
                        <i style={{ marginTop: '-38px', marginRight: '16px', position: 'absolute', zIndex: 1, cursor: 'pointer', right: '8px', bottom: '165px' }} onClick={() => { this.toggleEye() }}>{this.state.inputType == 'text' ? eyeSlash : eye}</i>

                        <p style={{ color: 'red' }}>{this.state.FromDataError.password == 'false' ? '' : this.state.FromDataError.password}</p>
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>

                        <Form.Control type="password"
                          className="password"
                          name="confirm_password"
                          onChange={this.handaleChange}
                          placeholder="Confirm Password"
                          validaterule={['required', 'password', 'same|' + this.state.FromData.password]}
                          validatemsg={['This field is required', 'password should be of at least 6 characters', 'Confirmed Password should be same as password']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.confirm_password == 'false' ? '' : this.state.FromDataError.confirm_password}</p>
                      </Form.Group>

                      <Row className='justify-content-center'>
                        <Col md={6}>
                          <Button type="submit" className="btn-main btn-block">
                            Change Password
                          </Button>
                        </Col>
                      </Row>

                    </Form>


                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>

    );
  }
}

export default PasswordChangeJobseeker;