import React, { useState,useEffect } from 'react';
import { Form, Button, Col,Row } from 'react-bootstrap';
import  TokenVerify,{ FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";

const FeedbackForm = (props) => {
  const questions = [
    {
      type : "gen",
      id: 1,
      question: 'Ease of Use',
      description: 'Did you find our platform user-friendly and easy to navigate?',
    },
    {
      type : "gen",
      id: 2,
      question: 'Value for Money',
      description: 'Do you believe the services offered provide good value for the price?',
    },
    {
      type : "communication",
      id: 3,
      question: 'Customer Support',
      description: 'Were you satisfied with the responsiveness and helpfulness of our Knowledge Base?',
    },
    {
      type : "gen",
      id: 4,
      question: 'Recommendation',
      description: 'Would you recommend our platform to a friend or colleague?',
    },
    {
      type : "service",
      id: 5,
      question: 'Resume Boost',
      description: 'Did you find our AI optimization tools helpful to rewrite or enhance you resume ?',
    },
    {
      type : "service",
      id: 6,
      question: 'Scoring Tools',
      description: 'Did the scores received for your resume and social profile assist you in making both stronger?',
    },
    {
      type : "service",
      id: 7,
      question: 'Direct Contacts',
      description: 'Did receiving contact information for HR managers and recruiters prove helpful in your job search?',
    },
    {
      type : "communication",
      id: 8,
      question: 'User Interface',
      description: 'Did our platform\'s design make your experience enjoyable and intuitive?',
    },
    {
      type : "communication",
      id: 9,
      question: 'Timely Updates',
      description: 'Have you appreciated the timely updates and enhancements we\'ve made to our platform?',
    },
    {
      type : "service",
      id: 10,
      question: 'Diverse Offerings',
      description: 'Were you pleased with the variety and diversity of features and services we offer?',
    },
    {
      type : "service",
      id: 11,
      question: 'Personalized Experience',
      description: 'Did you find our platform tailored to your individual needs and preferences?',
    },
    {
      type : "unknown",
      id: 12,
      question: 'Secure and Safe',
      description: 'Did you feel that your data and privacy were well-protected on our platform?',
    },
    {
      type : "communication",
      id: 13,
      question: 'Clear Communication',
      description: 'Did you find our communications, notifications, and updates clear and easy to understand?',
    },
    {
      type : "service",
      id: 14,
      question: 'Resource Availability',
      description: 'Were you able to easily access and utilize the resources {{career books, career master videos, coach master webinars and  jobseekernewshubb newsletter}} provided ?',
    },
    {
      type : "unknown",
      id: 15,
      question: 'Satisfaction Level',
      description: 'On a scale of 1 to 5, with 5 being the highest, how satisfied are you with our platform and its offerings?',
    },
  ];
  const gen = questions.filter(item=>item.type=='gen');
  const communication = questions.filter(item=>item.type=='communication');
  const service = questions.filter(item=>item.type=='service');
  const unknown = questions.filter(item=>item.type=='unknown');

  const [message,setMessage] = useState({});
  const [surveyData, setSurveyData] = useState({
      'Ease of Use' : 5,
      'Value for Money': 5,
      'Customer Support': 5,
      'Recommendation': 5,
      'Resume Boost': 5,
      'Scoring Tools': 5,
      'Direct Contacts': 5,
      'User Interface': 5,
      'Timely Updates': 5,
      'Diverse Offerings': 5,
      'Personalized Experience': 5,
      'Secure and Safe': 5,
      'Clear Communication': 5,
      'Resource Availability': 5,
      'Satisfaction Level': 5,
});

  const handleRatingChange = (questionId, rating) => {
    setSurveyData((prevData) => ({
      ...prevData,
      [questionId]: rating,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
            var formdata = new FormData();
            formdata.append("surveyData", JSON.stringify(surveyData));
            formdata.append("type", type);
            let request = {
              end_point : '/create-notification-new',
              formdata:formdata,
              token: localStorage.getItem("gs_token")

            };  

            POST_API(request).then(resp=>{

              if(resp.status == 'success')
              {
                setMessage({Msg:'Thank you for submitting your feedback',MsgType:'success'});
                let user = JSON.parse(localStorage.getItem("gs_user"))
                user[days] = '1';
               
                localStorage.setItem("gs_user",JSON.stringify(user))
                setTimeout(() => {
                  window.location.href= "#/jobseeker-dash"
                 }, 3000);
              }
              else{
                setMessage({Msg:'Notification Sent Failed',MsgType:'danger'})
              }
              }).catch((ee)=>{
                console.log(ee)
                setMessage({Msg:'Notification Sent Failed',MsgType:'danger'})
              });
  };

  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [amount,setAmount] = useState("");

  useEffect(()=>{
      let request = {
          end_point: "/get-subscriptions",
          token: localStorage.getItem("gs_token"),
      };
  
      GET_API(request)
          .then((resp) => {
              localStorage.setItem('mysubscriptions',JSON.stringify(resp.data))
              setEnd(resp.data[0].end);
              setStart(resp.data[0].start);
          });
},[]);

const startNew = new Date(start);
const today = new Date();

const difference = today - startNew;
const daysPassed = difference / (1000 * 60 * 60 * 24);
const calculateDiff = Math.floor(daysPassed);

  
  let days = "";
  let type = "";
  let dayRecords = 0;
  if(calculateDiff >= 30 && calculateDiff < 59){
    type = "30DAYSFEEDBACK";
    days = "30days";
    dayRecords = 30;
  }else if(calculateDiff >= 60 && calculateDiff < 90){
    type = "60DAYSFEEDBACK";
    days = "60days";
    dayRecords = 60;
  }else{
    type = "90DAYSFEEDBACK";
    days = "90days";
    dayRecords = 90;
  }
    
  return (
    <div className="pt-5">
      <h2>{dayRecords} Day Feedback Form</h2>

      {message?.Msg !==''? (<><FlashMess m={message?.Msg} mtype={message.MsgType ? message.MsgType:'success'} /></>):(<></>)}
      <Form onSubmit={handleSubmit}>
      <Row>
      <Col  md={4}>
       <Row>
       <h4>General</h4>
      {gen.map((question) => (
        <Col  md={12} key={question.id}>
          <p> <strong>{question.question} </strong> : {question.description}</p>
          <input
            type="range"
            min="1"
            max="5"
            required
            value={surveyData[question.question] || 4}
            onChange={(e) => handleRatingChange(question.question, parseInt(e.target.value))}
          />
          <span>{surveyData[question.question] || 4}</span>
        </Col>
      ))}
       <h4>Security & Assurance</h4>
    {unknown.map((question) => (
        <Col  md={12} key={question.id}>
          <p> <strong>{question.question} </strong> : {question.description}</p>
          <input
            type="range"
            min="1"
            max="5"
            required
            value={surveyData[question.question] || 4}
            onChange={(e) => handleRatingChange(question.question, parseInt(e.target.value))}
          />
          <span>{surveyData[question.question] || 4}</span>
        </Col>
      ))} 
      </Row>
      </Col>

      <Col  md={4} >
       <Row>
       <h4>Communication</h4>
      {communication.map((question) => (
        <Col  md={12} key={question.id}>
          <p> <strong>{question.question} </strong> : {question.description}</p>
          <input
            type="range"
            min="1"
            max="5"
            required
            value={surveyData[question.question] || 4}
            onChange={(e) => handleRatingChange(question.question, parseInt(e.target.value))}
          />
          <span>{surveyData[question.question] || 4}</span>
        </Col>
      ))}
      </Row>
      </Col>
      <Col  md={4} >
       <Row>
       <h4>Service</h4>
      {service.map((question) => (
        <Col  md={12} key={question.id}>
          <p> <strong>{question.question} </strong> : {question.description}</p>
          <input
            type="range"
            min="1"
            max="5"
            required
            value={surveyData[question.question] || 4}
            onChange={(e) => handleRatingChange(question.question, parseInt(e.target.value))}
          />
          <span>{surveyData[question.question] || 4}</span>
        </Col>
      ))}
      </Row>
      </Col>
      </Row>
      
        <Button variant="primary" type="submit">
          Submit
        </Button>
        &nbsp;&nbsp;&nbsp;
        {
          ((calculateDiff >=30 && calculateDiff <=43)
          || (calculateDiff >=60 && calculateDiff <=63)
          || (calculateDiff >=90 && calculateDiff <=93))
          &&
              <Button variant="primary" onClick={()=>{
                  localStorage.setItem('isSkipped','true');
                  window.location.href= "#/jobseeker-dash"
              }}> Skip For Now
              </Button>
         }

         
      </Form>
    </div>
  );
};

export default FeedbackForm;
