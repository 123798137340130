import React, { Component, useEffect,useState } from 'react';
import './middleSection.css';
import FreeBooks from '../FreeEbooks';
import JobAlert from '../JobAlert';
import TopFiveCoaches from '../TopFiveCoaches';
import TopInstructors from "../TopInstructors";
import HrContacts from '../HrContacts';
import ResumeScoring from '../ResumeScoring';
import ResumeCertified from '../ResumeCertified';
import ResumeSending from '../ResumeSending';
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import Mytimer from "../../Mytimer/Mytimer";
import { Link as LinkScroll } from "react-scroll"
import JobseekerPayment from '../JobseekerPayment'
import ResumeUpload from '../Section/ResumeUpload'
import ResumeUploadLinkedin from '../Section/ResumeUploadLinkedin'
import PaymentConfirmModel from '../Section/PaymentConfirmModel'
import validation from "../../Validate/validator";
import { BASE_URL,API_URL,CaptchaKey,ResumeScoringURL,SocialScoringURL,JobAlertsURL,CoachmasterURL,ResumeCertifiedURL,ResumeSendingURL,JobseekerURL,resumeboostURL,resumecertified_FRONT_URL,resumesending_FRONT_URL,coachmaster_FRONT_URL,careermaster_FRONT_URL,jobalerts_FRONT_URL,careerbooks_FRONT_URL,jobseekernewshubb_FRONT_URL,recruiterdirect_FRONT_URL,hrdirect_FRONT_URL,STUDENT_PROMO2 } from '../../Config';



import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import ReactPlayer from 'react-player'

import { Link } from "react-router-dom";

import Support from "../images/standing-resume-man.gif";
import logo1 from "../images/resume_scoring_blue.png";
import logo2 from "../images/Resume_Certified.png";
import logo3 from "../images/JAlerts.png";
import logo4 from "../images/Resume_Sending.png";
import logo5 from "../images/hri-direct-blue@9x.png";
import logo6 from "../images/logo6.png";
import logo7 from "../images/career_book.png";
import logo8 from "../images/logo8.png";
import logo9 from "../images/coach_master.png";
import logo10 from "../images/recruiter_direct.png";
import logo11 from "../images/social_profile_scoring_1_2.png";
import logo12 from "../images/career_master.png";
import logo13 from "../images/jobseeker_horizontal9x.png";
import logo14 from "../images/logo14.png";
import Get_Logo from "../images/get_logo.png";

import pcimg from "../images/big_window_device.png";

import coverletter from "../images/cover-letter.png";
import resume_tem from "../../images/resume_tem.png";
import resuemerewrite from "../images/resume-re-write.png";
import resuemewrite from "../../images/mail_writer.png";




import logo_1 from "../../images/logo1.png";
import logo_2 from "../../images/logo2.png";
import logo_3 from "../../images/logo3.png";
import logo_4 from "../../images/logo4.png";
import logo_5 from "../../images/logo5.png";
import logo_6 from "../../images/logo6.png";
import logo_7 from "../../images/logo7.png";
import logo_8 from "../../images/logo8.png";
import logo_9 from "../../images/logo9.png";
import logo_10 from "../../images/logo10.png";
import logo_11 from "../../images/logo11.png";
import logo_12 from "../../images/logo12.png";



import HR_direct_short from "../images/HR-direct-short.png";
import recruit_direct_short from "../images/recruit-direct-short.png";
import job_seeker_short from "../images/job-seeker-short.png";
import career_book_short from "../images/career-book-short.png";
import social_profile_scoring_short from "../images/social-profile-scoring-short.png";
import resume_scoring_short from "../images/resume-scoring-short.png";
import jobalert_short from "../images/jobalert-short.png";
import career_master_short from "../images/career-master-short.png";
import Coach_master_short from "../images/Coach-master-short.png";
import resume_sending_short from "../images/resume-sending-short.png";
import Resume_certified_short from "../images/Resume-certified-short.png";








import moment from 'moment';
import Resumecertifiedimg from "../images/Resume_Certified1_Edit.jpg"
var _ = require('lodash');


class middleSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_student : 0,
      activateError :  false,
      ShowHideSupportModal : false,
      resumescoring_show: false,
      resumecertyfied_show: false,
      resumesending_show: false,
      jobalerts_show: false,
      hrContacts_show: false,
      coachmaster_show: false,
      ebooks_show: false,
      rrewrite_show: false,
      reqruiter_show: false,
      social_views: false,
      career_master: false,
      job_seeker_news_hub: false,
      job_assist: false,
      width: 0, height: 0, sponsorDetails: "",

      coachval: 0,
      scoreval: 0,
      sendval: 0,
      certifiedval: 0,
      hrcontactsVal: 0,
      jobAlertVal: 0,
      ebooksVal: 0,
      resumeReformatVal: 0,
      reqruiterVal: 0,
      socialProfileViewVal: 0,
      careerMasterVal: 0,
      jobSeekerNewsVal: 0,
      jobAssistVal: 0,
      isIbutton: false,
      packageId: 0,
      sponsored_user: 0,
      hide: true,


      PaymentModal: false,
      userData: {},
      bundaleSite: {},
      resumeUpload: false,
      ResumeUploadLinkedin: false,
      PaymentConfirm: false,
      mysubscriptions: [],
      purchaseDetails: {},
      allSitesPackages: [],
      loader: "",
      packageIdArr: [],
      apiLoad: false,
      confirmDetails: {},
      cover_letter_show_modal: false,
      email_writer_show_modal: false,
      resume_re_writer_show_modal: false,
      resume_optimizer_show_modal: false,
      ai_content: '',
      coverletter_pdf: '',
      hide: true,
      sectionClick: "",


      cover_letter_form: false,
      FromData_ai: {
        company_name_ai: "",
        position_ai: "",
        description_ai: "",
        skil_ai: "",
      },
      FromDataError_ai: {
        company_name_ai: "false",
        position_ai: "false",
        description_ai: "false",
      },




    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handaleChangeForAi = this.handaleChangeForAi.bind(this);
    this.handaleSubmitForAi = this.handaleSubmitForAi.bind(this);






    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };


    this.handaleSwitchModal = (e) => {
      this.setState({ sectionClick: e });
      let userDetails = JSON.parse(localStorage.getItem("gs_user"))
      let field = '';
      let link = "";
      let link2 = "";
      let country = localStorage.getItem("Country") == null ? '' : localStorage.getItem("Country");
      let packId = 1;
      switch (e) {
        case 'resumescoring_show': packId = 2; break;
        case 'resumesending_show': packId = 4; break;
        case 'jobalerts_show': packId = 5; break;
        case 'social_views': packId = 6; break;
        case 'coachmaster_show': packId = 7; break;
        case 'career_master': packId = 8; break;
        case 'resumecertyfied_show': packId = 3; break;

        default:
          break;
      }

      if (
        (e == 'resumescoring_show' ||
          e == 'resumesending_show' ||
          e == 'jobalerts_show' ||
          // e == 'social_views' ||
          e == 'coachmaster_show' ||
          e == 'career_master' ||
          e == 'resumecertyfied_show')
        &&
        this.state.sponsored_user == 1 &&
        this.state.userData.bigdata_id == null &&
        (this.state.packageIdArr.includes(packId) || this.state.packageIdArr.includes(1))
      ) {

        this.switchResumeModal()
        return;
      }
      console.log(
        e == 'social_views',
        this.state.userData.linkedin_resume_url == null,
        this.state.packageIdArr.includes(packId), this.state.packageIdArr.includes(1)
      )
      if (
        (e == 'social_views') &&
        this.state.userData.linkedin_resume_url == null &&
        (this.state.packageIdArr.includes(packId) || this.state.packageIdArr.includes(1))
      ) {

        this.switchLinkedinResumeModal()
        return;
      }

      switch (e) {
        case "resumescoring_show": field = "scoreval"; link = ResumeScoringURL +"/direct-login/token/"+localStorage.getItem('gs_token'); break;
        case "resumesending_show": field = "sendval"; link = ResumeSendingURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token'); break;
        case "jobalerts_show":
          let get_category = JSON.parse(localStorage.getItem('get_category'))
          let selecteddata = "";
          if(get_category){
              selecteddata = get_category[0].map(category => category.value).join(",");
          }
          selecteddata = selecteddata != "undefined" ? selecteddata : "";
          field = "jobAlertVal";
          link = JobAlertsURL+"/view_job?job_type=" + encodeURIComponent(selecteddata) + "&location=" + country + "&email=" + userDetails.email + "&promo=&refcode=&gwi_token=" + localStorage.getItem("gs_token");
          break;
        case "hrContacts_show": field = "hrcontactsVal"; link2 = "/jobseeker-hrcontacts"; break;
        case "coachmaster_show": field = "coachval"; link = CoachmasterURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token'); break;
        case "ebooks_show": field = "ebooksVal"; link2 = "/free-ebooks"; break;
        case "rrewrite_show": field = "resumeReformatVal"; link2 = "/jobseeker-dash"; break;
        case "reqruiter_show": field = "reqruiterVal"; link2 = "/recruiter-list"; break;
        case "resumecertyfied_show": field = "certifiedval"; link = ResumeCertifiedURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token'); break;
        case "social_views": field = "socialProfileViewVal"; link = SocialScoringURL+"/direct-login/token/" + localStorage.getItem('gs_token'); break;
        case "career_master": field = "careerMasterVal"; link = CoachmasterURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?slug=sessions&token=" + localStorage.getItem('gs_token'); break;
        // case "career_master": field = "careerMasterVal"; link2 = "/career-master"; break;
        case "job_seeker_news_hub": field = "jobSeekerNewsVal"; link = JobseekerURL+"/"; break;
        case "job_assist": field = "jobAssistVal"; link2 = "/#"; break;

        default:
          break;
      }

      if (this.state[field] == 1 && this.state.isIbutton == false && this.state[e] == false) {
        if (link != "") { //console.log('kkkk')
          window.open(link, "_blank")
        }
        else {
          //console.log('rrrrrrrr')
          this.props.history.push({ pathname: link2 })

        }
      }
      else {
        this.setState({ isIbutton: false })
        let d = this.state[e]
        this.setState({ [e]: !d })
      }


    }





  }




  // componentDidMount() {
  //   setTimeout(
  //     function() {
  //       this.setState({ hide: false});
  //       alert("test")
  //     }
  //     .bind(this),
  //     1000
  //   );
  // }






  logoManage = (packageId) => {
    switch (packageId) {
      case 1: return logo_12;
      case 2: return logo_6;
      case 3: return logo_1;
      case 4: return logo_2;
      case 5: return logo_5;
      case 6: return logo_7;
      case 7: return logo_3;
      case 8: return logo_4;
      case 9: return logo_8;
      case 10: return logo_10;
      case 11: return logo_11;


        break;

      default:
        break;
    }
  }
  switchResumeModal = () => {
    this.setState({ resumeUpload: !this.state.resumeUpload })
  }
  switchCoverSectionModal = (e) => {
    switch (e) {
      case "cover_letter_show_modal": if (this.state.cover_letter_show_modal) { this.setState({ cover_letter_show_modal: false }); } else { this.setState({ cover_letter_show_modal: true, email_writer_show_modal: false, resume_re_writer_show_modal: false, resume_optimizer_show_modal: false }); } break;
      case "email_writer_show_modal": if (this.state.email_writer_show_modal) { this.setState({ email_writer_show_modal: false }); } else { this.setState({ email_writer_show_modal: true, cover_letter_show_modal: false, resume_re_writer_show_modal: false, resume_optimizer_show_modal: false }); } break;
      case "resume_re_writer_show_modal": if (this.state.resume_re_writer_show_modal) { this.setState({ resume_re_writer_show_modal: false }); } else { this.setState({ resume_re_writer_show_modal: true, email_writer_show_modal: false, cover_letter_show_modal: false, resume_optimizer_show_modal: false }); } break;
      case "resume_optimizer_show_modal": if (this.state.resume_optimizer_show_modal) { this.setState({ resume_optimizer_show_modal: false }); } else { this.setState({ resume_optimizer_show_modal: true, email_writer_show_modal: false, cover_letter_show_modal: false }); } break;


      default:
        break;
    }
  }

  switchCoverSectionform = () => {

    this.setState({ cover_letter_form: !this.state.cover_letter_form })

  }


  switchLinkedinResumeModal = () => {
    this.setState({ ResumeUploadLinkedin: !this.state.ResumeUploadLinkedin })
  }
  switchConfirmModal = (details = {}) => {
    this.setState({ PaymentConfirm: !this.state.PaymentConfirm, confirmDetails: details })
  }
  handleClose = (siteDetails = {}) => {
    console.log('siteDetails', siteDetails)
    if (this.state.PaymentModal) {
      this.setState({ PaymentModal: false })
    }
    else {
      this.setState({ purchaseDetails: siteDetails, PaymentModal: true }, () => {
        console.log(this.state.userData)
      })
      // if(this.state.isIbutton == true)
      // {
      //   this.setState({
      //     resumescoring_show: false,
      //     resumecertyfied_show: false,
      //     resumesending_show: false,
      //     jobalerts_show: false,
      //     hrContacts_show: false,
      //     coachmaster_show: false,
      //     ebooks_show: false,
      //     rrewrite_show: false,
      //     reqruiter_show: false,
      //     social_views: false,
      //     career_master: false,
      //     job_seeker_news_hub: false,
      //   })

      // }

    }
  }

  getPackagesList = () => {
    let ths = this
    return new Promise((resolve, reject) => {

      let request = {
        end_point: '/list-sites',
        token: localStorage.getItem("gs_token")

      };




      GET_API(request).then(resp => {

        let bundaleSite = _.find(resp.data, { 'id': 13 });
        let allSitesPackages = resp.data
        ths.setState({ bundaleSite, allSitesPackages })
        resolve()

      }).catch((error) => {
        console.log(error)
        reject()
      })


    })
  }

  forceOpenPurchaseModal = () => {
    let details = _.find(this.state.allSitesPackages, { 'site_short_name': localStorage.getItem("servicetype") })
    if (!this.state.packageIdArr.includes(1) && !this.state.packageIdArr.includes(details.package_id)) {
      this.switchConfirmModal(details)
      // this.handleClose(details)

    }


  }

  closeHeading = () => {
    this.setState({ hide: false })
  }

  userdataUpdate = () => {
    let sta = this;
    return new Promise(function (resolve, reject) {
      let ud = localStorage.getItem("gs_user")
      ud = JSON.parse(ud)
      sta.setState({ userData: ud }, () => {
        resolve();
      })
    });
  }



  async componentDidMount() {

    setTimeout(() => (this.setState({ hide: false })), 10000)
    localStorage.setItem('mysubscriptions', []);
    await TokenVerify()
    let ud = localStorage.getItem("gs_user")
    ud = JSON.parse(ud)
    let packageId = ud.package_id
    let sponsored_user = ud.sponsored_user
    this.setState({ userData: ud, packageId, sponsored_user,is_student: ud.is_student})
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // Get users subscription details
    await this.getMysubscriptions()
    // Get Package details
    await this.getPackagesList()






    let request = {
      end_point: '/getSelectedTab',
      token: localStorage.getItem("gs_token"),


    };
    this.setState({ loader: 'visible' })
    GET_API(request).then(resp => {
      this.setState({ loader: 'hidden' })
      // console.log("joblist",resp)
      // this.setState({joblist:resp.jobs})
      this.setState({
        coachval: resp.lifeguru,
        scoreval: resp.resumescoring,
        sendval: resp.resumesending,
        certifiedval: resp.resumecertified,
        hrcontactsVal: resp.hr_contacts,
        jobAlertVal: resp.job_alerts,
        ebooksVal: resp.free_ebooks,
        resumeReformatVal: resp.resume_reformat,
        reqruiterVal: resp.reqruiter_list,
        socialProfileViewVal: resp.social_profile_view,
        careerMasterVal: resp.career_master,
        jobSeekerNewsVal: resp.job_seeker_news,
        jobAssistVal: resp.job_assist,
      })
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })
    });

    if (localStorage.getItem("servicetype")) {
      this.forceOpenPurchaseModal()
    }


  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);


  }


  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight - 15 });
  }




  resumeCertifiedsubmit = () => {
    this.setState({ certifiedval: 1, resumecertyfied_show: false });
    var formdata = new FormData();
    formdata.append("flag", 1);
    let request = {
      end_point: '/ResumecertifiedSelected',
      token: localStorage.getItem("gs_token"),
      formdata: formdata


    };
    POST_API(request).then(resp => {
      window.open(ResumeCertifiedURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token'), "_blank")
    }).catch((ee) => {

    });
  }
  resumescoring_submit = () => {
    this.setState({ scoreval: 1, resumescoring_show: false });
    let newThis = this;
    var formdata = new FormData();
    formdata.append("flag", 1);
    let request = {
      end_point: '/ResumeScoringSelected',
      token: localStorage.getItem("gs_token"),
      formdata: formdata


    };
    POST_API(request).then(resp => {
      if(resp.message == "success"){
         window.open(ResumeScoringURL+"/direct-login/token/" + localStorage.getItem('gs_token'), "_blank")
      }else{
        newThis.setState({ scoreval: 0, resumescoring_show: false ,activateError :  resp.message});
        window.scrollTo(0, 0);
        setTimeout(function(){
          newThis.setState({ activateError :  false});
        },3000);
      }
    }).catch((ee) => {

    });
  }
  resumesending_submit = () => {
    this.setState({ sendval: 1, resumesending_show: false });
    var formdata = new FormData();
    formdata.append("flag", 1);
    let request = {
      end_point: '/ResumeSendingSelected',
      token: localStorage.getItem("gs_token"),
      formdata: formdata


    };

    POST_API(request).then(resp => {

      window.open(ResumeSendingURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token'), "_blank")
    }).catch((ee) => {

    });
  }
  coachmaster_submit = () => {
    this.setState({ coachval: 1, coachmaster_show: false });
    var formdata = new FormData();
    formdata.append("flag", 1);
    let request = {
      end_point: "/lifeguruCoachSelected",
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };

    POST_API(request)
      .then((resp) => {

        window.open(
          CoachmasterURL+"/direct-login/" +
          localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token'),
          "_blank"
        );
      })
      .catch((ee) => { });
  }
  othersAppSubmit = (e) => {
    let userDetails = JSON.parse(localStorage.getItem("gs_user"))
    let field = '';
    let modalField = '';
    let link = "";
    let link2 = "";
    switch (e) {
      case "hrcontactsVal": field = "hr_contacts"; modalField = "hrContacts_show"; link2 = "/jobseeker-hrcontacts"; break;
      case "jobAlertVal": field = "job_alerts"; modalField = "jobalerts_show";
        let get_category = JSON.parse(localStorage.getItem('get_category'))
        // let selecteddata = get_category != null ? get_category.map(x => x.value) : '';
        let selecteddata = get_category != null ? get_category[0].value : '';
        selecteddata = selecteddata != "undefined" ? selecteddata : "";
        let country = localStorage.getItem("Country") == null ? "" : localStorage.getItem("Country")
          link = JobAlertsURL+"/view_job?job_type=" + encodeURIComponent(selecteddata) + "&location=" + country + "&email=" + userDetails.email + "&promo=&refcode=&gwi_token=" + localStorage.getItem("gs_token");
        break;
      case "ebooksVal": field = "free_ebooks"; modalField = "ebooks_show"; link2 = "/free-ebooks"; break;
      case "resumeReformatVal": field = "resume_reformat"; modalField = "rrewrite_show"; link2 = "/jobseeker-dash"; break;
      case "reqruiterVal": field = "reqruiter_list"; modalField = "reqruiter_show"; link2 = "/recruiter-list"; break;
      case "socialProfileViewVal": field = "social_profile_view"; modalField = "social_views"; link = SocialScoringURL+"/direct-login/token/" + localStorage.getItem('gs_token'); break;
      case "careerMasterVal": field = "career_master"; modalField = "career_master"; link = CoachmasterURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?slug=sessions&token=" + localStorage.getItem('gs_token'); break;
      case "jobSeekerNewsVal": field = "job_seeker_news"; modalField = "job_seeker_news_hub"; link2 = "/#"; break;
      case "jobAssistVal": field = "job_assist"; modalField = "job_assist"; link2 = "/#"; break;

      default:
        break;
    }

    this.setState({ [e]: 1, [modalField]: false });
    var formdata = new FormData();
    formdata.append("flag", 1);
    formdata.append("key", field);
    let request = {
      end_point: "/others-app-selected",
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };

    POST_API(request)
      .then((resp) => {

        if (link != "") {
          window.open(link, "_blank")
        }
        else {
          this.props.history.push({ pathname: link2 })

        }
      })
      .catch((ee) => { });



  }

  checkTrialPeriod = ()=>{
    let packageIdArr = this.state.mysubscriptions.filter((e)=> e.is_trial == "Y");
    if(packageIdArr.length == 0) return false;
    let gs_user = JSON.parse(localStorage.getItem("gs_user"));
    if(gs_user.is_trial == "Y"){
      let givenTimestamp = gs_user.trial_end;
      let currentDateTime = moment();
      let givenDateTime = moment(givenTimestamp, 'YYYY-MM-DD HH:mm:ss');
      let isGreater = givenDateTime.isAfter(currentDateTime);
      return isGreater
    }
    return false;
  }

  accessCheckPackageWise = (site) => {
    let userData = this.state.userData
    let packageIdArr = this.state.mysubscriptions.map(e => e.package_id);
    // if (this.state.sponsored_user == 1) {
    if (packageIdArr.length == 0) {
      return false;
    }
    else if (packageIdArr.includes(1) && this.state.is_student == 0 &&  this.checkTrialPeriod()) {
      switch (site) {
        case "resumescoring": return true; break;
        case "resumecertified":  return false;  break;
        case "resumesending":  return true;  break;
        case "jobalerts": return true;  break;
        case "socialprofileview": return true; break;
        case "coachmaster": return true; break;
        case "careermaster": return true;  break;
        case "freeebooks": return true; break;
        case "reqruiterlist":  return true; break;
        case "hrcontacts":  return true; break;
        case "jobseekernewshub": return true; break;
        default:
          break;
      }
    }
    else if (packageIdArr.includes(1) && this.state.is_student == 0) {
      return true;

    }else if(packageIdArr.includes(1) && this.state.is_student == 1){
        switch (site) {
          case "resumescoring": return true; break;
          case "resumecertified":  return false;  break;
          case "resumesending":  return false;  break;
          case "jobalerts": return true;  break;
          case "socialprofileview": return true; break;
          case "coachmaster": return true; break;
          case "careermaster": return false;  break;
          case "freeebooks": return true; break;
          case "reqruiterlist":  return true; break;
          case "hrcontacts":  return true; break;
          case "jobseekernewshub": return true; break;
          default:
            break;
        }
    }
    else {
      switch (site) {
        case "resumescoring": if (packageIdArr.includes(2)) { return true; } else { return false } break;
        case "resumecertified": if (packageIdArr.includes(3)) { return true; } else { return false } break;
        case "resumesending": if (packageIdArr.includes(4)) { return true; } else { return false } break;
        case "jobalerts": if (packageIdArr.includes(5)) { return true; } else { return false } break;
        case "socialprofileview": if (packageIdArr.includes(6)) { return true; } else { return false } break;
        case "coachmaster": if (packageIdArr.includes(7)) { return true; } else { return false } break;
        case "careermaster": if (packageIdArr.includes(8)) { return true; } else { return false } break;
        case "freeebooks": if (packageIdArr.includes(9)) { return true; } else { return false } break;
        case "reqruiterlist": if (packageIdArr.includes(10)) { return true; } else { return false } break;
        case "hrcontacts": if (packageIdArr.includes(11)) { return true; } else { return false } break;
        case "jobseekernewshub": if (packageIdArr.includes(12)) { return true; } else { return false } break;

        default:
          break;
      }

    }
    // }
    // else {
    //   return true
    // }

  }



  getMysubscriptions = () => {
    let thhis = this
    return new Promise((resolve, reject) => {


      let request = {
        end_point: "/get-subscriptions",
        token: localStorage.getItem("gs_token"),
      };

      console.log("......request", request);

      GET_API(request)
        .then((resp) => {
          // this.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            console.log("Update")
            thhis.setState({ mysubscriptions: resp.data }, () => {

              let packageIdArr = thhis.state.mysubscriptions.map(e => e.package_id);
              thhis.setState({ packageIdArr, apiLoad: true })
            })

            localStorage.setItem('mysubscriptions', JSON.stringify(this.state.mysubscriptions));

          }

          resolve()

        }).catch((ee) => {

          console.log('/getMySubscription error', ee)
          resolve()


        });

    })
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError_ai = this.state.FromDataError_ai;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError_ai[key] = "This field is required";
          sta.setState({ FromDataError_ai });
        }

        valid = false;
        console.log("loop", valid);
      }
    }

    console.log("funct", valid);

    return valid;
  }


  handaleSubmitForAi(e) {
    e.preventDefault();

    let sta = this;
    let FromDataError_ai = this.state.FromDataError_ai;
    let FromData_ai = this.state.FromData_ai;

    if (this.validateForm(FromDataError_ai)) {
      sta.setState({ loader: "visible" }); ///loader on
      var formdata = new FormData();
      formdata.append("company_name", FromData_ai.company_name_ai);
      formdata.append("position", FromData_ai.position_ai);
      formdata.append("job_description", FromData_ai.description_ai);
      formdata.append("skills", FromData_ai.skil_ai);

      let request = {
        end_point: "/cover-letter-generate-gpt",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off
          this.setState({ ai_content: resp.data });
        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });

    }
  }


  handaleChangeForAi(data) {
    let sta = this;
    let FromDataError_ai = this.state.FromDataError_ai;

    let name = data.target.name;
    let value = "";
    let FromData_ai = this.state.FromData_ai;
    value = data.target.value;
    FromData_ai[name] = value;

    this.setState({ FromData_ai }, () => { });

    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError_ai[name] = error;
      this.setState({ FromDataError_ai }); //from error state
    });
    console.log(FromData_ai);
    console.log(FromDataError_ai);
  }

  coverLetterDownload = () => {
    if (this.state.ai_content != '') {
      this.setState({ loader: "visible" }); ///loader on
      var formdata = new FormData();
      formdata.append("content", this.state.ai_content);

      let request = {
        end_point: "/cover-letter-content-download",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      POST_API(request)
        .then((resp) => {
          ///loader off
          this.setState({ coverletter_pdf: resp.data })
          setTimeout(() => {
            document.getElementById('coverLetterLink').click();
            this.setState({ loader: "hidden" });
          }, 3000);

        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });

    }
  }

  visitLinkRedirect = (link) => {
    window.open(link, '_blank');

  }






  render() {




    return (
      <div>


        {/* {this.state.mysubscriptions.length > 0 && (<>
          <div className='topbar_active_package d-flex align-items-center justify-content-end mb-3'>
            <h5 className='mr-3'>Active Packages:</h5>


            {this.state.mysubscriptions.map((sub) => {
              return (<>

                <div className='active_package_item'>
                  <img src={this.logoManage(sub.pack.id)} className="img-fluid" />

                  <div className='active_package_item_details d-flex flex-column'>
                    <img src={this.logoManage(sub.pack.id)} className="img-fluid" />
                    <span className="mt-3">Time Remaining</span>
                    <Mytimer expiryTimestamp={sub.end} />
                  </div>
                </div>


              </>)
            })}
          </div>
        </>

        )} */}



        <Loader loadmsg="please wait" visibility={this.state.loader} />
        {(this.state.resumeUpload == true) ? (<>
          <ResumeUpload {...this.props} modalShow={this.state.resumeUpload} switchResumeModal={this.switchResumeModal} switchHandaleModal={this.handaleSwitchModal} sectionClick={this.state.sectionClick} userdataUpdate={this.userdataUpdate} />
        </>) : (<></>)}
        {(this.state.ResumeUploadLinkedin == true) ? (<>
          <ResumeUploadLinkedin {...this.props} modalShow={this.state.ResumeUploadLinkedin} switchResumeModal={this.switchLinkedinResumeModal} switchHandaleModal={this.handaleSwitchModal} sectionClick={this.state.sectionClick} userdataUpdate={this.userdataUpdate} />
        </>) : (<></>)}
        {(this.state.PaymentConfirm == true) ? (<>
          <PaymentConfirmModel {...this.props} modalShow={this.state.PaymentConfirm} pack={this.state.confirmDetails} confirm={() => { this.handleClose(this.state.confirmDetails) }} switchConfirmModal={this.switchConfirmModal} />
        </>) : (<></>)}

        {this.state.sponsored_user == 1 && this.state.mysubscriptions.length > 0 &&

          (

            <>
              {/* new heading */}
              {(this.state.mysubscriptions[0].pack.package_name != "Complete Jobseeker Bundle" || this.state.is_student == 1) ? <Container className={this.state.hide ? ' congra_head' : 'congra_head hide'}>
                <div className='closeHeading' onClick={this.closeHeading}>X</div>
                <h3 className='bundle_package_partts_head'>Congratulations !!! </h3>
                <p>You have purchased {this.state.mysubscriptions.length == 1 ? this.state.mysubscriptions[0].pack.package_name : "multiple services"}. Please Activate each of the products marked as Inactive/Purchased and then visit them below.</p>
              </Container> : <Container className={this.state.hide ? ' congra_head' : 'congra_head hide'}>
                <div className='closeHeading' onClick={this.closeHeading}>X</div>
                <p>Great job on purchasing the Complete Jobseeker Bundle! To fully benefit from all the amazing resources it has to offer, please activate each of the products marked as Inactive/Purchased, and then explore them below.</p>
              </Container>}



              {/* old heading */}

              {/* {this.state.mysubscriptions[0].pack.package_name !== "Complete Jobseeker Bundle" ? <Container className='congra_head'>
                <h3 className='bundle_package_partts_head'>Congratulations !!! </h3>
                <p>You have purchased {this.state.mysubscriptions.length == 1 ? this.state.mysubscriptions[0].pack.package_name : "multiple services"}. Please Activate each of the products marked as Inactive/Purchased and then visit them below.</p>
              </Container> : <Container className='congra_head'>
                <p>Great job on purchasing the Complete Jobseeker Bundle! To fully benefit from all the amazing resources it has to offer, please activate each of the products marked as Inactive/Purchased, and then explore them below.</p>
              </Container>} */}

              {/* <Container className='congra_head'>
                <h3>
                Great job on purchasing the Complete Jobseeker Bundle! To fully benefit from all the amazing resources it has to offer, please activate each of the products marked as Inactive/Purchased, and then explore them below.
                </h3>
              </Container> */}


            </>

          )}
        {/* {this.state.mysubscriptions.length > 0 && (<>

          <div className='bundle_package_partts'>
            <Container>
              <Row>
                {this.state.mysubscriptions.map((sub) => {
                  return (<>
                    <Col className='mt-3' md={3}>
                      <LinkScroll to="scrollDownToActiveService" smooth={true} duration={1000}>
                        <div className='current_active_box bundle_package_top justify-content-center h-100'>
                          <div className='logobox_inner_text logobox_inner_scroll'>

                            <div className='icon_logo'>
                              <img src={this.logoManage(sub.pack.id)} />
                            </div>

                            {sub.end != null ? (
                              <div className='text-center mb-1'>
                                <span className="mb-3">Time Remaining</span>
                                <Mytimer expiryTimestamp={sub.end} />
                              </div>
                            ) : (
                              <div className='text-center mb-1'>
                                <span className="mb-3">Lifetime service</span>

                              </div>
                            )}
                          </div>
                        </div>
                      </LinkScroll>
                    </Col>
                  </>)
                })}
              </Row>
            </Container>
          </div>

        </>

        )} */}



        {(this.state.userData != '' && this.state.PaymentModal == true) ? (<>

          <JobseekerPayment {...this.props} userDetails={this.state.userData} paymentDetails={this.state.purchaseDetails} show={this.state.PaymentModal} is_student = {this.state.is_student} promoCode={this.state.is_student == 1 ? STUDENT_PROMO2 : ``} close={() => this.handleClose()} reloadAfterPay={true} />
        </>) : (<></>)}
        {this.state.sponsored_user == 1 && this.state.apiLoad && (!this.state.packageIdArr.includes(1) || this.state.is_student == 1) &&

          (<div className='bundle_package_partts'>

            <Container>
              <div className='bundle_package_inner'>

                <Row className='ml-0 mr-0 align-items-center'>
                  <div className='logo_box_inner_img mb-3'>
                    <img src={Get_Logo} />
                  </div>
                  <div className='logobox_inner_text mb-3'>
                    <h2>Jobseeker Bundle</h2>
                    {/* <p>You can still unlock all these features with our Jobseeker Bundle Offer!!!
                      Price <span>$99</span> for all the other services you haven't purchased.</p> */}
                    <p>ACT NOW to unlock the inactive services by upgrading to the Complete Jobseeker Bundle for this limited-time offer of <span>$99</span>!</p>
                  </div>
                </Row>

                <div className='d-flex logos_upgrade_block'>
                  <div className='upgradelogos d-flex align-items-center mr-3'>
                    <Container fluid>
                      <Row>
                        <div className='items'>
                          <img src={logo_1} />
                        </div>
                        <div className='items'>
                          <img src={logo_2} />
                        </div>
                        <div className='items'>
                          <img src={logo_3} />
                        </div>
                        <div className='items'>
                          <img src={logo_4} />
                        </div>
                        <div className='items'>
                          <img src={logo_5} />
                        </div>
                        <div className='items'>
                          <img src={logo_6} />
                        </div>
                        <div className='items'>
                          <img src={logo_7} />
                        </div>
                        <div className='items'>
                          <img src={logo_8} />
                        </div>
                        <div className='items'>
                          <img src={logo_9} />
                        </div>
                        <div className='items'>
                          <img src={logo_10} />
                        </div>
                        <div className='items'>
                          <img src={logo_11} />
                        </div>
                        <div className='items'>
                          <img src={logo_12} />
                        </div>
                      </Row>
                    </Container>
                  </div>




                      { JSON.parse(localStorage.getItem("gs_user")).is_trial == "Y" ?
                      <a href="/#/renew-subscription" className="add_account pt-3 pb-3 btn btn-primary">Renew Now</a>
                        :
                        <Button onClick={() => this.handleClose(this.state.bundaleSite)} className="add_account ml-auto">UPGRADE NOW</Button>
                      }



                </div>
              </div>
            </Container>
          </div>)}
        <Container fluid>

          <Row>
          { this.checkTrialPeriod() ? <p className="text-danger mt-3">Resumecertified is currently not a part of the FREE TRIAL. It will become active upon successful payment of $99 after the Free Trial is over</p> : ""} 

            {this.state.activateError && 
              <Col xs={12} className={"mt-5 p-3 bg-light"}>
                    <p className='text-danger'>{this.state.activateError}</p>
              </Col>
            }
            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("resumecertified") ? "mt-5" : "mt-5 unpaidCover btn_fix"}
              id={this.accessCheckPackageWise("resumecertified") ? "scrollDownToActiveService" : ""}>
              { }
              {this.accessCheckPackageWise("resumecertified") ? (<>
                <span className="activetextOn">{this.state.certifiedval == 0 ? 'Inactive / Purchased' : 'Active / Purchased'} </span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('resumecertyfied_show'); }} className='learn_btn'>{this.state.certifiedval == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<>
                  <span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('resumecertyfied_show');
                      })
                    }} type='button' className='learn_btn'>Learn More</Button>
                  </div>
                </>)}


              <div className='jobseeker_block'>

                {/* {this.state.certifiedval == 1 &&
                  <div className="active_left_icon" 
                  onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('resumecertyfied_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.certifiedval == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo2} className="img-fluid" /></div></div>
                  {/* <p>Verify your Background, References, Education, Employment and Social Media profiles to create a BREES™ pre-screened resume to send to employers and recruiters to speed the hiring process</p> */}
                </div>
              </div>

              <Modal
                show={this.state.resumecertyfied_show}
                onHide={() => { this.handaleSwitchModal('resumecertyfied_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('resumecertyfied_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">

                      <div className="modal_heading" style={{ backgroundColor: '#4E54C8' }}>
                        <img src={Resume_certified_short} className="modal_service_img" />
                        <h2 className="modal-content-h2">Resume Certified</h2>
                      </div>
                      <p>Resume Certified’s BREES™ System is Revolutionizing the Hiring Process!</p>
                      <ul>
                        <li>Stand out with a pre-screened BREES™ resume</li>
                        <li>Used by recruiters and hiring managers to screen candidates across the US</li>
                        <li>Get a competitive advantage over other candidates</li>
                      </ul>



                      
                      {this.state.certifiedval == 0 && (<>
                        {!this.accessCheckPackageWise("resumecertified") ? (
                          <>
                            <span><Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(resumecertified_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                              this.handleClose(_.find(this.state.allSitesPackages, { 'id': 2 }))
                            }} >
                              Buy Now $59.95
                            </Button> <b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#4E54C8' }} onClick={this.resumeCertifiedsubmit}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">

                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>

                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/zKRJzlOZgEc'
                          width='100%'
                          height='100%'
                        />
                      
                      </div>
                      
                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>
            </Col>


            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("resumesending") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("resumesending") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("resumesending") ? (<>
                <span className="activetextOn">{this.state.sendval == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('resumesending_show'); }} className='learn_btn'>{this.state.sendval == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('resumesending_show');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}

              <div className='jobseeker_block'>
                {/* {this.state.sendval == 1 &&
                  <div className="active_left_icon"  onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('resumesending_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.sendval == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo4} className="img-fluid" /></div></div>
                  {/* <p>Upload your resume to quickly register on over 50 job boards and monitor the job boards daily for the latest job openings that interest you.</p> */}
                </div>
              </div>

              <Modal
                show={this.state.resumesending_show}
                onHide={() => { this.handaleSwitchModal('resumesending_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('resumesending_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#0C2444' }}>
                        <img src={resume_sending_short} className="modal_service_img" />
                        <h2 className="modal-content-h2">Resume Sending</h2>
                      </div>
                      <p>Put your resume in front of potential employers in a matter of minutes</p>
                      <ul>
                        <li>Upload your resume to automatically register on over 50 job boards</li>
                        <li>Get your resume in front of hiring managers and recruiters looking to fill positions</li>
                        <li>Check the job boards for the latest job openings that interest you</li>
                        <li>Manage your job search from a centralized location with a dedicated inbox</li>
                      </ul>




                      
                      {this.state.sendval == 0 && (<>
                        {!this.accessCheckPackageWise("resumesending") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$59.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(resumesending_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 3 }))
                              }} >Buy Now $59.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#0C2444' }} onClick={this.resumesending_submit}>Click to activate</Button>

                        )}

                      </>)}

                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/EcSE24VGJgI?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>

                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>


            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("coachmaster") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("coachmaster") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("coachmaster") ? (<>
                <span className="activetextOn">{this.state.coachval == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('coachmaster_show'); }} className='learn_btn'>{this.state.coachval == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('coachmaster_show');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}
              <div className='jobseeker_block'>
                {/* {this.state.coachval == 1 &&
                  <div className="active_left_icon" 
                  onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('coachmaster_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.coachval == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo9} className="img-fluid" /></div></div>
                  {/* <p>Coachmaster is a life enriching resources hub helping people across the globe achieve their goals and live their best life. We frequently invite master coaches and leading experts to share their expertise during live webinars to ensure that you have all the necessary tools to make positive changes and ensure success in your job search.</p> */}
                </div>
              </div>

              <Modal
                show={this.state.coachmaster_show}
                onHide={() => { this.handaleSwitchModal('coachmaster_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('coachmaster_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#244444' }}>
                        <img src={Coach_master_short} className="modal_service_img" />
                        <h2 className="modal-content-h2">Coach Master</h2>
                      </div>
                      <p>World-Class Coaching Platform To Help You Live Your Best Life!</p>
                      <ul>
                        <li>Learn from career, business leadership coaches</li>
                        <li>Life-empowering webinars</li>
                        <li>Inspirational speakers</li>
                        <li>Techniques to improve your health and well-being</li>
                      </ul>

                      
                      {this.state.coachval == 0 && (<>
                        {!this.accessCheckPackageWise("coachmaster") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$599.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(coachmaster_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 6 }))
                              }}>Buy Now $599.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#244444' }} onClick={this.coachmaster_submit}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>


                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/xsoZOqQZRBw?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>

                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>


            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("careermaster") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("careermaster") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("careermaster") ? (<>
                <span className="activetextOn">{this.state.careerMasterVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('career_master'); }} className='learn_btn'>{this.state.careerMasterVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('career_master');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}


              <div className='jobseeker_block'>
                {/* {this.state.careerMasterVal == 1 &&
                  <div className="active_left_icon" onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('career_master');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.careerMasterVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo12} className="img-fluid" /></div></div>
                  {/* <p>Whether your focus is to define career objectives, transition to a new career, conduct a successful job search, improve work skills, or integrate effective strategies to achieve work-life balance, you will have access to world-class career coaches who can assist you. A career coach can help you cultivate a meaningful career which is critical to living a fulfilling life.</p> */}
                </div>
              </div>



              <Modal
                show={this.state.career_master}
                onHide={() => { this.handaleSwitchModal('career_master') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('career_master') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#2c4c7c' }}>
                        <img src={career_master_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">Career Master</h2>
                      </div>
                      <p>Support & Guidance For Your Career Development</p>
                      <ul>
                        <li>One-on-one counseling to help during career transitions</li>
                        <li>Career objectives can help maximize your personal and professional potential</li>
                        <li>Career coaches provide a supportive working relationship to assess skills</li>
                        <li>Guidance to help you learn how to thrive professionally</li>
                      </ul>

                      
                      {this.state.careerMasterVal == 0 && (<>
                        {!this.accessCheckPackageWise("careermaster") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$269.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(careermaster_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 7 }))
                              }}>Buy Now $269.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#2c4c7c' }} onClick={() => { this.othersAppSubmit("careerMasterVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/JwTgvs8CC1I?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>

                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>



            </Col>


            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("jobalerts") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("jobalerts") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("jobalerts") ? (<>
                <span className="activetextOn">{this.state.jobAlertVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('jobalerts_show'); }} className='learn_btn'>{this.state.jobAlertVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('jobalerts_show');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}


              <div className='jobseeker_block'>
                {/* {this.state.jobAlertVal == 1 &&
                  <div className="active_left_icon" 
                  onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('jobalerts_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.jobAlertVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo3} className="img-fluid" /></div></div>
                  {/* <p>You can increase your chances of getting hired by signing up for job alerts today. When the right job opens up, you’ll be the first to know. Once you sign up for job alerts in your area, you will receive notifications on the latest job openings based on your search criteria. We will help you narrow your search to find the perfect job that matches your preferred location, job title, industry, and skill set.  Act quickly to be on the top of the hiring manager’s list!</p> */}
                </div>
              </div>

              <Modal
                show={this.state.jobalerts_show}
                onHide={() => { this.handaleSwitchModal('jobalerts_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('jobalerts_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#0C648C' }}>
                        <img src={jobalert_short} className="modal_service_img" />
                        <h2 className="modal-content-h2">Job Alerts</h2>
                      </div>
                      <p>Employers are looking for people with your skills right now. Find jobs with a simple click!</p>
                      <ul>
                        <li>Get instant alerts for job opportunities based on your interests and search criteria</li>
                        <li>Find the perfect job that matches your skill set and salary expectations</li>
                        <li>Enter your job title and location to get up to date job opportunities</li>
                        <li>Respond quickly to get noticed by employers in your area</li>
                      </ul>

                      

                      {this.state.jobAlertVal == 0 && (<>
                        {!this.accessCheckPackageWise("jobalerts") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$19.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(jobalerts_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 4 }))
                              }}>Buy Now $19.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#0C648C' }} onClick={() => { this.othersAppSubmit("jobAlertVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/qgghKs8zzdA?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>
                      


                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>

            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("resumescoring") ? "mt-5 tabclass" : "mt-5 unpaidCover tabclass"}
              id={this.accessCheckPackageWise("resumescoring") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("resumescoring") ? (<>
                <span className="activetextOn">{this.state.scoreval == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('resumescoring_show'); }} className='learn_btn'>{this.state.scoreval == 0 ? 'Activate' : (this.state.scoreval == 2 ) ? 'Reactivate' :  'Visit Now'}</Button>
                </div>
              </>) : (<><span className="activetext">Inactive / Not purchased</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => {
                    this.setState({ isIbutton: true }, () => {
                      this.handaleSwitchModal('resumescoring_show');
                    })
                  }} className='learn_btn'>Learn More</Button>
                </div>
              </>)}
              <div className='jobseeker_block' >

                {/* {this.state.scoreval == 1 &&
                  <div className="active_left_icon" onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('resumescoring_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.scoreval == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}

                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo1} className="img-fluid" /></div></div>
                  {/* <p>Get a resume score based on our AI-powered analysis and improve the quality of your resume to make sure it gets past the employer’s applicant tracking system (ATS) and lands on the desk of the hiring manager. We score your resume based on crucial parameters that are most often searched by recruiters including job title, educational qualifications, relevant work experience, and certifications.</p> */}
                </div>
              </div>



              <Modal
                show={this.state.resumescoring_show}
                onHide={() => { this.handaleSwitchModal('resumescoring_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('resumescoring_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#045CA4' }}>
                        <img src={resume_scoring_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">Resume Scoring</h2>
                      </div>
                      <p>With thousands of jobseekers looking for work, a cookie-cutter resume can easily get lost in the mix!</p>
                      <ul>
                        <li>AI-powered resume checker</li>
                        <li>Get a resume score based on key criteria</li>
                        <li>Revamp your resume to improve visibility</li>
                        <li>Improve your chances to secure interviews</li>
                        
                      </ul>
                      <p className='text-danger'>Note: You will have 3 attempts to upload and score your Resume after which you will be charged additional credits. If you wish to use the same resume you saved at the time of registration, please go ahead and activate the ResumeScoring service . If you wish to use a different Resume, please update it from your profile and THEN activate this service.</p>

                      

                      {(this.state.scoreval == 0 || this.state.scoreval == 2) && (
  <>
    {!this.accessCheckPackageWise("resumescoring") ? (
      <>
        {/* <span className="mt-1 mb-1"> Price: <b>$19.95</b></span> */}
        <span>
          <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(ResumeScoringURL) }}>Visit</Button>
          <Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
            this.handleClose(_.find(this.state.allSitesPackages, { 'id': 1 }))
          }}>Buy Now $19.95</Button>
          <b className='modal_button_side_price'></b>
        </span>
      </>
    ) : (
      this.state.scoreval === 2 ? 
        <Button className="add_account" style={{ backgroundColor: '#045CA4' }} onClick={this.resumescoring_submit}>Click to Reactivate</Button>
      : 
        <Button className="add_account" style={{ backgroundColor: '#045CA4' }} onClick={this.resumescoring_submit}>Click to activate</Button>
    )}
  </>
)}

                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/aiZ3JcpWruk?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>
                     



                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>


            </Col>

            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("socialprofileview") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("socialprofileview") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("socialprofileview") ? (<>
                <span className="activetextOn">{this.state.socialProfileViewVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('social_views'); }} className='learn_btn'>{this.state.socialProfileViewVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('social_views');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}

              <div className='jobseeker_block' >
                {/* {this.state.socialProfileViewVal == 1 &&
                  <div className="active_left_icon" onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('social_views');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                {/* <div className="active_left_icon" onClick={() => { this.handaleOpenModal('social_views'); }}><i className="fa fa-info-circle" aria-hidden="true"></i></div> */}
                <div>
                  {/* <div className={this.state.socialProfileViewVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo11} className="img-fluid" /></div></div>
                  {/* <p>Designed by top recruiters, our AI-powered platform instantly scores your LinkedIn profile on key criteria recruiters and hiring managers look for. Get actionable steps to revamp your LinkedIn profile and land more interviews and job offers.</p> */}
                </div>
              </div>

              <Modal
                show={this.state.social_views}
                onHide={() => { this.handaleSwitchModal('social_views') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('social_views') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#045CA4' }}>
                        <img src={social_profile_scoring_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">Social Profile Scoring</h2>
                      </div>
                      <p>Optimize Your LinkedIn Profile To Help You Get Your Dream Job!</p>
                      <ul>
                        <li>Upload a PDF of your LinkedIn profile</li>
                        <li>Instantly get a score and suggestions to optimize your profile</li>
                        <li>Improve your LinkedIn profile’s score</li>
                        <li>An optimized LinkedIn profile will lead to more job opportunities if seen by the right people</li>
                      </ul>

                      

                      {this.state.socialProfileViewVal == 0 && (<>
                        {!this.accessCheckPackageWise("socialprofileview") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$9.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(SocialScoringURL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 5 }))
                              }}>Buy Now $9.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#045CA4' }} onClick={() => { this.othersAppSubmit("socialProfileViewVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/Eoti-2GrLMI?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>
                     


                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>

            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("freeebooks") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("freeebooks") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("freeebooks") ? (<>
                <span className="activetextOn">{this.state.ebooksVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('ebooks_show'); }} className='learn_btn'>{this.state.ebooksVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('ebooks_show');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}

              <div className='jobseeker_block'>
                {/* {this.state.ebooksVal == 1 &&
                  <div className="active_left_icon"  onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('ebooks_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.ebooksVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo7} className="img-fluid" /></div></div>
                  {/* <p>Get immediate access to career related eBooks that will help you during your job search and offer suggestions to increase your chances of getting hired. Common topics that cause stress during a job search such as resume writing, interviewing, and dealing with change will be addressed so that you will be more confident throughout the hiring process.</p> */}
                </div>
              </div>

              <Modal
                show={this.state.ebooks_show}
                onHide={() => { this.handaleSwitchModal('ebooks_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('ebooks_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#3688BA' }}>
                        <img src={career_book_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">Career Books</h2>
                      </div>

                      <p>Feel more confident and less stressed in your job search!</p>
                      <ul>
                        <li>Learn how to be successful in your job search</li>
                        <li>Easy to download</li>
                        <li>Powerhouse of knowledge</li>
                        <li>Available anytime, anywhere</li>
                      </ul>

                      
                      {this.state.ebooksVal == 0 && (<>
                        {!this.accessCheckPackageWise("freeebooks") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$39.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(careerbooks_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 8 }))
                              }}>Buy Now $39.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#3688BA' }} onClick={() => { this.othersAppSubmit("ebooksVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/I51WRUNr8t4?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>
                      


                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>

            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("jobseekernewshub") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("jobseekernewshub") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("jobseekernewshub") ? (<>
                <span className="activetextOn">{this.state.jobSeekerNewsVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('job_seeker_news_hub'); }} className='learn_btn'>{this.state.jobSeekerNewsVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('job_seeker_news_hub');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}

              <div className='jobseeker_block' >
                {/* {this.state.jobSeekerNewsVal == 1 &&
                  <div className="active_left_icon" 
                  onClick={() => { this.setState({ isIbutton: true },()=>{
                          this.handaleSwitchModal('job_seeker_news_hub');
                        })   
                  }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>} */}
                <div>
                  {/* <div className={this.state.jobSeekerNewsVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo13} className="img-fluid" /></div></div>
                  {/* <h3 style={{ textAlign: 'center', fontSize: '19px' }}><strong><span
                    style={{ color: "#8533db" }}>Jobseeker News Hub</span></strong></h3> */}
                  {/* <p>JobseekerNewshubb will help you stay up to date on all the latest news during your job search to gain a competitive edge over other jobseekers.</p> */}
                </div>
              </div>



              <Modal
                show={this.state.job_seeker_news_hub}
                onHide={() => { this.handaleSwitchModal('job_seeker_news_hub') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('job_seeker_news_hub') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#3C3C7C' }}>
                        <img src={job_seeker_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">Job Seeker Newshubb</h2>
                      </div>

                      <p>Valuable information to help you navigate the challenges of your job search</p>
                      <ul>
                        <li>Newsletter featuring leading experts in the field</li>
                        <li>Stay up to date on the latest jobseeker news</li>
                        <li>Be the first to know about special offers</li>
                        <li>Option to have the latest news delivered direct to your inbox</li>
                      </ul>


                      

                      {this.state.jobSeekerNewsVal == 0 && (<>
                        {!this.accessCheckPackageWise("jobseekernewshub") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$9.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(jobseekernewshubb_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 11 }))
                              }}>Buy Now $9.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#3C3C7C' }} onClick={() => { this.othersAppSubmit("jobSeekerNewsVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/gdzaM5qPX-s?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>



                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>




            </Col>

            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("reqruiterlist") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("reqruiterlist") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("reqruiterlist") ? (<>
                <span className="activetextOn">{this.state.reqruiterVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('reqruiter_show'); }} className='learn_btn'>{this.state.reqruiterVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('reqruiter_show');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}


              <div className='jobseeker_block'>
                {/* {this.state.reqruiterVal == 1 &&
                  <div className="active_left_icon" onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('reqruiter_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.reqruiterVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo10} className="img-fluid" /></div></div>
                  {/* <p>Explore our recruiter’s list to be on the radar of the best recruiters and get hired faster than anyone else. Get to know the top recruiters in your area and connect with them to increase your chances of getting hired. Our recruiter’s list contains contact information of recruiters who are looking for candidates with professional experience like yours.</p> */}
                </div>
              </div>

              <Modal
                show={this.state.reqruiter_show}
                onHide={() => { this.handaleSwitchModal('reqruiter_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('reqruiter_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#0c54d4' }}>
                        <img src={recruit_direct_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">Recruiter Direct</h2>
                      </div>
                      <p>Get to know the top recruiters in your area who specialize in placing candidates like you!</p>
                      <ul>
                        <li>Connect with top recruiters</li>
                        <li>Build relationships and learn about employers</li>
                        <li>Learn about job openings in your industry</li>
                        <li>Increase your chances of getting hired</li>
                      </ul>


                      
                      {this.state.reqruiterVal == 0 && (<>
                        {!this.accessCheckPackageWise("reqruiterlist") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$99.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(recruiterdirect_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 9 }))
                              }}>Buy Now $99.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#0c54d4' }} onClick={() => { this.othersAppSubmit("reqruiterVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/_IxScINuI2A?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>


                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>

            <Col xs={12} sm={6} md={4} lg={3} className={this.accessCheckPackageWise("hrcontacts") ? "mt-5" : "mt-5 unpaidCover"}
              id={this.accessCheckPackageWise("hrcontacts") ? "scrollDownToActiveService" : ""}>
              {this.accessCheckPackageWise("hrcontacts") ? (<>
                <span className="activetextOn">{this.state.hrcontactsVal == 0 ? 'Inactive / Purchased' : 'Active / Purchased'}</span>
                <div className='hover_cover'>
                  <Button type='button' onClick={() => { this.handaleSwitchModal('hrContacts_show'); }} className='learn_btn'>{this.state.hrcontactsVal == 0 ? 'Activate' : 'Visit Now'}</Button>
                </div>
              </>) :
                (<><span className="activetext">Inactive / Not purchased</span>
                  <div className='hover_cover'>
                    <Button type='button' onClick={() => {
                      this.setState({ isIbutton: true }, () => {
                        this.handaleSwitchModal('hrContacts_show');
                      })
                    }} className='learn_btn'>Learn More</Button>
                  </div>
                </>)}

              <div className='jobseeker_block'>
                {/* {this.state.hrcontactsVal == 1 &&
                  <div className="active_left_icon" 
                  onClick={() => { this.setState({ isIbutton: true },()=>{
                    this.handaleSwitchModal('hrContacts_show');
                  })   }}><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                } */}
                <div>
                  {/* <div className={this.state.hrcontactsVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={logo5} className="img-fluid" /></div></div>
                  {/* <p>Explore our list of HR contacts and add employers in your area that interest you. A targeted list of HR contacts gives you the advantage of being able to connect with the exact companies that are hiring. Our HR contact  list contains contact information of HR managers who are looking for candidates with professional experience like yours.</p> */}
                </div>
              </div>

              <Modal
                show={this.state.hrContacts_show}
                onHide={() => { this.handaleSwitchModal('hrContacts_show') }}
                dialogClassName="modal-90w modal_all_ex_bun"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >
                <div className="modal_overlay"></div>
                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('hrContacts_show') }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className="modal_heading" style={{ backgroundColor: '#0C4DBA' }}>
                        <img src={HR_direct_short} className="modal_service_img" style={{ width: '20%' }} />
                        <h2 className="modal-content-h2">HR Direct</h2>
                      </div>


                     
                      <p>Use our HR contact list as a networking tool to connect with employers in your area</p>
                      <ul>
                        <li>Get a targeted list of HR contacts</li>
                        <li>Brings jobseekers and employers together</li>
                        <li>Build connections easily</li>
                        <li>Connect with the right employer</li>
                      </ul>

                      {this.state.hrcontactsVal == 0 && (<>
                        {!this.accessCheckPackageWise("hrcontacts") ? (
                          <>
                            {/* <span className="mt-1 mb-1"> Price: <b>$99.95</b></span> */}
                            <span>
                              <Button className="add_account mr-2" style={{ backgroundColor: '#4E54C8' }} onClick={() => { this.visitLinkRedirect(hrdirect_FRONT_URL) }}>Visit</Button><Button className="add_account" style={{ backgroundColor: '#009AFF' }} onClick={() => {
                                this.handleClose(_.find(this.state.allSitesPackages, { 'id': 10 }))
                              }}>Buy Now $99.95</Button><b className='modal_button_side_price'></b></span>
                          </>

                        ) : (
                          <Button className="add_account" style={{ backgroundColor: '#0C4DBA' }} onClick={() => { this.othersAppSubmit("hrcontactsVal") }}>Click to activate</Button>

                        )}

                      </>)}
                    </Col>
                    <Col sm={12} md={6} lg={6} className="videoframe">
                      
                      <div className="videoframe_img">
                        <img src={pcimg} className="img-fluid" />
                      </div>
                      <div className="video">
                        <ReactPlayer
                          className='react-player'
                          url='https://www.youtube.com/embed/mCGKrNCd6Z0?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                          width='100%'
                          height='100%'
                        />
                      </div>


                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>

            </Col>


            <Col id="scrollDownToActiveService" xs={12} sm={6} md={4} lg={3} className="mt-5">
              <span className="activetextOn"></span>
              <div className='hover_cover'>
                      <button style={{"cursor":"pointer"}} onClick={()=>{
                    this.setState({ShowHideSupportModal:true})
                  }} className='learn_btn btn btn-primary'>Visit Now</button>
              </div>

              <div className='jobseeker_block'>
                <div>
                  {/* <div className={this.state.jobAssistVal == 1 ? "active_jobseeker" : "dactive_jobseeker"}></div> */}
                  <div className='jobseeker_logo'><div className='icon-inner'><img src={Support} className="img-fluid" /></div></div>
                  <p>Support</p>
                </div>
              </div>

               
    <SupportForm ShowHideSupportModal={this.state.ShowHideSupportModal} handleSupport= {
      ()=>{
        this.setState({ShowHideSupportModal : false})
        }
    }/>
              <Modal
                show={this.state.job_assist}
                onHide={() => { this.handaleSwitchModal('job_assist'); }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
              >

                <Modal.Body>
                  <div className='close_btn' onClick={() => { this.handaleSwitchModal('job_assist'); }}>x</div>
                  <Row>
                    <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                      <div className='modal_left_top_content_box'>
                        <h2 className='modal_jobseeker_title'>JOB ASSIST</h2>
                        <div className="seperator"></div>
                        <p>Terminations or layoffs are unfortunate events that many employees have to go through. Our HR outplacement services can help them with a stress-free career transition without breaking their confidence due to sudden job loss. Our Job Assist helps in:</p>

                        <ul>
                          <li>Boosting employee morale</li>
                          <li>Providing Job coaching</li>
                          <li>Resume Writing</li>
                          <li>Finding better jobs</li>
                        </ul>

                      </div>
                      {this.state.jobAssistVal == 0 && (
                        <Button className="add_account" onClick={() => { this.othersAppSubmit("jobAssistVal") }}>Click to activate</Button>
                      )}
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <ReactPlayer
                        className='react-player'
                        url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                        width='100%'
                        height='100%'
                      />
                    </Col>
                  </Row>
                </Modal.Body>

              </Modal>



            </Col>



          </Row>


        </Container>

        <Container fluid className='mt-3 mb-3'>
          <div className='openai'>

            {this.state.packageIdArr.includes(1) ? (<>
              {/* <h4>OpenAi Cover Letter, Email & Resume Re-Writer ACTIVATED</h4> */}
              <div className='openai_heading mb-3'>
                <h4><b>BONUS :</b> OpenAi Resume Boost ACTIVATED</h4>
              </div>
            </>) : (<>
              <div className='openai_heading_inactive mb-3'>
                <h4>Upgrade to Jobseeker Bundle to activate Resume Optimizer, Cover Letter Writer, Email Writer and Resume Templates</h4>
              </div>
            </>)}
            <Row>
              <Col sm={4} md={3} col={4} className={this.state.packageIdArr.includes(1) ? 'd-flex justify-content-between align-items-center' : 'inactive d-flex justify-content-between align-items-center'}>
                <div className='logo_con d-flex justify-content-center align-items-center w-100'>
                  <img src={resuemerewrite} style={{ width: '70px' }} className='icons' />
                  <div className='d-flex align-items-center flex-column openai_right'>
                    <h5>RESUME</h5>
                    <strong>WRITER</strong>

                    {this.state.packageIdArr.includes(1) ? (<>
                      
                      <a href={resumeboostURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token')} target="_blank" className='btn btn-primary mt-1 cover_letter_btn_section'>Visit Now</a>
                    </>) : (<>
                      <a href='javascript:void(0)' className='btn btn-primary mt-1 cover_letter_btn_section' onClick={() => { this.switchCoverSectionModal('resume_optimizer_show_modal') }}>Learn More</a>
                    </>)}
                  </div>
                </div>






                <Modal
                  show={this.state.resume_optimizer_show_modal}
                  onHide={()=>{this.switchCoverSectionModal('resume_optimizer_show_modal')}}
                  dialogClassName="modal-90w modal_all_ex_bun"
                  aria-labelledby="example-custom-modal-styling-title"
                  className='jobseeker_modal'
                  centered
                >
                  <div className="modal_overlay"></div>
                  <Modal.Body>
                    <div className='close_btn' onClick={() => { this.switchCoverSectionModal('resume_optimizer_show_modal') }}>x</div>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                        <div className="modal_heading">
                          <img src={resuemerewrite} className="modal_service_img" style={{ width: '20%' }} />
                          <h2 className="modal-content-h2">RESUME WRITER</h2>
                        </div>
                      </Col>

                      <Col sm={12} md={6} lg={6} className="videoframe">
                        <div className="videoframe_img">
                          <img src={pcimg} className="img-fluid" />
                        </div>
                        <div className="video">
                          <ReactPlayer
                            className='react-player'
                            url='https://www.youtube.com/embed/RKthMBaCuvA?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </Col>

                    </Row>
                  </Modal.Body>

                </Modal>






              </Col>






              <Col sm={4} md={3} col={4} className={this.state.packageIdArr.includes(1) ? 'd-flex justify-content-between align-items-center' : 'inactive d-flex justify-content-between align-items-center'}>
                <div className='logo_con d-flex justify-content-center align-items-center w-100'>
                  <img src={coverletter} style={{ width: '70px' }} className='icons' />
                  <div className='d-flex align-items-center flex-column openai_right'>
                    <h5>COVER LETTER</h5>
                    <strong>WRITER</strong>

                    {this.state.packageIdArr.includes(1) ? (<>
                      <a href={resumeboostURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token') + "&slug=email-writerr"} target="_blank" className='btn btn-primary mt-1 cover_letter_btn_section'>Visit Now</a>

                      {/* <a href='javascript:void(0)' className='btn btn-primary mt-1 cover_letter_btn_section' onClick={() => { this.switchCoverSectionform('cover_letter_form') }}>Visit Now</a> */}
                    </>) : (<>
                      <a href='javascript:void(0)' className='btn btn-primary mt-1 cover_letter_btn_section' onClick={() => { this.switchCoverSectionModal('cover_letter_show_modal') }}>Learn More</a>
                    </>)}


                  </div>
                </div>





                <Modal
                  show={this.state.cover_letter_form}
                  onHide={this.state.cover_letter_form}
                  dialogClassName="modal-90w modal_all_ex_bun"
                  aria-labelledby="example-custom-modal-styling-title"
                  className='jobseeker_modal'
                  centered
                >
                  <div className="modal_overlay"></div>
                  <Modal.Body>
                    <div className='close_btn' onClick={() => { this.switchCoverSectionform('cover_letter_form') }}>x</div>

                    <Form className="w-100 tabform modal_form" onSubmit={this.handaleSubmitForAi}>
                      <Row>

                        <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">

                          <Form.Group className="mb-3 w-100" controlId="cname">
                            <Form.Control type="text" name="company_name_ai" placeholder="Google" onChange={this.handaleChangeForAi}
                              validaterule={[
                                "required",
                              ]}
                              validatemsg={[
                                "This field is required",
                              ]}
                            />
                            <Form.Label className='flable'>Company Name</Form.Label>
                            <p style={{ color: "red" }}>
                              {this.state.FromDataError_ai.company_name_ai == "false"
                                ? ""
                                : this.state.FromDataError_ai.company_name_ai}
                            </p>
                          </Form.Group>

                          <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                            <Form.Control type="text" name="position_ai" placeholder="Data Analyst" onChange={this.handaleChangeForAi}
                              validaterule={[
                                "required",
                              ]}
                              validatemsg={[
                                "This field is required",
                              ]}
                            />
                            <Form.Label className='flable'>Position/Title</Form.Label>
                            <p style={{ color: "red" }}>
                              {this.state.FromDataError_ai.position_ai == "false"
                                ? ""
                                : this.state.FromDataError_ai.position_ai}
                            </p>
                          </Form.Group>

                          <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                            <Form.Control
                              as="textarea"
                              name="description_ai"
                              placeholder="Marketing Assistant at Amazon"
                              style={{ height: '60px' }}
                              onChange={this.handaleChangeForAi}
                              validaterule={[
                                "required",
                              ]}
                              validatemsg={[
                                "This field is required",
                              ]}
                            />
                            <Form.Label className='flable'>Job Description</Form.Label>
                            <p style={{ color: "red" }}>
                              {this.state.FromDataError_ai.description_ai == "false"
                                ? ""
                                : this.state.FromDataError_ai.description_ai}
                            </p>
                          </Form.Group>


                          <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                            <Form.Control
                              as="textarea"
                              name="skil_ai"
                              placeholder="Excel, Database"
                              style={{ height: '100px' }}
                              onChange={this.handaleChangeForAi}
                            />
                            <Form.Label className='optional'>Relevant Skills (optional)</Form.Label>
                          </Form.Group>

                          <Form.Group className="mb-3 w-100 d-flex justify-content-center align-items-center" controlId="formBasicEmail">
                            <Button variant="primary" type="submit" className='btn-main tabformbutton'>
                              Generate with AI
                            </Button>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6} className="videoframe">

                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                              as="textarea"
                              style={{ height: '400px' }}
                              value={this.state.ai_content}
                            />
                            <Form.Label className='flable'>Job Description</Form.Label>
                          </Form.Group>

                          <Form.Group className="mb-3 w-100 d-flex justify-content-center align-items-center" controlId="formBasicEmail">
                            <Button variant="primary" type="button" className='btn-main tabformbutton' onClick={() => { this.coverLetterDownload() }}>
                              Download Now
                            </Button>
                            <a href={this.state.coverletter_pdf} id="coverLetterLink" download="coverletter.pdf" target="_blank" className='d-none'></a>
                          </Form.Group>

                        </Col>

                      </Row>
                    </Form>
                  </Modal.Body>

                </Modal>





                <Modal
                  show={this.state.cover_letter_show_modal}
                  onHide={this.state.cover_letter_show_modal}
                  dialogClassName="modal-90w modal_all_ex_bun"
                  aria-labelledby="example-custom-modal-styling-title"
                  className='jobseeker_modal'
                  centered
                >
                  <div className="modal_overlay"></div>
                  <Modal.Body>
                    <div className='close_btn' onClick={() => { this.switchCoverSectionModal('cover_letter_show_modal') }}>x</div>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                        <div className="modal_heading">
                          <img src={coverletter} className="modal_service_img" style={{ width: '20%' }} />
                          <h2 className="modal-content-h2">COVER LETTER WRITER</h2>
                        </div>
                      </Col>

                      <Col sm={12} md={6} lg={6} className="videoframe">
                        <div className="videoframe_img">
                          <img src={pcimg} className="img-fluid" />
                        </div>
                        <div className="video">
                          <ReactPlayer
                            className='react-player'
                            url='https://www.youtube.com/embed/RKthMBaCuvA?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </Col>

                    </Row>
                  </Modal.Body>

                </Modal>




              </Col>
              <Col sm={4} md={3} col={4} className={this.state.packageIdArr.includes(1) ? 'd-flex justify-content-between align-items-center' : 'inactive d-flex justify-content-between align-items-center'}>
                <div className='logo_con d-flex justify-content-center align-items-center w-100'>
                  <img src={resuemewrite} style={{ width: '70px' }} className='icons' />
                  <div className='d-flex align-items-center flex-column openai_right'>
                    <h5>EMAIL</h5>
                    <strong>WRITER</strong>
                    {this.state.packageIdArr.includes(1) ? (<>
                      <a href={resumeboostURL+"/direct-login/" + localStorage.getItem("gs_auth_code") + "?token=" + localStorage.getItem('gs_token') + "&slug=email-writer"} target="_blank" className='btn btn-primary mt-1 cover_letter_btn_section'>Visit Now</a>
                    </>) : (<>
                      <a href='javascript:void(0)' className='btn btn-primary mt-1 cover_letter_btn_section' onClick={() => { this.switchCoverSectionModal('email_writer_show_modal') }}>Learn More</a>
                    </>)}
                  </div>
                </div>




                <Modal
                  show={this.state.email_writer_show_modal}
                  onHide={this.state.email_writer_show_modal}
                  dialogClassName="modal-90w modal_all_ex_bun"
                  aria-labelledby="example-custom-modal-styling-title"
                  className='jobseeker_modal'
                  centered
                >
                  <div className="modal_overlay"></div>
                  <Modal.Body>
                    <div className='close_btn' onClick={() => { this.switchCoverSectionModal('email_writer_show_modal') }}>x</div>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                        <div className="modal_heading">
                          <img src={resuemewrite} className="modal_service_img" style={{ width: '20%' }} />
                          <h2 className="modal-content-h2">EMAIL WRITER</h2>
                        </div>
                      </Col>

                      <Col sm={12} md={6} lg={6} className="videoframe">
                        <div className="videoframe_img">
                          <img src={pcimg} className="img-fluid" />
                        </div>
                        <div className="video">
                          <ReactPlayer
                            className='react-player'
                            url='https://www.youtube.com/embed/RKthMBaCuvA?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </Col>

                    </Row>
                  </Modal.Body>

                </Modal>





              </Col>
              <Col sm={4} md={3} col={4} className={this.state.packageIdArr.includes(1) ? 'd-flex justify-content-between align-items-center' : 'inactive d-flex justify-content-between align-items-center'}>
                <div className='logo_con d-flex justify-content-center align-items-center w-100'>
                  <img src={resume_tem} style={{ width: '70px' }} className='icons' />
                  <div className='d-flex align-items-center flex-column openai_right'>
                    <h5>RESUME</h5>
                    <strong>Templates</strong>

                    {this.state.packageIdArr.includes(1) ? (<>
                      
                      <a href='#/resume-templates' className='btn btn-primary mt-1 cover_letter_btn_section'>Visit Now</a>
                     

                      
                    </>) : (<>
                      <a href='javascript:void(0)' className='btn btn-primary mt-1 cover_letter_btn_section' onClick={() => { this.switchCoverSectionModal('resume_re_writer_show_modal') }}>Learn More</a>
                    </>)}
                  </div>
                </div>






                <Modal
                  show={this.state.resume_re_writer_show_modal}
                  onHide={this.state.resume_re_writer_show_modal}
                  dialogClassName="modal-90w modal_all_ex_bun"
                  aria-labelledby="example-custom-modal-styling-title"
                  className='jobseeker_modal'
                  centered
                >
                  <div className="modal_overlay"></div>
                  <Modal.Body>
                    <div className='close_btn' onClick={() => { this.switchCoverSectionModal('resume_re_writer_show_modal') }}>x</div>
                    <Row>
                      <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">
                        <div className="modal_heading">
                          <img src={resume_tem} className="modal_service_img" style={{ width: '20%' }} />
                          <h2 className="modal-content-h2">RESUME Templates</h2>
                        </div>
                      </Col>

                      <Col sm={12} md={6} lg={6} className="videoframe">
                        <div className="videoframe_img">
                          <img src={pcimg} className="img-fluid" />
                        </div>
                        <div className="video">
                          <ReactPlayer
                            className='react-player'
                            url='https://www.youtube.com/embed/RKthMBaCuvA?autoplay=1&cc_load_policy=1&mute=1&fs=0'
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </Col>

                    </Row>
                  </Modal.Body>

                </Modal>






              </Col>
            </Row>
          </div>
        </Container>



      </div>
    );
  }
}

function SupportForm(props) {
  const [cateGory, setCateGory] = useState("");
  const [message, setMessage] = useState("");
  const [res, setRes] = useState("");
  const [status, setStatus] = useState("danger");
  const submitSupportForm = ()=>{
    var formdata = new FormData();
    formdata.append("cateGory", cateGory);
    formdata.append("message", message);
    let gs_user = localStorage.getItem('gs_user');
    gs_user = JSON.parse(gs_user);
    formdata.append("email", gs_user.email);
    formdata.append("name", gs_user.firstname+" "+gs_user.lastname);
    formdata.append("userID", gs_user.userID);

    let request = {
      end_point: '/gbtwSupportForm',
      token: localStorage.getItem("gs_token"),
      formdata: formdata
    };
    POST_API(request).then(resp => {
      if(resp.status == "success"){
        setRes("Thank you for submitting your query. Our Support team will get in touch with you shorlty.");
        setStatus("success");
        setCateGory("");
        setMessage("");
        setTimeout(function(){
          setRes("");
           props.handleSupport();
        },2000);
      }else{
        setRes("There is some error while submitting the form, please try again later");
        setStatus("danger");
        setTimeout(function(){
          setRes("");
          setStatus("");
        },1500);
         console.log(resp);
      }
    }).catch((ee) => {

    });
  }

  return (
    <Modal
    show={props.ShowHideSupportModal}
    onHide={props.handleSupport}
    dialogClassName="modal-50w"
    aria-labelledby="example-custom-modal-styling-title"
    className='jobseeker_modal'
    centered
  >

    <Modal.Body>
      <div className='close_btn' onClick={props.handleSupport}>x</div>
      <div className='modal_left_top_content_box'>
        {res != "" && <div className={"alert alert-"+status}>{res}</div>  }
            <h2 className='modal_jobseeker_title'>How Can We Help ?</h2>
            <div className="seperator"></div>
            <form onSubmit={submitSupportForm}>
              <div className="form-group">
                <label>Please Select <span className="text-danger">*</span></label> 
                <select value={cateGory} onChange={(e)=>{
                  setCateGory(e.target.value);
                }} className="form-control" required>
                    <option value="">Please Select</option>
                    <option value="Password Reset/Recovery">Password Reset/Recovery</option>
                    <option value="Update Profile Information">Update Profile Information</option>
                    <option value="Resume Upload Failure">Resume Upload Failure</option>
                    <option value="LinkedIn Profile PDF Upload Failure">LinkedIn Profile PDF Upload Failure</option>
                    <option value="Difficulty Accessing Certain Features">Difficulty Accessing Certain Features</option>
                    <option value="Report a Bug or System Error">Report a Bug or System Error</option>
                    <option value="Single Sign On Feature Not Working">Single Sign On Feature Not Working</option>
                    <option value="Browser Compatibility Issues">Browser Compatibility Issues</option>
                    <option value="Need Help with User Interface Navigation">Need Help with User Interface Navigation</option>
                    <option value="E-Book Download Failure">E-Book Download Failure</option>
                    <option value="Resume Template Download Failure">Resume Template Download Failure</option>
                    <option value="General Inquiry/Other Issues">General Inquiry/Other Issues</option>
                </select>  
              </div>
              <div className="form-group">
                <label>Message <span className="text-danger">*</span></label> 
                  <textarea rows="6" maxlength="1000" value={message} onChange={(e)=>{
                  setMessage(e.target.value);
                }}  required className="form-control"></textarea>
              </div>

              <div className="form-group">
                <button className='btn btn-primary btn-md tabformbutton btnfix btn_total btn btn-primary'>Send Message</button>
              </div>
            </form>
          </div>
    </Modal.Body>
  </Modal>
  );
}
export default middleSection;