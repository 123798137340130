import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';

import DataTable from "react-data-table-component";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import moment from "moment";

export default class CampaingViewModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        firstname : '',
                                        lastname : '',
                                        contact_no : '',
                                        email : '',
                                        type : '',
                                       
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                          firstname : 'false',
                                          lastname : 'false',
                                          contact_no : 'false',
                                          email : 'false',
                                          type : '',
                                        
                                        
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/contact-details',
                        ContactList:[],
                        $totalContact:0,
                        $totalSeen:0,
                        $totalSend:0,
                        $totalUnseen:0,
                                      
                        
                     };

    
        
        
            

    }

    
    columns = [
       
        {
            name: "Contact Name",
            cell: row => row.contact_name ,
            sortable: true
          },
          {
            name: "Email",
            cell: row => row.email ,
            sortable: true
          },
          {
            name: "Has send",
            cell: row =>
            {
              return(
                <div>{row.has_send==202?'Send':'Pending'}</div>
                         
                    
                      
                  )
            } 
            ,
            sortable: true
          },
          {
            name: "Has seen",
            cell: row => 
            {
                return(
                  <div>{row.has_seen==1?'Seen':'Not seen'}</div>
                           
                      
                        
                    )
              } 
              ,
            sortable: true
          },
          {
            name: "Created At",
            // cell: row => row.created_at ,
            selector: row => moment(row.created_at).format('MM-DD-YYYY'),
            sortable: true
          },
          
         
          
        
      ];
      


    // componentDidMount()
    // {

    //     let sta = this;
    //     let FromData =this.state.FromData
    //     let FromDataError =this.state.FromDataError

       

        
    //     if(sta.props.location.state!=undefined)
    //     {
    //         sta.setState({Msg:sta.props.location.state.Msg})
    //         sta.setState({MsgType:sta.props.location.state.MsgType})
    //     }

    //     try {
    //         if(localStorage.getItem("gs_user") != null)
    //         {
                

                
                
    //         }
            
           
    //       } 
    //       catch (error) {
            
    //       }
     

      
    // }

    getoneRecord()
    {

        let sta = this;
        let ContactList =this.state.ContactList
        let campaign_id =this.props.campaign_id;
        
     
        //for redirectin message show
   
        let request = {
            end_point : '/campaign-contact-view?id='+campaign_id,
            token: localStorage.getItem("gs_token")

        };

        sta.setState({loader:'visible'})///loader on


            GET_API(request).then(resp=>{

                     sta.setState({loader:'hidden'})///loader off

                   
                            if(resp.status == 'success')
                            {
                                    
                                ContactList = resp.data
                                    sta.setState({ContactList})
                                    let totalContact = resp.totalResult.totalContact;
                                    let totalSeen = resp.totalResult.totalSeen;
                                    let totalSend = resp.totalResult.totalSend;
                                    let totalUnseen = resp.totalResult.totalUnseen;
                                    sta.setState({totalContact})
                                    sta.setState({totalSeen})
                                    sta.setState({totalSend})
                                    sta.setState({totalUnseen})

                                    
                               }
                                else{
                                    
                                    
                                        
                                }
                    }).catch((ee)=>{
                      sta.setState({loader:'hidden'})///loader off

                        console.log('/campaign view list ERR',ee)
                    });


           
        

    }


  
 
 
  render() {
    let  ContactList = this.state.ContactList
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         >
        <Form
         className="tabform"
         onSubmit={this.handaleSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Contact List</Modal.Title>
            <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Total Contact : {this.state.totalContact} Send : {this.state.totalSend} Seen : {this.state.totalSeen} </div>
            </Row>
          </Modal.Header>
          <Modal.Body>


      
         <Row className="m-0">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}


                    <DataTable
                    title=""
                    columns={this.columns}
                    data={ContactList}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    />     

             
      
           </Col>
          
         </Row>

    

            </Modal.Body>
          <Modal.Footer>
         
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
