import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";

import Leftpanel from '../Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';








class CardList extends Component {


  constructor(props) {
    super(props)
    this.state = {
      FromData: {},
      FromDataError:
        {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/cards',
      help: '',
      totalRows: 0,
      offset: 1,
      limit: 10,

    };




  }


  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Card",
      selector: row => (row.is_default == 1) ? (<><h6>**** **** **** {row.last_digit}</h6><span className="badge badge-success">Default</span></>) : (<>**** **** **** {row.last_digit}</>),

    },
    {
      name: "Name",
      selector: row => row.card_name || '',
      sortable: true
    },
    {
      name: "Created At",
      // selector: row => row.created_at,
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      sortable: true
    },
    {
      name: "Activate",
      selector: row => row.is_active == 1 ? 'True' : 'false',
      sortable: true
    },

    {
      name: "Action",
      cell: row => {
        return (
          <div>
            {(row.is_default == 1) ? (<></>) : (<>
              <Button className="pl-1" disabled={false} variant="info " style={{ margin: '5px' }} onClick={() => { if (window.confirm('Set this card default?')) { this.handaleDefault(row.card_token) }; }}>Set Default</Button>
            </>)}
            <Button disabled={false} variant="danger" onClick={() => { if (window.confirm('Delete the Card?')) { this.handaleDelete(row.card_token) } }}>Delete</Button>
          </div>
        )
      }
      ,
      // sortable: true,
      width: '250px'
    },
  ];


  handlePageChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/get-saved-cards?offset=${page}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {
      this.setState({ loader: 'hidden' })///loader off

      if (resp.status == 'success') {

        let FromData = resp.data;
        // console.log(resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/card list ERR', ee)
    });
  };


  handlePerRowsChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/get-saved-cards?offset=${this.state.offset}&limit=${page}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {
      this.setState({ loader: 'hidden' })///loader off

      if (resp.status == 'success') {

        let FromData = resp.data;
        // console.log(resp.data)
        this.setState({ FromData })
        this.setState({ limit: page });
      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/card list ERR', ee)
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: `/get-saved-cards?offset=${this.state.offset}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resp.status == 'success') {


        FromData = resp.data
        // console.log(resp.data)
        sta.setState({ totalRows: resp.total });
        sta.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/card list ERR', ee)
    });

    let requestHelp = {
      end_point: '/help-details?key=sponsor_save_card',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })





  }

  handaleDelete(id) {
    let sta = this
    // console.log('deleted id'+id)
    var formdata = new FormData();
    formdata.append("card_id", id);

    let request = {
      end_point: '/delete-card',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };




    POST_API(request).then((resp) => {

      if (resp.status == 'success') {


        //  sta.setState({Msg:'Delete Successfully!',Msgtype:'succcess'})
        //  sta.setState({Msg:'Card has been deleted successfully!',Msgtype:'succcess'})
        sta.setState({ Msg: 'Card deleted successfully!', Msgtype: 'succcess' })
        setTimeout(() => {
          window.location.reload()

        }, 3000);





      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: "Delete Failed", MsgType: 'danger' })
      }

    }).catch(() => {

      sta.setState({ loader: 'hidden' })///loader on
      sta.setState({ Msg: "Some Thing Went wrong", MsgType: 'danger' })

    })

  }

  handaleDefault(token) {

    let sta = this

    var formdata = new FormData();
    formdata.append("card_id", token);

    let request = {
      end_point: '/set-default',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };



    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then((resp) => {
      sta.setState({ loader: 'hidden' })///loader on

      if (resp.status == 'success') {


        sta.setState({ Msg: 'Set Default Successfully!', Msgtype: 'succcess' })

        setTimeout(() => {
          window.location.reload()

        }, 3000);





      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: "Set Default Failed", MsgType: 'danger' })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader on
      sta.setState({ Msg: "Some Thing Went wrong", MsgType: 'danger' })
    })

  }


  render() {
    let FromData = this.state.FromData

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12} className="pt-4 pb-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm">

                  <Row>
                    <Col md={9}>
                      <div>
                        {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                      </div>
                      <h3>Card List</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.help,
                        }}
                      ></p>
                    </Col>
                    <Col md={3}>
                      <a href="#/create-card"><button className=" btn btn-primary ghost_button mt-3 mb-4" style={{ float: "right" }}>Add Card</button></a>
                    </Col>
                  </Row>








                  <DataTable
                    title=""
                    columns={this.columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

export default CardList;