import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';



import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddContactcsvModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        firstname : '',
                                        lastname : '',
                                        contact_no : '',
                                        email : '',
                                        type : '',
                                       
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                          firstname : 'false',
                                          lastname : 'false',
                                          contact_no : 'false',
                                          email : 'false',
                                          type : 'false',
                                        
                                        
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/contact-details',
                        
                                      
                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
       
        this.handalecsvSubmit = this.handalecsvSubmit.bind(this)
        
        
 
        

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

  


    handalecsvSubmit(e)
    {   
        // console.log(e)
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
        let group_id =this.props.group_id;
        
       
          var fileUpload = document.getElementById("csv"); 
          var regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.csv|.txt)$/; 
         
          if (regex.test(fileUpload.value.toLowerCase())) { 
          if (typeof (FileReader) != "undefined") { 
          var reader = new FileReader(); 
          reader.onload = function (e) { 
          var table = document.createElement("table"); 
          var rows = e.target.result.split("\n"); 
          

          var allTextLines = e.target.result.split("\n"); 
          var headers = allTextLines[0].split(',');
          var lines = [];
          
          allTextLines.forEach((element,key) => { 
           // console.log(element,key)

            if(key>0 && element)
            {
              element=element.split(",");
              let a={}
              element.forEach((eachelement,keyeach) => {
                
               let headerkeys=headers[keyeach].trim() 
               
               a[headerkeys]=eachelement.trim()
                //console.log(eachelement, keyeach,headers[keyeach])
                
              })
              lines.push(a);
            }
          });

          

         
          console.log('rr '+JSON.stringify(lines));
          sta.setState({loader:'visible'})///loader on
            var formdata = new FormData();
              
                formdata.append("allcontactData", JSON.stringify(lines));
                formdata.append("group_id",group_id);
                
                let request = {
                  end_point : '/create-bulkcontact',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                //console.log('......request',formdata)

                POST_API(request).then(resp=>{
                 

                  sta.setState({loader:'hidden'})///loader off
                  //console.log('......res',resp)

                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:resp.data+' Contact added successfully',MsgType:'success'})
                          
                          
                           
                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3000);

                          

                        }
                        else{
                            
                             sta.setState({Msg:resp.message,MsgType:'danger'})
                           
                        }
                        }).catch((ee)=>{

                            console.log('/addcontactERR',ee)
                        });
                      


        
      
          } 
          reader.readAsText(fileUpload.files[0]); 
          } else { 
          alert("This browser does not support HTML5."); 
          } 
          } else { 
          
           sta.setState({Msg:'Please upload a valid CSV file.',MsgType:'danger'})
          } 

      

       


       
    }



    componentDidMount()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError
        let group_id =this.props.group_id;
        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_user") != null)
            {
                //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                //sta.setState({loader:'visible'})///loader on

                
                
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }

  
 
 
  render() {
    let  FromData = this.state.FromData
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         >
        <Form
         className="tabform"
         onSubmit={this.handalecsvSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Add Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>

         <Row className="m-0 mt-4 mb-4">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}



             
      
              <Form.Row>
                
              <Form.Group controlId="formBasicEmail42" as={Col}>
               <Form.Label>Select CSV File</Form.Label>
               <input
                 type="file"
                 accept=".csv,.xlsx,.xls"
                
                 id='csv'
                 validaterule={['required']}
                 validatemsg={['This field is required']}
               />
                    
               </Form.Group>

              
             </Form.Row>
            
            

           </Col>
          
         </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
