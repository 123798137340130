import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import '../App.css';

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import FrontendRoute from './Layout/Frontend.js';

import EmployeRoute from './Layout/Employe.js';
// import Login from './Auth/Login';
import Login from './Auth/Login';
import CancellationPolicy from "../Cmponents/Jobseeker/cancellationPolicy.js";
import Home from './Home/home';
import AffiliateReffer from './Home/AffiliateReffer';
import ForgetPassword from './Auth/ForgetPassword';
import ChangePassword from './Auth/ChangePassword'
  ;
import Dashboard from './Dashboard/Dashboard';



import SponserDashboard from './Sponser/SponserDashboard';
import PersonalProfile from './Sponser/PersonalProfile'
import CompanyProfile from './Sponser/CompanyProfile'
import NgoProfile from './Sponser/NgoProfile'
import RedemCode from './Sponser/RedemCode'
import SponsershipList from './Sponser/SponsershipList'
import SponserSettings from './Sponser/SponserSettings'
import Notifications from './Sponser/Notifications'
import MemberJobseeker from './Sponser/MemberJobsekerList'

import DonateHistory from './Sponser/PaymentHistory'


import ManageUser from './Sponser/Member/UserList'
import CreateMember from './Sponser/Member/CreateMember'
import UpdateMember from './Sponser/Member/UpdateMember'

import cards from './Sponser/Cards/CardList'
import CreateCard from './Sponser/Cards/CreateCard'
import PasswordChange from './Sponser/PasswordChange'
import PaymentPrice from './Sponser/PaymentPrice';

// sponsor bulk referal
import SponsorReferalGroupDetails from './Sponser/SponsorReferalGroupDetails';
import SponsorReferalContactDetails from './Sponser/SponsorReferalContactDetails';


//campaign sponsor
import SponsorgroupDetails from './Sponser/SponsorgroupDetails'
import SponsorContactDetails from './Sponser/SponsorContactDetails'
import SponsorTempleteDetails from './Sponser/SponsorTempleteDetails'
import SponsorCampaignDetails from './Sponser/SponsorCampaignDetails'
import AddCampaignSponsor from './Sponser/addCampaign'
import EditCampaignSponsor from './Sponser/EditCampaign'
import AddEmailSettingsCampaign from './Sponser/addAffiliateEmailSetting'

// Jobseeker
import JobseekerDashboard from './Jobseeker/JobseekerDashboard'
import JobseekerKnowledgebase from './Jobseeker/JobseekerKnowledgebase';
import JobseekerCareerMaster from './Jobseeker/JobseekerCareerMaster';
import PasswordChangeJobseeker from './Jobseeker/PasswordChangeJobseeker'
import JobseekerPersonalProfile from './Jobseeker/JobseekerPersonalProfile'
import HrContacts from './Jobseeker/HrContacts'
import MyHrContactsList from './Jobseeker/MyHrContactsList'
import TopFiveCoaches from './Jobseeker/TopFiveCoaches'
import JobAlert from './Jobseeker/JobAlert'
import FreeEbooks from './Jobseeker/FreeEbooks'
import Recruiter from './Jobseeker/Recruiter'
import Purchase from './Jobseeker/Purchase/Purchase'
import Cart from './Jobseeker/Cart/Cart'
import JobseekerPaymentHistory from './Jobseeker/JobseekerPaymentHistory'
import Credittranasctions from './Jobseeker/Credittranasctions'
import BulkUnlockQuee from './Jobseeker/BulkUnlockQuee'
import ResumeTemplates from './Jobseeker/ResumeTemplates'





// Affiliate
import AffiliateDashboard from './Affiliate/AffiliateDashboard'
import AffiliatePersonalProfile from './Affiliate/AffiliatePersonalProfile'
import AffiliateContactDetails from './Affiliate/AffiliateContactDetails'
import AffiliateSponsershipList from './Affiliate/SponsershipList'
import AffiliateJobseekerList from './Affiliate/AffiliateJobseekerList'
import AffiliateUpdateContact from './Affiliate/UpdateContactdetails'
import AffiliateCampaignDetails from './Affiliate/AffiliateCampaignDetails'
import AffiliateTempleteDetails from './Affiliate/AffiliateTempleteDetails'
import AddCampaign from './Affiliate/addCampaign'
import EditCampaign from './Affiliate/EditCampaign'
import AffiliategroupDetails from './Affiliate/AffiliategroupDetails'
import AffiliateCommisionReport from './Affiliate/AffiliateCommisionReport'
import AffiliateEmployeeCommision from './Affiliate/AffiliateEmployeeCommision'
import AddAffiliateBankDetails from './Affiliate/AffiliateBankDetails'
import AddBankDetails from './Affiliate/addAffiliateBankDetails'
import EditBankDetails from './Affiliate/EditAffiliateBankDetails'

import ManageUserAffiliate from './Affiliate/Member/UserList'
import CreateMemberAffiliate from './Affiliate/Member/CreateMember'
import UpdateAffiliate from './Affiliate/Member/UpdateMember'
import NgoProfileAffiliate from './Affiliate/NgoProfile'
import CompanyProfileAffiliate from './Affiliate/CompanyProfile'
import EmployeeCommissionAffiliate from './Affiliate/EmployeeCommission'

import AddEmailSettings from './Affiliate/addAffiliateEmailSetting'
import AffiliatePromocode from './Affiliate/AffiliatePromocode'
import CreateAffiliatePromocode from './Affiliate/CreateAffiliatePromocode'
import EditAffiliatePromocode from './Affiliate/EditAffiliatePromocode'
import AffiliateReferalGroupDetails from './Affiliate/AffiliateReferalGroupDetails'
import AffiliateReferalContactDetails from './Affiliate/AffiliateReferalContactDetails';

import AffiliateSignup from './Home/AffiliateSignup'


// end

import EmployeDashboard from './Dashboard/EmployeDashboard';


import SponserHome from './Home/SponserHome';
import JobSeekerHome from './Home/JobSeeker';
import SponserLanding from './Home/SponserLanding';

import test from './test/from'
import RefferFriend from './Sponser/RefferFriend';

import Notfound from './Layout/Notfound';
import Logout from './Helpers/Logout';
import JobAssistSignup from './jobassist/JobAssistSignup';
import JobOutPlacement from './jobassist/JobOutPlacement';





import FeedbackForm from './Jobseeker/middleSection/FeedBackForm';
import FeedBackNotifications from './Sponser/FeedBackNotifications';
import SponsorKnowledgeBase from './Sponser/SponsorKnowledgeBase';
import RenewSubscription from './Jobseeker/middleSection/RenewSubscription';

function App() {
  return (
    <div className="RouteGroup">
      <HashRouter>
        <Switch>


          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route exact path="/cancellation-policy" component={CancellationPolicy} />


          <FrontendRoute exact path="/feedback-form" component={FeedbackForm} auth="yes" />

          <FrontendRoute exact path="/renew-subscription" component={RenewSubscription} />
          <FrontendRoute exact path="/logout" component={Logout} />
          <FrontendRoute exact path="/test" component={test} />
          <FrontendRoute exact path="/sponser/:code" component={SponserLanding} />
          <FrontendRoute exact path="/sponsor/:code" component={SponserLanding} />
          <FrontendRoute exact path="/becomesponsor" component={SponserHome} />
          <FrontendRoute exact path="/becomejobseeker/:code?" component={JobSeekerHome} />
          <FrontendRoute exact path="/affiliate-becomejobseeker/:fcode/:pcode" component={JobSeekerHome} />

          <FrontendRoute exact path="/affiliate/:code" component={Home} />
          <FrontendRoute exact path="/affiliateMember/:code" component={Home} />
          <FrontendRoute exact path="/affiliate-signup/:code?" component={AffiliateSignup} />

          {/* Old route not using now  2023 5 january*/}
          {/* <FrontendRoute exact path="/affiliatesponsor/:code" component={SponserHome}/>
            <FrontendRoute exact path="/affiliatejobseeker/:affiliatecode" component={JobSeekerHome}/> */}

          {/* For jobseeker and sponsor signup */}
          <FrontendRoute exact path="/affiliate/:code/:role" component={AffiliateReffer} />
          <FrontendRoute exact path="/affiliateMember/:code/:role" component={AffiliateReffer} />


          <FrontendRoute exact path="/login" component={Login} />
          <FrontendRoute exact path="/forget-password" component={ForgetPassword} />
          <FrontendRoute exact path="/change-password/:token" component={ChangePassword} />

          <FrontendRoute exact path="/refer/:refercode" component={Home} />

          <FrontendRoute exact path="/dash" component={Dashboard} auth="yes" roles={[4]} />




          <FrontendRoute exact path="/sponser-dash" component={SponserDashboard} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/sponsor-knowledgebase" component={SponsorKnowledgeBase} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/sponser-personal-profile" component={PersonalProfile} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/reedem-code" component={RedemCode} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/jobseeker-list" component={SponsershipList} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/password-change/" component={PasswordChange} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/member-jobseeker-list" component={MemberJobseeker} auth="yes" roles={[2, 5, 6, 3, 7, 13, 14, 15, 16]} />


          <FrontendRoute exact path="/donate-history" component={DonateHistory} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />

          <FrontendRoute exact path="/cards" component={cards} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/create-card" component={CreateCard} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />

          <FrontendRoute exact path="/sponser-company-profile" component={CompanyProfile} auth="yes" roles={[2, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/sponser-ngo-profile" component={NgoProfile} auth="yes" roles={[6]} />

          <FrontendRoute exact path="/members" component={ManageUser} auth="yes" roles={[2, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/create-members" component={CreateMember} auth="yes" roles={[2, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/update-members/:id" component={UpdateMember} auth="yes" roles={[2, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/invitations" component={RefferFriend} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/settings" component={SponserSettings} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/payment" component={PaymentPrice} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/notification" component={Notifications} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16]} />

          <FrontendRoute exact path="/feedback-notification" component={FeedBackNotifications} auth="yes" roles={[2, 5, 6, 13, 14, 15, 16,4]} />

          <FrontendRoute exact path="/employe-dash" component={SponserDashboard} auth="yes" roles={[3]} />
          <FrontendRoute exact path="/referal-group-details" component={SponsorReferalGroupDetails} auth="yes" roles={[13, 14]} />
          <FrontendRoute exact path="/referal-contact-details/:id" component={SponsorReferalContactDetails} auth="yes" roles={[13, 14]} />



          {/* Job Seeker */}
          <FrontendRoute exact path="/jobseeker-dash" component={JobseekerDashboard} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/knowledge-base" component={JobseekerKnowledgebase} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/career-master" component={JobseekerCareerMaster} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-hrcontacts" component={HrContacts} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-hrcontacts-list/:id" component={MyHrContactsList} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-topcoaches" component={TopFiveCoaches} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-jobalert" component={JobAlert} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-password-change/" component={PasswordChangeJobseeker} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-personal-profile" component={JobseekerPersonalProfile} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/free-ebooks" component={FreeEbooks} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/resume-templates" component={ResumeTemplates} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/recruiter-list" component={Recruiter} auth="yes" roles={[4]} />
          {/* <FrontendRoute exact path="/jobseeker-purchase" component={Purchase} auth="yes" roles={[4]}/> */}
          <FrontendRoute exact path="/jobseeker-purchase" component={Cart} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-payment-history" component={JobseekerPaymentHistory} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-credit-transactions" component={Credittranasctions} auth="yes" roles={[4]} />
          <FrontendRoute exact path="/jobseeker-bulk-unlock-quee" component={BulkUnlockQuee} auth="yes" roles={[4]} />


          {/*affiliate */}

          <FrontendRoute exact path="/affiliate-dash" component={AffiliateDashboard} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/affiliate-personal-profile" component={AffiliatePersonalProfile} auth="yes" roles={[8, 9, 10, 11, 12]} />
          <FrontendRoute exact path="/contact-details" component={AffiliateContactDetails} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/affiliate-sponsor-list" component={AffiliateSponsershipList} auth="yes" roles={[9, 10, 11, 12]} />
          <FrontendRoute exact path="/affiliate-jobseeker-list" component={AffiliateJobseekerList} auth="yes" roles={[8, 9, 10, 11, 12]} />
          <FrontendRoute exact path="/update-contact/:id" component={AffiliateUpdateContact} auth="yes" roles={[8, 9, 10]} />

          <FrontendRoute exact path="/campaign-details" component={AffiliateCampaignDetails} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/create-campaign" component={AddCampaign} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/templete-details" component={AffiliateTempleteDetails} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/edit-campaign/:code" component={EditCampaign} auth="yes" roles={[8, 9, 10]} />

          <FrontendRoute exact path="/contact-group-details" component={AffiliategroupDetails} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/contact-details/:code" component={AffiliateContactDetails} auth="yes" roles={[8, 9, 10, 16]} />
          <FrontendRoute exact path="/commission-report/" component={AffiliateCommisionReport} auth="yes" roles={[8, 9, 10, 11, 12]} />
          <FrontendRoute exact path="/employee-commission/" component={AffiliateEmployeeCommision} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/add-affiliate-bank/" component={AddAffiliateBankDetails} auth="yes" roles={[8, 9, 10, 11, 12]} />
          <FrontendRoute exact path="/add-bank/" component={AddBankDetails} auth="yes" roles={[8, 9, 10, 11, 12]} />
          <FrontendRoute exact path="/edit-bank/:code" component={EditBankDetails} auth="yes" roles={[8, 9, 10, 11, 12]} />

          <FrontendRoute exact path="/add-email-setting/" component={AddEmailSettings} auth="yes" roles={[8, 9, 10]} />

          {/* sponsor campaign link */}
          <FrontendRoute exact path="/contact-group-details-sponsor" component={SponsorgroupDetails} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/contact-details-sponsor/:code" component={SponsorContactDetails} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/templete-details-sponsor" component={SponsorTempleteDetails} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/campaign-details-sponsor" component={SponsorCampaignDetails} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/create-campaign-sponsor" component={AddCampaignSponsor} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/edit-campaign-sponsor/:code" component={EditCampaignSponsor} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />
          <FrontendRoute exact path="/add-campaign-email-setting" component={AddEmailSettingsCampaign} auth="yes" roles={[2, 3, 5, 6, 7, 13, 14, 15, 16]} />

          {/*affiliate member */}
          <FrontendRoute exact path="/members-affiliate" component={ManageUserAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/create-members-affiliate" component={CreateMemberAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/update-members-affiliate/:id" component={UpdateAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/affiliate-company-profile" component={CompanyProfileAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/employee-commission-setting" component={EmployeeCommissionAffiliate} auth="yes" roles={[9, 10]} />

          {/*affiliate member Login */}
          <FrontendRoute exact path="/affiliate-member-dash" component={AffiliateDashboard} auth="yes" roles={[11, 12]} />
          {/*affiliate promocode */}
          <FrontendRoute exact path="/affiliate-promocode" component={AffiliatePromocode} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/create-affiliate-promocode" component={CreateAffiliatePromocode} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/edit-affiliate-promocode/:id" component={EditAffiliatePromocode} auth="yes" roles={[8, 9, 10]} />
          <FrontendRoute exact path="/affiliate-referal-group-details" component={AffiliateReferalGroupDetails} auth="yes" roles={[]} />
          <FrontendRoute exact path="/affiliate-referal-contact-details/:id" component={AffiliateReferalContactDetails} auth="yes" roles={[8, 9, 10]} />

          {/* jobassist */}

          <FrontendRoute exact path="/jobassist-signup/:code?" component={JobAssistSignup} />

          <FrontendRoute exact path="/joboutplacement/:jobassist_code" component={JobOutPlacement} />




          <FrontendRoute component={Notfound} />
        </Switch>
      </HashRouter>


    </div>
  );
}

export default App;
