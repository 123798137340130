import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  TokenVerify,{ FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class FeedBack extends React.Component {
  constructor(props)
  {
      super(props)
      this.state = {
                      FeedBackMessages:{},
                      feedbackLIst:'',
                     
                      FromData: {
                                      FeedBackId : '',
                                      FeedBackMessage : "",
                                     
                                  },
                      FromDataError :
                                      {
                                        FeedBackId : '',
                                        FeedBackId : '',
                                      },
                      Msg: '',
                      MsgType:'',
                      loader:'hidden',
                      

                      
                   };

      //Binding

      this.handaleChange = this.handaleChange.bind(this)
      this.handaleSubmit = this.handaleSubmit.bind(this)
      

  }

  validateForm  (errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if(value.length > 0 )
      {

        if(value=='false')
        {
          FromDataError[key] = 'This field is required';
          sta.setState({FromDataError})
        }
        
        valid = false;
      }
    }
  
    return valid;
};


validateForm  (errors) {
  let valid = true;
  let sta = this;
  let FromDataError = this.state.FromDataError;

  for (const [key, value] of Object.entries(errors)) {
    if(value.length > 0 )
    {

      if(value=='false')
      {
        FromDataError[key] = 'This field is required';
        sta.setState({FromDataError})
      }
      
      valid = false;
    }
  }

  return valid;
};

        handaleChange(data)
                {
                let sta = this;
                let FromDataError = this.state.FromDataError;


                let name = data.target.name;
                let value = data.target.value;
                let FromData = this.state.FromData;

                 
                FromData.FeedBackMessage = this.state.FeedBackMessages[value]

                FromData[name] = value;

                this.setState({FromData},()=>{

              }) 

                // //validate from
                // var valid_obj = {
                // value:value,
                // rules:data.target.getAttribute("validaterule"),
                // message:data.target.getAttribute("validatemsg")
                // }

                //     validation(valid_obj).then((error)=>{
                //         FromDataError[name] = error
                //         this.setState({FromDataError}) //from error state
                //     })


        }

handaleSubmit(e)
        {   

        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;

        console.log(this.state)
        // return 0;




        if(this.validateForm(FromDataError) && FromData.message != '')
        {

        sta.setState({loader:'visible'})///loader on


              var formdata = new FormData();
              formdata.append("feedback_id", FromData.FeedBackId  || '');
              
            

              let request = {
                end_point : '/create-notification',
                formdata:formdata,
                token: localStorage.getItem("gs_token")

              };

              console.log('......request',formdata)

              POST_API(request).then(resp=>{

                sta.setState({loader:'hidden'})///loader off


                      if(resp.status == 'success')
                      {
                        TokenVerify().then(()=>{
                          sta.setState({Msg:'Notification Send Successfully',MsgType:'success'})
                          setTimeout(() => {
                           window.location.reload()
                          }, 3000);
                          
                        }).catch(()=>{
                          sta.setState({Msg:'Notification Send Successfully',MsgType:'success'})
                          setTimeout(() => {
                           window.location.reload()
                          }, 3000);
                        })
                      
                        
                      }
                      else{
                          
                          
                              sta.setState({Msg:'Notification Send Failed',MsgType:'danger'})
                      }
                      }).catch((ee)=>{
                      sta.setState({loader:'hidden'})///loader on
                      sta.setState({Msg:'Something Went Wrong. Try After Some Time.',MsgType:'danger'})
                          console.log('/InvittationERR',ee)
                      });
                    


        }else
        {
          sta.setState({Msg:'Fill the form correctly!'})
          sta.setState({MsgType:'danger'})
        }


}

componentDidMount()
{
  let sta =this
  let {FeedBackMessages} =this.state
  let request = {
                end_point : '/feedback-list',
                
                token: localStorage.getItem("gs_token")
          }

          sta.setState({loader:'visible'})///loader on

          GET_API(request).then((resp)=>{
          sta.setState({loader:'hidden'})///loader off

          console.log(resp)

          if(resp.status == 'success')
          {
          let feedbackLIst = resp.data
          this.setState({feedbackLIst})

         

                for (const [key, value] of Object.entries(feedbackLIst)) {
                  FeedBackMessages[value.feed_id]=value.feedback_body
                  this.setState({FeedBackMessages})
                 
                }
          }

          }).catch(()=>{
          sta.setState({loader:'hidden'})///loader off


          })
}
  
 
 
  render() {
    
    let {FeedBackMessages,feedbackLIst} =this.state
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         size="lg"
         centered
         >
          <Modal.Header closeButton>
            <Modal.Title>Give Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Loader loadmsg="please wait" visibility={this.state.loader}/>
          <Form
                          className="tabform"
                          onSubmit={this.handaleSubmit}
                        >
                          <Row className="m-0 mt-4 mb-4">
                            <Col md={12} className="pl-0 pr-5 ">
                                                {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                            {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
              
              
                              
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Subject</Form.Label>
                                    <Form.Control
                                     as="select" defaultValue="Type" name="FeedBackId" onChange={this.handaleChange}>
                                    <option value="">Select</option>

                                    {(feedbackLIst!='')?(<>
                                            {feedbackLIst.map((data,key) => {
                                              
                                          return(
                                            // {console.log(FeedBackMessages)}
                                          <option key={key} value={data.feed_id}>{data.feedback_subject}</option>
                                             

                                          )
                                        })}

                                    </>):(<></>) }


                                    
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.FeedBackId=='false'?'': this.state.FromDataError.FeedBackId}</p>
                                </Form.Group> 
                              
                                <Form.Group controlId="formGridAddress1" className="ck-editor__editable_inline">
                                  <Form.Label>Message</Form.Label>
                                        <CKEditor 
                                           disabled ={true}
                                            editor={ ClassicEditor }
                                            data={this.state.FromData.FeedBackMessage}
                                            onInit={ editor => {
                                                // You can store the "editor" and use when it is needed.
                                               // console.log( 'Editor is ready to use!', editor );
                                            } }
                                            onChange={ ( event, editor ) => {
                                              
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                //console.log( 'Blur.', editor );
                                            } }
                                            onFocus={ ( event, editor ) => {
                                                //console.log( 'Focus.', editor );
                                            } }
                                        />
                                       
                                </Form.Group>

                                
                            

                               

                            </Col>
                            <Col md={8} className="pl-5 tabformRight">

                              {(this.state.FromData.FeedBackMessage !='')? (<>
                              
                                <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                              >
                                Send FeedBack
                              </Button>
                              
                              </>) : (<></>)}
                            
                            </Col>
                          </Row>
                        </Form>
          </Modal.Body>
         
        </Modal>
      </div>
    );
  }
}
