import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Image,
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader, roleName, roleImg } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import Leftpanel from "./Section/LeftPanel";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import profileimageRemove from "../images/x-circle.svg";

import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

class NgoProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        company_logo: "",
        company_banner: "",
        company_name: "",
        company_email: "",
        company_tagline: "",
        company_employee_no: "",
        company_address: "",
        company_weburl: "",
        company_description: "",
        //personal Profile
        profileimage: "",
        firstname: "",
        lastname: "",
        contact_no: "",
        address: "",
      },
      FromDataError: {
        company_logo: "",
        company_banner: "",
        company_name: "false",
        company_email: "false",
        company_tagline: "",
        company_employee_no: "false",
        company_address: "",
        company_weburl: "false",
        company_description: "",
        //personal Profile
        profileimage: "false",
        firstname: "false",
        lastname: "false",
        contact_no: "false",
        address: "false",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/sponser-company-profile",
      help: "",
      profileimageCheck: null,
      company_banner_check: null,
      company_logo_check: null
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
  }

  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }
  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
      }
    }

    return valid;
  }

  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (
      name == "company_logo" ||
      name == "company_banner" ||
      name == "profileimage"
    ) {
      value = data.target.files[0];
    } else {
      value = data.target.value;
    }

    FromData[name] = value;

    this.setState({ FromData }, () => { });

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError }); //from error state
    });
  }

  handaleSubmit(e) {
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    // console.log(this.state)
    // return 0;

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();

      if (
        FromData.company_logo != undefined &&
        typeof FromData.company_logo == "object"
      ) {
        formdata.append(
          "company_logo",
          FromData.company_logo,
          FromData.company_logo.name
        );
      }

      if (
        FromData.company_banner != undefined &&
        typeof FromData.company_banner == "object"
      ) {
        formdata.append(
          "company_banner",
          FromData.company_banner,
          FromData.company_banner.name
        );
      }
      formdata.append("company_name", FromData.company_name || "");
      formdata.append("company_email", FromData.company_email || "");
      formdata.append(
        "company_employee_no",
        FromData.company_employee_no || ""
      );
      formdata.append(
        "company_description",
        FromData.company_description || ""
      );

      formdata.append("company_weburl", FromData.company_weburl || "");

      formdata.append("company_address", FromData.company_address || "");
      formdata.append("company_tagline", FromData.company_tagline || "");

      //Personal Profile
      if (
        FromData.profileimage != undefined &&
        typeof FromData.profileimage == "object"
      ) {
        formdata.append(
          "profileimage",
          FromData.profileimage,
          FromData.profileimage.name
        );
      }
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("contact_no", FromData.contact_no);
      formdata.append("address", FromData.address);

      let request = {
        end_point: "/edit-company-profile-details",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      console.log("......request", formdata);

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            sta.setState({
              Msg: "NGO Profile Update Successfully",
              MsgType: "success",
            });

            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            sta.setState({
              Msg: " NGO Profile Update Failed",
              MsgType: "danger",
            });
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader on

          console.log("/CompanyEditERR", ee);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    try {
      if (localStorage.getItem("gs_user") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        sta.setState({ loader: "visible" }); ///loader on

        GET_API({
          token: localStorage.getItem("gs_token"),
          end_point: "/get-profile-details",
        })
          .then((resp) => {
            if (resp.status == "success") {
              sta.setState({ profileimageCheck: resp.data.user.profileimageCheck })
              sta.setState({ company_logo_check: resp.data.company_profile.company_logo_check })
              sta.setState({ company_banner_check: resp.data.company_profile.company_banner_check })
              localStorage.setItem('profile_image', resp.data.user.profileimage)

              FromData["company_logo"] =
                resp.data.company_profile.company_logo || "";
              // FromDataError['company_logo'] = resp.data.company_profile.company_logo!=null? '' : 'false';

              FromData["company_banner"] =
                resp.data.company_profile.company_banner || "";
              // FromDataError['company_banner'] = resp.data.company_profile.company_banner!=null? '' : 'false';

              FromData["company_name"] =
                resp.data.company_profile.company_name || "";
              FromDataError["company_name"] =
                resp.data.company_profile.company_name != null ? "" : "false";

              FromData["company_email"] =
                resp.data.company_profile.company_email;
              FromDataError["company_email"] =
                resp.data.company_profile.company_email != null ? "" : "false";

              FromData["company_tagline"] =
                resp.data.company_profile.company_tagline;
              //FromDataError['company_tagline'] = resp.data.company_profile.company_tagline!=null? '' : '';

              FromData["company_address"] =
                resp.data.company_profile.company_address;
              FromDataError["company_address"] =
                resp.data.company_profile != null ? "" : "false";

              FromData["company_weburl"] =
                resp.data.company_profile.company_weburl;
              FromDataError["company_weburl"] =
                resp.data.company_profile.company_weburl != null ? "" : "false";

              FromData["company_description"] =
                resp.data.company_profile.company_description || "";
              FromDataError["company_description"] =
                resp.data.company_profile.company_description != null ? "" : "";

              FromData["company_employee_no"] =
                resp.data.company_profile.company_employee_no || "";
              FromDataError["company_employee_no"] =
                resp.data.company_profile.company_employee_no != null
                  ? ""
                  : "false";

              //Personal Profile
              FromData["firstname"] = resp.data.user.firstname;
              FromDataError["firstname"] =
                resp.data.user.firstname != null ? "" : "false";

              FromData["lastname"] = resp.data.user.lastname;
              FromDataError["lastname"] =
                resp.data.user.lastname != null ? "" : "false";

              FromData["profileimage"] = resp.data.user.profileimage || "";
              FromDataError["profileimage"] =
                resp.data.user.profileimage != null ? "" : "false";

              FromData["contact_no"] = resp.data.profile.contact_no;
              FromDataError["contact_no"] =
                resp.data.profile.contact_no != null ? "" : "false";

              FromData["user_email"] = resp.data.user.email;

              FromData["address"] =
                resp.data.profile.address != null
                  ? resp.data.profile.address
                  : "";
              FromDataError["address"] =
                resp.data.profile.address != null ? "" : "";

              this.setState({ FromData });
            }
            sta.setState({ loader: "hidden" }); ///loader off
          })
          .catch((ee) => {
            sta.setState({ loader: "hidden" }); ///loader off

            console.log("/userdetailsEditERR", ee);
          });
      }
    } catch (error) { }

    //help api
    let requestHelp = {
      end_point: "/help-details?key=sponsor_organization_profile",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }

  deleteProfileImg = async () => {
    let self = this;
    const cnfirm = window.confirm(
      `Are you sure you want to delete this profile image?`
    )
    console.log("alert", cnfirm)
    if (cnfirm) {

      let request = {
        end_point: '/delete-user-profile-image',
        token: localStorage.getItem("gs_token")

      };

      POST_API(request).then(resp => {

        self.setState({ loader: 'hidden' })///loader off
        console.log("delete image", resp)

        if (resp.status == 'success') {
          self.setState({ Msg: 'Profile image deleted successfully', MsgType: 'success' })

          // this.getuserDetails()
          setTimeout(() => {
            window.location.reload()

          }, 3000);

        }
        else {


          self.setState({ Msg: 'Profile image delete Failed', MsgType: 'danger' })
        }
      }).catch((ee) => {

        console.log('/userEditERR', ee)
      });


    }
  }

  deleteCompanyImg = async (imageType) => {
    let self = this;
    const cnfirm = window.confirm(
      `Are you sure you want to delete this organization image?`
    )
    console.log("alert", cnfirm)
    if (cnfirm) {
      var formdata = new FormData();
      formdata.append('imageType', imageType);

      let request = {
        end_point: '/delete-company-image',
        token: localStorage.getItem("gs_token"),
        formdata: formdata
      };

      POST_API(request).then(resp => {

        self.setState({ loader: 'hidden' })///loader off
        console.log("delete image", resp)

        if (resp.status == 'success') {
          self.setState({ Msg: 'Image deleted successfully', MsgType: 'success' })

          // this.getuserDetails()
          setTimeout(() => {
            window.location.reload()

          }, 3000);

        }
        else {


          self.setState({ Msg: 'Image delete Failed', MsgType: 'danger' })
        }
      }).catch((ee) => {

        console.log('/userEditERR', ee)
      });


    }
  }


  render() {
    let FromData = this.state.FromData;
    console.log(FromData);

    return (
      <div>
        {FromData.hasOwnProperty("company_name") ? (
          <>
            <Container fluid className="dash_container">
              <Row>
                {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
                <Col md={12} className="pt-4 pb-4">
                  <Loader loadmsg="please wait" visibility={this.state.loader} />

                  <div className="tabpanelOneForm">

                    <h3>Organization Profile</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.help,
                      }}
                    ></p>


                    <Form
                      className="tabform"
                      onSubmit={this.handaleSubmit}
                    >
                      <Row className="m-0 mt-4 mb-4">
                        <Col md={12} className="pl-0">
                          {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                          {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}



                          <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Col md={12}>
                                {typeof FromData.company_logo == "string" ? (

                                  <>
                                    <img
                                      src={FromData.company_logo}
                                      className="img-fluide pimage_logo"
                                    />

                                    {this.state.company_logo_check != null ?
                                      <img src={profileimageRemove} onClick={() => this.deleteCompanyImg('logo')} className="delete_image" height="20px" width="20px" />
                                      : ""
                                    }

                                  </>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Form.Label>Organization Logo (jpeg, jpg, png)</Form.Label>
                              <Form.File
                                id="custom-file-translate-scss"
                                name="company_logo"
                                onChange={this.handaleChange}
                                label={
                                  FromData.company_logo.name || "Choose file"
                                }
                                validaterule={[
                                  "filesize|250000",
                                  "mime|jpeg/png",
                                ]}
                                validatemsg={[]}
                                lang="en"
                                custom
                              />
                              <p style={{ color: 'red' }}><small>Maximum file size allowed 2mb</small></p>
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_logo ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.company_logo}
                              </p>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Col md={12}>
                                {typeof FromData.company_banner == "string" ? (

                                  <>
                                    <img
                                      src={FromData.company_banner}
                                      className="img-fluide pimage_banaer"
                                    />

                                    {this.state.company_banner_check != null ?
                                      <img src={profileimageRemove} onClick={() => this.deleteCompanyImg('banaer')} className="delete_image" height="20px" width="20px" />
                                      : ""
                                    }

                                  </>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Form.Label>Organization Banner (jpeg, jpg, png)</Form.Label>
                              <Form.File
                                id="custom-file-translate-scss"
                                name="company_banner"
                                onChange={this.handaleChange}
                                label={
                                  FromData.company_banner.name || "Choose file"
                                }
                                validaterule={[
                                  "filesize|250000",
                                  "mime|jpeg/png",
                                ]}
                                validatemsg={[]}
                                lang="en"
                                custom
                              />
                              <p style={{ color: 'red' }}><small>Maximum file size allowed 1mb</small></p>
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_banner ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.company_banner}
                              </p>
                            </Form.Group>
                          </Form.Row>

                          <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>
                                Organization Name
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="company_name"
                                onChange={this.handaleChange}
                                placeholder="Enter Organization name"
                                value={FromData.company_name || ""}
                                validaterule={["required"]}
                                validatemsg={["This field is required"]}
                                readOnly
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_name ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.company_name}
                              </p>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>
                                Organization Email
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="company_email"
                                onChange={this.handaleChange}
                                placeholder="Enter Organization email"
                                value={FromData.company_email || ""}
                                validaterule={["required", "isEmail"]}
                                validatemsg={["This field is required"]}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_email ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.company_email}
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>Organization Tag Line</Form.Label>
                              <Form.Control
                                type="text"
                                name="company_tagline"
                                onChange={this.handaleChange}
                                placeholder="Enter Organization Tag Line"
                                value={FromData.company_tagline || ""}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_tagline ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.company_tagline}
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group controlId="formGridAddress1" as={Col}>
                              <Form.Label>
                                Organization Website Url
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="company_weburl"
                                value={FromData.company_weburl || ""}
                                onChange={this.handaleChange}
                                placeholder="Enter Organization website Url (www.example.com)"
                                validaterule={["required", "org_web_url_check"]}
                                validatemsg={["This field is required", "Please Enter valid URL"]}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_weburl ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.company_weburl}
                              </p>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>
                                Organization Employee Total
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>

                              <Form.Control
                                validaterule={['required']}
                                validatemsg={['This field is required']}
                                as="select" value={FromData.company_employee_no || ""} name="company_employee_no" onChange={this.handaleChange}>
                                <option value="">select</option>
                                <option value="below 50">below 50</option>
                                <option value="50 - 100">50 - 100</option>
                                <option value="100 - 300">100 - 300</option>
                                <option value="300 - 500">300 - 500</option>
                                <option value="500 - 1000">500 - 1000</option>
                                <option value="above 1000">above 1000</option>
                              </Form.Control>


                              {/* <Form.Control
                                type="number"
                                name="company_employee_no"
                                onChange={this.handaleChange}
                                placeholder="Enter your Organization employe total"
                                value={FromData.company_employee_no || ""}
                                validaterule={["required"]}
                                validatemsg={["This field is required"]}
                              /> */}
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.company_employee_no ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError
                                    .company_employee_no}
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <Form.Group controlId="formGridAddress1">
                            <Form.Label>
                              Organization Address
                              <span style={{ color: "red" }}> *</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="company_address"
                              placeholder="Example : 241 Turnpike Rd, Montague, MA, 01351, USA"
                              onChange={this.handaleChange}
                              value={FromData.company_address || ""}
                              validaterule={["required", "min|10"]}
                              validatemsg={[
                                "This field is required",
                                "Enter Full Address",
                              ]}
                            />
                            <p style={{ color: "red" }}>
                              {this.state.FromDataError.company_address ==
                                "false"
                                ? ""
                                : this.state.FromDataError.company_address}
                            </p>
                          </Form.Group>

                          <Form.Group
                            controlId="formGridAddress1"
                            className="ck-editor__editable_inline"
                          >
                            <Form.Label>Organization Description</Form.Label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={FromData.company_description || ""}
                              onInit={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                // console.log( 'Editor is ready to use!', editor );
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                FromData.company_description = data;
                                this.setState({ FromData });
                              }}
                              onBlur={(event, editor) => {
                                //console.log( 'Blur.', editor );
                              }}
                              onFocus={(event, editor) => {
                                //console.log( 'Focus.', editor );
                              }}
                            />
                          </Form.Group>

                          {/* company_description */}
                          <hr />
                          <h3>{roleName(localStorage.getItem('roleID'))} Contact Information</h3>
                          {/*Personal Profile*/}
                          <Form.Row className="align-items-center mb-4">
                            <Col md={3}>
                              {typeof FromData.profileimage == "string" ?
                                <>
                                  <img
                                    src={FromData.profileimage}
                                    className="img-fluide pimage"
                                  />

                                  {this.state.profileimageCheck != null ?
                                    <img src={profileimageRemove} onClick={() => this.deleteProfileImg()} className="delete_image" height="20px" width="20px" />
                                    : ""
                                  }

                                </>
                                :
                                ""
                              }
                            </Col>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>Profile Image (jpeg, jpg, png)</Form.Label>
                              <Form.File
                                id="custom-file-translate-scss"
                                name="profileimage"
                                onChange={this.handaleChange}
                                label={
                                  FromData.profileimage.name || "Choose file"
                                }
                                validaterule={[
                                  "required",
                                  "filesize|250000",
                                  "mime|jpeg/png",
                                ]}
                                validatemsg={["This field is required"]}
                                lang="en"
                                custom
                              />  
                              <p style={{ color: 'red' }}><small>Maximum file size allowed 1mb</small></p>
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.profileimage ==
                                  "false"
                                  ? ""
                                  : this.state.FromDataError.profileimage}
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>
                                First Name
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="firstname"
                                onChange={this.handaleChange}
                                placeholder="Enter your first name"
                                value={FromData.firstname}
                                validaterule={["required", 'isString']}
                                validatemsg={["This field is required", 'First name must contain alphabets']}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.firstname == "false"
                                  ? ""
                                  : this.state.FromDataError.firstname}
                              </p>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>
                                Last Name
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="lastname"
                                onChange={this.handaleChange}
                                placeholder="Enter your last name"
                                value={FromData.lastname}
                                validaterule={["required", 'isString']}
                                validatemsg={["This field is required", 'Last name must contain alphabets']}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.lastname == "false"
                                  ? ""
                                  : this.state.FromDataError.lastname}
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>
                                Contact No
                                <span style={{ color: "red" }}> *</span>
                              </Form.Label>
                              <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="US"
                                  value={FromData.contact_no}
                                  name="contact_no"
                                  onChange={this.handalePhoneNoChange.bind(this)}
                                  validaterule={['required', 'phone']}
                                  validatemsg={['This field is required', 'Invalid Phone number']}
                                />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.contact_no == "false"
                                  ? ""
                                  : this.state.FromDataError.contact_no}
                              </p>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="user_email"
                                value={FromData.user_email}
                                onChange={this.handaleChange}
                                readOnly
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Group controlId="formGridAddress1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="address"
                              onChange={this.handaleChange}
                              placeholder="Example : 241 Turnpike Rd, Montague, MA, 01351, USA"
                              value={FromData.address}
                            // validaterule={["nullable", "min|10"]}
                            // validatemsg={[
                            //   "This field is required",
                            //   "Enter Full Address",
                            // ]}
                            />
                            <p style={{ color: "red" }}>
                              {this.state.FromDataError.address == "false"
                                ? ""
                                : this.state.FromDataError.address}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col md={12} className="pl-0 tabformRight">
                          <Button
                            variant="primary"
                            type="submit"
                            style={{ float: 'left' }}
                            className="btn-main tabformbutton"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}

export default NgoProfile;
