import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Dropdown, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import moment from "moment";
import JobseekercPaymentCredit from "../Jobseeker/JobseekercPaymentCredit";
import { useDispatch, useSelector } from "react-redux";

const Credittranasctions = (props, dispatch) => {
    const [userData, setUserdata] = useState({});
    const [transactions, setTransaction] = useState([]);
    const [transactionsCount, setTransactionCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState("created_at");
    const [sortD, setSortD] = useState("desc");
    const [search, setSearch] = useState("");
    const [tType, setType] = useState("");
    const [searchType, setSearchType] = useState(false);
    const [refillModal, setrefillModal] = useState(false);
    const [customDiv, setcustomDiv] = useState(false);
    const [amount, setAmount] = useState(0);
    const [credit, setCredit] = useState(0);
    const [PaymentModal, setPaymentModal] = useState(false)
    //const [creditBalance, setcreditBalance] = useState(0);
    const [customerror, setcustomerror] = useState(false);
    const [customerrormsg, setcustomerrormsg] = useState("");
    const [customCreditNumber, setcustomCreditNumber] = useState("");



    useEffect(() => {
        console.log(dispatch);
        let ud = localStorage.getItem("gs_user")
        ud = JSON.parse(ud)
        setUserdata(ud)
        setLoading(true)
        GET_API({
            end_point: `/get-credit-transactions?page=${page}&limit=${limit}&sort=${sort}&sortD=${sortD}&search=${search}&type=${tType}`,
            token: localStorage.getItem("gs_token")
        }).then((resp) => {
            setLoading(false)
            setSearchType(false);
            if (resp.status == "success") {
                setTransaction(resp.data);
                setTransactionCount(resp.total);

            }

        })
            .catch((err) => {
                setLoading(false)
                console.log("creditTransactions", err)
            })







    }, [limit, page, sort, sortD, search, searchType])


    useEffect(() => {
        setAmount(0)
        setCredit(0)
        setcustomCreditNumber("")

        setrefillModal(false)

        setLoading(true)
        setcustomDiv(false)
        GET_API({
            end_point: `/get-credit-transactions?page=${page}&limit=${limit}&sort=${sort}&sortD=${sortD}&search=${search}&type=${tType}`,
            token: localStorage.getItem("gs_token")
        }).then((resp) => {
            setLoading(false)
            setSearchType(false);
            if (resp.status == "success") {
                setTransaction(resp.data);
                setTransactionCount(resp.total);

            }

        })
            .catch((err) => {
                setLoading(false)
                console.log("creditTransactions", err)
            })
    }, [props.creditBalance])



    const typeChange = (e) => {
        setType(e.target.value);
    }

    const searchAll = () => {
        setSearchType(true);
    }

    const resetAll = () => {
        setSearchType(true);
        setType("");
        document.getElementById("search-form").reset();
    }

    const refillCredit = () => {
        handaleSwitchModal('refillModal');
    }

    const handaleSwitchModal = (type) => {
        if (type == 'refillModal') {
            if (refillModal) {
                setrefillModal(false)
            } else {
                setrefillModal(true)
            }
        }
    }

    const customSectionOpen = () => {
        setcustomDiv(true);
    }

    let purchaseCredits = (e) => {
        if (e.target.dataset.value) {
            let credit = e.target.dataset.value;
            let amount = credit * 1; // 1 represent $1 = 1 credit
            // Open payment popup
            setAmount(amount)
            setCredit(credit)

            setPaymentModal(true)
        }
    }

    let handleClose = () => {
        setPaymentModal(!PaymentModal);
        if (!PaymentModal == false) {
            // setAmount(0)
            // setCredit(0)
            // setcustomCreditNumber("")
        }
    }

    const refillChange = (e) => {
        console.log(e);
        setcustomCreditNumber(e.target.value)
        if (e.target.value > 0) {
            if (e.target.value >= 10 && e.target.value <= 500) {
                let credit = e.target.value;
                let amount = credit * 1; // 1 represent $1 = 1 credit
                // Open payment popup
                setAmount(amount)
                setCredit(credit)

                //setPaymentModal(true)
                setcustomerror(false)
                setcustomerrormsg("");
            } else {
                setcustomerror(true)
                setcustomerrormsg("Credit value should be minimum 10 and maximum 500");
            }
        } else {
            setcustomerror(true)
            setcustomerrormsg("This field is required");
        }
        //creditBalance

    }

    const refillsubmit = () => {
        if (credit > 0) {
            if (customCreditNumber >= 10 && customCreditNumber <= 500) {
                setPaymentModal(true)
            } else {
                setcustomerror(true)
                setcustomerrormsg("Credit value should be minimum 10 and maximum 500");
            }
        } else {
            setcustomerror(true)
            setcustomerrormsg("This field is required");
        }
    }






    let conditionalRowStyles = [
        {
            when: row => (row.type == 1 && row.status == 1),
            style: {
                backgroundColor: "#93ddea"
            }
        },
        {
            when: row => (row.status == 0),
            style: {
                backgroundColor: "#8080807a"
            }
        }
    ];
    let columns = [
        {
            name: "Amount",
            selector: row => { return row.type == 1 ? `- ${row.amount}` : `+ ${row.amount}` },
            sortable: true,
            width: "10%",
            keyField: "amount"
        },
        {
            name: "Transaction Type",
            selector: row => { return row.type == 1 ? `Debit` : `Credit` },

            // sortable: true,
            width: "10%",
        },
        {
            name: "Status",
            selector: row => { return row.status == 1 ? `Success` : `Pending` },

            // sortable: true,
            width: "10%",
        },
        {
            name: "Description",
            selector: "description",
            // sortable: true,
            width: "40%",
        },
        {
            name: "Date",
            selector: row => { return moment(row.created_at).format("MM-DD-YYYY HH:ss") },
            // selector: row => (row.Email != 'null') ? <a href={'mailto:' + row.Email}>{row.Email}</a> : '',
            // selector: row => (row.unlock_contact == '0') ? (row.Email.length > 2) ? row.Email.substring(0, 3) + '*'.repeat(row.Email.length - 2) : '' : <a href={'mailto:' + row.Email}>{row.Email}</a>,
            sortable: true,
            width: "30%",
            keyField: "created_at"

        }
    ]
    const customStyles = {
        header: {
            style: {
                display: 'none',
            },
        },
    };

    return (
        <div className='pt-5 pb-5 data_table_gwi'>
            <Container >
                <Row>
                    <Col md={12}>
                        <h2 className='jobseeker_heading_hr'>Credit Transactions</h2>
                        <div className='w-100 mb-2'>&nbsp;</div>
                        <div style={{ width: "300px", float: "right" }}>
                            <Form.Control
                                size="large"
                                name="searchInput"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                                placeholder="Search"
                            />
                        </div>
                    </Col>
                    <Col md={12}>
                        <Loader loadmsg="please wait" visibility={loading ? "visible" : "hidden"} />


                        <div className='mb-2'>
                            <form id="search-form">
                                <Row>
                                    <Col sm="3">
                                        <label>Transaction Type</label>
                                        <Form.Control as="select" name="transaction_type" onChange={typeChange} defaultValue={''}>
                                            <option value="">--Select Type--</option>
                                            <option value="credit">Credit</option>
                                            <option value="debit">Debit</option>
                                        </Form.Control>
                                    </Col>
                                    <Col sm="1">
                                        <Button
                                            variant="primary"
                                            type="button"
                                            className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                            onClick={searchAll}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                    <Col sm="1" className='pl-4'>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                            onClick={resetAll}
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                    <Col sm="3"></Col>
                                    <Col sm="2"></Col>
                                    <Col sm="2">
                                        <Button
                                            variant="primary"
                                            type="button"
                                            className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                            onClick={refillCredit}
                                        >
                                            <i className="fa fa-plus" aria-hidden="true"></i> Add Credit
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </div>


                        <DataTable
                            title=""
                            columns={columns}
                            data={transactions}
                            defaultSortField="Date"
                            conditionalRowStyles={conditionalRowStyles}
                            noContextMenu
                            customStyles={customStyles}

                            pagination={true}
                            NoDataComponent={"No data"}
                            noHeader={true}
                            paginationServer
                            paginationTotalRows={transactionsCount}
                            onChangeRowsPerPage={(limit) => { setLimit(limit) }}
                            onChangePage={(page) => { setPage(page) }}
                            sortServer
                            onSort={(column, sortDirection) => {
                                // console.log(column)
                                setSort(column.keyField)
                                setSortD(sortDirection)
                            }}

                        />

                    </Col>
                </Row>

            </Container>




            <Modal
                show={refillModal}
                onHide={() => { handaleSwitchModal('refillModal') }}
                // dialogClassName="modal-90w"
                // aria-labelledby="example-custom-modal-styling-title"
                // className='jobseeker_modal'
                centered
            >
                <Modal.Header closeButton style={{ backgroundColor: '#c0f1fa' }}>
                    <Modal.Title>Add Credit</Modal.Title>
                    {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
                </Modal.Header>
                <Modal.Body className='details_drop_custom'>

                    <div className=''>
                        <Row >
                            <Col sm="12">
                                <div className=''>
                                    <div className='cBal d-flex flex-column text-center' >{props.creditBalance} <span>Credits</span></div>
                                    <div className='amt_list d-flex' style={{ marginLeft: '100px' }} onClick={purchaseCredits}>
                                        <span className='cp' data-value="10">+ 10</span>
                                        <span className='cp' data-value="50">+ 50</span>
                                        <span className='cp' data-value="100">+ 100</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {(customDiv == false) ? (<>
                            <Row className="mt-2">
                                <Col sm="12 text-center mt-3">
                                    <a href='javascript:void(0)' className='cr_trans_custom' onClick={() => customSectionOpen()}>Custom</a>
                                </Col>
                            </Row>
                        </>) : (<></>)}
                        {(customDiv) ? (<>
                            <Row className="mt-2">
                                <Col sm="12">
                                    <Row >
                                        <Col sm="12">
                                            <Form.Control
                                                type='number'
                                                name="credit_value"
                                                value={customCreditNumber}
                                                placeholder="Enter Credit Value"
                                                validaterule={["required"]}
                                                validatemsg={[
                                                    "This field is required",
                                                ]}
                                                onChange={refillChange}
                                            />
                                            {(customerror) ? (<>
                                                <p style={{ color: "red" }}>
                                                    {customerrormsg}
                                                </p>
                                            </>) : (<></>)}
                                        </Col>
                                        <Col sm="12 text-center">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                className="btn btn-primary btn-md tabformbutton mt-2 btn_total"
                                                onClick={() => refillsubmit()}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </>) : (<></>)}
                    </div>
                </Modal.Body>

            </Modal>





            {(userData != '' && PaymentModal == true) ? (<>

                <JobseekercPaymentCredit {...props} userDetails={userData} amount={amount} credit={credit} show={PaymentModal} close={handleClose} />
            </>) : (<></>)}
        </div>
    )
}

const WithUseReducer = (props) => {

    let dispatch = useDispatch();
    const creditBalance = useSelector((state) => state.creditBalance);



    return <Credittranasctions {...props} {...{ dispatch, creditBalance }} />;
}

export default WithUseReducer

