import React, { Component } from 'react';
//import "./styles.css";

import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Card } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './cart.css';
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import JobseekerPayment from '../JobseekerPayment'
import ResumeUpload from '../Section/ResumeUpload'
import BundaleRequest from '../Section/BundaleRequest'

import {
    Logo_1,
    Delete_Img,
    career_book,
    career_master,
    coach_master,
    getback_work,
    // hr_direct,
    hri_direct_blue,
    JAlerts,
    // Jobseeker,
    jobseeker_horizontal9x,
    recruiter_direct,
    Resume_Certified,
    // Resume_Scoring_1,
    resume_scoring_blue,
    Resume_Sending,
    // social_profile_scoring_1,
    social_profile_scoring_1_2,
    RSending
}from "../../images";
var _ = require('lodash');

export default class CartPage extends Component {
    constructor(props)
    {
        super(props)
    }
    componentDidMount()
    {
      
    }

    logoDrop(key)
    {
        switch (key) {
            case "logo_1.png": return resume_scoring_blue;break;
            case "logo_2.png": return Resume_Certified;break;
            case "logo_3.png": return JAlerts;break;
            case "logo_4.png": return social_profile_scoring_1_2;break;
            case "logo_5.png": return coach_master;break;
            case "logo_6.png": return career_master;break;
            case "logo_7.png": return career_book;break;
            case "logo_8.png": return recruiter_direct;break;
            case "logo_9.png": return hri_direct_blue;break;
            case "logo_10.png": return jobseeker_horizontal9x;break;
            case "logo_12.png": return getback_work;break;
            case "RSending.png": return RSending;break;
        
            default:
                break;
        }
    }
    render() {
        return (
            <Container fluid className="dash_container">
               <CartPart {...this.props} logoDrop = {this.logoDrop} />
          </Container>
        );
      }
}

class CartPart extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            siteDetails:[],
            userData:{},
            setSpecificSite:{},
            bundaleSite:{},
            otherSite:{},
            promoApplied:{},
            PaymentModal:false,
            hasPromo:false,
            Msg:"",
            MsgType:"",
            promoCode:"",
            tempOrginalPrice:0,
            resumeUpload:false,
            bundaleRequest:false,
            refundApprove:false, // This is checking for is the user will get refund for upgrade to bundale package
            allowBuyBundale:true, //this is checking for user is allowed for buing bundale or not
            subtotalCart:true, // this is checking for subtotal section active/inactive
            loader: 'hidden',
            is_subscribed:false,
            package_ids:[]


        }
    }
    switchResumeModal = ()=>{
        this.setState({resumeUpload:!this.state.resumeUpload})
    }
    switchBundaleRequestModal = ()=>{
        this.setState({bundaleRequest:!this.state.bundaleRequest})
    }
    async componentDidMount()
    {
        this.setState({ loader: 'visible' })///loader on
        // await this.bundalebuyAloowCheck()
        // Bundale buing request check temporary closed
        let request = {
            end_point: "/get-subscriptions",
            token: localStorage.getItem("gs_token"),
          };
      
          console.log("......request", request);
      
          GET_API(request)
            .then((resp) => {
                  // this.setState({ loader: "hidden" }); ///loader off
      
                     
                  
    
             
        let user = localStorage.getItem("gs_user")
            user = JSON.parse(user)
            let package_ids = resp.data.map(e =>e.package_id);
          
                  
            this.setState({mysubscriptions:resp.data,
                package_ids,
                is_subscribed:resp.data.length>0? true: false,
                userData:user
                    },()=>{
                // Upgrade package temporary cloased bundale buy not allow for basic users
                if(this.state.package_ids.includes(1) && this.state.is_subscribed==true)
                // if(this.state.userData.is_subscribed==1)
                {
                    this.props.history.push({
                        pathname: '/jobseeker-dash',
                        state: { Msg: '', Msgtype: '' }
        
                      })
                }
            })
              let request = {
                            end_point: '/list-sites',
                            token: localStorage.getItem("gs_token")

                        };

   


    GET_API(request).then(resp => {

        this.setState({siteDetails:resp.data},()=>{
            console.log(this.state)
        })
        let setSpecificSite;
        let bundaleSite;
        

        let otherSite = _.find(resp.data, { 'id': this.state.userData.jobseeker_from_site? this.state.userData.jobseeker_from_site : 1 });
        
        if(this.state.is_subscribed==false)
        {
            setSpecificSite = _.find(resp.data, { 'id': this.state.userData.jobseeker_from_site? this.state.userData.jobseeker_from_site : 1 });
        }
        else
        {
            setSpecificSite = _.find(resp.data, { 'id': 13 });

            // temporary close this things
            // if(this.state.allowBuyBundale == false)
            // {
            //     this.setState({subtotalCart:false})
            // }
            
        }

        bundaleSite = _.find(resp.data, { 'id': 13 });
        // resp.data.forEach(element => {

        //     // user first payment user will auto selected to specific site pricing
        //     if(this.state.userData.jobseeker_from_site == element.id && this.state.userData.is_paid=="0" )
        //     {
        //         this.setState({setSpecificSite:element},()=>{})
        //     }

        //     //if user trying to upgrading then auto choose bundale package
        //     if(this.state.userData.is_paid=="1" && element.package_id == 1)
        //     {
                
        //         this.setState({setSpecificSite:element},()=>{})
        //     }

        //     //fetch bundale details from loop
        //     if(element.package_id == 1)
        //     {
        //         console.log(element)
        //         this.setState({bundaleSite:element},()=>{})
        //     }
        // });

        //if user come as affiliate refer
        if(this.state.userData.jobseeker_from_site == null && this.state.userData.affiliate_reffered==1)
        {
            setSpecificSite = {...bundaleSite}
           
        }

        this.setState({setSpecificSite,promoApplied:{...setSpecificSite}})
        this.setState({bundaleSite})
        this.setState({otherSite},()=>{
            this.setState({ loader: 'hidden' })///loader off
        })
        

    }).catch((error)=>{
        console.log(error)
        this.setState({ loader: 'hidden' })///loader off

    })


              
          
            }).catch((ee)=>{
                this.setState({ loader: 'hidden' })///loader on
                console.log('/getMySubscription error',ee)
               
        });  

               
    }

    bundalebuyAloowCheck = ()=>{
        let sta = this;
        return new Promise((resolve,reject)=>{
            let request3 = {
                end_point: '/bundle-newsletter-check',
                token: localStorage.getItem("gs_token"),
                
        
            };
        
                POST_API(request3).then(resp => {
        
                            if(resp.status=='success')
                            {
                                //you are elligeble for refund
                                sta.setState({allowBuyBundale:true,subtotalCart:true})
                            }
                            else
                            {
                                sta.setState({allowBuyBundale:false})
        
                            }
                            resolve()
        
        
                        }).catch((error)=>{
                        console.log(error)
                        resolve()
                        })

        })
    }
  

    selctedpackage=(e)=>{
        this.setState({setSpecificSite:e,promoApplied:e})
        this.setState({hasPromo:false,promoCode:''})

    }

    handleClose=(price=0)=>
    {
      
      if(this.state.PaymentModal)
      {
         this.setState({PaymentModal: false})
      }
      else{
        this.setState({price: price,PaymentModal: true})
       
      }
    }
    validatePromo=(e)=>{
        this.setState({ loader: 'visible' })///loader on
        console.log("a",this.state)
        
        
        var promo = document.getElementById("promoCode").value;

        // let promo = e.target.value
           
        let formSavedata = new FormData();
        formSavedata.append("amount",  this.state.setSpecificSite.offerPrice);
        formSavedata.append("promo", promo);
        formSavedata.append("siteId", this.state.setSpecificSite.id);
                
        let requestData = {
            end_point: '/validate-promocode',
            formdata: formSavedata,
            token: localStorage.getItem("gs_token")
  
          };
  
          console.log('......request', formSavedata)
  
  
          POST_API(requestData).then(resp => {
  
         
            this.setState({ loader: 'hidden' })///loader off

  
            if (resp.success == true) {
              this.setState({ Msg: resp.message, MsgType: 'success' })
              let promoApplied = {...this.state.promoApplied}
              promoApplied.offerPrice = resp.amount
            //   console.log(this.state)
            //   return 0;

              this.setState({promoApplied,promoCode:promo},()=>{
                console.log(this.state)
              })
            }
            else {
                this.setState({ Msg: (typeof resp.message == 'string')? resp.message : "Invalid Promo", MsgType: 'danger' })
                this.setState({promoCode:''})
  
  
  
  
            }
  
  
          }).catch((ee) => {
            this.setState({promoCode:''})
         
  
            this.setState({ loader: 'hidden' })///loader off

            this.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })
         
  
  
          });
  
    }
    cleanPromoApplied=()=>{
        if(this.state.hasPromo==false)
        {
            this.setState({promoCode:'',MsgType:'',Msg:'',promoApplied:this.state.setSpecificSite})
        }
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          The "All in one package" services worth $1299 is available for only $99. 
          Hurry and click on the "Get it now" button to avail the bundle service only for $99.
        </Tooltip>
      );

    render() {
        let {otherSite,hasPromo,setSpecificSite,promoApplied,refundApprove,} = this.state;
        console.log('otherSite',otherSite)
        return (
            <Row>
                <Col md={12}>
                    <Loader
                        loadmsg="please wait"
                        visibility={this.state.loader}
                    />

                    <div className="tabpanelOneForm pt-3">
                        <Card className="p-5 body_card">
                    
                            <Loader loadmsg="please wait" visibility={this.state.loader} />
                            {(this.state.userData != '' && this.state.PaymentModal ==true)? (<>
                            <JobseekerPayment {...this.props}  userDetails={this.state.userData} paymentDetails={this.state.hasPromo? this.state.promoApplied : this.state.setSpecificSite} show={this.state.PaymentModal} promoCode={this.state.promoCode} close={()=>this.handleClose()}/>
                            </>) : (<></>) }

                            {(this.state.resumeUpload == true)? (<>
                                <ResumeUpload {...this.props} modalShow={this.state.resumeUpload} switchResumeModal = {this.switchResumeModal} />
                            </>) : (<></>) }
                            {(this.state.bundaleRequest == true)? (<>
                                <BundaleRequest {...this.props} fromSite={otherSite} userdata={this.state.userData} bundalebuyAloowCheck={this.bundalebuyAloowCheck} modalShow={this.state.bundaleRequest} switchBundaleRequestModal = {this.switchBundaleRequestModal} />
                            </>) : (<></>) }
                            <Container>
                                <div className='cart_partts_inner pb-5'>
                                    <h2>Your Cart</h2>
                                    <Row>
                                        <Col lg="8" md="12">
                                            <div className='cart_inner_left'>
                                                {this.state.userData.jobseeker_from_site !=13 && this.state.userData.jobseeker_from_site !=null && this.state.is_subscribed==false &&(
                                                    <div className='cart_left_top mb-3'>
                                                
                                                        <div className='cart_left_inner_left align-items-center' onClick={()=>this.selctedpackage(otherSite)}>
                                                
                                                            <div className='cart_left_inner_box mr-4 p-2'>
                                                                <img className='img-fluid' src={this.props.logoDrop(otherSite.logo)} />
                                                            </div>
                                                            {/* <div className='cart_left_inner_text'>
                                                                <h3>RESUMESCORING.COM</h3>
                                                                <Form.Control as="select" className='select_box'>
                                                                    <option value="DICTUM">5 Years</option>
                                                                    <option value="CONSTANCY">5 Years</option>
                                                                    <option value="COMPLEMENT">5 Years</option>
                                                                </Form.Control>
                                                                <p>Renews November 2027 for  $52.2/mo ($261.54 Total)</p>
                                                            </div> */}
                                                            <p>{otherSite.description}</p>
                                                    

                                                        </div>
                                                        <div className='cart_left_inner_right align-items-center d-flex'>
                                                            <p className='org_price'>${otherSite.offerPrice}</p>
                                                            {/* <label><strike>$99</strike></label>
                                                            <p className='off_price'>20% Off</p>
                                                            <div className='delete_button'>
                                                                <a href='#'><img src={Delete_Img} /></a>
                                                            </div> */}
                                                        </div>
            
                                                    </div>
                                                )}

                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={this.renderTooltip}
                                                    >
                                                        <div className='cart_left_bottom'>
                                                    <div className='all_in_one_pack'>
                                                        <div className='all_in_text'>
                                                            <h2>All-in-one Package (Special Offer)  </h2>
                                                            <h6 style={{color: "#ffff"}}> <strike>$1299</strike> worth of services for just ${this.state.bundaleSite.offerPrice}</h6>
                                                            
                                                            
                                                        </div>
                                                        {this.state.userData.jobseeker_from_site !=13  && this.state.userData.jobseeker_from_site !=null &&(
                                                        <Button className='get_it_button'>
                                                                <a style={{cursor: 'grab'}} onClick={()=>{
                                                                    // if(this.state.userData.bigdata_id ==null)
                                                                    // {
                                                                    //     this.switchResumeModal()
                                                                    // }
                                                                    // temporary close for requesting bundale part
                                                                    // else if(this.state.allowBuyBundale == false)
                                                                    // {
                                                                    //     this.switchBundaleRequestModal()
                                                                    // }
                                                                    // else
                                                                    // {
                                                                        this.selctedpackage(this.state.bundaleSite)
                                                                    // }
                                                                }}>GET IT NoW</a>
                                                            </Button>
                                                        )}
                                                    </div>
                                                    <div className='logo-list'>
                                                        <Row className='justify-content-between'>
                                                        {this.state.siteDetails.map((allsite,index)=>{
                                                            return(<>
                                                            <Col md="2" key={index}><img className='img-fluid' src={this.props.logoDrop(allsite.logo)}/></Col>
                                                            </>)
                                                        })}        
                                                            {/* <Col md="2"><img className='img-fluid' src={career_master}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={coach_master}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={getback_work}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={hr_direct}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={JAlerts}/></Col>
                                                        
                                                            <Col md="2"><img className='img-fluid' src={Jobseeker}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={recruiter_direct}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={Resume_Scoring_1}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={Resume_Sending}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={social_profile_scoring_1}/></Col>
                                                            <Col md="2"><img className='img-fluid' src={Resume_Certified}/></Col> */}
                                                            
                                                            
                                                        </Row>
                                                    </div>
                                                </div>
                                                </OverlayTrigger>

                                                
                                            
                                            </div>
                                        </Col>
                                        {
                                            this.state.subtotalCart &&(
                                        <Col lg="4" md="12">
                                            <div className='order_summery'>
                                                <h3>Order Summary</h3>
                                                <h5 style={{color: "#1AA3B9"}}>{hasPromo? promoApplied.package_name : setSpecificSite.package_name}</h5>
                                                <p className='item_number'>1 item</p>
                                                
                                                <div className='border1'></div>
                                                <div className='sub_total_partts'>
                                                    <h4>Total</h4>
                                                    <div className='sub_t_amnt'>
                                                        <h5 style={{color: "#1AA3B9"}}>${hasPromo? promoApplied.offerPrice : setSpecificSite.offerPrice}</h5>
                                                        {/* <strike>$99</strike> */}
                                                    </div>
                                                </div>
                                                {refundApprove && (
                                                                <h6 style={{color: "#1AA3B9"}}> Please proceed to purchase the bundle, your existing service cost will be refunded to you within 10 days.</h6>
                                                            )}
                                                {/* <div className='saved'>You saved  <span>$69.01</span> on your order</div> */}
                                                {/* <div className='taxes'>Total does not include applicable taxes</div> */}
                                                <div className="form-group1">
                                                    <div className='d-flex'>
                                                        <input type="checkbox" id="html" checked={this.state.hasPromo} className='check_input' onChange={()=>{
                                                            this.setState({hasPromo:!this.state.hasPromo},()=>{
                                                                this.cleanPromoApplied()
                                                            })
                                                            
                                                        }}/>
                                                        <label htmlFor="html">Have a promo code?</label>
                                                    </div>



                                                    <div className='d-flex text-center w-100 mt-2'>
                                                        {this.state.hasPromo &&(
                                                            <Form.Group controlId="formBasicEmail" className="promo_field w-100">
                                                            <div className="promo_field col-md-9 pl-0">
                                                                <Form.Control type="text" id="promoCode" placeholder="promo"/>
                                                                {this.state.Msg!="" && (<p style={{color:this.state.MsgType=="danger"?"red":"green"}}>{this.state.Msg}</p>)}
                                                            </div>
                                                            <div className='col-md-3 p-0'>
                                                                <Button type="button" className="promo_code_apply" size="sm" onClick={this.validatePromo}>Apply</Button>
                                                            </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>



                                                    <div className='submit_button'>
                                                        <button onClick={()=>{this.handleClose(0)}} type="submit">Click to Pay</button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </Col>
                                        )
                                        }
                                    </Row>
                                </div>
                            </Container>
                        </Card>
                    </div>
                </Col>
            </Row>

            );
    }
  }