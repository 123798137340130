import React, { Component } from 'react';
import {
  Container, Row, Col, Navbar, Nav, ButtonGroup, Button, Accordion,
  Card
} from 'react-bootstrap';
import { logout, roleName } from "../Helpers/Helpers";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";


import Moment from "moment";

class NotificationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {

      userDetails: {},
    };


  }


  componentDidMount() {
    let roletype = "";

    if (localStorage.getItem("gs_user") != null) {

      let userDetails = JSON.parse(localStorage.getItem("gs_user"));

      this.setState({ userDetails }, () => {
        roletype = roleName(userDetails.roleID)
        this.setState({ roletype: roletype });
      });
    }
  }





  render() {
    let userDetails = this.state.userDetails;
    return (
      <div className="app_header d-flex">
        <Nav className="ml-auto">
          {[ 5, 6, 13, 14, 15].includes(userDetails.roleID) ? (<>

            <Nav.Link href="#/notification"><i className="fa fa-bell"></i></Nav.Link>

          </>) : (<></>)}
          {[ 2,4].includes(userDetails.roleID) ? (<>
              <Nav.Link href="#/feedback-notification"><i className="fa fa-bell"></i></Nav.Link>
          </>) : (<></>)}

          {!localStorage.getItem("gs_token") ||
            localStorage.getItem("gs_user") == null ||
            localStorage.getItem("gs_user") == undefined ||
            localStorage.getItem("gs_user") == ''
            ? (<>
              <Nav.Link className="link_logout" href="#/login"> Login</Nav.Link>
            </>) : (<>
              {/* <Nav.Link className="link_logout" href="#/logout"><i className="fa fa-sign-out"></i> Logout</Nav.Link> */}
            </>)}

        </Nav>
      </div>

    );
  }
}

export default NotificationHeader;