import React from "react";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class EditTempleteModal extends React.Component {
    constructor(props)
    {
        super(props)
       
        this.state = {
                        
                            FromData: {
                                                    
                                templete_name : '',
                                content : '',
                                templete_type:'',
                                contentsms:'',
                            
                            
                                
                            },
                            FromDataError :
                                        {
                                          
                                          templete_name : 'false',
                                          content : '',
                                          templete_type:'',
                                          
                                        },
                        FromDataError1 :
                        {
                                          
                            templete_type:'false',
                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/update-contact',
                        
                                      
                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        this.handleCloseDescription = this.handleCloseDescription.bind(this)
       
        
        

        

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromDataError1 = this.state.FromDataError1;
        let FromData = this.state.FromData;
        let gs_user = '';
        let id =this.props.id;

        if(FromData.templete_type=='SMS')
        {
          FromData.content=FromData.contentsms
        }else{
          FromData.content=FromData.content
        }

       

        if(this.validateForm(FromDataError) && FromData.content!=''  && FromData.templete_type!='')
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("templete_name", FromData.templete_name);
                formdata.append("content", FromData.content);
                formdata.append("templete_type", FromData.templete_type);
                formdata.append("id", id);
               

                let request = {
                  end_point : '/update-campaign-templete',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                console.log('......request',formdata)

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off


                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:'Template Updated Successfully',MsgType:'success'})
                          
                          
                          
                        //   this.props.history.push({
                        //     pathname: '/contact-details',
                           
                        //     state:{Msg:'Contact update Successfully',MsgType:'success'}
                           
                  
                        //   })

                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3000);

                          

                        }
                        else{
                            
                            //sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
                                sta.setState({Msg:'Template add Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/editTempleteERR',ee)
                        });
                      

           

        }else
        {
          if(FromData.templete_type=='')
          {
           
            FromDataError1.templete_type = 'This field is required';
            this.setState({FromDataError1})

          }

            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }

       
    }





    componentDidMount()
    {

      

      
    }


    getoneRecord()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError
        let id =this.props.id;
        let FromDataError1 = this.state.FromDataError1;
      console.log(id)
        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_token") != null)
            {
                //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                sta.setState({loader:'visible'})///loader on

                
                GET_API({token:localStorage.getItem("gs_token"),end_point:'/campaign-templete-details?id='+id}).then(resp=>{

                 // console.log(resp.data.contact.firstname)
                    if(resp.status == 'success')
                    {
                            console.log(resp)
                     
                      FromData['templete_name'] = resp.data.templete.templete_name;
                      FromDataError['templete_name'] = (resp.data.templete.templete_name != null)?'': 'false'

                      if(resp.data.templete.template_type=='SMS')
                      {
                        FromData['contentsms'] = resp.data.templete.content;
                        FromDataError1['content'] = (resp.data.templete.content != null)?'': 'false'
                      }else{
                        FromData['content'] = resp.data.templete.content;
                        FromDataError1['content'] = (resp.data.templete.content != null)?'': 'false'
                      }  
                     
                        

                      FromData['templete_type'] = resp.data.templete.template_type;
                      FromDataError['templete_type'] = (resp.data.templete.template_type != null)?'': 'false'
                    
                      this.handleCloseDescription(FromData['templete_type']);

                      this.setState({FromData})
                    }
                   sta.setState({loader:'hidden'})///loader off

                   
                  }).catch((ee)=>{
                    
                    sta.setState({loader:'hidden'})///loader off

                        console.log('/templatedetailsEditERR',ee)
                    });
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }

    handleCloseDescription(data)
    {
      let FromData = this.state.FromData;
      let FromDataError1 = this.state.FromDataError1;
     
      let value = '';
      if(data!='SMS' || data!='EMAIL')
      {
       value = data;
      }else{
        value = data.target.value;
      }
       FromData.templete_type=value
      
      
        if(value=='SMS'){
            document.getElementById('smsDes').style.display = 'block';
            document.getElementById('emailDes').style.display = 'none';
        }else if(value=='EMAIL'){
            document.getElementById('smsDes').style.display = 'none';
            document.getElementById('emailDes').style.display = 'block';
        }

        FromDataError1.templete_type = 'false';
        this.setState({FromDataError1})

    }

    
 
  render() {
    let  FromData = this.state.FromData
      
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         >
        <Form
         className="tabform"
         onSubmit={this.handaleSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Update Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>

         
      
         <Row className="m-0 mt-4 mb-4">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}



              
      
                     
              <Form.Row>
               <Form.Group controlId="formBasicEmail4" as={Col}>
                 <Form.Label>Template Name</Form.Label>
                 <Form.Control
                   type="text"
                   name="templete_name"
                   onChange={this.handaleChange}
                   placeholder="Enter templete name"
                   value={FromData.templete_name}
                   validaterule={['required']}
                   validatemsg={['This field is required']}
                 />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.templete_name=='false'?'': this.state.FromDataError.templete_name}</p>
               </Form.Group>
            </Form.Row>
            <Form.Row>
              
                 <Form.Group controlId="formBasicEmail22" as={Col}>
                      <Form.Label>Template Type</Form.Label>
                       <Form.Control as="select" name="templete_type" onChange={this.handaleChange,this.handleCloseDescription} defaultValue={''}>
                            <option value="">Select Type</option>
                            <option selected={FromData.templete_type=='SMS'?'selected':''} value="SMS">SMS Template</option>
                            <option selected={FromData.templete_type=='EMAIL'?'selected':''} value="EMAIL">Email Tamplate</option>
                                
                        </Form.Control>
                          <p style={{ color: 'red' }}>{this.state.FromDataError1.templete_type=='false'?'': this.state.FromDataError1.templete_type}</p>
                    </Form.Group>

                            
                  </Form.Row>
            <Form.Row>
            <Form.Group controlId="formGridAddress1" id="emailDes" className="ck-editor__editable_inline" style={{ display: 'none' }} as={Col}>
                <Form.Label>Description</Form.Label>
                    <CKEditor 
                        editor={ ClassicEditor }
                        data={FromData.content || ''}
                        name='content'
                                            
                        onInit={ editor => {
                            // You can store the "editor" and use when it is needed.
                            // console.log( 'Editor is ready to use!', editor );
                        } }
                            onChange={ ( event, editor ) => {
                                 const data = editor.getData();
                                FromData.content = data;
                                 this.setState({FromData})
                            } }
                                            
                            onBlur={ ( event, editor ) => {
                                                //console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                  //console.log( 'Focus.', editor );
                             } }
                        />
                                       
                    </Form.Group>

                            <Form.Group controlId="formGridAddress1" id="smsDes" style={{ display: 'none' }} as={Col} >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name='contentsms'
                                    value={FromData.contentsms}
                                    placeholder="Enter Template Description"
                                    onChange={this.handaleChange}
                                    validaterule={['required']}
                                    validatemsg={['This field is required','Enter Description']}
                                  />
                                    

                              </Form.Group>

               </Form.Row>
             
           
             

           </Col>
           


         </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
