import React from "react";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import { Chart, Axis, Line, Point, LineAdvance, Interval } from "bizcharts";

export default class LineChart extends React.Component {
  constructor() {
    super();
    this.state = {
      lineGraphData: [],
      loader: "hidden",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;

    let request2 = {
      end_point: "/get-sponsorship-report",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    POST_API(request2)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
        //   console.log("graph data", resp.data);
          sta.setState({ lineGraphData: resp.data });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }

  render() {
    //console.log("dddd",this.state.lineGraphData)
   let data = []
   this.state.lineGraphData.forEach((i, ind)=>{
    data.push({
        label: i.month,
        city: "Total Sponsorship",
        total: i.total_sponsorship

      })
    data.push({
        label: i.month,
        city: "Total Spend",
        total: i.total_spend
      })

   })
    
    return (
      <>
      <h4>My Sponsorship Statistics</h4>
        <Chart padding={[10, 20, 50, 40]} autoFit height={300} data={data}>
          <LineAdvance
            shape="smooth"
            point
            area
            position="label*total"
            color="city"
          />
        </Chart>
        <br />
          <span>
            <strong style={{color:"gray", fontSize:"11px"}}><i className="fa-solid fa-arrow-up"></i>  X-Axis represents number of jobseekers on boarded,<i className="fa-solid fa-arrow-right ml-2"></i> Y-Axis represents past 12 months from now</strong>
          </span>
        {/* <span>Year - 2022</span> */}
      </>
    );
  }
}
