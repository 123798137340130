// import './css/dashboard.css';
import Leftpanel from './Section/LeftPanel';
import React, { Component } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Dropdown, Modal } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import profileimage from "../images/dashboard_profile_image.jpg";
import DataTable from "react-data-table-component";
import AutocompleteSearch from "../AutocompleteSearch";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Threedots_img from '../images/dotsss.png'

class Recruiter extends Component {
    constructor(props) {
        super(props)
        this.state = {

            Msg: '',
            MsgType: '',
            urlPath: '/jobseeker-personal-profile',
            help: '',
            hrcontacts: {},
            searchInput: "",
            filteredData: [],
            location: "",
            industryData: [],
            searchIndustry: [],
            searchAddressType: "",
            searchAddress: "",
            resetAutoComplete: false,
            viewModal: false,
            viewData: [],
            unlockModal: false,
            unlockId: '',
            selectedRows: [],
            dataIds: [],
            creditCount: 1,
            loading: false,
            loader: 'hidden',
            debitForUnlock: 0,
            totalRows: 0,
            offset: 1,
            limit: 10,
            searchSection: false,
            status_type: "",
            singleUnlock: true,


        };



    }

    getDebitRules = () => {
        GET_API({ end_point: '/get-credit-debit-rules/unlock_recruiter', token: localStorage.getItem("gs_token") })
            .then((resp) => {
                if (resp.status == "success") {
                    this.setState({ debitForUnlock: resp.data.debit_value })

                }
            })
            .catch((err) => {
                console.log("getDebitRules", err)
            })
    }


    componentDidMount() {

        this.getDebitRules();
        this.getRecruiterList();



        let request_api = {
            end_point: '/get-industry',
            token: localStorage.getItem("gs_token")

        };

        // this.setState({loader:'visible'})///loader on


        GET_API(request_api).then(resp => {


            this.setState({ industryData: resp.contacts_industry })
            // this.setState({loader:'hidden'})

        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });





    }


    getRecruiterList = () => {
        let request = {
            end_point: `/get-recruiterList?offset=${this.state.offset}&limit=${this.state.limit}`,
            token: localStorage.getItem("gs_token")

        };

        this.setState({ loader: 'visible' })///loader on


        GET_API(request).then(resp => {



            this.setState({ totalRows: resp.row_count });
            this.setState({ hrcontacts: resp.contacts })
            this.setState({ loader: 'hidden' })

        }).catch((ee) => {

            this.setState({ loader: 'hidden' })
        });
    }

    handleChange = (event) => {
        this.setState({ searchInput: event.target.value }, () => {
            this.globalSearch();
        });
    };

    globalSearch = () => {
        let { searchInput, hrcontacts } = this.state;
        let filteredData = hrcontacts.filter((value) => {
            return (
                value.Company_Name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.Phone.includes(searchInput) ||
                value.Country.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.Full_Name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.City.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.State.toLowerCase().includes(searchInput.toLowerCase())
            );
        });
        this.setState({ filteredData });
    };
    getAddress = (value) => {
        if (value !== undefined) {
            let { city, postal_code, state, street_address, country } = value;

            console.log(country);

            if (this.state.searchAddressType == 'City') {
                this.setState({ searchAddress: city });
            } else if (this.state.searchAddressType == 'State') {
                this.setState({ searchAddress: state });
            } else if (this.state.searchAddressType == 'Country') {
                this.setState({ searchAddress: country });
            }
        }
    };


    industryChange = (event) => {
        //console.log(event);

        // for (let index = 0; index < event.length; index++) {
        //     dataT = [event[index].value];
        // }
        //console.log(dataT);
        this.setState({ searchIndustry: event })
    };

    addressTypeChnage = (event) => {
        this.setState({ resetAutoComplete: false })
        this.setState({ searchAddressType: event.target.value })
    };

    addressChnage = (event) => {
        this.setState({ searchAddress: event.target.value })
    };


    searchAll = () => {
        this.setState({ offset: 1 })
        this.setState({ loader: 'visible' })///loader on
        console.log('searchIndustry', JSON.stringify(this.state.searchIndustry));
        console.log('searchAddressType', this.state.searchAddressType);
        console.log('searchAddress', this.state.searchAddress);

        var formdata = new FormData();
        formdata.append("searchIndustry", JSON.stringify(this.state.searchIndustry));
        formdata.append("searchAddressType", this.state.searchAddressType);
        formdata.append("searchAddress", this.state.searchAddress);
        formdata.append("status_type", this.state.status_type);

        let request_post = {
            end_point: `/submit-recruiter-search?offset=${this.state.offset}&limit=${this.state.limit}`,
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };


        POST_API(request_post).then(resp => {
            if (resp.status == 'success') {

                // this.setState({ Msg: resp.message, MsgType: 'success' })
                //window.location.reload();
                this.setState({ hrcontacts: resp.contacts_list })
                console.log(this.state.hrcontacts);
                this.setState({ searchSection: true })
                this.setState({ totalRows: resp.row_count });
            } else {
                // this.setState({ Msg: resp.message, MsgType: 'danger' })
            }
            // this.checkHasContacts();
            // this.setState({ searchIndustry: [] })
            // this.setState({ searchAddressType: "" })
            // this.setState({ searchAddress: "" })
            this.setState({ loader: 'hidden' })
        }).catch((ee) => {
            this.setState({ loader: 'hidden' })
            console.log('error', ee)
        });
    }







    searchSectionFunction = () => {
        this.setState({ loader: 'visible' })///loader on
        console.log('searchIndustry', JSON.stringify(this.state.searchIndustry));
        console.log('searchAddressType', this.state.searchAddressType);
        console.log('searchAddress', this.state.searchAddress);

        var formdata = new FormData();
        formdata.append("searchIndustry", JSON.stringify(this.state.searchIndustry));
        formdata.append("searchAddressType", this.state.searchAddressType);
        formdata.append("searchAddress", this.state.searchAddress);
        formdata.append("status_type", this.state.status_type);

        let request_post = {
            end_point: `/submit-recruiter-search?offset=${this.state.offset}&limit=${this.state.limit}`,
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };


        POST_API(request_post).then(resp => {
            if (resp.status == 'success') {

                // this.setState({ Msg: resp.message, MsgType: 'success' })
                //window.location.reload();
                this.setState({ hrcontacts: resp.contacts_list })
                console.log(this.state.hrcontacts);
                this.setState({ searchSection: true })
                this.setState({ totalRows: resp.row_count });
            } else {
                // this.setState({ Msg: resp.message, MsgType: 'danger' })
            }
            // this.checkHasContacts();
            // this.setState({ searchIndustry: [] })
            // this.setState({ searchAddressType: "" })
            // this.setState({ searchAddress: "" })
            this.setState({ loader: 'hidden' })
        }).catch((ee) => {
            this.setState({ loader: 'hidden' })
            console.log('error', ee)
        });
    }

    resetAll = (e) => {
        this.setState({ loader: 'visible' })///loader on
        e.preventDefault();
        this.setState({ searchIndustry: [] })
        this.setState({ status_type: "" })
        //this.setState({ searchAddressType: "" })
        //this.setState({ searchAddress: '' })
        console.log('searchIndustry', this.state.searchIndustry);
        console.log('searchAddressType', this.state.searchAddressType);
        console.log('searchAddress', this.state.searchAddress);
        this.setState({ resetAutoComplete: true })

        this.setState({ searchAddress: "" }, () => {
            console.log(this.state.searchAddress, 'searchAddress');
        });
        document.getElementById("search-form").reset();
        this.setState({ offset: 1 });
        this.setState({ searchSection: false })
        this.getRecruiterList();



        // let request = {
        //     end_point: '/get-recruiterList',
        //     token: localStorage.getItem("gs_token")

        // };

        // // this.setState({loader:'visible'})///loader on


        // GET_API(request).then(resp => {




        //     this.setState({ hrcontacts: resp.contacts })
        //     // this.setState({loader:'hidden'})
        //     this.setState({ loader: 'hidden' })
        // }).catch((ee) => {

        //     // this.setState({loader:'hidden'})
        //     this.setState({ loader: 'hidden' })
        // });
    }

    modalopenForView = (id) => {
        var formdata = new FormData();
        formdata.append("rc_id", id);
        let request_api_type = {
            end_point: '/get-recruiter-details',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            this.setState({ viewData: resp.contacts });
            this.setState({ viewModal: true });
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
    }

    handaleSwitchModal = (type) => {
        if (type == 'viewModal') {
            if (this.state.viewModal) {
                this.setState({ viewModal: false });
            } else {
                this.setState({ viewModal: true });
            }
        } else if (type == 'unlockModal') {
            if (this.state.unlockModal) {
                this.setState({ unlockModal: false });
            } else {
                this.setState({ unlockModal: true });
            }
        }
    }

    unlockContactModal = (id) => {
        this.setState({ singleUnlock: true });
        this.setState({ unlockId: id });
        this.setState({ creditCount: 1 });
        this.handaleSwitchModal('unlockModal');
    }

    unlockContact = (id) => {
        this.setState({ loader: 'visible' })///loader on
        var formdata = new FormData();
        formdata.append("rc_id", id);
        let request_api_type = {
            end_point: '/unlock-recruiter-contact',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            if (resp.status == 'success') {
                //refresh credit balance
                this.props.dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })
                this.setState({ unlockModal: false });
                this.setState({ Msg: "Contact Successfully Unlocked", MsgType: "success" });
                if (this.state.selectedRows.length > 0) {
                    let indexArr = this.state.selectedRows.indexOf(id);
                    if (indexArr >= 0) {
                        this.state.selectedRows.splice(indexArr, 1);
                    }
                }
                if (this.state.searchSection) {
                    this.searchSectionFunction();
                } else {
                    this.getRecruiterList();
                }
            }
        }).catch((ee) => {
            this.setState({ unlockModal: false });
            this.setState({ Msg: "Low Credit Balance", MsgType: "danger" });
            if (this.state.searchSection) {
                this.searchSectionFunction();
            } else {
                this.getRecruiterList();
            }
            // this.setState({loader:'hidden'})
        });
    }

    handleChangeRow = (event) => {

        let selectedRows = [];
        console.log(event);
        console.log(this.state.dataIds);

        event.selectedRows.map((items, key2) => {
            if (!this.state.dataIds.includes(items.id)) {
                selectedRows.push(items.id);
            }
        })
        this.setState({ selectedRows });

    }

    unlockContactModalMultiple = () => {
        console.log(this.state.selectedRows);
        if (this.state.selectedRows.length > 0) {
            this.setState({ singleUnlock: false });
            this.setState({ creditCount: this.state.selectedRows.length });
            this.handaleSwitchModal('unlockModal');
        } else {

        }
    }

    statusTypeChnage = (event) => {
        this.setState({ status_type: event.target.value })
    }

    unlockBulkContact = () => {
        this.setState({ loader: 'visible' })///loader on
        var formdata = new FormData();
        formdata.append("rc_ids", this.state.selectedRows);
        let request_api_type = {
            end_point: '/unlock-recruiter-contact',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            this.setState({ singleUnlock: true });
            if (resp.status == 'success') {
                //refresh credit balance
                this.props.dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })
                let dataIds = this.state.dataIds;
                this.state.selectedRows.map((items, key2) => {
                    dataIds.push(items);
                })
                this.setState({ dataIds });
                this.setState({ unlockModal: false });
                this.setState({ Msg: "Contact Successfully Unlocked", MsgType: "success" });
                let selectedRows = [];
                this.setState({ selectedRows });
                if (this.state.searchSection) {
                    this.searchSectionFunction();
                } else {
                    this.getRecruiterList();
                }
            }
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
    }

    handlePerRowsChange = async (page) => {
        if (this.state.searchSection) {
            this.setState({ loader: 'visible' })///loader on
            console.log('searchIndustry', JSON.stringify(this.state.searchIndustry));
            console.log('searchAddressType', this.state.searchAddressType);
            console.log('searchAddress', this.state.searchAddress);

            var formdata = new FormData();
            formdata.append("searchIndustry", JSON.stringify(this.state.searchIndustry));
            formdata.append("searchAddressType", this.state.searchAddressType);
            formdata.append("searchAddress", this.state.searchAddress);
            formdata.append("status_type", this.state.status_type);

            let request_post = {
                end_point: `/submit-recruiter-search?offset=${this.state.offset}&limit=${page}`,
                formdata: formdata,
                token: localStorage.getItem("gs_token")

            };


            POST_API(request_post).then(resp => {
                if (resp.status == 'success') {
                    this.setState({ limit: page });
                    // this.setState({ Msg: resp.message, MsgType: 'success' })
                    //window.location.reload();
                    this.setState({ totalRows: resp.row_count });
                    this.setState({ hrcontacts: resp.contacts_list })
                    console.log(this.state.hrcontacts);
                    this.setState({ searchSection: true })
                } else {
                    // this.setState({ Msg: resp.message, MsgType: 'danger' })
                }
                // this.checkHasContacts();
                // this.setState({ searchIndustry: [] })
                // this.setState({ searchAddressType: "" })
                // this.setState({ searchAddress: "" })
                this.setState({ loader: 'hidden' })
            }).catch((ee) => {
                this.setState({ loader: 'hidden' })
                console.log('error', ee)
            });
        } else {
            let request = {
                end_point: `/get-recruiterList?offset=${this.state.offset}&limit=${page}`,
                token: localStorage.getItem("gs_token")

            };

            this.setState({ loader: 'visible' })///loader on


            GET_API(request).then(resp => {


                this.setState({ limit: page });
                this.setState({ totalRows: resp.row_count });
                this.setState({ hrcontacts: resp.contacts })
                this.setState({ loader: 'hidden' })

            }).catch((ee) => {

                this.setState({ loader: 'hidden' })
            });
        }
    };

    handlePageChange = async (page) => {
        if (this.state.searchSection) {
            this.setState({ loader: 'visible' })///loader on
            console.log('searchIndustry', JSON.stringify(this.state.searchIndustry));
            console.log('searchAddressType', this.state.searchAddressType);
            console.log('searchAddress', this.state.searchAddress);

            var formdata = new FormData();
            formdata.append("searchIndustry", JSON.stringify(this.state.searchIndustry));
            formdata.append("searchAddressType", this.state.searchAddressType);
            formdata.append("searchAddress", this.state.searchAddress);
            formdata.append("status_type", this.state.status_type);

            let request_post = {
                end_point: `/submit-recruiter-search?offset=${page}&limit=${this.state.limit}`,
                formdata: formdata,
                token: localStorage.getItem("gs_token")

            };


            POST_API(request_post).then(resp => {
                if (resp.status == 'success') {
                    this.setState({ offset: page });
                    // this.setState({ Msg: resp.message, MsgType: 'success' })
                    //window.location.reload();
                    this.setState({ totalRows: resp.row_count });
                    this.setState({ hrcontacts: resp.contacts_list })
                    console.log(this.state.hrcontacts);
                    this.setState({ searchSection: true })
                } else {
                    // this.setState({ Msg: resp.message, MsgType: 'danger' })
                }
                // this.checkHasContacts();
                // this.setState({ searchIndustry: [] })
                // this.setState({ searchAddressType: "" })
                // this.setState({ searchAddress: "" })
                this.setState({ loader: 'hidden' })
            }).catch((ee) => {
                this.setState({ loader: 'hidden' })
                console.log('error', ee)
            });
        } else {
            let request = {
                end_point: `/get-recruiterList?offset=${page}&limit=${this.state.limit}`,
                token: localStorage.getItem("gs_token")

            };

            this.setState({ loader: 'visible' })///loader on


            GET_API(request).then(resp => {


                this.setState({ offset: page });
                this.setState({ totalRows: resp.row_count });
                this.setState({ hrcontacts: resp.contacts })
                this.setState({ loader: 'hidden' })

            }).catch((ee) => {

                this.setState({ loader: 'hidden' })
            });
        }
    };






    render() {
        let FromData = this.state.FromData
        let data = this.state.hrcontacts
        let conditionalRowStyles = [
            {
                when: row => (row.unlock_flag > 0),
                style: {
                    backgroundColor: "#93ddea",
                    'input[type="checkbox"]': {
                        display: "none",
                    },
                }
            }
        ];
        const rowDisabledCriteria = row => (row.unlock_flag > 0) ? true : false;
        const rowUnselectedCriteria = row => (row.unlock_flag > 0) ? true : true;


        const customStyles = {
            header: {
                style: {
                    display: 'none',
                },
            },
        };

        const caseInsensitiveSortForComapny = (rowA, rowB) => {
            const a = rowA.Company_Name.toLowerCase();
            const b = rowB.Company_Name.toLowerCase();

            if (a > b) {
                return 1;
            }

            if (b > a) {
                return -1;
            }

            return 0;
        };

        const caseInsensitiveSortForTitle = (rowA, rowB) => {
            const a = rowA.Title.toLowerCase();
            const b = rowB.Title.toLowerCase();

            if (a > b) {
                return 1;
            }

            if (b > a) {
                return -1;
            }

            return 0;
        };

        const caseInsensitiveSortForName = (rowA, rowB) => {
            const a = rowA.Full_Name.toLowerCase();
            const b = rowB.Full_Name.toLowerCase();

            if (a > b) {
                return 1;
            }

            if (b > a) {
                return -1;
            }

            return 0;
        };




        let columns = [
            {
                name: "Company",
                selector: "Company_Name",
                sortable: true,
                width: "22%",
                sortFunction: caseInsensitiveSortForComapny,
            },
            {
                name: "Title",
                selector: "Title",
                sortable: true,
                width: "17%",
                sortFunction: caseInsensitiveSortForTitle,
            },
            {
                name: "Name",
                selector: "Full_Name",
                // selector: row => {
                //     let fName = row.Full_Name.split(' ')
                //     return (
                //         <span>
                //             {row.unlock_flag == 0 ? (
                //                 <>
                //                     <span>{fName[0]} *****</span>
                //                 </>
                //             ) : (<>
                //                 <span>{row.Full_Name}</span>
                //             </>)}
                //         </span>
                //     )

                // },
                sortable: true,
                width: "15%",
                sortFunction: caseInsensitiveSortForName,
            },
            {
                name: "Email",
                //selector: "Email",
                // selector: row => (row.Email != 'null') ? <a href={'mailto:' + row.Email}>{row.Email}</a> : '',
                //selector: row => (row.unlock_flag == 0) ? (row.Email.length > 2) ? row.Email.substring(0, 3) + '*********' : '' : <a href={'mailto:' + row.Email}>{row.Email}</a>,
                selector: row => (row.unlock_flag == 0) ? row.Email : <a href={'mailto:' + row.Email}>{row.Email}</a>,

                //sortable: true,
                width: "16%",
            },
            // {
            //     name: "Address",
            //     selector: "address",
            //     sortable: true,
            // },
            // {
            //     name: "City",
            //     selector: "City",
            //     sortable: true,
            // },
            // {
            //     name: "State",
            //     selector: "State",
            //     sortable: true,
            // },
            // {
            //     name: "Zip",
            //     selector: "Zip",
            //     sortable: true,
            // },
            // {
            //     name: "Country",
            //     selector: "Country",
            //     sortable: true,
            // },
            {
                name: "Phone",
                //selector: "Phone",
                // selector: row => (row.Phone != 'null') ? <a href={'tel:' + row.Phone}>{row.Phone}</a> : '',
                //selector: row => (row.unlock_flag == 0) ? (row.Phone.length > 2) ? row.Phone.substring(0, 3) + '*********' : '' : <a href={'tel:' + row.Phone}>{row.Phone}</a>,
                selector: row => (row.unlock_flag == 0) ? row.Phone : <a href={'tel:' + row.Phone}>{row.Phone}</a>,
                //sortable: true,
                width: "10%",
            },
            {
                name: "Linkedin Profile",
                // selector: row => <a target="_blank" href={row.Company_Url}>Click Here</a>,
                //selector: row => (row.unlock_flag == 0) ? (row.Company_Url.length > 2) ? row.Company_Url.substring(0, 3) + '*********' : '' : <a target="_blank" href={row.Company_Url}>Click Here</a>,
                selector: row => (row.unlock_flag == 0) ? row.Company_Url : <a target="_blank" href={row.Company_Url}>Click Here</a>,

                //selector: "Company_Url",
                //sortable: true,
                width: "9%",
            },



            // {
            //     name: "Sales",
            //     selector: "Sales",
            //     sortable: true,
            // },
            // {
            //     name: "Employees",
            //     selector: "Company_Size",
            //     sortable: true,
            // },
            // {
            //     name: "SIC Code",
            //     selector: "Sic_Code",
            //     sortable: true,
            // },
            // {
            //     name: "Industry",
            //     selector: "Industry",
            //     sortable: true,
            // },
            {
                name: "Action",
                selector: row => {
                    return (


                        <div>
                            {row.unlock_flag > 0 ? (
                                <>
                                    <div className="three_dotss">
                                        <Dropdown className="drop_partts">
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <img src={Threedots_img} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item >
                                                    <Button variant="danger" onClick={() => this.modalopenForView(row.id)}> <i className="fa fa-eye" aria-hidden="true"></i> View</Button>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                            ) : (<>
                                <Button title='Unlock contact information' className='btn btn-primary tabformbutton btn_total' onClick={() => { this.unlockContactModal(row.id) }}> <i className="fa fa-unlock" aria-hidden="true"></i></Button>
                            </>)}
                        </div>







                    )
                },
                //sortable: true,
                width: "11%",
            },

        ]


        return (
            <div className='pt-5 pb-5 data_table_gwi'>
                <Container >
                    <Row>
                        <Col md={12}>
                        <p><a className="btn btn-primary btn-md tabformbutton btnfix btn_total btn btn-primary" href="/#/jobseeker-dash">Go to Dashboard</a></p>
                        <p style={{ fontWeight: 600 }}><span className='text-danger'>*</span> One "Contact" is equivalent to either one or three valid details consisting of email address, phone number or Linkedin profile. 1 Credit will be deducted if any of the 3 (email address, phone number, linkedin URL) is found valid</p>                  
                            <h2 className='jobseeker_heading_hr'>Recruiter list</h2>
                           
                            <div className='w-100 mb-2'>&nbsp;</div>
                           
                            <Loader loadmsg="please wait" visibility={this.state.loader} />
                            {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                            {/* <Loader loadmsg="please wait" visibility={this.state.loader}/> */}
                            <div style={{ width: "400px", float: "right" }}>
                                <Form.Control
                                    size="large"
                                    name="searchInput"
                                    value={this.state.searchInput || ""}
                                    onChange={this.handleChange}
                                    placeholder="Search Recruiter List"
                                />
                            </div>
                        </Col>
                        <Col md={12}>





                            <div className='mb-2'>
                                <form id="search-form">
                                    <Row>
                                        <Col sm="4">

                                            <label>Industry</label>
                                            <Select
                                                closeMenuOnSelect={false}
                                                defaultValue={''}
                                                components={makeAnimated()}
                                                isMulti
                                                value={this.state.searchIndustry}
                                                name="industry"
                                                options={this.state.industryData.map((items, key2) => ({
                                                    label: items.Industry,
                                                    value: items.Industry,
                                                }))}
                                                onChange={this.industryChange}
                                            />


                                        </Col>
                                        <Col sm="4">
                                            <label>Location</label>
                                            <Form.Control as="select" name="address_type" onChange={this.addressTypeChnage} defaultValue={''}>
                                                <option value="">--Select Type--</option>
                                                <option value="City">City</option>
                                                <option value="State">State</option>
                                                {/* <option value="Country">Country</option> */}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="4">
                                            <label>Search {this.state.searchAddressType}</label>
                                            {/* <Form.Control as="select" name="searchAddress" onChange={this.addressChnage} defaultValue={''}>
                                            <option value="">--Select Address--</option>
                                            {this.state.city_state_country.map((items2, key3) => {
                                                return (
                                                    <option value={items2}>{items2}</option>
                                                )
                                            })}
                                        </Form.Control> */}
                                            {/* <Form.Control
                                            size="large"
                                            name="searchAddress"
                                            value={this.state.searchAddress || ""}
                                            onChange={this.addressChnage}
                                            placeholder="Search Address"
                                        /> */}
                                            <AutocompleteSearch
                                                name="searchAddress"
                                                placeHolder={this.state.searchAddress}
                                                getAddress={this.getAddress}
                                                addressType={this.state.searchAddressType}
                                                //onChange={this.addressChnage}
                                                value={this.state.searchAddress || ""}
                                                reset={this.state.resetAutoComplete}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <label>Status</label>
                                            <Form.Control as="select" name="status_type" onChange={this.statusTypeChnage} defaultValue={''}>
                                                <option value="">--Select Status--</option>
                                                <option value="lock">Lock</option>
                                                <option value="unlock">Unlock</option>
                                                {/* <option value="Country">Country</option> */}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="1" style={{"margin-right": "2rem"}}>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                                onClick={this.searchAll}
                                            >
                                                Search
                                            </Button>
                                        </Col>
                                        <Col sm="1">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                                onClick={this.resetAll}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>


                            <button
                                variant="primary"
                                type="submit"
                                className={this.state.selectedRows.length > 0 ? "btn btn-primary btn-md tabformbutton  mt-3 mb-3 btn_total" : "btn btn-primary btn-md tabformbutton  mt-3 mb-3 d-none btn_total"}
                                disabled={this.state.selectedRows.length > 0 ? false : true}
                                onClick={() => { this.unlockContactModalMultiple() }}><i className="fa fa-unlock" aria-hidden="true"></i> Unlock</button>


                            <DataTable
                                title=""
                                columns={columns}
                                data={this.state.filteredData.length > 0
                                    ? this.state.filteredData : data}
                                defaultSortField="title"
                                pagination={true}
                                selectableRows
                                conditionalRowStyles={conditionalRowStyles}
                                selectableRowDisabled={rowDisabledCriteria}
                                onSelectedRowsChange={this.handleChangeRow}
                                noContextMenu
                                customStyles={customStyles}
                                paginationServer
                                paginationTotalRows={this.state.totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}


                            />
                            {/* <p style={{ fontWeight: 600 }}><span className='text-danger'>*</span> One "Contact" is equivalent to either one or three valid details consisting of email address, phone number or Linkedin profile. 1 Credit will be deducted if any of the 3 (email address, phone number, linkedin URL) is found valid</p> */}

                        </Col>
                    </Row>

                </Container>


                <Modal
                    show={this.state.viewModal}
                    onHide={() => { this.handaleSwitchModal('viewModal') }}
                    // dialogClassName="modal-90w"
                    // aria-labelledby="example-custom-modal-styling-title"
                    // className='jobseeker_modal'
                    centered
                >

                    <Modal.Header closeButton>
                        <Modal.Title>Recruiter Details</Modal.Title>
                        {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
                    </Modal.Header>

                    <Modal.Body>
                        {/* <div className='close_btn' onClick={() => { handaleSwitchModal('viewModal') }}>x</div> */}
                        <div>

                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Company :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Company_Name}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Name :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Full_Name}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Title :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Title}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Email :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Email}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Phone :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Phone}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Linkedin Profile :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Company_Url}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Industry :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Industry}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Address :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.address}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        City :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.City}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        State :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.State}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Zip :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Zip}</p>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        Country :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Country}</p>
                                </Col>
                            </Row>
                            {/* <Row className="m-0">
                                <Col>
                                    <strong>
                                        Sales :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Sales}</p>
                                </Col>
                            </Row>

                            <Row className="m-0">
                                <Col>
                                    <strong>
                                        SIC Code :
                                    </strong>
                                </Col>
                                <Col>
                                    <p className="textwrap">{this.state.viewData.Sic_Code}</p>
                                </Col>
                            </Row> */}
                        </div>
                    </Modal.Body>

                </Modal>



                <Modal show={this.state.unlockModal}
                    backdrop="static"
                    className='modal_upload'
                    keyboard={false}
                    onHide={() => { this.handaleSwitchModal('unlockModal') }}
                    id="modal"
                >

                    <Modal.Body style={{ minHeight: "250px" }}>
                        <div className="upload_file_area text-center">
                            <div className="upload_info">
                                <h4>Unlock Contact <span className='text-danger'>*</span></h4>
                                {(this.state.creditCount * this.state.debitForUnlock) <= this.props.creditBalance ? (
                                    <p className="mb-0" style={{ fontSize: '15px' }}>{this.state.creditCount * this.state.debitForUnlock} credit{this.state.selectedRows.length > 1 ? '(s)' : ''} will be deducted to <br /> unlock {this.state.selectedRows.length > 1 ? "these contacts":"this contact"} <span className='text-danger'>*</span></p>

                                ) : (
                                    <p className="mb-0" style={{ fontSize: '15px', color: "red" }}>Low credit balance</p>

                                )}
                            </div>
                            <div className="upload_action row">
                                <div className="col pr-2">
                                    <Button variant="primary" onClick={() => { this.handaleSwitchModal('unlockModal') }} className="btn btn-danger tabformbutton btn_total">Cancel</Button>
                                </div>

                                {(this.state.creditCount * this.state.debitForUnlock) <= this.props.creditBalance && (<>
                                    {/* {this.state.selectedRows.length > 0 ? ( */}
                                    {this.state.singleUnlock == false ? (

                                        <>
                                            <div className="col pl-2">
                                                <Button onClick={() => { this.unlockBulkContact() }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn_total">Accept</Button>
                                            </div>
                                        </>) : (<>
                                            <div className="col pl-2">
                                                <Button onClick={() => { this.unlockContact(this.state.unlockId) }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn_total">Accept</Button>
                                            </div>
                                        </>)}
                                </>)}

                            </div>
                        </div>
                    </Modal.Body>

                </Modal>




            </div>
        )
    }
}
const WithUseReducer = (props) => {

    let dispatch = useDispatch();
    const creditBalance = useSelector((state) => state.creditBalance);




    return <Recruiter {...props} {...{ dispatch, creditBalance }} />;
}

export default WithUseReducer
