import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';


 
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddCustomSmtpModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                            mail_driver : '',
                            mail_host : '',
                            mail_port : '',
                            mail_username : '',
                            mail_password : '',
                            mail_encryption : '',
                            mail_address : '',
                            

                            
                        },
                        FromDataError :
                        {
                            
                            mail_driver : 'false',
                            mail_host : 'false',
                            mail_port : 'false',
                            mail_username : 'false',
                            mail_password : 'false',
                            mail_encryption : 'false',
                            mail_address : 'false',
                         
                            
                            
                            
                            },

                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/add-email-setting',
                       
                                        
                        };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        
        
            

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
       
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
       

        if(this.validateForm(FromDataError)  )
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("mail_driver", FromData.mail_driver);
                formdata.append("mail_host", FromData.mail_host);
                formdata.append("mail_username", FromData.mail_username);
                formdata.append("mail_encryption", FromData.mail_encryption);
                formdata.append("mail_address", FromData.mail_address);
                formdata.append("mail_port", FromData.mail_port);
                formdata.append("mail_password", FromData.mail_password);
                formdata.append("type", 'custom_smtp');
                
                    
                let request = {
                  end_point : '/update-affiliate-email',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off

                  //console.log('......response',resp)

                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:'Mail Settings Successfully',MsgType:'success'})
                         
                         
                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3000);
               

                        }
                        else{
                            
                            //sta.setState({Msg:resp.message.campaign_name.join(),MsgType:'danger'})
                                sta.setState({Msg:'Mail Settings Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/Mail SettingsERR',ee)
                        });
                      

           

        }else
        { 

   
           
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
           
        }

       
    }

    componentDidMount()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_user") != null)
            {
                
                this.getoneRecord()
                
                
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }

            getoneRecord()
            {
                let sta = this;
                let FromData = this.state.FromData;
                let FromDataError = this.state.FromDataError;
                try {
                  if(localStorage.getItem("gs_user") != null)
                  {
                      //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                      sta.setState({loader:'visible'})///loader on
          
                      
                      GET_API({token:localStorage.getItem("gs_token"),end_point:'/get-affiliate-setting'}).then(resp=>{
          
          
                          if(resp.status == 'success')
                          {
                            //console.log('ddd'+resp.smtp.type)
                           
                            FromData['mail_address'] = resp.smtp.mail_address;
                            FromDataError['mail_address'] = (resp.smtp.mail_address != null)?'': 'false'
                            FromData['mail_driver'] = resp.smtp.mail_driver;
                            FromDataError['mail_driver'] = (resp.smtp.mail_driver != null)?'': 'false'
                            FromData['mail_encryption'] = resp.smtp.mail_encryption;
                            FromDataError['mail_encryption'] = (resp.smtp.mail_encryption != null)?'': 'false'
                            FromData['mail_host'] = resp.smtp.mail_host;
                            FromDataError['mail_host'] = (resp.smtp.mail_host != null)?'': 'false'
                            FromData['mail_password'] = resp.smtp.mail_password;
                            FromDataError['mail_password'] = (resp.smtp.mail_password != null)?'': 'false'
                            FromData['mail_port'] = resp.smtp.mail_port;
                            FromDataError['mail_port'] = (resp.smtp.mail_port != null)?'': 'false'
                            FromData['mail_username'] = resp.smtp.mail_username;
                            FromDataError['mail_username'] = (resp.smtp.mail_username != null)?'': 'false'
                           
                           
                          
                           
                         
                            this.setState({FromData})
                          }
                         sta.setState({loader:'hidden'})///loader off
          
                         
                        }).catch((ee)=>{
                          
                          sta.setState({loader:'hidden'})///loader off
          
                              console.log('/emailEditERR',ee)
                          });
                  }
                  
                 
                } 
                catch (error) {
                  
                }
           
                
            
                

            }


  
 
 
  render() {
    let  FromData = this.state.FromData
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         >
        <Form
         className="tabform"
         onSubmit={this.handaleSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Set Custom SMTP Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>


      
         <Row className="m-0 mt-4 mb-4">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}

      <Form.Row >
                        <Form.Group controlId="formBasicEmail41" as={Col}>
                                  <Form.Label>MAIL DRIVER</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_driver"
                                    onChange={this.handaleChange}
                                    placeholder="MAIL DRIVER"
                                    value={FromData.mail_driver}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_driver=='false'?'': this.state.FromDataError.mail_driver}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail42" as={Col}>
                                  <Form.Label>MAIL HOST</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_host"
                                    onChange={this.handaleChange}
                                    placeholder="MAIL HOST"
                                    value={FromData.mail_host}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_host=='false'?'': this.state.FromDataError.mail_host}</p>
                                </Form.Group>
                              </Form.Row>


                              <Form.Row>
                              <Form.Group controlId="formBasicEmail43" as={Col}>
                                  <Form.Label>MAIL HOST</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_port"
                                    onChange={this.handaleChange}
                                    placeholder="MAIL HOST"
                                    value={FromData.mail_port}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_port=='false'?'': this.state.FromDataError.mail_port}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail45" as={Col}>
                                  <Form.Label>MAIL USERNAME</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_username"
                                    onChange={this.handaleChange}
                                    placeholder="MAIL USERNAME"
                                    value={FromData.mail_username}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_username=='false'?'': this.state.FromDataError.mail_username}</p>
                                </Form.Group>
                        
                             
                                </Form.Row>

                                <Form.Row>
                                  
                                <Form.Group controlId="formBasicEmail46" as={Col}>
                                  <Form.Label>MAIL PASSWORD</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_password"
                                    onChange={this.handaleChange}
                                    placeholder="MAIL PASSWORD"
                                    value={FromData.mail_password}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_password=='false'?'': this.state.FromDataError.mail_password}</p>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail47" as={Col}>
                                  <Form.Label>MAIL ENCRYPTION</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_encryption"
                                    onChange={this.handaleChange}
                                    placeholder="MAIL ENCRYPTION"
                                    value={FromData.mail_encryption}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_encryption=='false'?'': this.state.FromDataError.mail_encryption}</p>
                                </Form.Group>
                              </Form.Row> 
                             
                              <Form.Row>  
                                <Form.Group controlId="formBasicEmail48" as={Col}>
                                  <Form.Label>FROM ADDRESS</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="mail_address"
                                    onChange={this.handaleChange}
                                    placeholder="FROM ADDRESS"
                                    value={FromData.mail_address}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.mail_address=='false'?'': this.state.FromDataError.mail_address}</p>
                                </Form.Group>

                                
              
                                {/* <Form.Group controlId="formBasicEmail22" as={Col}>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" name="type" onChange={this.handaleChange}>
                                    <option value="">Select Status</option>
                                    <option selected={FromData.type=='active'?'selected':''} value="SG_active">Active</option>
                                    <option selected={FromData.type=='inactive'?'selected':''} value="inactive">Inactive</option>
                                    
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.type=='false'?'': this.state.FromDataError.type}</p>
                                </Form.Group> */}

                                
                             

                              </Form.Row>
                              {/* <Form.Row>
              
                                <Form.Group controlId="formBasicEmail22" as={Col}>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" name="type" onChange={this.handaleChange}>
                                    <option value="">Select Status</option>
                                    <option selected={FromData.type=='SG_active'?'selected':''} value="SG_active">Active</option>
                                    <option selected={FromData.type=='inactive'?'selected':''} value="inactive">Inactive</option>
                                    
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.type=='false'?'': this.state.FromDataError.type}</p>
                                </Form.Group>

                                
                              </Form.Row> */}


                        </Col>
                    
                    </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn-main tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
