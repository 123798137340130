import React, { Component, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip, Dropdown } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import csvfilename from './files/add-referal-contact.csv';
import './css/dashboard.css';
import DataTable from "react-data-table-component";
import AddReferalContactGroupModal from './Section/AddReferalContactGroupModal';
import Threedots_img from '../images/dotsss.png'
import moment from 'moment';




export default class AffiliateReferalGroupDetails extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                       
                        ContactData: {},
                        ContactDataError :{},
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/affiliate-referal-group-details',
                        ContactGroupModal:false,
                        ContactGroupEditModal:false,
                        id:0,
                        help:'',
                        search:'',
                        filteredData: [],
                        totalRows:0,
                        offset: 1,
                        limit: 10,
                        searchValue:""
                        
                     };

        //Binding

       
        

    }
    handleClose()
    {
      
      if(this.state.ContactGroupModal)
      {
         this.setState({ContactGroupModal: false})
      }
      else{
        this.setState({ContactGroupModal: true})
       
      }
    }
    
    handleCloseEdit(id=0)
    {
      console.log(id)
     
      if(this.state.ContactGroupEditModal)
      {
         this.setState({ContactGroupEditModal: false})
      }
      else{
        this.setState({id: id,ContactGroupEditModal: true})
       
       
      }
    }
    columns = [
      {
        name: "SL. No.",
        selector: (row, index) =>  index + 1,
        sortable: true,
        width: "100px",
        wrap:true,
      },
       
        {
            name: "Group Name",
            selector: row => row.group_name,
            width:'200px',
            sortable: true,
            wrap:true,
          },
          
          {
            name: "Total Contact",
            width:'130px',
            selector: row => row.contactCount ,
            sortable: true
          },
          {
            name: "Code Sent",
            width:'130px',
            selector: row => row.has_send ,
            sortable: true
          },
          {
            name: "Code Used",
            width:'130px',
            selector: row => row.has_seen ,
            sortable: true
          },
         
          {
            name: "Created At",
            selector: row => moment(row.created_at).format('MM-DD-YYYY'),
            // selector: row => row.created_at ,
            sortable: true,
            width:'130px',
          },
         
          // {
          //   name: "",
          //   cell: row =>  {
          //               return(
          //                 <div>
          //                        <a href={'#/contact-details/'+row.id} ><Button variant="primary mr-1">Manage Contact</Button></a>
          //                   </div>
          //                   )
          //                   } 
          //                  ,
          //   sortable: true,
          //   width: '250px'
          // },
          {
            name: "Actions",
            cell: row =>  {
                        return(
                          // <div>
                          //   {/* <a href="javascript:"><Button variant="primary mr-1"onClick={()=>this.handleCloseEdit(row.id)} >Edit</Button></a> */}
                                   
                          //        <Button  disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Referal Contact Group?')){this.handaleDelete(row.id)}}}>Delete</Button> 
                          //        <a href={'#/affiliate-referal-contact-details/'+row.id} ><Button variant="primary ml-1">Manage Contact</Button></a>
                          //   </div>


                         <div className="three_dotss">
                          <Dropdown className="drop_partts">
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            <img src={Threedots_img} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                            <Button disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Referal Contact Group?')){this.handaleDelete(row.id)}}}> <i className="fa fa-trash"></i> Delete</Button>
                            </Dropdown.Item>
                            <Dropdown.Item >
                            <a href={'#/affiliate-referal-contact-details/'+row.id} ><Button variant="primary ml-1"> <i className="fa fa-address-book"></i> Manage Contact</Button></a>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                            )
                            } 
                           ,
            // sortable: true,
            width: '150px'
          },

          
        
      ];

    componentDidMount()
    {
      window.scrollTo(0, 0)
        let sta = this;

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
       
        this.getContactGroupList()

    }

  

    handaleDelete(id)
    {
       let sta = this
        
       var formdata = new FormData();
       formdata.append("id", id)

       let request = {
           end_point : '/delete-referal-group-contact',
           formdata:formdata,
           token: localStorage.getItem("gs_token")
           };

       
           sta.setState({loader:'visible'})///loader on

       POST_API(request).then((resp)=>{

           if(resp.status=='success')
           {
               
            
            sta.setState({loader:'hidden'})///loader off

            sta.setState({Msg:'Referal contact group deleted successfully',Msgtype:'succcess'})
            this.getContactGroupList()

           }
           else{
               //console.log(resp.message.password.join())
               sta.setState({Msg:"faild",MsgType:'danger'})
           }

       }).catch(()=>{

       })
       
    }

    handlePerRowsChange = async (page) => {
        
        console.log(page);
        let request = {
          end_point: `/list-referal-group?offset=${this.state.offset}&limit=${page}`,
          token: localStorage.getItem("gs_token"),
        };
    
        this.setState({ loader: "visible" }); ///loader on
    
        GET_API(request)
          .then((resp) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            if (resp.status == "success") {
              let ContactData = resp.data;
              console.log("response data", resp.data);
              this.setState({ limit: page });
              this.setState({ ContactData });
            } else {
            }
          })
          .catch((ee) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            console.log("/code list ERR", ee);
          });
      };
      
      handlePageChange = async (page) => {
        //this.setState({ offset: page });
        console.log(page);
        let request = {
          end_point: `/list-referal-group?offset=${page}&limit=${this.state.limit}`,
          token: localStorage.getItem("gs_token"),
        };
    
        this.setState({ loader: "visible" }); ///loader on
    
        GET_API(request)
          .then((resp) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            if (resp.status == "success") {
              let ContactData = resp.data;
              
             
              this.setState({ ContactData });
            } else {
            }
          })
          .catch((ee) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            console.log("/code list ERR", ee);
          });
      };

      handleSearchChange = async (searchValue) => {
        //this.setState({ offset: page });
       
        let request = {
          end_point: `/list-referal-group?searchValue=${searchValue}&offset=${this.state.offset}&limit=10`,
          token: localStorage.getItem("gs_token"),
        };
    
        this.setState({ loader: "visible" }); ///loader on
    
        GET_API(request)
          .then((resp) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            if (resp.status == "success") {
              let ContactData = resp.data;
             
              this.setState({ ContactData });
            } else {
            }
          })
          .catch((ee) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            console.log("/code list ERR", ee);
          });
      };
    getContactGroupList()
    {

        let sta = this;
        let ContactData =this.state.ContactData
        let ContactDataError =this.state.ContactDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
      

       
                let request = {
                    end_point : `/list-referal-group?offset=${this.state.offset}&limit=${this.state.limit}`,
                    token: localStorage.getItem("gs_token")

                };

                sta.setState({loader:'visible'})///loader on


                    GET_API(request).then(resp=>{

                             sta.setState({loader:'hidden'})///loader off


                                    if(resp.status == 'success')
                                    {
                                            
                                        ContactData = resp.data

                                            sta.setState({ContactData,totalRows:resp.total_group_count})
                                            
                                       }
                                        else{
                                            
                                            
                                                
                                        }
                            }).catch((ee)=>{
                              sta.setState({loader:'hidden'})///loader off

                                console.log('/code list ERR',ee)
                            });

       
                      

      
    }

    
        render() {
          let  ContactData = this.state.ContactData
          
          return (
              <div>
                  {ContactData!=null? (<>
              

              <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12}>
              <Loader loadmsg="please wait" visibility={this.state.loader}/>

              <div className="tabpanelOneForm pt-3">
              
                <AddReferalContactGroupModal {...this.props} 
                show={this.state.ContactGroupModal} 
                close={()=>this.handleClose()}/>
              

                
                 
                <div style={{float: "right"}}>
                  <a
                      href={csvfilename}
                      download="add-referal-contact.csv"
                      className="btn btn-primary ghost_button mr-3"
                    >
                      Download Sample CSV
                    </a>
                  <a href="javascript:" onClick={()=>this.handleClose()} className="btn btn-primary ghost_button">Upload Bulk Referal</a>
               </div>
              <h3>Referal Group List</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></div>
             
              {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                  
             
                <div className="table_search" style={{marginTop:"25px", float: "right"}}>
                  search:
                  <input
                      className="form-control float-right"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchChange(e.target.value)}
                      style={{maxWidth: '15rem'}}
                  />
                  </div>
              
              <DataTable
                    title=""
                    columns={this.columns}
                    data={ContactData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    />
                          
                
                </div>
                                
                                
                            
              </Col>     
            </Row>
          </Container>

          </>):(<p>....Loading</p>) }


          </div>
            
                  
                  
            
          );
      }
}



  
  