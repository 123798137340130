// import React, { Component } from 'react';
import React, { Component, useState } from 'react';

import DataTable from 'react-data-table-component';
// import * as XLSX from 'xlsx'; 
import { Container, Row, Col, Tab, Nav, Form, Button, Image } from "react-bootstrap";
//import { DateTime } from 'react-datetime-bootstrap';
//import DateTimePicker from 'react-bootstrap-date-time-picker';
//import { DateTimePicker } from '@progress/kendo-react-dateinputs';
//import 'node_modules/bootstrap/dist/css/bootstrap.css'
//import '../../node_modules/react-bootstrap-date-time-picker/lib/react-bootstrap-date-time-picker.css'
//import { DateTimePicker } from '@progress/kendo-react-dateinputs';
//import { DateTimeField } from 'react-bootstrap-datetimepicker';
//var DateTimeField = require('react-bootstrap-datetimepicker');
export default class Example  extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }


    componentDidMount()
    {
        console.log(this.props)
    }
    render() {
        return (
            <body>
            <div id="payment-box">
              
               
                <form action="https://www.sandbox.paypal.com/cgi-bin/webscr"
                    method="post" target="_top">
                    {/* <input type='hidden' name='business'
                        value='sb-gfiqa3215069@business.example.com'/> 
                        <input type='hidden' name='item_name' value='Camera'/> 
                        <input type='hidden' name='item_number' value='CAM#N1'/> 
                        <input type='hidden' name='amount' value='10'/> 
                        <input type='hidden' name='no_shipping' value='1'/> 
                        <input type='hidden' name='currency_code' value='USD'/> 
                        <input type='hidden' name='notify_url'
                        value='http://localhost:3000/#/test'/>
                    <input type='hidden' name='cancel_return'
                        value='http://localhost:3000/#/test'/>
                    <input type='hidden' name='return'
                        value='http://localhost:3000/#/test'/>
                    <input type="hidden" name="cmd" value="_xclick"/> <input
                        type="submit" name="pay_now" id="pay_now"
                        Value="Pay Now"/> */}
                  
                  {/* <TextField
    id="datetime-local"
    label="Next appointment"
    type="datetime-local"
    defaultValue="2017-05-24T10:30"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
  /> */}
   
           
                </form>
            </div>
        </body>
        );
      }
    }




    


 
