import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Card
} from "react-bootstrap";
import Select from "react-select";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import reducer from "../../Reducers/index";
import validation from "../Validate/validator";
import Leftpanel from "./Section/LeftPanel";
import profileimage from "../images/dashboard_profile_image.jpg";
import "./css/dashboard.css";
import makeAnimated from "react-select/animated";
import profileimageRemove from "../images/x-circle.svg";
import moment from "moment";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

class JobseekerPersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mysubscriptions: [],
      FromData: {
        profileimage: "",
        resumeFile: "",
        resumefilename: "",
        resumeformat: "",
        linkedinresumeFile: "",
        linkedinresumefilename: "",
        linkedinresumeformat: "",
        firstname: "",
        lastname: "",
        contact_no: "",
        dob: moment(new Date()).format("YYYY-MM-DD"),
        address: "",
        about_me: "",
        web_url: "",
      },
      FromDataError: {
        profileimage: "false",
        // resumeFile: "false",
        firstname: "false",
        lastname: "false",
        contact_no: "false",
        // dob: "",
        address: "false",
        about_me: "",
        web_url: "",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/jobseeker-personal-profile",
      help: "",
      jobCategory: [],
      selectedJobs: [],
      category: [],
      selecteddata: [],
      selectflag: 0,
      profileimageCheck: null,
      resume: "",
      dobErr: "",
      linkedinResumeUrl: '',
      validMsg: false,
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.handleselect = this.handleselect.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  cancelSubscription = (sub)=>{
    let thhis = this
    thhis.setState({ loader: "visible" }); ///loader on
    if(!sub.subscriptionId ||sub.subscriptionId == "" ){
      thhis.setState({ loader: "hidden" }); ///loader off
      thhis.setState({ Msg: "No Subsciption ID found" });
      thhis.setState({ MsgType: "danger" });
      return;
    }
    let formData = new FormData();
    formData.append("subscriptionId",sub.subscriptionId)
    formData.append("id",sub.id)
    
    
    return new Promise((resolve, reject) => {

        let request = {
            end_point: "/cancel-subscriptions",
            formdata: formData,
            token: localStorage.getItem("gs_token"),
        };

        POST_API(request)
            .then((resp) => {
              thhis.setState({ loader: "hidden" }); ///loader off
                if (resp.status == "success") {
                  thhis.setState({ Msg: resp.message });
                  thhis.setState({ MsgType: "success" });
                  setTimeout(function(){
                    window.location.reload();
                  },2000);
                }else{
                  thhis.setState({ Msg: resp.message });
                  thhis.setState({ MsgType: "danger" });
                }
                resolve()
            }).catch((ee) => {
                thhis.setState({ loader: "hidden" }); ///loader off
                thhis.setState({ Msg: ee });
                thhis.setState({ MsgType: "danger" });
                resolve()
            });
    })
  }
  getMysubscriptions = () => {
    let thhis = this
    return new Promise((resolve, reject) => {


        let request = {
            end_point: "/get-subscriptions",
            token: localStorage.getItem("gs_token"),
        };

        // console.log("......request", request);

        GET_API(request)
            .then((resp) => {
                // this.setState({ loader: "hidden" }); ///loader off

                if (resp.status == "success") {
                    // console.log("Update")
                    thhis.setState({ mysubscriptions: resp.data }, () => {

                        let packageIdArr = thhis.state.mysubscriptions.map(e => e.package_id);
                        thhis.setState({ packageIdArr })
                    })

                }

                resolve()

            }).catch((ee) => {

                // console.log('/getMySubscription error', ee)
                resolve()


            });

    })
}


  dateValidate() {
    let dobErr = "";
    let FromData = this.state.FromData
    if (FromData.dob == "Invalid date") {
      dobErr = "required";
    } else if (FromData.dob > moment(new Date()).format("YYYY-MM-DD")) {
      dobErr = "DOB can not be a future date";
    }
    if (dobErr) {
      this.setState({ dobErr });
      return false;
    } else {
      this.setState({ dobErr: "" });
      return true;
    }
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    FromDataError.dob = ""
    this.setState({ FromDataError })

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
        console.log("loop", valid);
      }
    }

    console.log("funct", valid);

    return valid;
  }

  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }
  

  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (name == "profileimage") {


      let reader = new FileReader();
      reader.readAsDataURL(data.target.files[0]);
      reader.onload = (e) => {
        console.log('image data: ', e.target.result);
      };
      value = data.target.files[0];


    } else if (name == "linkedinresumeFile") {
      value = data.target.files[0];
      FromData.linkedinresumefilename = value.name;
    } else {
      value = data.target.value;
    }


    FromData[name] = value;

    this.setState({ FromData }, () => { });

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError }); //from error state
    });
    console.log(FromData);
  }
  handleselect(e) {
    localStorage.setItem("display_category", JSON.stringify([e]));
    this.setState({ category: [e], selectflag: 1 });
    // console.log("data",e)
    // console.log("display data",localStorage.getItem('display_category'))
    // let selecteddata=Array.isArray(e) ? e.map(x => x.value) : [];
    // this.setState({selectedJobs:selecteddata});
    // console.log("selected data",this.state.selectedJobs)
  }

  handaleResumeUpload = (data) => {
    let name = data.target.name;
    let FromData = this.state.FromData;

    let file = data.target.files[0]
    let value = '';
    value = file
    if (file.type == "application/pdf") {
      this.toBase64(data.target.files[0]).then((resp) => {

        FromData.resumeFile = resp.split(",")[1];
        FromData.resumefilename = file.name;
        FromData.resumeformat = file.type;

        this.setState({ FromData }, () => {
          console.log(FromData.resumeFile)
        })
      })
    }
  }


  handaleLinkedinResumeUpload = (data) => {
    let name = data.target.name;
    let FromData = this.state.FromData;

    let file = data.target.files[0]
    let value = '';
    value = file
    if (file.type == "application/pdf") {
      this.toBase64(data.target.files[0]).then((resp) => {
        
        FromData.linkedinresumeFile = resp.split(",")[1];
        FromData.linkedinresumefilename = file.name;
        FromData.linkedinresumeformat = file.type;
        
        this.setState({ FromData }, () => {
          console.log(FromData.linkedinresumeFile)
        })
      })
    }
  }




  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  handaleSubmit(e) {
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let gs_user = "";
    console.log(FromData.linkedinresumeFile);
    localStorage.setItem("job_category", this.state.selectedJobs);
    // console.log("selected data",localStorage.getItem("job_category"))
    if (this.validateForm(FromDataError) && this.dateValidate(this.state.dobErr)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();
      formdata.append("about_me", FromData.about_me || "");
      formdata.append("dob", moment(FromData.dob).format("YYYY-MM-DD"));

      if (
        FromData.profileimage != undefined &&
        typeof FromData.profileimage == "object"
      ) {
        formdata.append(
          "profileimage",
          FromData.profileimage,
          FromData.profileimage.name
        );
      }
      if (
        FromData.resumeFile.file != ""
      ) {
        formdata.append(
          "resumeFile",
          FromData.resumeFile
        );
        formdata.append(
          "resumefilename",
          FromData.resumefilename
        );
        formdata.append(
          "resumeformat",
          FromData.resumeformat
        );
      }



      if (
        FromData.linkedinresumeFile != undefined && FromData.linkedinresumeFile != ''
      ) {
        formdata.append(
          "linkedinresumeFile",
          FromData.linkedinresumeFile,
          FromData.linkedinresumeFile.name
        );
        
        // formdata.append(
        //   "linkedinresumeFile",
        //   FromData.linkedinresumeFile.file
        // );
        // formdata.append(
        //   "linkedinresumefilename",
        //   FromData.linkedinresumefilename
        // );
        // formdata.append(
        //   "linkedinresumeformat",
        //   FromData.linkedinresumeformat
        // );
      }
      formdata.append("contact_no", FromData.contact_no);
      formdata.append("web_url", FromData.web_url || "");
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("address", FromData.address);
      if (this.state.selectflag == 1) {
        formdata.append("job_category", JSON.stringify(this.state.category));
      } else {
        formdata.append("job_category", localStorage.getItem("get_category"));
      }

      let request = {
        end_point: "/edit-user-profile-details",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      console.log("......request", FromData);

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            sta.setState({
              Msg: "Profile Updated Successfully",
              MsgType: "success",
            });

            localStorage.setItem("gs_user", JSON.stringify(resp.data.user));
            localStorage.setItem(
              "get_category",
              resp.data.profile.job_category
            );
            // update
            localStorage.setItem("profile_image", resp.data.user.profileimage);

            setTimeout(() => {
              window.location.reload();
            }, 300);
          } else {
            sta.setState({ Msg: resp.message, MsgType: "danger" });
            // sta.setState({ Msg: "Profile Update Failed", MsgType: "danger" });
          }
        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
    }
  }



  componentDidMount() {
    window.scrollTo(0, 0);
    this.getuserDetails();
    this.getresume();
    // this.props.dispatch({type:"UPDATE-BALANCE",refreshBalance:true})

    let checkParam = this.getParameterByName("uploadLinkdin");
    console.log(checkParam);
    if (checkParam) {
      this.setState({ validMsg: true });
      setTimeout(() => {
        this.setState({ validMsg: false });
      }, 10000);
    } else {
      this.setState({ validMsg: false });
    }

    if (localStorage.getItem("gs_user")) {
        let user = JSON.parse(localStorage.getItem("gs_user"))
        if (user.roleID == 4)
            this.getMysubscriptions()
    }

  }

  checkTrialPeriod = (gs_user)=>{
      if(gs_user.is_trial == "Y"){
        let givenTimestamp = gs_user.trial_end;
        let currentDateTime = moment();
        let givenDateTime = moment(givenTimestamp, 'YYYY-MM-DD HH:mm:ss');
        let isGreater = givenDateTime.isAfter(currentDateTime);
        return isGreater
      }
      return false;
  }

  // checkTrialPeriodDays = (sub)=>{
  //     const givenTimestamp = sub;
  //     const currentDateTime = moment();
  //     const givenDateTime = moment(givenTimestamp, 'YYYY-MM-DD HH:mm:ss');
  //     return givenDateTime.diff(currentDateTime, 'days') + 1;
  // }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getresume() {
    let request = {
      end_point: "/download-resume",
      // formdata: formdata,
      token: localStorage.getItem("gs_token"),
    };

    POST_API(request)
      .then((resp) => {

        console.log(resp)
        if (resp.status == "success") {
          this.setState({ resume: resp.url })
        }
      })

  }


  getuserDetails() {
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    //get job category list
    let request = {
      end_point: "/get-jobcategory",
      token: localStorage.getItem("gs_token"),
    };
    GET_API(request)
      .then((resp) => {
        // console.log("job category",resp.display)
        this.setState({
          jobCategory: resp.category,
          selecteddata: resp.display,
        });
        localStorage.setItem("selected", resp.display);
      })
      .catch((ee) => { });

    try {
      if (localStorage.getItem("gs_user") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        sta.setState({ loader: "visible" }); ///loader on

        GET_API({
          token: localStorage.getItem("gs_token"),
          end_point: "/get-profile-details",
        })
          .then((resp) => {
            if (resp.status == "success") {
              console.log("user details", resp.data);
              sta.setState({
                profileimageCheck: resp.data.user.profileimageCheck,
              });
              localStorage.setItem(
                "profile_image",
                resp.data.user.profileimage
              );
              sta.setState({ linkedinResumeUrl: (resp.data.user.linkedin_resume_url == '') ? '' : resp.data.user.linkedin_resume_url });

              FromData["firstname"] = resp.data.user.firstname;
              FromDataError["firstname"] =
                resp.data.user.firstname != null ? "" : "false";

              FromData["lastname"] = resp.data.user.lastname;
              FromDataError["lastname"] =
                resp.data.user.lastname != null ? "" : "false";

              FromData["profileimage"] = resp.data.user.profileimage;
              FromDataError["profileimage"] =
                resp.data.user.profileimage != null ? "" : "false";

              FromData["contact_no"] = resp.data.profile.contact_no;
              FromDataError["contact_no"] =
                resp.data.profile.contact_no != null ? "" : "false";

              FromData["dob"] = (resp.data.profile.dob == '0000-00-00' || resp.data.profile.dob == "" || resp.data.profile.dob == "undefined") ? "Invalid date": resp.data.profile.dob;
              FromDataError["dob"] =
                resp.data.profile.dob != null ? "" : "false";

              FromData["user_email"] = resp.data.user.email;

              FromData["address"] = resp.data.profile.address;
              FromDataError["address"] =
                resp.data.profile.address != null ? "" : "false";

              FromData["about_me"] = resp.data.profile.about_me || "";
              // FromDataError['about_me'] = (resp.data.profile.about_me != 'null')?'': false

              FromData["web_url"] = resp.data.profile.web_url || "";
              // FromDataError['web_url'] = (resp.data.profile.web_url != 'null')?'': false

              this.setState({ FromData });
            }
            sta.setState({ loader: "hidden" }); ///loader off
          })
          .catch((ee) => {
            sta.setState({ loader: "hidden" }); ///loader off

            console.log("/userdetailsEditERR", ee);
          });
      }
    } catch (error) { }

    //help api
    let requestHelp = {
      end_point: "/help-details?key=jobseeker_my_profile",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }

  deleteProfileImg = async () => {
    let self = this;
    const cnfirm = window.confirm(
      `Are you sure you want to delete this profile image?`
    );
    console.log("alert", cnfirm);
    if (cnfirm) {
      let request = {
        end_point: "/delete-user-profile-image",
        token: localStorage.getItem("gs_token"),
      };

      POST_API(request)
        .then((resp) => {
          self.setState({ loader: "hidden" }); ///loader off
          console.log("delete image", resp);

          if (resp.status == "success") {
            self.setState({
              Msg: "Profile image deleted successfully",
              MsgType: "success",
            });

            // this.getuserDetails()
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            self.setState({
              Msg: "Profile image delete Failed",
              MsgType: "danger",
            });
          }
        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });
    }
  };

  handleDateChange(DOBdate) {
    // console.log("DOBdate",moment(DOBdate).format("YYYY-MM-DD"))
    let FromData = this.state.FromData

    FromData.dob = moment(DOBdate).format("YYYY-MM-DD")
    this.setState({ FromData })
  }

  render() {
    let FromData = this.state.FromData;
    // let selecteddata = JSON.parse(localStorage.getItem('display_category'));
    // let selected = JSON.parse(localStorage.getItem('selected'));
    let data = JSON.parse(localStorage.getItem("get_category"));
    // console.log(moment(new Date()).format("YYYY-MM-DD"))

    // console.log("selected",this.state.FromDataError.dob,this.state.FromData.dob)

    return (
      <div>
        {FromData.hasOwnProperty("firstname") ? (
          <>
            <Container fluid className="dash_container">
              <Row>
                {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
                <Col md={12}>
                  <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />
                  <div className="tabpanelOneForm pt-3">
                    <Card className="p-5 body_card">
                      {this.state.validMsg ? (<>
                        <div className="text-center">
                          <h5 className="text-primary"><b>Please Upload your Linkedin Resume</b></h5>
                        </div>
                      </>) : (<></>)}
                      <h3>Personal Profile   

                        {this.state.mysubscriptions.map((sub) => {
                            return (<>
                                    { this.checkTrialPeriod(sub) && 
                                    <button className="btn btn-danger" onClick={()=>{this.cancelSubscription(sub)}} style={{"width": "210px","float": "right"}}>Cancel Subscription</button> 
                                    }
                              </>)
                        })}

                      </h3>
                      {/* <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.help,
                      }}
                    ></p> */}
                      {/*<div className="tooltip-block-redeem-list" style={{left: "220px", top:"20px"}}>   
                  <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2"><div
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></div></Tooltip>}
              >
                    <Image
                      roundedCircle
                      src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1774ca3ddcc%20text%20%7B%20fill%3A%23FFF%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1774ca3ddcc%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%2328a745%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%227.1171875%22%20y%3D%2215.100000000000001%22%3EJ%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                    /> 
                    
            
               </OverlayTrigger>
              </div>*/}
                      <Form className="tabform" onSubmit={this.handaleSubmit}>
                        <Row className="m-0 mt-4 mb-4">
                          <Col md={12}>
                            {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                            {this.state.Msg !== "" ? (
                              <>
                                <FlashMess
                                  m={this.state.Msg}
                                  mtype={
                                    this.state.MsgType
                                      ? this.state.MsgType
                                      : "success"
                                  }
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <Form.Row className="align-items-center mb-3">
                              <Col md={3} className="d-flex justify-content-center">
                                {typeof FromData.profileimage == "string" ? (
                                  <>
                                    <img
                                      src={FromData.profileimage}
                                      className="img-fluide pimage"
                                    />
                                    {this.state.profileimageCheck != null ? (
                                      <img
                                        src={profileimageRemove}
                                        onClick={() => this.deleteProfileImg()}
                                        className="delete_image"
                                        height="20px"
                                        width="20px"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  Profile Image (jpeg, jpg, png)
                                </Form.Label>
                                <Form.File
                                  id="custom-file-translate-scss"
                                  name="profileimage"
                                  accept="image/*"
                                  onChange={this.handaleChange}
                                  label={
                                    FromData.profileimage.name || "Select file"
                                  }
                                  // validaterule={[
                                  //   "required",
                                  //   "filesize|250000",
                                  //   "mime|jpeg/png/jpg",
                                  // ]}
                                  // validatemsg={["This field is required"]}
                                  lang="en"
                                  custom
                                />
                                  <span>Max size should be 2MB</span>
                                <p style={{ color: "red" }}>
                                  {this.state.FromDataError.profileimage ==
                                    "false"
                                    ? ""
                                    : this.state.FromDataError.profileimage}
                                </p>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row className="align-items-center mb-3">
                              <Col md={3}>
                                {this.state.resume != "" && (

                                  <a target="_blank" download className="btn btn-primary" href={this.state.resume}>

                                    Download Resume

                                  </a>



                                )}


                              </Col>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  Upload Updated Resume (PDF)
                                </Form.Label>
                                <Form.File
                                  // disabled={true}
                                  accept="application/pdf"
                                  id="custom-file-translate-scss"
                                  name="file"
                                  onChange={this.handaleResumeUpload}
                                  label={this.state.FromData.resumefilename != '' ? this.state.FromData.resumefilename : "PDF File"
                                  }
                                  // validaterule={[
                                  //   "required",
                                  //   "filesize|250000",
                                  //   "mime|jpeg/png",
                                  // ]}
                                  // validatemsg={["This field is required"]}
                                  lang="en"
                                  custom
                                />

                                <p style={{ color: "#1c9baf" }}>
                                  New updated resume email and phone should be same as provided previously.
                                </p>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row className="align-items-center mb-3">
                              <Col md={3}>
                                {(this.state.linkedinResumeUrl != "" && this.state.linkedinResumeUrl != null) && (

                                  <a target="_blank" download className="btn btn-primary" href={this.state.linkedinResumeUrl}>

                                    Download Profile PDF

                                  </a>



                                )}

                              </Col>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  Upload LinkedIn profile (PDF)
                                </Form.Label>
                                <Form.File
                                  // disabled={true}
                                  accept="application/pdf"
                                  id="custom-file-translate-scss"
                                  name="linkedinresumeFile"
                                  onChange={this.handaleChange}
                                  label={this.state.FromData.linkedinresumefilename != '' ? this.state.FromData.linkedinresumefilename : "PDF File"
                                  }
                                  // validaterule={[
                                  //   "required",
                                  //   "filesize|250000",
                                  //   "mime|jpeg/png",
                                  // ]}
                                  // validatemsg={["This field is required"]}
                                  lang="en"
                                  custom
                                />


                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  First Name{" "}
                                  <span className="text-danger"> *</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="firstname"
                                  onChange={this.handaleChange}
                                  placeholder="Enter your first name"
                                  value={FromData.firstname}
                                  validaterule={["required", "isString"]}
                                  validatemsg={[
                                    "This field is required",
                                    "First name must contain alphabets",
                                  ]}
                                />
                                <p style={{ color: "red" }}>
                                  {this.state.FromDataError.firstname == "false"
                                    ? ""
                                    : this.state.FromDataError.firstname}
                                </p>
                              </Form.Group>

                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  Last Name{" "}
                                  <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="lastname"
                                  onChange={this.handaleChange}
                                  placeholder="Enter your last name"
                                  value={FromData.lastname}
                                  validaterule={["required", "isString"]}
                                  validatemsg={[
                                    "This field is required",
                                    "Last name must contain alphabets",
                                  ]}
                                />
                                <p style={{ color: "red" }}>
                                  {this.state.FromDataError.lastname == "false"
                                    ? ""
                                    : this.state.FromDataError.lastname}
                                </p>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  Contact No{" "}
                                  <span className="text-danger"> *</span>
                                </Form.Label>

                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    value={FromData.contact_no}
                                    name="contact_no"
                                    onChange={this.handalePhoneNoChange.bind(this)}
                                    validaterule={['required', 'phone']}
                                    validatemsg={['This field is required', 'Invalid Phone number']}
                                  />
                                <p style={{ color: "red" }}>
                                  {this.state.FromDataError.contact_no == "false"
                                    ? ""
                                    : this.state.FromDataError.contact_no}
                                </p>
                              </Form.Group>

                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>
                                  DOB <span className="text-danger"> *</span>
                                </Form.Label>
                                {/* <Form.Control
                                  className="w-100"
                                  type="date"
                                  name="dob"
                                  value={FromData.dob}
                                  onChange={this.handaleChange}
                                  validaterule={["required", "validDOB"]}
                                  validatemsg={[
                                    "This field is required",
                                    "DOB can not be a future date",
                                  ]}
                                />
                                <p style={{ color: "red" }}>
                                  {this.state.FromDataError.dob == "false"
                                    ? ""
                                    : this.state.FromDataError.dob}
                                </p> */}
                                <DatePicker
                                  className='pl-2'
                                  selected={FromData.dob == "Invalid date" ? "" : new Date(FromData.dob)}
                                  name="dob"
                                  type="date"
                                  placeholderText="mm-dd-yyyy"
                                  dateFormat="MM-dd-yyyy"
                                  onChange={this.handleDateChange}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                                <p style={{ color: 'red' }}>{this.state.dobErr}</p>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="user_email"
                                  value={FromData.user_email}
                                  onChange={this.handaleChange}
                                  readOnly
                                />
                              </Form.Group>
                            </Form.Row>
                            <Form.Group controlId="formGridAddress1">
                              <Form.Label>
                                Address <span className="text-danger"> *</span>
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name="address"
                                onChange={this.handaleChange}
                                placeholder="Example : 241 Turnpike Rd, Montague, MA, 01351, USA"
                                value={FromData.address}
                                validaterule={["required", "min|10"]}
                                validatemsg={[
                                  "This field is required",
                                  "Enter Full Address",
                                ]}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.address == "false"
                                  ? ""
                                  : this.state.FromDataError.address}
                              </p>
                            </Form.Group>

                            {/* <Form.Group controlId="formGridAddress1">
                              <Form.Label>About Me</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                name="about_me"
                                value={FromData.about_me}
                                onChange={this.handaleChange}
                                placeholder="Enter about me"
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.about_me == "false"
                                  ? ""
                                  : this.state.FromDataError.about_me}
                              </p>
                            </Form.Group> */}

                            {/* <Form.Group controlId="formGridAddress1">
                              <Form.Label>Web Url</Form.Label>
                              <Form.Control
                                type="text"
                                name="web_url"
                                value={FromData.web_url}
                                onChange={this.handaleChange}
                                placeholder="www.example.com"
                                validaterule={["web_url_check"]}
                                validatemsg={["Please Enter valid URL"]}
                              />
                              <p style={{ color: "red" }}>
                                {this.state.FromDataError.web_url == "false"
                                  ? ""
                                  : this.state.FromDataError.web_url}
                              </p>
                            </Form.Group> */}
                            <Form.Label> Select Job Categories for Receiving Jobalerts</Form.Label>
                            <Select
                              
                              closeMenuOnSelect={false}
                              defaultValue={
                                data != null ? data[0].map((job) => job) : []
                              }
                              components={makeAnimated()}
                               isMulti
                              options={this.state.jobCategory.map((job) => ({
                                label: job.name,
                                value: job.name,
                              }))}
                              onChange={this.handleselect}
                            />
                          </Col>

                          <Row className="justify-content-center w-100">
                            <Col md={6} className="pl-0 tabformRight mt-3">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn-main tabformbutton"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}

const WithUseReducer = (props) => {

  let dispatch = useDispatch();




  return <JobseekerPersonalProfile {...props} {...{ dispatch }} />;
}

export default WithUseReducer
