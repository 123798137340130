import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../Config';
import moment from 'moment';




export default class JobseekerPaymentHistory extends Component {

  constructor(props) {
    super(props)
    this.state = {

      FromData: {},
      FromDataError:
        {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/jobseeker-payment-history',
      help: '',
      payment_userID: 0,
      totalRows: 0,
      offset: 1,
      limit: 10,
      JobseekerTotal: []

    };

    //Binding




  }

  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '100px',
      sortable: true,
      wrap: true,
    },
    {
        name: "Package Name",
        selector: row => row.package_name,
        sortable: true
    },
    {
      name: "Payment Date",
      selector: row => moment(row.payment_date).format('MM-DD-YYYY'),
      sortable: true
    },
    {
      name: "Amount",
      selector: row => '$' + row.amount,
      sortable: true
    },

    {
      name: "Payment Status",
      selector: row => row.payment_status == 1 ? <span className="badge badge-success">Success</span> : <span className="badge badge-danger">Failed</span>,
      sortable: true
    },
    {
      name: "Payment From",
      selector: row => row.payment_gateway,
      sortable: true
    },
    // {
    //   name: "Action",
    //   cell: row => {
    //     console.log('payment id', row.users_userID);
    //     return (
    //       <div>
    //         <Button variant="primary" onClick={() => this.handlepaymentmodal(row.paymentID)}>View</Button>
    //       </div>
    //     )
    //   }
    //   ,
     
    // }

  ];


  handlePageChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/get-jobseeker-payment-history?offset=${page}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        console.log("payment history", resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };


  handlePerRowsChange = async (page) => {
    //this.setState({ offset: page });
    console.log(page);
    let request = {
      end_point: `/get-jobseeker-payment-history?offset=${this.state.offset}&limit=${page}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        this.setState({ limit: page });
        console.log("payment history", resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };

  handleSearchChange = async (searchValue) => {
    let request = {
      end_point: `/get-jobseeker-payment-history?offset=${this.state.offset}&limit=${this.state.limit}&searchValue=${searchValue}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        console.log("payment history", resp.data)
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: `/get-jobseeker-payment-history?offset=${this.state.offset}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        FromData = resp.data
        console.log("payment history", resp.data)
        sta.setState({ totalRows: resp.total });

        sta.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });


  }


  render() {
    let FromData = this.state.FromData

    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row>
          
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12} className="pt-4 pb-4">
                <Loader loadmsg="please wait" visibility={this.state.loader} />
               
                

                  {/* <div>
                  <a href='#' style={{float: "right"}}><Button className="btn_header mb-2" >UPGRADE</Button></a>
                  </div> */}
                <div className="tabpanelOneForm">


                    <Row className='align-items-start'>
                      <Col md={12}>
                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                      </Col>
                      <Col md={9}>
                      <h3>Payment History</h3>
                      <p>Payment history details</p>
                      </Col>
                      <Col md={3} className="d-flex justify-content-end">
                          
                      </Col>
                    </Row>


                    <div className="table_search" style={{float: "right",margin:'10px'}}>   
                      Search : 
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => this.handleSearchChange(e.target.value)}
                      />
                    </div>
                  

                  
                 

                  

                  <DataTable
                    title=""
                    columns={this.columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

