import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Image,
  Card
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import Leftpanel from "./Section/LeftPanel";
import profileimage from "../images/dashboard_profile_image.jpg";
import "./css/dashboard.css";
import profileimageRemove from "../images/x-circle.svg";

import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

class PersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        profileimage: "",
        firstname: "",
        lastname: "",
        contact_no: "",
        address: "",
      },
      FromDataError: {
        profileimage: "false",
        firstname: "false",
        lastname: "false",
        contact_no: "false",
        address: "false",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/sponser-personal-profile",
      help: "",
      profileimageCheck:null,
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
  }
  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }
  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;
    console.log(Object.entries(errors));
    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        //console.log(value + "-" + key);
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
        console.log("loop", valid);
      }
    }

    console.log("funct", valid);
    console.log(this.state.FromDataError);
    return valid;
  }

  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (name == "profileimage") {
      value = data.target.files[0];
    } else {
      value = data.target.value;
    }

    FromData[name] = value;

    this.setState({ FromData }, () => {});

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError }); //from error state
    });
  }

  handaleSubmit(e) {
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let gs_user = "";

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();
      formdata.append("about_me", FromData.about_me || "");
     // formdata.append("dob", FromData.dob);

      if (
        FromData.profileimage != undefined &&
        typeof FromData.profileimage == "object"
      ) {
        formdata.append(
          "profileimage",
          FromData.profileimage,
          FromData.profileimage.name
        );
      }
      formdata.append("contact_no", FromData.contact_no);
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("address", FromData.address);
     
      let request = {
        end_point: "/edit-user-profile-details",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      console.log("......request", formdata);

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            sta.setState({
              Msg: "Profile Update Successfully",
              MsgType: "success",
            });

            localStorage.setItem("gs_user", JSON.stringify(resp.data.user));
            // update

            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            sta.setState({ Msg: "Profile Update Failed", MsgType: "danger" });
          }
        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    try {
      if (localStorage.getItem("gs_user") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        sta.setState({ loader: "visible" }); ///loader on

        console.log("in " + localStorage.getItem("gs_token"));
        GET_API({
          token: localStorage.getItem("gs_token"),
          end_point: "/get-profile-details",
        })
          .then((resp) => {
            if (resp.status == "success") {
              sta.setState({profileimageCheck:resp.data.user.profileimageCheck})
              FromData["firstname"] = resp.data.user.firstname;
              FromDataError["firstname"] =
                resp.data.user.firstname != null ? "" : "false";

              FromData["lastname"] = resp.data.user.lastname;
              FromDataError["lastname"] =
                resp.data.user.lastname != null ? "" : "false";

              FromData["profileimage"] = resp.data.user.profileimage;
              FromDataError["profileimage"] =
                resp.data.user.profileimage != null ? "" : "false";

              FromData["contact_no"] = resp.data.profile.contact_no;
              FromDataError["contact_no"] =
                resp.data.profile.contact_no != null ? "" : "false";

              // FromData["dob"] = resp.data.profile.dob;
              // FromDataError["dob"] =
              //   resp.data.profile.dob != null ? "" : "false";

              FromData["address"] =
                resp.data.profile.address != null
                  ? resp.data.profile.address
                  : "";
              FromDataError["address"] =
                resp.data.profile.address != null ? "" : "";

              // FromData["about_me"] = resp.data.profile.about_me || "";
              // FromDataError['about_me'] = (resp.data.profile.about_me != 'null')?'': false

              // FromData["web_url"] = resp.data.profile.web_url || "";
              // FromDataError['web_url'] = (resp.data.profile.web_url != 'null')?'': false

              this.setState({ FromData });
            }
            sta.setState({ loader: "hidden" }); ///loader off
          })
          .catch((ee) => {
            sta.setState({ loader: "hidden" }); ///loader off

            console.log("/userdetailsEditERR", ee);
          });
      }
    } catch (error) {}

    //help api
    let requestHelp = {
      end_point: "/help-details?key=sponsor_my_profile",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }

  deleteProfileImg = async () =>{
    let self = this;
    const cnfirm = window.confirm(
        `Are you sure you want to delete this profile image?`
    )
    console.log("alert",cnfirm)
    if(cnfirm){

      let request = {
        end_point : '/delete-user-profile-image',
        token: localStorage.getItem("gs_token")

      };

      POST_API(request).then(resp=>{

        self.setState({loader:'hidden'})///loader off
        console.log("delete image",resp)

              if(resp.status == 'success')
              {
                self.setState({Msg:'Profile image deleted successfully',MsgType:'success'})
                
               // this.getuserDetails()
               setTimeout(() => {
                window.location.reload()
                
              }, 3000);

              }
              else{
                  
                  
                self.setState({Msg:'Profile image delete Failed',MsgType:'danger'})
              }
              }).catch((ee)=>{

                  console.log('/userEditERR',ee)
              });
            

    }
  }  

  render() {
    let FromData = this.state.FromData;

    return (
      <div>
        {FromData.hasOwnProperty("firstname") ? (
          <>
            <Container fluid className="dash_container">
          <Row>
            <Leftpanel urlPath={this.state.urlPath} {...this.props} />
            <Col md={9} className="pt-4 pb-4">
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm">
            <Card className="p-5 body_card">
            <h3>Personal Profile</h3>
            <p dangerouslySetInnerHTML={{  __html: this.state.help }}></p>    
                        <Form
                          className="tabform"
                          onSubmit={this.handaleSubmit}
                        >
                          <Row className="m-0 mt-4 mb-4">

                        
                            <Col md={12}>
                                {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
               {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
              
              
             
                              
                        <Form.Row className="align-items-center mb-3">
                            <Col md={3} className="d-flex justify-content-center">

                              {typeof FromData.profileimage == 'string' ? 
                            
                              <>
                                <img src={FromData.profileimage  } className="img-fluide pimage"/> 
                            
                           {this.state.profileimageCheck!=null?
                           <img src={profileimageRemove} onClick={()=>this.deleteProfileImg()} className="delete_image" height="20px" width="20px"/>
                           :""
                           }
                          
                          </>
                              : '' }
                              

                            </Col>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                    <Form.Label>Profile Image (jpeg, jpg, png)</Form.Label>
                                     <Form.File id="custom-file-translate-scss" 
                                                name='profileimage'
                                              
                                                onChange={this.handaleChange}
                                                label={FromData.profileimage.name || "Choose file"}  
                                                validaterule={['required','filesize|250000','mime|jpeg/png']}
                                                validatemsg={['This field is required']}
                                                lang="en"  custom />
                                              
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.profileimage=='false'?'': this.state.FromDataError.profileimage}</p>

                               </Form.Group>
                               </Form.Row>
                               <Form.Row>
                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>First Name
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="firstname"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your first name"
                                    value={FromData.firstname}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.firstname=='false'?'': this.state.FromDataError.firstname}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Last Name
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="lastname"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your last name"
                                    value={FromData.lastname}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.lastname=='false'?'': this.state.FromDataError.lastname}</p>
                                </Form.Group>
                              </Form.Row> 
                               <Form.Row>
                               

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Contact No
                                    <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <PhoneInput
                                      international
                                      countryCallingCodeEditable={false}
                                      defaultCountry="US"
                                      value={FromData.contact_no}
                                      name="contact_no"
                                      onChange={this.handalePhoneNoChange.bind(this)}
                                      validaterule={['required', 'phone']}
                                      validatemsg={['This field is required', 'Invalid Phone number']}
                                    />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.contact_no=='false'?'': this.state.FromDataError.contact_no}</p>
                                </Form.Group>

                              </Form.Row> 
                              <Form.Group controlId="formGridAddress1">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name='address'
                                    onChange={this.handaleChange}
                                    placeholder="Example : 241 Turnpike Rd, Montague, MA, 01351, USA"
                                    value={FromData.address}
                                    // validaterule={['required','min|10']}
                                    // validatemsg={['This field is required','Enter Full Address']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.address=='false'?'': this.state.FromDataError.address}</p>

                                </Form.Group> 

                            </Col>
                        

                            <Row className="justify-content-center w-100">
                              <Col md={6} className="pl-0 tabformRight mt-3">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn-main tabformbutton"
                              >
                                Submit
                              </Button>
                              </Col>
                            </Row>


                       
                      </Row>
                    </Form>
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}

export default PersonalProfile;
