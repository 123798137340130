import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Dropdown, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import moment from "moment";

const BulkUnlockQuee = (props) => {

    const [records, setRecords] = useState([]);
    const [recordsCount, setRecordsCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState("created_at");
    const [sortD, setSortD] = useState("desc");
    // const [search, setSearch] = useState("");
    // const [tType, setType] = useState("");
    // const [searchType, setSearchType] = useState(false);


    useEffect(() => {
        setLoading(true)
        GET_API({
            end_point: `/unlock-bulk-quee-list?page=${page}&limit=${limit}&sort=${sort}&sortD=${sortD}`,
            token: localStorage.getItem("gs_token")
        }).then((resp) => {
            setLoading(false)
            if (resp.status == "success") {
                setRecords(resp.data);
                setRecordsCount(resp.total);

            }

        })
            .catch((err) => {
                setLoading(false)
                console.log("creditTransactions", err)
            })

    }, [limit, page, sort, sortD])









    let conditionalRowStyles = [
        {
            when: row => (row.type == 1 && row.status == 1),
            style: {
                backgroundColor: "#93ddea"
            }
        },
        {
            when: row => (row.status == 0),
            style: {
                backgroundColor: "#8080807a"
            }
        }
    ];
    let columns = [
        {
            name: "Total Unlock Request",
            selector: row => { return `${row.totalContacts} Contacts` },
            sortable: true,
            width: "30%",
            keyField: "amount"
        },
        {
            name: "Total Valid",
            selector: row => { return `${row.totalValid}` },

            // sortable: true,
            width: "20%",
        },
        {
            name: "Total Invalid",
            selector: row => { return row.totalInvalid },

            // sortable: true,
            width: "20%",
        },
        {
            name: "Status",
            selector: row => { return row.status == 0 ? "Pending" : row.status == 1 ? "Processing" : "Completed" },
            // sortable: true,
            width: "40%",
        }
    ]
    const customStyles = {
        header: {
            style: {
                display: 'none',
            },
        },
    };

    return (
        <div className='pt-5 pb-5 data_table_gwi'>
            <Container >
                <Row>
                    <Col md={12}>
                        <h2 className='jobseeker_heading_hr'>Data Validation List</h2>
                        <div className='w-100 mb-2'>&nbsp;</div>
                        {/* <div style={{ width: "300px", float: "right" }}>
                            <Form.Control
                                size="large"
                                name="searchInput"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                                placeholder="Search"
                            />
                        </div> */}
                    </Col>
                    <Col md={12}>
                        <Loader loadmsg="please wait" visibility={loading ? "visible" : "hidden"} />


                        <div className='mb-2 mt-2'>

                            <form id="search-form">
                                {/* <Row>
                                    <Col sm="3">
                                        <label>Transaction Type</label>
                                        <Form.Control as="select" name="transaction_type" onChange={typeChange} defaultValue={''}>
                                            <option value="">--Select Type--</option>
                                            <option value="credit">Credit</option>
                                            <option value="debit">Debit</option>
                                        </Form.Control>
                                    </Col>
                                    <Col sm="1">
                                        <Button
                                            variant="primary"
                                            type="button"
                                            className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                            onClick={searchAll}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                    <Col sm="1" className='pl-4'>
                                        <Button
                                            variant="primary"
                                            type="button"
                                            className="btn btn-primary btn-md tabformbutton mt-4 btn_total"
                                            onClick={resetAll}
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row> */}
                                <a
                                    href="javascript: history.go(-1)"
                                    className="btn btn-primary tabformbutton ghost_button float-left btn_total"
                                >
                                    Back
                                </a>
                            </form>
                            <div className="mb-2 pb-5"></div>
                        </div>


                        <DataTable
                            title=""
                            columns={columns}
                            data={records}
                            defaultSortField="Date"
                            conditionalRowStyles={conditionalRowStyles}
                            noContextMenu
                            customStyles={customStyles}

                            pagination={true}
                            NoDataComponent={"No data"}
                            noHeader={true}
                            paginationServer
                            paginationTotalRows={recordsCount}
                            onChangeRowsPerPage={(limit) => { setLimit(limit) }}
                            onChangePage={(page) => { setPage(page) }}
                            sortServer
                            onSort={(column, sortDirection) => {
                                // console.log(column)
                                setSort(column.keyField)
                                setSortD(sortDirection)
                            }}

                        />

                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default BulkUnlockQuee;