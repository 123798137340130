import React, { Component } from 'react'

import fm from "../images/fm.gif"
import resumeManStand from "../images/resume-man-flying-pose-and-standing.gif";
import resumemanstand2 from "../images/standing-resume-man.gif"
import "./css/resumeman.css";

export default class ResumeMan extends Component {

    constructor(props)
    {   
        super(props)
        this.state = {
            // man1:'none',
            man2:'none',
            man3:'none',
            man4:'none',
            man3Width:"0",
            man3Height:"0"

        }

    }


    componentDidMount(){
            // const run1st = setTimeout(()=>{this.ran1start()}, 1);
            // const run1sto = setTimeout(()=>{this.ran1stop()}, 2000);

            
            const run2st = setTimeout(()=>{this.ran2start()}, 1);
            const run2sto = setTimeout(()=>{this.ran2stop()}, 4400);

            const run3st = setTimeout(()=>{this.ran3start()}, 4402);
            const run3sto = setTimeout(()=>{this.ran3stop()}, 6902);



  
            const run4st = setTimeout(()=>{this.ran4show()}, 6902);

            
           var thissss = this

            //     Clear all efects
           let alldone=(display="block")=>{
            //   clearTimeout(run1st);
            //   clearTimeout(run1sto);
            clearTimeout(run2st);
            clearTimeout(run2sto);
            clearTimeout(run3st);
            clearTimeout(run3sto);
            clearTimeout(run4st);


            // thissss.ran1stop()
            thissss.ran2stop()
            thissss.ran3stop()
            if(display=="block")
            {
                thissss.ran4show()
            }
            else
            {
                thissss.ran4stop()
            }
           }

            //     clear all checks on body click
            document.getElementsByTagName("BODY")[0].addEventListener("click",alldone)

            // If session storage found clar all repeat checking
            var timer = setInterval(()=>{  
                if(sessionStorage.getItem("allanimdone") == 'true'  ){
                    alldone()
                    clearInterval(timer);
                }
            }, 1);



        var winWidth = window.innerWidth;
        var winHeight = window.innerHeight;
        var netW = winWidth;
        this.setState({man3Width:netW+30,man3Height:winHeight})

        // if windows size low then clear reume man
        if(winWidth < 770){
         clearInterval(timer);
         sessionStorage.setItem("allanimdone", true);
     
         var timertwo = setInterval(function(){  
           if(sessionStorage.getItem("allanimdone") == 'true'  ){
                alldone("none")
               clearInterval(timertwo);
           }
         }, 1);
         
        }





    }

    // ran1start = ()=>{
    //     this.setState({man1:"block"})
    // }
    // ran1stop = () =>{
    //     this.setState({man1:"none"})
    // }
    ran2start = () => {
        this.setState({man2:"block"})
    }
    ran2stop = () => {
        this.setState({man2:"none"})
    }

    ran3start = () =>{
        this.setState({man3:"block"})
    }
    ran3stop = () =>{
        this.setState({man3:"none"})
    }

    ran4show = () =>{
        this.setState({man4:"block"})
        sessionStorage.setItem("allanimdone", true);
    }
    ran4stop = () =>{
        this.setState({man4:"none"})
    }
    

  render() {
    return (<>
            {/* <div className="main_div" id="randomCol" style={{display:this.state.man1}}>
                    <div className="flying_man3 fm fm1">
                        <img src={fm} alt=""/>
                    </div>
            </div> */}

            <div className="main_div" id="randomCol2" style={{display:this.state.man2}}>
                <div className="flying_man4 fm fm2">
                    <img src={fm} alt=""/>
                </div>
            </div>

            <div className="main_div3" id="randomCol3" style={{display:this.state.man3,width:this.state.man3Width,height:this.state.man3Height}}>
                <div className="flying_man_stop fm fm3">
                    <img src={resumeManStand} alt=""/>
                </div>
            </div>

            <div className="rm_stop_gif" id="rm_stop" style={{display:this.state.man4}}>
                <img src={resumemanstand2} alt=""/>
            </div>

    </>)
  }
}
