import React, { Component } from 'react';

class Dashboard extends Component {

    constructor()
    {
        super()
        this.state = {
                    userDetails:{}
                    
                   
                    }

        //console.log('....sate', this.state)

        
    }

    componentDidMount()
    {
        try {
            if(localStorage.getItem("gs_user") != null)
            {
                let userDetails = JSON.parse(localStorage.getItem("gs_user"))
                this.setState({userDetails},()=>{
                    console.log('..onloadState',this.state)
                })
            }
           
        } catch (error) {
            
        }
    }

    render() {

        let userDetails = this.state.userDetails
        return (
            <div>
                <h1>Hello {userDetails.firstname} {userDetails.lastname}</h1>
                
            </div>
        );
    }
}

export default Dashboard;