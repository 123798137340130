import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
    Form,
    Button,
    Table,
    Modal,
  } from "react-bootstrap";
import TokenVerify,{ FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import "./resumeupload.css";


export default class BundaleRequest extends Component {
    constructor(props)
    {
        super(props)

        this.state = {
            loader:'hidden',
            show:this.props.modalShow,
            
        }

        this.requestBundale = this.requestBundale.bind(this);
    }
    
    componentDidMount(){
      


    }

   
    requestBundale(data) {
      let sta =this
      sta.setState({ loader: "" }); ///loader on

            var formdata = new FormData();
            formdata.append("email", this.props.userdata.email);
            formdata.append("pagesource", this.props.fromSite.site_short_name);
          
            
              
              
      console.log("......request2", formdata);

      let request = {
        end_point: "/bundle-newsletter",
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      POST_API(request)
        .then((resp) => {
          
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            this.props.bundalebuyAloowCheck()
            this.props.switchBundaleRequestModal()
          }
           
                }).catch((ee)=>{

                  console.log('/resumeUpload error',ee)
                 


                });

    }
  render() {
    
    return (
       <div id="resumeUpload">
        <Modal show={this.props.modalShow}
          backdrop="static"
          className='modal_upload'
          keyboard={false}
          onHide={this.props.switchBundaleRequestModal}
          id="modal"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>All-in-one Package Request</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />
            If you want All-in-one Package request to admin 
          
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.props.switchBundaleRequestModal} className="btn-main tabformbutton mr-2">Cancel</Button>
            <Button onClick={this.requestBundale} variant="primary" type="submit" className="btn-main tabformbutton">Request</Button>

          </Modal.Footer> */}
          <Modal.Body style={{ minHeight: "250px" }}>
          <div className="upload_file_area text-center">
          <div className="upload_info">
                  <h4>All-in-one <br/> Package Request</h4>
                  <p className="mb-0">If you want All-in-one Package <br/> request to admin </p>
                </div>
                <div className="upload_action row">
                  <div className="col pr-2">
                  <Button variant="primary" onClick={this.props.switchBundaleRequestModal} className="btn btn-danger tabformbutton">Cancel</Button>
                  </div>
                  <div className="col pl-2">
                  <Button onClick={this.requestBundale} variant="primary" type="submit" className="btn btn-primary tabformbutton">Request</Button>
                  </div>
          </div>
          </div>
          </Modal.Body>

        </Modal>
      </div>
    )
  }
}
