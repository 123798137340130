import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip, Dropdown } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../../Helpers/Helpers";

import Leftpanel from '../Section/LeftPanel';
import DataTable from "react-data-table-component";
import {BASE_URL} from '../../Config';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MemberDashboardViewModal from '../Section/MemberDashboardViewModal'
import MemberBankViewModal from '../Section/MemberBankViewModal'
import moment from 'moment';
import Threedots_img from '../../images/dotsss.png'
import { Link } from '@mui/material';


class UserList extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                       
                        FromData: {},
                        FromDataError :
                                        {},
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/members',
                        member_id:0,
                        memberDashModal:false,
                        memberbankhModal:false,
                        help:''


                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        

    }

    handleClose(member_id=0)
    {
     
      
      if(this.state.memberDashModal)
      {
         this.setState({memberDashModal: false})
      }
      else{
        this.setState({member_id: member_id,memberDashModal: true})
       
      }
    }

    handleCloseBank(member_id=0)
    {
     
      
      if(this.state.memberbankhModal)
      {
         this.setState({memberbankhModal: false})
      }
      else{
        this.setState({member_id: member_id,memberbankhModal: true})
       
      }
    }

    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {

            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
};

 columns = [
  {
    name: "SL. No.",
    selector: (row, index) =>  index + 1,
    sortable: true,
    width: "100px",
    wrap:true,
  },
    {
      name: "Member Type",
      selector: row => row.roleID == 11 ? <span className="badge badge-primary">Employee</span> : <span className="badge badge-info">Manager</span>,
      // sortable: true,
    },
    {
        name: "Name",
        selector: row => row.firstname+' '+row.lastname ,
        width:'150px',
        sortable: true,
        wrap:true,
      },
      {
        name: "Email",
        // selector: "email",
        selector: (row) =>{return <a href={`mailto:${row.email}`}>{row.email}</a>},
        width:'250px',
        sortable: true,
        wrap:true,
      },
      // {
      //   name: "Max User Share",
      //   selector: "maxSponsership",
      //   width:'115px',
      //   sortable: true,
      //   wrap:true,
      // },
      {
        name: "Status",
        // selector: "created_at",
        selector: row => row.is_activated ===1  ? "Inactive" : "Active",
        width:'150px',
        sortable: false,
        wrap:false,
       
      },
    {
      name: "Created at",
      // selector: "created_at",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width:'150px',
      sortable: true,
      wrap:true,
     
    },

    
  
      {
        name: "Actions",
        cell: row =>  {
                    return(
                            //     <div><a href={'#/update-members-affiliate/'+row.userID}><Button variant="primary mr-1">Edit</Button></a>
                            // <Button className="mr-1" variant={row.is_activated==0? 'danger' : 'info'} onClick={()=>{if(window.confirm(row.is_activated==0? 'Inactive  the Member?' : 'Active the Member?')){this.handaleDelete(row.userID)};}}>{row.is_activated==0? 'Activate' : 'Deactivate'}</Button>
                            // <Button className="mr-1" variant="primary mr-1" onClick={()=>this.handleClose(row.userID)}>View</Button>
                            // <Button className="p-1" variant="primary mr-1"  onClick={()=>this.handleCloseBank(row.userID)}>View Bank</Button>
                            // </div>


                            <div className="three_dotss">
                          <Dropdown className="drop_partts">
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            <img src={Threedots_img} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                            <Link onClick={()=>window.location.href='#/update-members-affiliate/'+row.userID} to={'#/update-members-affiliate/'+row.userID}><Button variant="primary mr-1">Edit</Button></Link>
                            </Dropdown.Item>
                            <Dropdown.Item >
                            <Button className="mr-1" variant={row.is_activated==0? 'danger' : 'info'} onClick={()=>{if(window.confirm(row.is_activated===1? 'Activate the member?' : 'De-activate the member?')){this.handaleDelete(row.userID)};}}>{row.is_activated===1? 'Activate' : 'Deactivate'}</Button>
                            </Dropdown.Item>
                            <Dropdown.Item >
                            <Button className="mr-1" variant="primary mr-1" onClick={()=>this.handleClose(row.userID)}>View</Button>
                            </Dropdown.Item>
                            <Dropdown.Item >
                            <Button className="p-1" variant="primary mr-1"  onClick={()=>this.handleCloseBank(row.userID)}>View Bank</Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        )
                        } 
                       ,
        // sortable: true,
        width: '350px'
      },
  ];

    

    handaleChange(data)
    {
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

       

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;

        // console.log(this.state)
        // return 0;


        
     }

     handaleDelete(id)
     {
        let sta = this

        var formdata = new FormData();
        formdata.append("userID", id);

        let request = {
            end_point : '/deactive-employee',
            formdata:formdata,
            token: localStorage.getItem("gs_token")
            };

        


        POST_API(request).then((resp)=>{

            if(resp.status=='success')
            {
                
                
              if(resp.data.is_activated!=0){
                sta.setState({Msg:'User inactivated successfully!',Msgtype:'succcess'})
              }else{
              sta.setState({Msg:'User Activated Successfully!',Msgtype:'succcess'})
              }

                setTimeout(() => {
                    window.location.reload()
                    
                }, 3000);

                
                
                
                
            }
            else{
                //console.log(resp.message.password.join())
                sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
            }

        }).catch(()=>{

        })
        
     }


    componentDidMount()
    {
         window.scrollTo(0, 0)
        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
        var formdata = new FormData();
        formdata.append("employerID",JSON.parse(localStorage.getItem("gs_user")).userID);


                let request = {
                    end_point : '/employee-List',
                    formdata:formdata,
                    token: localStorage.getItem("gs_token")

                };

                sta.setState({loader:'visible'})///loader on


                    POST_API(request).then(resp=>{

                             sta.setState({loader:'hidden'})///loader off


                                    if(resp.status == 'Success')
                                    {
                                            
                                            FromData = resp.data

                                            sta.setState({FromData})
                                            
                                       }
                                        else{
                                            
                                            
                                                
                                        }
                            }).catch((ee)=>{
                              sta.setState({loader:'hidden'})///loader off

                                console.log('/code list ERR',ee)
                            });

                            //help api
        let requestHelp = {
          end_point : '/help-details?key=affiliate_member_list',
          
          token: localStorage.getItem("gs_token")
        }

        sta.setState({loader:'visible'})///loader on

          GET_API(requestHelp).then((resph)=>{
            sta.setState({loader:'hidden'})///loader off

            if(resph.status == 'success')
            {
              let help = resph.data.value
              sta.setState({help})
            }

          }).catch(()=>{
            sta.setState({loader:'hidden'})///loader off


          })

       
     

      
    }

 
    render() {
        let  FromData = this.state.FromData
        console.log("ajay",FromData)
        return (
            <div>
                 {FromData!=null? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">
            {(this.state.member_id != 0)? (<>
                <MemberDashboardViewModal {...this.props} member_id={this.state.member_id} show={this.state.memberDashModal} close={()=>this.handleClose()}/>
                </>) : (<></>) }  

                {(this.state.member_id != 0)? (<>
                <MemberBankViewModal {...this.props} member_id={this.state.member_id} show={this.state.memberbankhModal} close={()=>this.handleCloseBank()}/>
                </>) : (<></>) }  

                

            <h3>Member List </h3>
            <p
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></p>
          
            {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                
                
                <a href="#/create-members-affiliate" className='btn btn-primary ghost_button float-right'>Create</a>

                <div className="table_search" style={{float: "right",margin:'10px'}}>   
                Search : <input type='text' placeholder='Search' name="search_val"  onChange={this.searchChange}  /></div>

              <DataTable
                    title=""
                    columns={this.columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    />
                        
               
              </div>
                              
                              
                          
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}

export default UserList;