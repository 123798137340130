import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav, Form, Button, Modal } from "react-bootstrap";
import { Route, Redirect, Link } from "react-router-dom";

import { withRouter } from "react-router";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import logo10 from "../images/getback_work.png"; // GBW
import EOR from "../images/EOR-Main-logo.png"; //EOR
import GWIAF from "../images/GWI_Affiliates_LOGO_dark.png"; //GWIAF

import arrower from "../images/arrower.png";

import validation from "../Validate/validator";
import 'react-phone-number-input/style.css'
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { BASE_URL,CaptchaKey } from '../Config';
import recurator from '../images/recruiters.png';
import TokenVerify from "../Helpers/Helpers";


class affiliateSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Msg: "",
      MsgType: "",
      userDetails: {},
      FromData: {
        contact_no: "",
        firstname: "",
        lastname: "",
        email: "",
        roleID: "",
        password: "",
        recapcha: "",
        // otptext: "",
        confirm_email: "",
        confirm_password: ""
      },
      FromData1: {
        otptext: "",
      },
      FromDataError: {
        contact_no: "false",
        firstname: "false",
        lastname: "false",
        email: "false",
        roleID: "false",
        password: "false",
        recapcha: "false",
        //otptext: "false",
        confirm_email: "false",
        confirm_password: "false"
      },
      FromDataError1: {
        otptext: "false",
      },
      loader: "hidden",
      liveUrl: "",
      shortUrl: "",
      pagesource: "",
      servicetype: "",
      siteDetails: {},
      apiStatus: "",
      VerifyapiStatus: "",
      inputType: 'password',
      inputTypeConfirm: 'password',
      showOtpModal: false,
      isPasswordValid: false,
      isMailValid: false
    };

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleChangeOtp = this.handaleChangeOtp.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.toggleEye = this.toggleEye.bind(this);
    this.toggleEyeConfirm = this.toggleEyeConfirm.bind(this);

  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;


    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";

          sta.setState({ FromDataError });
        }

        valid = false;
      }
    }

    return valid;
  }
  validateForm1(errors) {
    let valid = true;
    let sta = this;

    let FromDataError1 = this.state.FromDataError1;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {

          FromDataError1[key] = "This field is required";
          sta.setState({ FromDataError1 });
        }

        valid = false;
      }
    }

    return valid;
  }
  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;

    console.log("handle change", data.target.name);

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (name == "file") {
      let file = data.target.files[0];
      value = file;

      this.toBase64(data.target.files[0]).then((resp) => {
        FromData[name] = resp.split(",")[1];
        FromData.filename = file.name;
        FromData.format = file.type;
      });
    } else if (name == "password" || name == "confirm_password") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.password == FromData.confirm_password) {
        FromDataError['confirm_password'] = "";
        this.setState({ isPasswordValid: true, FromDataError });
      } else {
        if (FromData.confirm_password.length < 6) {
          // FromDataError['confirm_password'] = "password should be 6 charecter";
          FromDataError['confirm_password'] = "password should be of at least 6 characters";
        } else {
          FromDataError['confirm_password'] = "Confirmed Password should be same as password";
        }
        this.setState({ isPasswordValid: false, FromDataError });

      }

    } else if (name == "email" || name == "confirm_email") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.email == FromData.confirm_email) {
        FromDataError['confirm_email'] = "";
        this.setState({ isMailValid: true, FromDataError });
      } else {

        FromDataError['confirm_email'] = "Confirmed email doesn't match";

        this.setState({ isMailValid: false, FromDataError });

      }

    } else {
      value = data.target.value;
      FromData[name] = value;
    }
    this.setState({ FromData }, () => {
      console.log(FromData);
    });
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      //console.log(error)
      FromDataError[name] = error;
      this.setState({ FromDataError });

      setTimeout(() => {

      }, 100);
    });
  }

  handaleChangeOtp(data) {
    let sta = this;
    let FromDataError1 = this.state.FromDataError1;

    let name = data.target.name;
    let value = "";
    let FromData1 = this.state.FromData1;

    if (name == "file") {
      let file = data.target.files[0];
      value = file;

      this.toBase64(data.target.files[0]).then((resp) => {
        FromData1[name] = resp.split(",")[1];
        FromData1.filename = file.name;
        FromData1.format = file.type;
      });
    } else {
      value = data.target.value;
      FromData1[name] = value;
    }
    this.setState({ FromData1 }, () => {

    });
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      //console.log(error)
      FromDataError1[name] = error;
      this.setState({ FromDataError1 });

      setTimeout(() => {

      }, 100);
    });
  }

  handlePhoneChange = (value) => {
    let { FromDataError, FromData } = this.state;
    if (value !== undefined) {
      FromData.contact_no = value;
      this.setState({ FromData });

      //let number = parsePhoneNumber(value);
      // if (number) {
      //   let country_code = "+" + number.countryCallingCode;
      //   let mobile = number.nationalNumber;
      //   this.setState({ country_code, mobile });
      // }
      if (isValidPhoneNumber(value)) {
        FromDataError.contact_no = "";

      } else {
        FromDataError.contact_no = "Enter Valid Mobile Number";

      }
      this.setState({ FromDataError });
    } else {
      FromDataError.contact_no = "Enter Valid Mobile Number";

      this.setState({ FromDataError });
    }
  };


  async handaleSubmit(e) {
    e.preventDefault();
    if (!["GWIAF"].includes(this.state.pagesource)) {
      let FromDataError = this.state.FromDataError;
      FromDataError.roleID = "";
      this.setState(FromDataError);
    }
    this.sendOTP();
  }


  async finalhandaleSubmit() {

    if (!["GWIAF"].includes(this.state.pagesource)) {
      let FromDataError = this.state.FromDataError;
      FromDataError.roleID = "";
      this.setState(FromDataError);
    }

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("email", FromData.email);
      formdata.append("contact_no", FromData.contact_no);
      formdata.append("roleID", FromData.roleID || "");
      formdata.append("password", FromData.password);
      formdata.append("site_short_name", this.state.pagesource);
      formdata.append("app_url", BASE_URL);

      // console.log(formdata)
      let request = {
        end_point: "/create-affiliate",
        formdata: formdata,
      };

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            // let userDetails = resp.data;
            // sta.setState({ userDetails });
            // sta.setState({ Msg: "Thank you", MsgType: "success" });
            // //this.props.history.push("/login");
            // this.props.history.push({
            //   pathname: '/login',
            //   state: { Msg: 'Congratulations, you have registered successfully. Please check your mail for more details.', Msgtype: 'success' }
            // });

            //////////////////////// NEW AUTO LOGIN ////////////////////


            let email = resp.data.email;
            let password = resp.code;

            //Auto login code
            var loginformdata = new FormData();
            loginformdata.append("email", email);
            loginformdata.append("password", password);

            let request2 = {
              end_point: '/login',
              formdata: loginformdata

            };

            POST_API(request2).then(resp => {

              localStorage.setItem('gs_token', resp.user.token)
              localStorage.setItem('roleID', resp.user.roleID)
              localStorage.setItem('gs_user', JSON.stringify(resp.user))

              if (resp.user.resume_score != null) {
                localStorage.setItem('resumeScore', resp.user.resume_score)
              }

              if (localStorage.getItem("gs_token") != null) {
                localStorage.setItem('profile_image', resp.image)

                if (resp.user.roleID == 8 || resp.user.roleID == 9 || resp.user.roleID == 10) {
                  localStorage.setItem('dashboardLink', '#/affiliate-dash')
                  TokenVerify()
                } else if (resp.user.roleID == 11 || resp.user.roleID == 12) {
                  localStorage.setItem('dashboardLink', '#/affiliate-member-dash')
                  TokenVerify()
                }



                localStorage.setItem('gs_auth_code', resp.user.auth_code)
                localStorage.setItem('roleID', resp.user.roleID)
                localStorage.setItem('get_category', resp.user.job_category)
                localStorage.setItem('profile_image', resp.image)
                if (resp.user.roleID == 8 || resp.user.roleID == 9 || resp.user.roleID == 10) {
                  this.props.history.push('/affiliate-dash')
                } else if (resp.user.roleID == 11 || resp.user.roleID == 12) {
                  this.props.history.push('/affiliate-member-dash')
                }




              }

            }).catch((ee) => {

              console.log('/AutologinERR', ee)
              this.props.history.push({
                pathname: '/login',
                state: { Msg: 'Congratulations, you have registered successfully. Please check your mail for more details.', Msgtype: 'success' }
              });


            });






            //////////////////// END AUTO LOGIN ///////////////////////////









            // let count = 5;
            // setInterval(()=>{
            //   count --;
            //   document.getElementById("countDown").innerHTML = count
            //   if(count == 0){
            //     this.props.history.push("/login")
            //   }
            // })
            // setTimeout(this.props.history.push("/login"), 10000);

            //   function startTimer(duration, display) {
            //     var timer = duration, minutes, seconds;
            //     var end =setInterval(function () {
            //         minutes = parseInt(timer / 60, 10)
            //         seconds = parseInt(timer % 60, 10);

            //         minutes = minutes < 10 ? "0" + minutes : minutes;
            //         seconds = seconds < 10 ? "0" + seconds : seconds;

            //         display.textContent = minutes + ":" + seconds;

            //         if (--timer < 0) {
            //             //window.location = "http://www.example.com";
            //             clearInterval(end);
            //         }
            //     }, 1000);
            // }
          } else {
            sta.setState({ Msg: resp.message.email_id.join(), MsgType: "danger" });
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
      this.closeMsg();
    }
  }


  componentDidMount() {
    try {
      if (localStorage.getItem("gs_user") != null) {
        let user = JSON.parse(localStorage.getItem("gs_user"));

        if (user.roleID == 4) {
          this.props.history.push("/jobseeker-dash");
        } else {
          this.props.history.push("/sponser-dash");
        }
      }
    } catch (error) { }

    let sta = this;
    const { match, location, history } = this.props;
    this.setState({ liveUrl: location.search });

    console.log(location.search);
    const queryParams = new URLSearchParams(location.search);
    this.setState({
      pagesource: queryParams.get("pagesource")
        ? queryParams.get("pagesource")
        : "",
    });
    this.setState({
      servicetype: queryParams.get("servicetype")
        ? queryParams.get("servicetype")
        : "",
    });
    console.log(queryParams.get("pagesource"));
    var formdata = new FormData();
    formdata.append("site_short_name", queryParams.get("pagesource"));
    let request2 = {
      end_point: "/check-affiliate-site",
      formdata: formdata,
    };

    POST_API(request2)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let siteDetails = resp.data;
          sta.setState({ siteDetails });
        } else if (resp.status === "failed") {
          history.push("/login");
          // return (<Redirect to="/login" />)
        } else {
          sta.setState({ Msg: "Some thing went wrong", MsgType: "danger" });
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off
        sta.setState({
          Msg: "Something Went Wrong, Please try again later.",
          MsgType: "danger",
        });

        console.log("/js registration ERR", ee);
      });
  }

  pagesourceWiseImg() {
    switch (this.state.pagesource) {
      case "EOR":
        return EOR;
        break;
      case "GWIAF":
        return GWIAF;
        break;
      default:
        break;
    }
  }
  capchaChange = (value) => {
    console.log("Captcha value:", value);
    let { FromData, FromDataError } = this.state;
    FromData.recapcha = value;
    FromDataError.recapcha = "";
    this.setState({ FromData, FromDataError }, () => {
      this.validateForm(FromDataError);
    });
  };
  sendOTP = () => {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.pagesource || "");

      let request2 = {
        end_point: "/email-otp-send",
        formdata: formdata,
      };

      POST_API(request2)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let respData = resp.status;

            sta.setState({ apiStatus: respData });
            sta.setState({ Msg: "One Time Passcode sent successfully. Please check your email", MsgType: "success" });
            this.setState({ showOtpModal: true })
          } else {
            let respData = resp.status;
            sta.setState({ apiStatus: respData });
            sta.setState({ Msg: resp.message.email_id.join(), MsgType: "danger" });

            this.closeMsg();
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!", MsgType: "danger" });
      this.closeMsg()
    }
  };

  verifyOTP = () => {

    let sta = this;
    let FromDataError1 = this.state.FromDataError1;
    let FromData = this.state.FromData;
    let FromData1 = this.state.FromData1;
    if (!["GWIAF"].includes(this.state.pagesource)) {
      let FromDataError = this.state.FromDataError;
      FromDataError.roleID = "";

      this.setState({ FromDataError }, () => {

      });
    }

    if (this.validateForm1(FromDataError1)) {
      sta.setState({ loader: "visible" }); ///loader on
      var formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.pagesource);
      formdata.append("otp", FromData1.otptext);

      let request2 = {
        end_point: "/email-otp-verify",
        formdata: formdata,
      };

      POST_API(request2)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let respData = resp.status;
            sta.setState({ VerifyapiStatus: respData });
            sta.setState({ Msg: "One Time Passcode Verified", MsgType: "success" });
            this.finalhandaleSubmit();
          } else {
            let respData = resp.status;
            sta.setState({ VerifyapiStatus: respData });
            sta.setState({ Msg: "One Time Passcode not valid!", MsgType: "danger" });
            this.closeMsg();
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
          this.closeMsg();
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!", MsgType: "danger" });
      this.closeMsg();
    }
  };
  // password hide and show
  toggleEye() {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      this.setState({ inputType: 'text' });

    }
    else {
      this.setState({ inputType: 'password' });

    }
  }
  toggleEyeConfirm() {
    var temp = document.getElementById("confirm_password");
    if (temp.type === "password") {
      this.setState({ inputTypeConfirm: 'text' });

    }
    else {
      this.setState({ inputTypeConfirm: 'password' });

    }
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      A one time code will be sent to your email. Please use the code to validate you email ID.
    </Tooltip>
  );
  closeMsg = (duration = 2000) => {
    setTimeout(() => {
      this.setState({ Msg: "", MsgType: "", step1Msg: "", step1MsgType: "" });
    }, duration);
  };

  onshowModal() {
    if (this.state.showOtpModal) {
      this.setState({ showOtpModal: false })

    } else {
      this.setState({ showOtpModal: true })
    }
  }

  render() {
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
    //console.log("check-affiliate-site", this.state.siteDetails);
    let { FromData } = this.state;

    return (
      <div className="Becomesponsorfromarea " style={{ "padding-top": "20px", "padding-bottom": "20px" }}>
        <Container>

          <span className="d-flex justify-content-center container-max align-items-center fromto">
            <img src={this.pagesourceWiseImg()} className="fix_logo_align" />
            <img src={arrower} className="arrow_fix mx-4" />
            <img src={logo10} className="fix_logo_align" />
          </span>

          {/* <div style={{ margin: "50px 0px 39px -10px" }}> */}
          <h2 style={{ margin: "50px 0px 2px -2px" }}>{this.state.pagesource == "EOR" ? "Recruiter Sign Up" : "Affiliate Sign Up"}</h2>
          <p>Please fill in your details</p>
          {/* </div> */}
          <Form onSubmit={this.handaleSubmit} className="tabform">
            <Row className="sponsorfrom p-4">
              <Col lg={7} >
                {this.state.Msg !== "" ? (
                  <>
                    <FlashMess
                      m={this.state.Msg}
                      mtype={this.state.MsgType ? this.state.MsgType : "success"}
                    />
                  </>
                ) : (
                  <></>
                )}
                <Loader loadmsg="please wait" visibility={this.state.loader} />
                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <Form.Label>
                      First Name
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      value={FromData.firstname}
                      onChange={this.handaleChange}
                      placeholder="Enter first name"
                      validaterule={["required"]}
                      validatemsg={["This field is required"]}
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.firstname == "false"
                        ? ""
                        : this.state.FromDataError.firstname}
                    </p>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <Form.Label>
                      Last Name
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      value={FromData.lastname}
                      onChange={this.handaleChange}
                      placeholder="Enter last name"
                      validaterule={["required"]}
                      validatemsg={["This field is required"]}
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.lastname == "false"
                        ? ""
                        : this.state.FromDataError.lastname}
                    </p>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    controlId="formBasicEmail"
                    as={Col}
                  >
                    {/* <div className="otp_field"> */}
                    <Form.Label>
                      Email
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={FromData.email}
                      onChange={this.handaleChange}
                      placeholder="Enter  email"
                      validaterule={["required", "isEmail"]}
                      validatemsg={["This field is required", "Invalid Email"]}
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.email == "false"
                        ? ""
                        : this.state.FromDataError.email}
                    </p>
                    {/* </div> */}

                    {/* <Button type="button" 
                    variant="otp_btn"
                    onClick={this.sendOTP} className="otp_btn">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip}>
                      <i className="fa-solid fa-circle-question"></i>
                  </OverlayTrigger>  Confirm Email</Button> */}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <Form.Label>
                      Confirm Email
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <div className="d-flex w-100 verify_field">
                      <Form.Control
                        type="email"
                        name="confirm_email"
                        value={FromData.confirm_email}
                        onChange={this.handaleChange}
                        placeholder="Enter confirm email"
                        validaterule={["required", "isEmail", 'same|' + FromData.email]}
                        validatemsg={["This field is required", "Invalid Email", "Confirmed e-mail don't match"]}
                      />
                      {this.state.isMailValid &&
                        <div className="verify_icon d-flex align-items-center">
                          <i className="fa fa-check-circle" aria-hidden="true"></i>
                        </div>

                      }
                    </div>
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.confirm_email == "false"
                        ? ""
                        : this.state.FromDataError.confirm_email}
                    </p>

                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <Form.Label>
                      Phone
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="US"
                      value={FromData.contact_no}
                      onChange={this.handlePhoneChange}
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.contact_no == "false"
                        ? ""
                        : this.state.FromDataError.contact_no}
                    </p>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {["GWIAF"].includes(this.state.pagesource) && (
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Affiliate Type</Form.Label>
                      <span className="text-danger"> *</span>
                      <select
                        className="form-control"
                        onChange={this.handaleChange}
                        name="roleID"
                      >
                        <option value="">Select</option>
                        <option value="corporate">Corporate</option>
                        <option value="associate">Associate</option>
                      </select>
                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.roleID == "false"
                          ? ""
                          : this.state.FromDataError.roleID}
                      </p>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <Form.Label>
                      Password
                      <span className="text-danger"> *</span>
                    </Form.Label>
                    <Form.Control
                      type={this.state.inputType}
                      id='password'
                      name="password"
                      onChange={this.handaleChange}
                      value={FromData.password}
                      placeholder="Enter Password"
                      validaterule={["required", "password"]}
                      validatemsg={[
                        "This field is required",
                        "password should be of at least 6 characters",
                      ]}
                    />
                    <i style={{ float: 'right', marginTop: '-30px', marginRight: '8px', position: 'relative', zIndex: 1, cursor: 'pointer' }} onClick={this.toggleEye}>{this.state.inputType == 'text' ? eyeSlash : eye}</i>

                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.password == "false"
                        ? ""
                        : this.state.FromDataError.password}
                    </p>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <Form.Label>Confirm Password</Form.Label>

                    <div className="d-flex w-100 verify_field">

                      <Form.Control
                        id='confirm_password'
                        type={this.state.inputTypeConfirm}
                        name="confirm_password"
                        onChange={this.handaleChange}
                        placeholder="Confirm Password"
                        value={FromData.confirm_password}
                        validaterule={[
                          "required",
                          "password",
                          "same|" + FromData.password,
                        ]}
                        validatemsg={[
                          "This field is required",
                          "password should be of at least 6 characters",
                          "Confirmed Password should be same as password",
                        ]}
                      />

                      {this.state.isPasswordValid &&
                        <div className="verify_icon d-flex align-items-center">
                          <i className="fa fa-check-circle" aria-hidden="true"></i>
                        </div>

                      }
                    </div>
                    {/* <i  style={{  float: 'right', marginTop: '-30px', marginRight:'8px', position: 'relative', zIndex: 1, cursor:'pointer'}} onClick={this.toggleEyeConfirm}>{this.state.inputTypeConfirm=='text'?eyeSlash:eye}</i>  */}

                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.confirm_password == "false"
                        ? ""
                        : this.state.FromDataError.confirm_password}
                    </p>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formBasicEmail" as={Col}>
                    <ReCAPTCHA
                      sitekey={CaptchaKey}
                      onChange={this.capchaChange}
                    />

                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.recapcha == "false"
                        ? ""
                        : this.state.FromDataError.recapcha}
                    </p>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="justify-content-end mt-5 form-row">
                  <Form.Group as={Col} md={3}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-main tabformbutton"
                      disabled={this.state.loader != "hidden" ? true : false}
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col lg={5}>
                <img src={recurator} className="img-fluid" />
              </Col>
            </Row>
          </Form>

          <Modal show={this.state.showOtpModal}
            backdrop="static"
            keyboard={false}
            onHide={() => { this.onshowModal() }}
            id="modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>OTP Verification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Loader loadmsg="please wait" visibility={this.state.loader} />

              <Form
                className="tabform"

              >
                <Row className="m-0 mt-4 mb-4">
                  <Col md={12} className="pr-5 ">

                    {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                    <Form.Group
                      controlId="formBasicEmail"
                      as={Col}
                      className="field_box"
                    >
                      <div className="otp_field">
                        <Form.Label>
                          One Time Passcode
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="otptext"
                          value={this.state.FromData1.otptext}
                          onChange={this.handaleChangeOtp}
                          placeholder="Enter one time passcode"
                          validaterule={["required"]}
                          validatemsg={["This field is required", "Invalid one time passcode"]}
                        />
                        <p style={{ color: "red" }}>
                          {this.state.FromDataError1.otptext == "false"
                            ? ""
                            : this.state.FromDataError1.otptext}
                        </p>
                      </div>

                    </Form.Group>

                  </Col>

                  <Col md={3} className="">
                    <Button

                      variant="primary"
                      type="button"
                      className="btn btn-primary btn-md tabformbutton"
                      onClick={this.verifyOTP}
                    >
                      Submit
                    </Button>
                  </Col>

                </Row>
              </Form>
            </Modal.Body>

          </Modal>
        </Container>
      </div>
    );
  }
}
export default affiliateSignup;
