import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import { PayPal } from '../../Config';


import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";

export default class Payment extends React.Component {
  state = {
    key: "strip",
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    amount: '',
    Msg: '',
    MsgType: '',
    loader: 'hidden',
    show: '',
    save_card: '',
    FromData: {},
    card_details: [],
    card_id_attr: '',
    button_text: 'Show My Cards',
    card_exits: false,
    FromDataError:
    {
      cvc: 'false',
      expiry: 'false',
      name: "false",
      number: "false",
      amount: '',
    },

  };

  tabsctive(j) {
    this.setState({ key: j });



  }

  onValueChange(event) {
    console.log("event", event.target.value);
    this.setState({
      card_id_attr: event.target.value,
    });

    document.getElementById("card_id").value = event.target.value;
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };


  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let FromDataError = this.state.FromDataError;

    if (name == 'save_card' && e.target.checked) {
      this.setState({ [name]: 1 });

    } else {

      var valid_obj = {
        value: value,
        rules: e.target.getAttribute("validaterule"),
        message: e.target.getAttribute("validatemsg")
      }



      validation(valid_obj).then((error) => {
        FromDataError[name] = error
        this.setState({ FromDataError })
      })

      this.setState({ [name]: value }, () => {
        //console.log(this.state)
      });

    }



  };

  clickSubmit(id) {
    if (id == 'strip') {
      this.handaleSubmit()
    }
    else {
      document.getElementById(id).submit();
    }




  }

  savedCardDetails = () => {
    let requestCard = {
      end_point: '/get-saved-cards',
      token: localStorage.getItem("gs_token")
    };
    GET_API(requestCard).then((resp) => {
      if (resp.data.length > 0) {
        this.setState({
          card_details: resp.data,
          card_exits: true,
        });
      }
      console.log('savedcard', this.state.card_details);
    }).catch(() => {
      console.log('savedcard error');
    })
  }



  handaleSubmit = (e) => {
    //e.preventDefault();
    let sta = this;
    let { FromData, FromDataError } = this.state


    //User details and amount 
    if (this.state.amount == '' && this.props.price == 0) {
      FromDataError.amount = 'false'
      this.setState({ FromDataError }, () => {
        //console.log(FromData)
      })
    }


    console.log(FromData)

    if (this.state.card_exits == false) {

      if (this.validateForm(FromDataError)) {
        sta.setState({ loader: 'visible' })///loader on

        var formdata = new FormData();
        formdata.append("card_name", this.state.name);
        formdata.append("card_number", this.state.number);
        formdata.append("card_cvc", this.state.cvc);
        formdata.append("card_exp_month", this.state.expiry.substring(0, 2));
        formdata.append("card_exp_year", this.state.expiry.slice(-2));
        formdata.append("description", "");
        if (this.state.save_card == 1) {
          formdata.append("save_card", 1);
        }
        if (this.props.price != 0) {

          formdata.append("amount", this.props.price);


        }
        else {
          formdata.append("amount", this.state.amount);

        }









        let request = {
          end_point: '/charge-payment',
          formdata: formdata,
          token: localStorage.getItem("gs_token")

        };

        console.log('......request', formdata)




        POST_API(request).then(resp => {

          sta.setState({ loader: 'hidden' })///loader off


          if (resp.status == 'success') {
            if (this.props.history.location.pathname == '/payment') {
              localStorage.setItem('msg_for_payment_history', resp.message);
              sta.setState({ Msg: 'Your Payment is successfully placed', MsgType: 'success' })
            } else {
              sta.setState({ Msg: 'Your Payment is successfully placed', MsgType: 'success' })
            }

            if (this.props.history.location.pathname == '/payment') {
              setTimeout(() => {
                this.setState({ Msg: '' })
                sta.props.history.push({
                  pathname: '/donate-history',
                  state: { Msg: '', Msgtype: '' }

                })
              }, 3000);
            } else {
              setTimeout(() => {
                this.setState({ Msg: '' })
                sta.props.history.push({
                  pathname: '/login',
                  state: { Msg: '', Msgtype: '' }

                })
              }, 3000);
            }

            // setTimeout(() => {
            //   this.setState({ Msg: '' })
            //   sta.props.history.push({
            //     pathname: '/login',
            //     state: { Msg: '', Msgtype: '' }

            //   })
            // }, 3000);





          }
          else {


            sta.setState({ Msg: 'Payment Failed', MsgType: 'danger' })




          }


        }).catch((ee) => {
          sta.setState({ loader: 'hidden' })///loader off


          sta.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })
          console.log('/paymentERR', ee)


        });
      }
      else {
        sta.setState({ Msg: 'Enter Valid Payment Details' })
        sta.setState({ MsgType: 'danger' })
      }
    } else {
      if (this.state.card_id_attr != '') {


        if ((this.state.amount == '' || this.state.amount == 0) && this.props.price == 0) {
          FromDataError.amount = "Amount is required"
          this.setState({ FromDataError })
          return 0;
        }
        sta.setState({ loader: 'visible' })///loader on

        var formSavedata = new FormData();
        if (this.props.price != 0) {

          formSavedata.append("amount", this.props.price);


        }
        else {
          formSavedata.append("amount", this.state.amount);

        }
        formSavedata.append("card_id", this.state.card_id_attr);
        formSavedata.append("description", "");

        let requestData = {
          end_point: '/charge-payment',
          formdata: formSavedata,
          token: localStorage.getItem("gs_token")

        };

        console.log('......request', formSavedata)



        POST_API(requestData).then(resp => {

          sta.setState({ loader: 'hidden' })///loader off


          if (resp.status == 'success') {
            if (this.props.history.location.pathname == '/payment') {
              sta.setState({ Msg: resp.message, MsgType: 'success' })
            } else {
              sta.setState({ Msg: 'Your Payment is successfully placed', MsgType: 'success' })
            }
            if (this.props.location.pathname == '/payment') {
              setTimeout(() => {
                this.setState({ Msg: '' })
                sta.props.history.push({
                  pathname: '/login',
                  state: { Msg: '', Msgtype: '' }

                })
              }, 3000);
            } else {
              setTimeout(() => {
                this.setState({ Msg: '' })
                sta.props.history.push({
                  pathname: '/donate-history',
                  state: { Msg: '', Msgtype: '' }

                })
              }, 3000);
            }





          }
          else {


            sta.setState({ Msg: 'Payment Failed', MsgType: 'danger' })




          }


        }).catch((ee) => {
          sta.setState({ loader: 'hidden' })///loader off


          sta.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })
          console.log('/paymentERR', ee)


        });

      } else {
        sta.setState({ Msg: 'Enter Valid Payment Details' })
        sta.setState({ MsgType: 'danger' })
      }


    }

  }

  componentDidMount() {
    if (this.props.tab != '') {
      this.setState({ key: this.props.tab })
      this.setState({ Msg: 'Your Payment is successfully placed', MsgType: 'success' })

      setTimeout(() => {
        this.setState({ Msg: '' })

        this.props.history.push({
          pathname: '/sponser-dash',
          state: { Msg: 'Your total amount will be reflecte within 10 minutes', MsgType: 'success' }

        })
      }, 3000);
    }
    this.savedCardDetails();


    console.log(this.props.history.location.pathname);

  }

  handleClick = () => {
    if (this.state.card_exits) {
      this.setState({
        card_exits: false,
        card_id_attr: '',
        button_text: 'Show My Cards'
      });
    } else {
      this.setState({
        card_exits: true,
        card_id_attr: '',
        button_text: 'Add Card'
      });
    }
  }




  render() {


    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment<p style={{ fontSize: "10px", margin: "0" }}>We use stripe for payments</p></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />

            {/* <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="strip"
              transition={false}
              id="noanim-tab-example"
              activeKey={this.state.key}
              onSelect={(k) => this.tabsctive(k)}
            >

              <Nav
                variant="pills"
                defaultActiveKey="strip"
                className="navTab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="strip">
                    Stripe
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="paypal" target="_blank">
                    Paypal
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                      <Nav.Link eventKey="ach">
                       ACH
                      </Nav.Link>
                    </Nav.Item> 
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="strip"> */}
            <div className="tabpanelOneForm">
              <Button variant="primary" onClick={this.handleClick} className="btn-main mr-2">{this.state.card_exits ? 'Add Card' : 'Show My Cards'}</Button>
              <Row className="mt-4 mb-4" style={{ display: this.state.card_exits ? "none" : "block" }}>
                <Col md={12} className="mb-5">
                  <Cards
                    cvc={this.state.cvc}
                    expiry={this.state.expiry}
                    focused={this.state.focus}
                    name={this.state.name}
                    number={this.state.number}
                  />
                </Col>
                <Col md={12} className="">
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}


                  <form id="strip" onSubmit={this.handaleSubmit}>
                    <Form.Group controlId="formBasicCardNo" className="" >
                      <input
                        type="hidden"
                        name="card_id"
                        id="card_id"
                        value={this.state.card_id_attr}
                      />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name on Card"
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        validaterule={['required']}
                        validatemsg={['Name on Card is required']}
                      />
                      <p style={{ color: 'red' }}>{this.state.FromDataError.name == 'false' ? '' : this.state.FromDataError.name}</p>
                    </Form.Group>

                    <Row>
                      <Col md={6} className="">
                        <Form.Group controlId="formBasicCardNo">
                          <Form.Control
                            type="tel"
                            name="number"
                            placeholder="Card Number"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            maxLength="16"
                            validaterule={['required', 'max|16', 'min|14']}
                            validatemsg={['Card no required', 'Card number must be 14-16 character long', 'Card number must be 14-16 character long']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.number == 'false' ? '' : this.state.FromDataError.number}</p>


                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formBasicCardNo">
                          <Form.Control
                            type="text"
                            name="expiry"
                            placeholder="MMYY"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            maxLength="4"
                            validaterule={['required', 'min|4', 'expire', 'numeric']}
                            validatemsg={['Card expire required', 'Card Expiry field must contain 4 digit', 'Expiry month and year should be a future date.', 'Invalid Number']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.expiry == 'false' ? '' : this.state.FromDataError.expiry}</p>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formBasicCardNo">

                          <Form.Control
                            type="text"
                            name="cvc"
                            placeholder="CVC"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            maxLength="3"
                            validaterule={['required', 'min|3', 'max|3', 'numeric']}
                            validatemsg={['Card expire required', 'CVC field must contain 3 digit', 'CVC field must contain 3 digit', 'Invalid Number']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.cvc == 'false' ? '' : this.state.FromDataError.cvc}</p>
                        </Form.Group>
                      </Col>
                    </Row>








                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check onChange={this.handleInputChange} name="save_card" type="checkbox" label="Save This Card" />
                    </Form.Group>

                  </form>


                </Col>
              </Row>
              {(this.props.price == 0) ? (<>

                <Form.Group controlId="formBasicCardNo" className="mt-3">
                  <Form.Label>Sponsor Amount ($)</Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    validaterule={['required', 'numeric', 'priceValue|100', 'maxpriceValue|900000']}
                    validatemsg={['Amount required', 'Invalid Number', 'Minimum 100 $ need to sponsor', 'Amount is to long']}
                    // placeholder="Sponsor Amount"
                    placeholder="Please enter the amount you would like to sponsor"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                  <p style={{ color: 'red' }}>{this.state.FromDataError.amount == 'false' ? '' : this.state.FromDataError.amount}</p>

                </Form.Group>


              </>) : (<>

                <Form.Group controlId="formBasicCardNo" className="pr-0 mt-3 text-center" as={Col}>
                  {/* <Form.Label>Pay Amount (<strong>${this.props.price}</strong>)</Form.Label> */}
                  <Form.Label style={{ fontSize: '17px' }}>Amount Payable: (<strong>${this.props.price}</strong>)</Form.Label>

                </Form.Group>

              </>)}
              <Row className="mt-4 mb-4" style={{ display: this.state.card_exits ? "block" : "none" }}>
                <Col md={12} className="">
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Choose Card</th>
                        <th>Card Number</th>
                        <th>Card Name</th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.card_details.map((data, key) => {
                        return (
                          <tr>
                            <td>
                              <input
                                type="radio"
                                name="card_token"
                                value={data.card_token}
                                onChange={this.onValueChange.bind(this)} />
                            </td>
                            <td>**** **** **** {data.last_digit}</td>
                            <td>{data.card_name}</td>

                          </tr>
                        )
                      })}

                      {
                        this.state.card_details.length <= 0 && (
                          <tr>
                            <td Colspan="3" className="justify-content-center text-center">Card's Not Found</td>

                          </tr>
                        )
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
            {/* </Tab.Pane>

                <Tab.Pane eventKey="paypal">
                  <div className="tabpanelOneForm">
                    {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                    <div id="payment-box">




                      {(this.props.price == 0) ? (<>

                      </>) : (<>
                        <div className="txt-price mt-5 mb-5">Amount Payable: <strong>${this.props.price}</strong></div>




                      </>)}
                      <form action={PayPal.action}
                        method="post" id="paypal" target="_top">
                        <input type='hidden' name='business'
                          value={PayPal.account} />
                        <input type='hidden' name='item_name' value='Package' />
                        <input type='hidden' name='item_number' value={localStorage.getItem("gs_token")} />


                        {(this.props.price == 0) ? (<>

                          <Form.Group controlId="formBasicCardNo">
                            <Form.Label>Sponsor Amount ($)</Form.Label>
                            <Form.Control
                              type="number"
                              name="amount"
                              validaterule={['required', 'numeric', 'priceValue|100', 'maxpriceValue|900000']}
                              validatemsg={['Amount required', 'Invalid Number', 'Minimum 100 $ need to sponsor', 'Amount is to long']}
                              placeholder="Sponsor Amount"
                              onChange={this.handleInputChange}
                              onFocus={this.handleInputFocus}
                            />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.amount == 'false' ? '' : this.state.FromDataError.amount}</p>

                          </Form.Group>


                        </>) : (<>


                          <input type='hidden' name='amount' value={this.props.price} />



                        </>)}




                        <input type='hidden' name='no_shipping' value='1' />
                        <input type='hidden' name='currency_code' value='USD' />
                        <input type='hidden' name='notify_url'
                          value={PayPal.notify_url} />
                        <input type='hidden' name='cancel_return'
                          value={PayPal.return_url} />
                        <input type='hidden' name='return'
                          value={PayPal.return_url} />
                        <input type="hidden" name="cmd" value="_xclick" />

                      </form>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="ach">


                  <div className="tabpanelOneForm">
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container> */}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.props.close} className="btn-main tabformbutton mr-2">Cancel</Button>
            <Button onClick={() => { this.clickSubmit((this.state.key == 'strip') ? 'strip' : 'paypal') }} variant="primary" type="submit" className="btn-main tabformbutton">Pay</Button>

            {/* <input type="submit" name="pay_now" 
                                    className="btn btn-primary btn-md tabformbutton" id="pay_now"
                                    Value="Pay Now"/> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
