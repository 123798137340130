import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";


import DataTable from "react-data-table-component";

class AffiliatePromocode extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                       
                        FromData: {},
                        FromDataError :
                                        {},
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/affiliate-promocode',
                        member_id:0,
                        memberDashModal:false,
                        memberbankhModal:false,
                        help:'',
                        totalRows: 0,
                        offset: 1,
                        limit: 10,
                        searchValue:'',


                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        

    }



    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {

            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
};

 columns = [
    {
      name: "Promo Code",
      selector: "promo_code",
      sortable: true,
      width:"150px",
      wrap:true,
    },
    {
        name: "Discount Type",
        selector: row => row.discount_type=='1'?'Flat':'Percentage' ,
        sortable: true,
        wrap:true,
        width:"150px",
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
        wrap:true,
      },
      {
        name: "Maximum Users",
        selector: "maximum_users",
        sortable: true,
        wrap:true,
      },{
        name: "Valid From Date",
        selector: "valid_from_date",
        sortable: true,
        wrap:true,
        width:"120px",
      }
      ,{
        name: "Valid To Date",
        selector: "valid_to_date",
        sortable: true,
        wrap:true,
        width:"120px",
      },
    {
      name: "Created at",
      selector: "created_at",
      sortable: true,
      wrap:true,
     
    },
    
    {
        name: "Actions",
        cell: row =>  {
                    return(
                                <div><a href={'#/edit-affiliate-promocode/'+row.id}><Button variant="primary mr-1">Edit</Button></a>
                            
                            </div>
                        )
                        } 
                       ,
        // sortable: true,
        width: '120px'
      },
  ];

    

    handaleChange(data)
    {
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        
       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;

        // console.log(this.state)
        // return 0;


        
     }

    componentDidMount()
    {
         window.scrollTo(0, 0)
        let sta = this;
        let FromData =this.state.FromData

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
        let request = {
            end_point: `/get-all-prmocode?offset=${this.state.offset}&limit=${this.state.limit}`,
            token: localStorage.getItem("gs_token"),
          };
      
          sta.setState({ loader: "visible" }); ///loader on
      
          GET_API(request)
            .then((resp) => {
              sta.setState({ loader: "hidden" }); ///loader off
      
              if (resp.status == "success") {
                FromData = resp.data;
               
                sta.setState({ totalRows: resp.row_count });
                sta.setState({ FromData });
              } else {
              }
            })
            .catch((ee) => {
              sta.setState({ loader: "hidden" }); ///loader off
      
              console.log("/code list ERR", ee);
            });

        setTimeout(() => {
          this.setState({ Msg: '' })
  
        }, 4000);

    }

    handlePerRowsChange = async (page) => {
      
        console.log(page);
        let request = {
          end_point: `/get-all-prmocode?offset=${this.state.offset}&limit=${page}`,
          token: localStorage.getItem("gs_token"),
        };
    
        this.setState({ loader: "visible" }); ///loader on
    
        GET_API(request)
          .then((resp) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            if (resp.status == "success") {
              let FromData = resp.data;
              console.log("response data", resp.data);
              this.setState({ limit: page });
              this.setState({ FromData });
            } else {
            }
          })
          .catch((ee) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            console.log("/code list ERR", ee);
          });
      };
    
      handlePageChange = async (page) => {
      
        let request = {
          end_point: `/get-all-prmocode?offset=${page}&limit=${this.state.limit}`,
          token: localStorage.getItem("gs_token"),
        };
    
        this.setState({ loader: "visible" }); ///loader on
    
        GET_API(request)
          .then((resp) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            if (resp.status == "success") {
              let FromData = resp.data;
            
              this.setState({ FromData });
            } else {
            }
          })
          .catch((ee) => {
            this.setState({ loader: "hidden" }); ///loader off
    
            console.log("/code list ERR", ee);
          });
      };
 
    render() {
        let  FromData = this.state.FromData
        
        return (
            <div>
                 {FromData!=null? (<>

            <Container fluid className="dash_container">
          <Row>
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">

            <h3>Manage Promo Code</h3>
   
            {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                
                
               
            <div style={{ float: 'right' }}>
                <a href="#/create-affiliate-promocode"  className="btn btn-primary ghost_button mt-3 mb-4">Create Promo Code</a>
            </div>

              <DataTable
                    title=""
                    columns={this.columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    paginationServer
                    paginationTotalRows={this.state.totalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    />
                        
               
              </div>
                              
                              
                          
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}

export default AffiliatePromocode;