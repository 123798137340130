import React, { Component,useEffect,useState } from 'react';
import { Route, Redirect } from "react-router-dom";

import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import moment from "moment";
import Header from "./Header";
import Sidepanel from "./Side-panel";
import NotificationHeader from "./NotificationHeader";
import Footer from "./Footer";
import TokenVerify,{GET_API} from "../Helpers/Helpers";
import JobseekerSubscriptionBar from "./JobseekerSubscriptionBar";
import UserCreditBalance from "./UserCreditBalance";






class Frontend extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuCollapse: false,
            setMenuCollapse: false
        };
    }


    menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        this.setState({ menuCollapse: !this.state.menuCollapse })
    };

    componentDidMount() {
        if (window.innerWidth < 768) {
            this.setState({ menuCollapse: true })
        }

    }

 

    render() {

        setInterval(() => {
            TokenVerify()
        }, 200000);
        let condition = localStorage.getItem("gs_token") != null &&
            localStorage.getItem("gs_user") != null &&
            localStorage.getItem("gs_user") != undefined &&
            localStorage.getItem("gs_user") != ''
        //    &&
        //    this.props.children.props.match.path != "/jobseeker-purchase-cart"






        return (
            <>


                {/* || this.props.children.props.match.path == "/payment" */}
                {!condition ? (

                    <div>
                        {/* {this.props.children.props.match.path != "/login" && (
                    <Header {...this.props.children.props}/>
                )} */}

                        {this.props.children}

                        {this.props.children.props.match.path != "/login" && (
                            <Footer />
                        )}
                    </div>

                ) : (<>

                    <Container fluid>

                        <Row className='layout_row'>
                            <Sidepanel {...this.props.children.props} collapsed={this.state.menuCollapse} collapsedHandle={this.menuIconClick} />
                            <div className={this.state.menuCollapse ? ('topbar_active_package d-flex align-items-center justify-content-end mb-3 col-md-12') : ('topbar_active_package d-flex align-items-center justify-content-end mb-3 col-md-10')} style={this.state.menuCollapse ? ({ paddingLeft: "47px" }) : ({ paddingLeft: "120px" })}>
                            
                            
                            <UserCreditBalance/>
                            <NotificationHeader />
                            <JobseekerSubscriptionBar/>
                                
                           
                            </div>





                            {this.state.menuCollapse ?
                                (
                                    <Col sm={12} md={12} lg={12} className={this.state.menuCollapse ? "full_width_container pl-5 pr-5 right_panel" : ""} style={{ marginTop: "60px" }}>
                                        {/* <NotificationHeader /> */}
                                        {this.props.children}
                                    </Col>
                                ) :
                                (
                                    <Col sm={12} md={8} lg={9} className="right_panel" style={{ marginTop: "60px" }}>
                                        {/* <NotificationHeader /> */}
                                        {this.props.children}
                                    </Col>
                                )}

                        </Row>
                    </Container>
                    <Footer />
                </>)}




            </>

        );
    }
}


const FrontendRoute = ({ component: Component, auth, roles, ...rest }) => {



    const [start,setStart] = useState("");
    const [end,setEnd] = useState("");
    const [amount,setAmount] = useState("");

    useEffect(()=>{
    if(!auth) return;

    if (localStorage.getItem("gs_token") == null &&
        localStorage.getItem("gs_user") == null &&
        localStorage.getItem("gs_user") == undefined &&
        localStorage.getItem("gs_user") == '') { return; }

        if (roles == undefined) return;
        let user = JSON.parse(localStorage.getItem("gs_user"))
        if(!user) return;
        if (user.roleID != 4) return;

        let request = {
            end_point: "/get-subscriptions",
            token: localStorage.getItem("gs_token"),
        };
    
        GET_API(request)
            .then((resp) => {
                localStorage.setItem('mysubscriptions',JSON.stringify(resp.data))
                setEnd(resp.data[0].end);
                setStart(resp.data[0].start);
            });
},[Component,auth]);
    if (auth == 'yes') {
        //auth requeired
        if (localStorage.getItem("gs_token") != null &&
            localStorage.getItem("gs_user") != null &&
            localStorage.getItem("gs_user") != undefined &&
            localStorage.getItem("gs_user") != '') { //token have

            if (roles != undefined) {
                let user = JSON.parse(localStorage.getItem("gs_user"))
                if (roles.includes(user.roleID)) {//role matched and allow

                    if (user.roleID == 4) {

                            
                                let userDet = JSON.parse(localStorage.getItem("gs_user"))
                                const start2 = new Date(start);
                                const today = new Date();
                                const difference = today - start2;
                                const daysPassed = difference / (1000 * 60 * 60 * 24);
                                const calculateDiff = Math.floor(daysPassed);                                
                                // calculateDiff ==  43 || 
                                // if((calculateDiff >= 30 && calculateDiff < 60 && userDet['30days'] == 0)|| (calculateDiff >= 60 && calculateDiff < 90 && userDet['60days'] == 0) || (calculateDiff >= 90 && userDet['90days'] == 0) && (localStorage.getItem('isSkipped') == undefined || localStorage.getItem('isSkipped') == 'false')){
                                if((calculateDiff >= 30 && userDet['30days'] == 0) && (localStorage.getItem('isSkipped') == undefined || localStorage.getItem('isSkipped') == 'false')){
                                return (  
                                    <Route {...rest} render={matchProps => (  
                                        <Redirect to="/feedback-form" />  
                                    )} />  
                                  ) ;
                                }
                                if(moment().isAfter(end)){
                                return (  
                                    <Route {...rest} render={matchProps => (  
                                        <Redirect to="/renew-subscription" />
                                    )} />  
                                    ) ;
                                }
                        return (


                            <Route {...rest} render={matchProps => (
                                <Frontend>
                                    <Component {...matchProps} />
                                </Frontend>
                            )} />
                        )
                        // }

                    }
                    else {
                        return (


                            <Route {...rest} render={matchProps => (
                                <Frontend>
                                    <Component {...matchProps} />
                                </Frontend>
                            )} />
                        )
                    }

                }
                else {//role not matched resirect login
                    return (


                        <Route {...rest} render={matchProps => (
                            <Redirect to="/login" />
                        )} />
                    )
                }
            }
            else {//all roles allowed
                return (


                    <Route {...rest} render={matchProps => (
                        <Frontend>
                            <Component {...matchProps} />
                        </Frontend>
                    )} />
                )
            }






        }
        else {
            //token not found
            return (


                <Route {...rest} render={matchProps => (
                    <Redirect to="/login" />
                )} />
            )

        }

    }
    else {
        //without auth allow
        return (


            <Route {...rest} render={matchProps => (
                <Frontend>
                    <Component {...matchProps} />
                </Frontend>
            )} />
        )
    }
};

export default FrontendRoute;