import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';


 
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddSendgridModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {

                            email_api : '',
                            email_from : '',
                           
                        },
                            FromDataError :
                            {

                                email_api : 'false',
                                email_from : 'false',
                                 
                            },

                
                                      
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/add-email-setting',
                       
                                        
                        };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        
        
            

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
       
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
       
        console.log(FromData)
        console.log(FromDataError)
        if(this.validateForm(FromDataError)  )
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("email_api", FromData.email_api);
                formdata.append("email_from", FromData.email_from);
                formdata.append("type", 'sendgrid');
                
                    
                let request = {
                  end_point : '/update-affiliate-email',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off

                  //console.log('......response',resp)

                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:'Mail Settings Successfully',MsgType:'success'})
                          
                          setTimeout(() => {
                            window.location.reload()
                            
                          }, 3000);

                        }
                        else{
                            
                            //sta.setState({Msg:resp.message.campaign_name.join(),MsgType:'danger'})
                                sta.setState({Msg:'Mail Settings Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/Mail SettingsERR',ee)
                        });
                      

           

        }else
        { 

   
           
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
           
        }

       
    }

    componentDidMount()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_user") != null)
            {
                
                this.getoneRecord()
                
                
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }

            getoneRecord()
            {
                let sta = this;
                let FromData = this.state.FromData;
                let FromDataError = this.state.FromDataError;
                try {
                  if(localStorage.getItem("gs_user") != null)
                  {
                      //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                      sta.setState({loader:'visible'})///loader on
          
                      
                      GET_API({token:localStorage.getItem("gs_token"),end_point:'/get-affiliate-setting'}).then(resp=>{
          
          
                          if(resp.status == 'success')
                          {
                            //console.log('ddd'+resp.smtp.type)
                           
                            FromData['email_api'] = resp.sg.email_api;
                            FromDataError['email_api'] = (resp.sg.email_api != null)?'': 'false'
          
                            FromData['email_from'] = resp.sg.email_from;
                            FromDataError['email_from'] = (resp.sg.email_from != null)?'': 'false'

                           
          
                            this.setState({FromData})
                          }
                         sta.setState({loader:'hidden'})///loader off
          
                         
                        }).catch((ee)=>{
                          
                          sta.setState({loader:'hidden'})///loader off
          
                              console.log('/emailEditERR',ee)
                          });
                  }
                  
                 
                } 
                catch (error) {
                  
                }
           
                
            
                

            }


  
 
 
  render() {
    let  FromData = this.state.FromData
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         >
        <Form
         className="tabform"
         onSubmit={this.handaleSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Set sendgrid Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>


      
         <Row className="m-0 mt-4 mb-4">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}


      <Form.Row >
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Sendgrid API</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="email_api"
                                    onChange={this.handaleChange}
                                    placeholder="Sendgrid API"
                                    value={FromData.email_api}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.email_api=='false'?'': this.state.FromDataError.email_api}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                              <Form.Group controlId="formBasicEmail55" as={Col}>
                                  <Form.Label>From Email</Form.Label>
                                  <Form.Control
                                    type="email"
                                    name="email_from"
                                    onChange={this.handaleChange}
                                    placeholder="From Email"
                                    value={FromData.email_from}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.email_from=='false'?'': this.state.FromDataError.email_from}</p>
                                </Form.Group>
                                </Form.Row>
           
                                

                        </Col>
                    
                    </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn-main tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
