import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';


import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class EditContactModal extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        firstname : '',
                                        lastname : '',
                                        contact_no : '',
                                        email : '',
                                        id:'',
                                        type:'',
                                       
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                          firstname : 'false',
                                          lastname : 'false',
                                          contact_no : 'false',
                                          email : 'false',
                                          type : '',
                                        
                                        
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/update-contact',
                        
                                      
                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
       
       
        
        

        

    }

    handalePhoneNoChange(data) {
      let FromData = this.state.FromData;
      let FromDataError = this.state.FromDataError;
      let value = '';
      FromData['contact_no'] = data;
      this.setState({ FromData }, () => {
  
      })
  
      if (FromData.contact_no != '') {
        var valid_obj = {
          value: value,
          rules: 'required',
          message: 'This field is required'
        }
        validation(valid_obj).then((error) => {
          FromDataError['contact_no'] = true
          this.setState({ FromDataError }) //from error state
        })
      }
  
    }
    
    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
        let gs_user = '';
        let id =this.props.id;
      

       

        if(this.validateForm(FromDataError))
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("contact_no", FromData.contact_no);
                formdata.append("firstname", FromData.firstname);
                formdata.append("lastname", FromData.lastname);
                formdata.append("email", FromData.email);
                formdata.append("id", id);
                

                let request = {
                  end_point : '/update-contact',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                console.log('......request',formdata)

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off


                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:'Contact Updated Successfully',MsgType:'success'})
                          
                          
                          
                        //   this.props.history.push({
                        //     pathname: '/contact-details',
                           
                        //     state:{Msg:'Contact update Successfully',MsgType:'success'}
                           
                  
                        //   })

                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3000);

                          

                        }
                        else{
                            
                            sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
                                //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/addcontactERR',ee)
                        });
                      

           

        }else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }

       
    }





    componentDidMount()
    {

      

      
    }


    getoneRecord()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError
        let id =this.props.id;
        
      console.log(id)
        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_token") != null)
            {
                //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                sta.setState({loader:'visible'})///loader on

                
                GET_API({token:localStorage.getItem("gs_token"),end_point:'/contact-details?id='+id}).then(resp=>{

                 // console.log(resp.data.contact.firstname)
                    if(resp.status == 'success')
                    {
                            console.log(resp)
                     
                      FromData['firstname'] = resp.data.contact.firstname;
                      FromDataError['firstname'] = (resp.data.contact.firstname != null)?'': 'false'

                      FromData['lastname'] = resp.data.contact.lastname;
                      FromDataError['lastname'] = (resp.data.contact.lastname != null)?'': 'false'


                      FromData['contact_no'] = resp.data.contact.contact_no;
                      FromDataError['contact_no'] = (resp.data.contact.contact_no != null)?'': 'false'

                      FromData['email'] = resp.data.contact.email;
                      FromDataError['email'] = (resp.data.contact.email != null)?'': 'false'

                      // FromData['type'] = resp.data.contact.type;
                      // FromDataError['type'] = (resp.data.contact.type != null)?'': 'false'
                    
                      

                      this.setState({FromData})
                    }
                   sta.setState({loader:'hidden'})///loader off

                   
                  }).catch((ee)=>{
                    
                    sta.setState({loader:'hidden'})///loader off

                        console.log('/contactdetailsEditERR',ee)
                    });
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }

    
 
 
  render() {
    let  FromData = this.state.FromData
      
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         >
        <Form
         className="tabform"
         onSubmit={this.handaleSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Update Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>

         
      
         <Row className="m-0 mt-4 mb-4">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}



             
      
                            <Form.Row>
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="firstname"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your first name"
                                    value={FromData.firstname}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.firstname=='false'?'': this.state.FromDataError.firstname}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail3" as={Col}>
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="lastname"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your last name"
                                    value={FromData.lastname}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.lastname=='false'?'': this.state.FromDataError.lastname}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                               

                                <Form.Group controlId="formBasicEmail2" as={Col}>
                                  <Form.Label>Contact No</Form.Label>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    value={FromData.contact_no}
                                    name="contact_no"
                                    onChange={this.handalePhoneNoChange.bind(this)}
                                    validaterule={['required', 'phone']}
                                    validatemsg={['This field is required', 'Invalid Phone number']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.contact_no=='false'?'': this.state.FromDataError.contact_no}</p>
                                </Form.Group>

                                

                                <Form.Group controlId="formBasicEmail1" as={Col}>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    value={FromData.email}
                                    onChange={this.handaleChange}
                                    placeholder="Enter email"
                                    validaterule={['required','isEmail']}
                                    validatemsg={['This field is required','Input a valid Email']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.email=='false'?'': this.state.FromDataError.email}</p>
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Row>
              
                            <Form.Group controlId="formBasicEmail22" as={Col}>
                                <Form.Label>Type</Form.Label>
                                <Form.Control as="select" name="type" onChange={this.handaleChange} defaultValue={''}>
                                <option value="">Select Type</option>
                                <option selected={FromData.type=='jobseeker'?'selected':''} value="jobseeker">Jobseeker</option>
                                <option selected={FromData.type=='sponsor'?'selected':''} value="sponsor">Sponsor</option>
                                
                                </Form.Control>
                                <p style={{ color: 'red' }}>{this.state.FromDataError.type=='false'?'': this.state.FromDataError.type}</p>
                            </Form.Group>

                            
                            </Form.Row> */}
           
             

           </Col>
           


         </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
