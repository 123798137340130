import React, { Component, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip, Dropdown, Modal } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import csvfilename from './files/add-contact.csv';
import './css/dashboard.css';
import DataTable from "react-data-table-component";
import AddContactGroupModal from './Section/AddContactGroupModal';
import AddContactcsvModal from "../Affiliate/Section/AddContactcsvModal";
import EditContactGroupModal from './Section/EditContactGroupModal';

import AddContactModal from "./Section/AddContactModal";

import Threedots_img from '../images/dotsss.png'
import moment from 'moment';
import { BASE_URL } from "../Config";




export default class AffiliategroupDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {

      ContactData: {},
      ContactDataError: {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/contact-group-details-sponsor',
      ContactGroupModal: false,
      ContactGroupEditModal: false,
      id: 0,
      help: '',
      search: '',
      filteredData: [],


      ContactModal: false,
      contactUrl: "",
      ContactcsvModal: false,
      googleContactModal: false,
      googleGroupNameFailed: false,
      group_name_google: '',
      contactGroupId: '',
      googleGroupNameExits: false,
      googleGroupNameExitsButton: false,
    };

    //Binding




  }
  handleAddContact = () => {
    this.setState({ ContactModal: !this.state.ContactModal });
  }
  handleClosCsv = () => {

    this.setState({ ContactcsvModal: !this.state.ContactcsvModal });

  }
  handleClose() {

    if (this.state.ContactGroupModal) {
      this.setState({ ContactGroupModal: false })
    }
    else {
      this.setState({ ContactGroupModal: true })

    }
  }
  getContactList = () => {
    let sta = this;
    let ContactData = this.state.ContactData;
    let ContactDataError = this.state.ContactDataError;

    let group_id = this.props.match.params.code;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    let request = {
      end_point: "/list-contact?group_id=" + group_id,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = resp.data;

          sta.setState({ ContactData });

          let group_name = resp.group_name.group_name;
          sta.setState({ group_name });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });

    let requestGoogle = {
      end_point: "/getAuthGoogle?group_id=" + group_id,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestGoogle)
      .then((respG) => {
        sta.setState({ loader: "hidden" }); ///loader off

        //console.log(respG.data.contactUrl)
        if (respG.status == "success") {
          let contactUrl = respG.data.contactUrl;

          sta.setState({ contactUrl });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/google url ERR", ee);
      });
  }

  handleCloseEdit(id = 0) {
    console.log(id)

    if (this.state.ContactGroupEditModal) {
      this.setState({ ContactGroupEditModal: false })
    }
    else {
      this.setState({ id: id, ContactGroupEditModal: true })


    }
  }
  handlebulkReferal(group_id) {

    let sta = this;
    var formdata = new FormData();
    formdata.append("group_id", group_id);
    formdata.append("link", BASE_URL);
    let request = {
      end_point: '/bulk-referral-ganarate-code-and-share',
      formdata: formdata,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        sta.setState({ Msg: resp.message, MsgType: "success" });

        this.getContactGroupList()
        setTimeout(() => {
          this.setState({ Msg: '' })

        }, 4000);
      } else {
        sta.setState({ Msg: resp.message, MsgType: "danger" });
      }
    })
  }
  columns = [
    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "10%",
      wrap: true,
    },

    {
      name: "List Name",
      selector: row => { return <a href={'#/contact-details/' + row.id} >{row.group_name}</a> },
      width: '25%',
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Contact",
      selector: row => row.contactCount,
      width: '20%',
      sortable: true,
      style: {
        textAlign: 'right'
      }
    },
    {
      name: "Created At",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: '20%',
      sortable: true,
      wrap: true
    },

    // {
    //   name: "Created At",
    //   selector: row => row.created_at ,
    //   sortable: true
    // },

    // {
    //   name: "",
    //   cell: row =>  {
    //               return(
    //                 <div>
    //                        <a href={'#/contact-details/'+row.id} ><Button variant="primary mr-1">Manage Contact</Button></a>
    //                   </div>
    //                   )
    //                   } 
    //                  ,
    //   sortable: true,
    //   width: '250px'
    // },
    {
      name: "Actions",
      cell: row => {
        return (
          // <div><a href="javascript:"><Button variant="primary mr-1"onClick={()=>this.handleCloseEdit(row.id)} >Edit</Button></a>

          //        <Button  disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Contact Group?')){this.handaleDelete(row.id)}}}>Delete</Button> 
          //        <a href={'#/contact-details/'+row.id} ><Button variant="primary ml-1">Manage Contact</Button></a>
          //   </div>

          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Button
                    onClick={() => this.handleCloseEdit(row.id)} > <i className="fa fa-pencil"></i> Edit</Button>
                </Dropdown.Item>
                <Dropdown.Item >
                  <Button disabled={false}
                    onClick={() => { if (window.confirm('Delete the Contact Group?')) { this.handaleDelete(row.id) } }}> <i className="fa fa-trash"></i> Delete</Button>
                </Dropdown.Item>
                {row.has_send == 0 && (
                  <Dropdown.Item >
                    <Button disabled={false}
                      onClick={() => { if (window.confirm('Are you sure want to send bulk referal?')) { this.handlebulkReferal(row.id) } }}> <i className="fa fa-paper-plane"></i> Send Bulk Referral</Button>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      },
      // sortable: true,
      width: '10%',
    },



  ];

  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let ContactData = this.state.ContactData
    let ContactDataError = this.state.ContactDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    this.getContactGroupList()

    //help api
    let requestHelp = {
      end_point: '/help-details?key=affiliate_contact_group_list',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })

    // this.getContactList();

  }

  searchChange = (event) => {
    this.setState({ search: event.target.value }, () => {
      this.globalSearch();
    });
    //     event.preventDefault();
    //     const { name, value } = event.target;


    //    let sta = this;
    //    let ContactData =this.state.ContactData
    //    let ContactDataError =this.state.ContactDataError
    //    let request = {
    //       end_point : '/list-contact-group?search_key='+value,
    //       token: localStorage.getItem("gs_token")

    //   };

    // sta.setState({loader:'visible'})///loader on


    //     GET_API(request).then(resp=>{

    //              sta.setState({loader:'hidden'})///loader off


    //                     if(resp.status == 'success')
    //                     {

    //                         ContactData = resp.data

    //                             sta.setState({ContactData})

    //                        }
    //                         else{



    //                         }
    //             }).catch((ee)=>{
    //               sta.setState({loader:'hidden'})///loader off

    //                 console.log('/code list ERR',ee)
    //             });

  }
  globalSearch = () => {
    let { search, ContactData } = this.state;
    let filteredData = ContactData.filter((value) => {
      return (
        value.group_name.toLowerCase().includes(search.toLowerCase()) ||
        value.group_type.toLowerCase().includes(search.toLowerCase())
        // value.Phone.includes(searchInput)||
        // value.Country.toLowerCase().includes(searchInput.toLowerCase())||
        // value.Full_Name.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  handaleDelete(id) {
    let sta = this

    var formdata = new FormData();
    formdata.append("id", id)

    let request = {
      end_point: '/delete-contact-group',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };


    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then((resp) => {

      if (resp.status == 'success') {


        sta.setState({ loader: 'hidden' })///loader off

        sta.setState({ Msg: 'Contact group deleted successfully', Msgtype: 'succcess' })
        this.getContactGroupList()

        // setTimeout(() => {
        //    window.location.reload()

        // }, 3000);



      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: "faild", MsgType: 'danger' })
      }

    }).catch(() => {

    })

  }


  getContactGroupList() {

    let sta = this;
    let ContactData = this.state.ContactData
    let ContactDataError = this.state.ContactDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: '/list-contact-group',
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        ContactData = resp.data

        sta.setState({ ContactData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });





  }
  googleModalClick = () => {
    if (this.state.googleContactModal) {
      this.setState({ googleContactModal: false })
    } else {
      this.setState({ googleContactModal: true })
    }
  }

  grpNameChnage = (event) => {
    let request = {
      end_point: "/contact-group-exits?g_name=" + event.target.value,
      token: localStorage.getItem("gs_token"),
    };

    GET_API(request)
      .then((resp) => {
        if (resp.status == "success") {
          this.setState({ googleGroupNameExits: false })
          this.setState({ googleGroupNameExitsButton: false })
        } else {
          this.setState({ googleGroupNameExits: true })
          this.setState({ googleGroupNameExitsButton: true })
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/google url ERR", ee);
      });


    this.setState({ group_name_google: event.target.value })
  }
  googleUploadFromSubmit = () => {
    if (this.state.group_name_google != '') {
      this.setState({ googleGroupNameFailed: false })

      let requestGoogle = {
        end_point: "/getAuthGoogle?group=" + this.state.group_name_google,
        token: localStorage.getItem("gs_token"),
      };

      this.setState({ loader: "visible" }); ///loader on

      GET_API(requestGoogle)
        .then((respG) => {
          this.setState({ loader: "hidden" }); ///loader off

          //console.log(respG.data.contactUrl)
          if (respG.status == "success") {
            let contactUrl = respG.data.contactUrl;
            localStorage.setItem('group_id_storage', respG.data.groupId);
            this.setState({ contactUrl });
            this.setState({ contactGroupId: respG.data.groupId });
            window.location.href = contactUrl;
          } else {
          }
        })
        .catch((ee) => {
          this.setState({ loader: "hidden" }); ///loader off

          console.log("/google url ERR", ee);
        });




    } else {
      this.setState({ googleGroupNameFailed: true })
    }
  }


  render() {
    let ContactData = this.state.ContactData

    return (
      <div>
        {ContactData != null ? (<>

          {/* Add contact to group modal */}
          {this.state.ContactModal && (
            <AddContactModal
              {...this.props}
              groupList={this.state.ContactData}
              show={this.state.ContactModal}
              close={() => this.handleAddContact()}
            />
          )}

          {/* Add bulk contacts to group modal */}
          {
            this.state.ContactcsvModal && (
              <AddContactcsvModal
                {...this.props}
                groupList={this.state.ContactData}
                show={this.state.ContactcsvModal}
                close={() => this.handleClosCsv()}
              />
            )}

          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12}>
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm pt-3">






                  {(this.state.id != 0) ? (<>
                    <EditContactGroupModal {...this.props} id={this.state.id} show={this.state.ContactGroupEditModal} close={() => this.handleCloseEdit()} />
                  </>) : (<></>)}

                  <Row>
                    <h3>Employee Contact Lists</h3>
                    <br/>
                    &#10;&#13;
                    <p>Add employee contact information by importing a bulk contact list or adding them manually.</p>
                  </Row>
                  <Row>

                    <div className="btnList">
                      {/* Download samle csv */}
                      <a href={csvfilename} download="add-contact.csv" className="btn btn-primary ghost_button">Download Sample CSV</a>
                    </div>

                    <div className="btnList">
                      {/* Csv import bulk contacts add */}
                      <a href="javascript:" onClick={this.handleClosCsv} className="btn btn-primary ghost_button">Upload Bulk CSV</a>
                    </div>
                    <div className="btnList">
                      {/* Import from google */}
                      {/* <a href="javascript:" className="btn btn-primary ghost_button" onClick={() => this.googleModalClick()}>Import google contacts</a> */}
                    </div>
                    <div className="btnList">
                      {/* Add contact */}
                      {/* <a href="javascript:" onClick={this.handleAddContact} className="btn btn-primary ghost_button" >Add Contact</a> */}
                    </div>

                  </Row>
                  <div style={{ float: "right" }}>









                  </div>

                  {/* <div
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></div> */}

                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                  <div style={{ float: "right", margin: '10px' }} className="table_search">
                    Search : <input type='text' placeholder='Search' name="search_val" onChange={this.searchChange} /></div>
                  <DataTable
                    title=""
                    columns={this.columns}
                    data={this.state.filteredData.length > 0 ? this.state.filteredData : ContactData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                  />


                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}

        <Modal
          show={this.state.googleContactModal}

          className="modal_upload"
          keyboard={false}
          onHide={() => { this.googleModalClick() }}
          id="modal"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                className="tabform">
                <Row className="m-0 mt-4 mb-4">
                  <Col>
                    <Form.Row>
                      <Form.Group controlId="formBasicEmail22" as={Col}>
                        <Form.Label>List Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="group_name"
                          onChange={this.grpNameChnage}
                          placeholder="Enter List name"

                          validaterule={['required']}
                          validatemsg={['This field is required']}
                        />
                        <p style={{ color: 'red' }}>{this.state.googleGroupNameFailed == false ? '' : 'This field is required!'}</p>
                        <p style={{ color: 'red' }}>{this.state.googleGroupNameExits == true ? 'Name already exists!' : ''}</p>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group controlId="formBasicEmail22" as={Col}>
                        <div className="upload_action row">
                          <div className="col pl-2">
                            <Button variant="primary" type="button" disabled={this.state.googleGroupNameExitsButton} onClick={() => this.googleUploadFromSubmit()} className="btn btn-primary tabformbutton btn-block">Submit</Button>
                          </div>
                        </div>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Row>
              </Form>

            </div>
          </Modal.Body>

        </Modal>


      </div>




    );
  }
}





