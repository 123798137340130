import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';



import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class EditContactGroupModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      FromData: {

        group_name: '',
        group_type: '',

      },
      FromDataError:
      {

        group_name: 'false',
        group_type: 'false',


      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/update-contact',



    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)







  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
        console.log('loop', valid)
      }

    }


    console.log('funct', valid)

    return valid;
  };




  handaleChange(data) {

    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;


    value = data.target.value;
    FromData[name] = value;
    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }

  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let gs_user = '';
    let id = this.props.id;




    if (this.validateForm(FromDataError)) {

      sta.setState({ loader: 'visible' })///loader on


      var formdata = new FormData();

      formdata.append("group_name", FromData.group_name);
      formdata.append("group_type", FromData.group_type);
      formdata.append("id", id);
      let request = {
        end_point: '/update-contact-group',
        formdata: formdata,
        token: localStorage.getItem("gs_token")

      };

      console.log('......request', formdata)

      POST_API(request).then(resp => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp.status == 'success') {
          sta.setState({ Msg: 'Contact Group Updated Successfully', MsgType: 'success' })



          //   this.props.history.push({
          //     pathname: '/contact-details',

          //     state:{Msg:'Contact update Successfully',MsgType:'success'}


          //   })

          setTimeout(() => {
            window.location.reload()

          }, 3000);



        }
        else {

          sta.setState({ Msg: resp.message.email.join(), MsgType: 'danger' })
          //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
        }
      }).catch((ee) => {

        console.log('/addcontactERR', ee)
      });




    } else {
      sta.setState({ Msg: 'Fill the form correctly!' })
      sta.setState({ MsgType: 'danger' })
    }


  }





  componentDidMount() {





  }


  getoneRecord() {

    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError
    let id = this.props.id;

    console.log(id)
    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }

    try {
      if (localStorage.getItem("gs_token") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        sta.setState({ loader: 'visible' })///loader on


        GET_API({ token: localStorage.getItem("gs_token"), end_point: '/contact-group-details?id=' + id }).then(resp => {

          // console.log(resp.data.contact.firstname)
          if (resp.status == 'success') {
            console.log(resp)

            FromData['group_name'] = resp.data.contactGroup.group_name;
            FromDataError['group_name'] = (resp.data.contactGroup.group_name != null) ? '' : 'false'

            FromData['group_type'] = resp.data.contactGroup.group_type;
            FromDataError['group_type'] = (resp.data.contactGroup.group_type != null) ? '' : 'false'






            this.setState({ FromData })
          }
          sta.setState({ loader: 'hidden' })///loader off


        }).catch((ee) => {

          sta.setState({ loader: 'hidden' })///loader off

          console.log('/contactdetailsEditERR', ee)
        });
      }


    }
    catch (error) {

    }



  }




  render() {
    let FromData = this.state.FromData

    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          onShow={() => this.getoneRecord()}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
        >
          <Form
            className="tabform"
            onSubmit={this.handaleSubmit}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Contact Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>



              <Row className="m-0 mt-4 mb-4">


                <Col md={12} className="pl-0">
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}





                  <Form.Row>
                    <Form.Group controlId="formBasicEmail4" as={Col}>
                      <Form.Label>Group Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="group_name"
                        onChange={this.handaleChange}
                        placeholder="Enter Group Name"
                        value={FromData.group_name}
                        validaterule={['required']}
                        validatemsg={['This field is required']}
                      />
                      <p style={{ color: 'red' }}>{this.state.FromDataError.group_name == 'false' ? '' : this.state.FromDataError.group_name}</p>
                    </Form.Group>


                  </Form.Row>


                  <Form.Row>

                    <Form.Group controlId="formBasicEmail22" as={Col}>
                      <Form.Label>Type</Form.Label>
                      <Form.Control as="select" name="group_type" onChange={this.handaleChange}>
                        <option value="">Select Type</option>
                        <option selected={FromData.group_type == 'jobseeker' ? 'selected' : ''} value="jobseeker">Jobseeker</option>
                        {['9'].includes(localStorage.getItem("roleID")) ? (<>
                          <option selected={FromData.group_type == 'sponsor' ? 'selected' : ''} value="sponsor">Sponsor</option>
                        </>) : (<></>)}


                      </Form.Control>
                      <p style={{ color: 'red' }}>{this.state.FromDataError.group_type == 'false' ? '' : this.state.FromDataError.group_type}</p>
                    </Form.Group>


                  </Form.Row>



                </Col>



              </Row>



            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-primary btn-md tabformbutton"
              >
                Submit
              </Button>

            </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
