import React, { Component, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import csvfilename from './files/add-contact.csv';
import './css/dashboard.css';
import DataTable from "react-data-table-component";
import AddContactModal from './Section/AddContactModal'
import AddContactcsvModal from './Section/AddContactcsvModal'
import EditContactModal from './Section/EditContactModal'
import {BASE_URL} from '../Config';
import moment from 'moment';


export default class SponsorContactDetails extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                       
                        ContactData: {},
                        ContactDataError :{},
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/contact-group-details-sponsor',
                        ContactModal:false,
                        ContactcsvModal:false,
                        ContactEditModal:false,
                        id:0,
                        group_id:0,
                        group_name:'',
                        FromData: {
                                       
                          group_id : '',
                          import_code : '',
                           
                      },
                     };

        //Binding

       
        

    }
    handleClose(group_id=0)
    {
      
      if(this.state.ContactModal)
      {
         this.setState({ContactModal: false})
      }
      else{
        this.setState({group_id: group_id,ContactModal: true})
       
      }
    }
    handleClosCsv(group_id=0)
    {
      
      if(this.state.ContactcsvModal)
      {
         this.setState({ContactcsvModal: false})
      }
      else{
        this.setState({group_id: group_id,ContactcsvModal: true})
       
      }
    }
    handleCloseEdit(id=0)
    {
      console.log(id)
     
      if(this.state.ContactEditModal)
      {
         this.setState({ContactEditModal: false})
      }
      else{
        this.setState({id: id,ContactEditModal: true})
       
       
      }
    }
    columns = [
       
        {
            name: "Name",
            cell: row => (row.firstname|| '') +' '+ (row.lastname|| '') ,
            sortable: true
          },
          {
            name: "Email",
            cell: row => row.email ,
            sortable: true
          },
          
          {
            name: "Phone",
            cell: row => row.contact_no ,
            sortable: true
          },
          {
            name: "Created At",
            // cell: row => row.created_at ,
            cell: row => moment(row.created_at).format('MM-DD-YYYY'),
            sortable: true
          },
          {
            name: "Actions",
            cell: row =>  {
                        return(
                          <div><a href="javascript:"><Button variant="primary mr-1"onClick={()=>this.handleCloseEdit(row.id)} >Edit</Button></a>
                                   
                                 <Button disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Contact?')){this.handaleDelete(row.id)}}}>Delete</Button> 
                                </div>
                            )
                            } 
                           ,
            sortable: true,
            width: '250px'
          },
          
        
      ];

    componentDidMount()
    {

        let sta = this;
        let ContactData =this.state.ContactData
        let ContactDataError =this.state.ContactDataError

        let group_id=this.props.match.params.code
        this.setState({group_id})
        localStorage.setItem('group_id',group_id)
        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
        
        if(localStorage.getItem('import_code')!=null && localStorage.getItem('import_code')!=undefined)
        {
          window.location = BASE_URL+'#/contact-details-sponsor/'+localStorage.getItem('group_id');
          sta.setState({loader:'visible'})///loader on
          let FromData = this.state.FromData;
          var formdata = new FormData();
              
          formdata.append("group_id", localStorage.getItem('group_id'));
          formdata.append("import_code", localStorage.getItem('import_code'));

          let requestSave = {
            end_point : '/import-google-contact',
            formdata:formdata,
            token: localStorage.getItem("gs_token")

          };

          

          POST_API(requestSave).then(respCon=>{

            sta.setState({loader:'hidden'})///loader off

                console.log('save '+respCon)

                  if(respCon.status == 'success')
                  {
                    
                    sta.setState({Msg:respCon.message,MsgType:'success'})
                    
                      
                      //localStorage.removeItem("group_id");
                      localStorage.removeItem("import_code");
                    

                  }
                  else{
                      
                      sta.setState({Msg:respCon.message,MsgType:'danger'})
                          //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
                  }
                  }).catch((ee)=>{

                      console.log('/addcontactERR',ee)
                  });
                
        }
      

       
        this.getContactList()
                      

      
    }

    searchChange = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      let group_id=this.props.match.params.code
    
     let sta = this;
     let ContactData =this.state.ContactData
     let ContactDataError =this.state.ContactDataError
     let request = {
        end_point : '/list-contact?search_key='+value+'&group_id='+group_id,
        token: localStorage.getItem("gs_token")

    };

  sta.setState({loader:'visible'})///loader on


      GET_API(request).then(resp=>{

               sta.setState({loader:'hidden'})///loader off


                      if(resp.status == 'success')
                      {
                        ContactData={};
                        sta.setState({ContactData})    
                          ContactData = resp.data

                              sta.setState({ContactData})
                              
                         }
                          else{
                              
                              
                                  
                          }
              }).catch((ee)=>{
                sta.setState({loader:'hidden'})///loader off

                  console.log('/code list ERR',ee)
              });

    }

    handaleDelete(id)
    {
       let sta = this
        
       var formdata = new FormData();
       formdata.append("id", id)

       let request = {
           end_point : '/delete-contact',
           formdata:formdata,
           token: localStorage.getItem("gs_token")
           };

       
           sta.setState({loader:'visible'})///loader on

       POST_API(request).then((resp)=>{

           if(resp.status=='success')
           {
               
            
            sta.setState({loader:'hidden'})///loader off

            sta.setState({Msg:'Delete Successfully!',Msgtype:'success'})
            this.getContactList()

                // setTimeout(() => {
                //    window.location.reload()
                   
                // }, 3000);
              
         
               
           }
           else{
               //console.log(resp.message.password.join())
               sta.setState({Msg:"faild",MsgType:'danger'})
           }

       }).catch(()=>{

       })
       
    }


    getContactList()
    {

        let sta = this;
        let ContactData =this.state.ContactData
        let ContactDataError =this.state.ContactDataError

        let group_id=this.props.match.params.code
        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
      

       
                let request = {
                    end_point : '/list-contact?group_id='+group_id,
                    token: localStorage.getItem("gs_token")

                };

                sta.setState({loader:'visible'})///loader on


                    GET_API(request).then(resp=>{

                             sta.setState({loader:'hidden'})///loader off


                                    if(resp.status == 'success')
                                    {
                                            
                                        ContactData = resp.data

                                            sta.setState({ContactData})
                                          
                                            let group_name=resp.group_name.group_name
                                            sta.setState({group_name})
                                       }
                                        else{
                                            
                                            
                                                
                                        }
                            }).catch((ee)=>{
                              sta.setState({loader:'hidden'})///loader off

                                console.log('/code list ERR',ee)
                            });

                      
                     
                    
                            let requestGoogle = {
                              end_point : '/getAuthGoogle?group_id='+group_id,
                              token: localStorage.getItem("gs_token")
          
                          };
          
                          sta.setState({loader:'visible'})///loader on
          
          
                              GET_API(requestGoogle).then(respG=>{
          
                                       sta.setState({loader:'hidden'})///loader off
          
                                       //console.log(respG.data.contactUrl)
                                              if(respG.status == 'success')
                                              {
                                                      
                                               let contactUrl = respG.data.contactUrl
          
                                                      sta.setState({contactUrl})
                                                    
                                                     
                                                 }
                                                  else{
                                                      
                                                      
                                                          
                                                  }
                                      }).catch((ee)=>{
                                        sta.setState({loader:'hidden'})///loader off
          
                                          console.log('/google url ERR',ee)
                               });        

       
                      

      
    }

    
        render() {
          let  ContactData = this.state.ContactData
          
          return (
              <div>
                  {ContactData!=null? (<>
              

              <Container fluid className="dash_container">
            <Row>
              <Leftpanel urlPath={this.state.urlPath} {...this.props} />
              <Col md={9} className="pt-4 pb-4">
              <Loader loadmsg="please wait" visibility={this.state.loader}/>

              <div className="tabpanelOneForm">
 
              {(this.state.group_id != 0)? (<>
                <AddContactModal {...this.props} group_id={this.state.group_id} show={this.state.ContactModal} close={()=>this.handleClose()}/>
                </>) : (<></>) }  

                {(this.state.group_id != 0)? (<>
                <AddContactcsvModal {...this.props} group_id={this.state.group_id} show={this.state.ContactcsvModal} close={()=>this.handleClosCsv()}/>
                </>) : (<></>) }

                {(this.state.id != 0)? (<>
                <EditContactModal {...this.props} id={this.state.id} show={this.state.ContactEditModal} close={()=>this.handleCloseEdit()}/>
                </>) : (<></>) }

               <a href="javascript:"  onClick={()=>this.handleClose(this.state.group_id)} className="btn btn-primary ghost_button mt-3">Add Contact</a>
               <a href="javascript:" onClick={()=>this.handleClosCsv(this.state.group_id)} className="btn btn-primary ghost_button mt-3 ml-3">CSV Import</a>    
               <a href={csvfilename} download="add-contact.csv" className="btn btn-primary ghost_button mt-3 ml-3">Download Sample CSV</a>    
               <a href={this.state.contactUrl}  className="btn btn-primary ghost_button mt-3 ml-3">Import google contacts</a>   

                <h3>Contact List Of {this.state.group_name}</h3>
              {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                  
              <div style={{float: "right"}}>   
                Search : <input type='text' name="search_val" placeholder='Search' onChange={this.searchChange}  /></div>
              <DataTable
                    title=""
                    columns={this.columns}
                    data={ContactData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    />
                          
                
                </div>
                                
                                
                            
              </Col>     
            </Row>
          </Container>

          </>):(<p>....Loading</p>) }


          </div>
            
                  
                  
            
          );
      }
}



  
  