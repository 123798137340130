import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, OverlayTrigger, Tooltip, Image, Dropdown, ButtonGroup, Modal } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import ShareLink from './Section/ShareLink';
import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RedeemDashboardViewModal from './Section/RedeemDashboardViewModal'
import SplitButton from 'react-bootstrap/SplitButton'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Threedots_img from '../images/dotsss.png'




class RedemCode extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ShareModal: false,
      reddemCode: '',
      shared_to_name : false,
      shared_to_email :false,
      FromData: {},
      FromDataError:
        {},
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/reedem-code',
      help: '',
      referral: "",
      redeemDashModal: false,
      gs_userID: 0,
      totalRows: 0,
      offset: 1,
      limit: 10,
      searchInput: "",
      filteredData: [],
      copied: false,
      t_key: '',
      has_joined: '',
      codeModal: false,
      ShareModal: false,

    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)

    this.genarateCode = this.genarateCode.bind(this)


  }


  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };



  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;





  }

  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    // console.log(this.state)
    // return 0;



  }
  handlemodal(referral_code = 0, gs_users_userID = 0) {


    if (this.state.redeemDashModal) {
      this.setState({ redeemDashModal: false })
    }
    else {
      this.setState({ referral: referral_code, redeemDashModal: true, gs_userID: gs_users_userID })
      console.log("redeem state", this.state.gs_userID);
    }
  }
  columns = [
    {
      name: "SL No",
      selector: (row, index) => row.sl_no,
      width: '70px',
      sortable: true,
      wrap: true,
    },
    {
      // name: "Employee Email",
      name: "Generated By",
      selector: "employee.email",
      cell: row => row.is_admin_generate == 1 ? <span>Admin</span> : <span>{row.employee.email}<br />{<p style={{ fontSize: "12px" }}>{(row.employee.firstname || "") + " " + (row.employee.lastname || "")}</p>}</span>,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Code Used",
      selector: "jobseeker.created_at",
      cell: row => row.jobseeker.created_at ? <span>Yes</span> : <span>No</span>,
      sortable: true,
      wrap: true
    },

    {
      name: "Code",
      selector: "referral_code",
      sortable: true,
      width: '150px',

    },

    {
      name: "Generated At",
      selector: "created_at",
      sortable: true,
      wrap: true
    },
    // {
    //   name: "Employee Name",

    //   cell: row => row.employee.firstname+' '+row.employee.lastname ,
    //   sortable: true
    // },
    {
      // name: "Has Joined",
      name: "Joining Status",
      selector: "has_joined",
      cell: row => row.has_joined == 1 ? <span className="badge badge-info">Joined</span> : (row.is_shared == 0) ? "" : <span className="badge badge-danger">Not Joined</span>,
      sortable: true
    },
    {
      // name: "Has Joined",
      name: "Shared",
      selector: "is_shared",
      cell: row => row.is_shared == 1 ? "Shared" : "Not Shared",
      sortable: true
    },
    {
      name: "Jobseeker ",
      // cell: row => (row.jobseeker.firstname|| '') +' '+(row.jobseeker.lastname|| '') ,
      selector: row => <span>{row.jobseeker.email ? row.jobseeker.email : row.shared_to_email}<br />{<p style={{ fontSize: "12px" }}>{row.jobseeker.firstname ? (row.jobseeker.firstname || '') + ' ' + (row.jobseeker.lastname || '') : row.shared_to_name}</p>}</span>,
      sortable: true,
      wrap: true,
      width: "250px",

    },
    // {
    //   name: "Jobseeker Email ",
    //   selector: "jobseeker.email",
    //   sortable: true,
    //   wrap: true

    // },

    // {
    //   name: "Copy",
    //   cell: row =>  row.has_joined!=1? <CopyToClipboard text={(JSON.parse(localStorage.getItem("gs_user")).roleID == 5 )? BASE_URL+'#/becomejobseeker/'+row.referral_code : BASE_URL+'#/sponser/'+row.referral_code }>
    //                   <Button variant="info">Copy</Button>
    //                 </CopyToClipboard> : ''
    //                  ,
    //   sortable: true,

    // },
    // {
    //   name: "Share",
    //   cell: row =>  row.has_joined!=1?
    //   (JSON.parse(localStorage.getItem("gs_user")).roleID == 5 )? <button className="btn btn-primary" onClick={()=>{this.handleClose(BASE_URL+'#/becomejobseeker/'+row.referral_code)}}>Share</button>  : 
    //   <button className="btn btn-primary" onClick={()=>{this.handleClose(BASE_URL+'#/sponser/'+row.referral_code)}}>Share</button> 
    //     : ''
    //                  ,
    //   sortable: true,

    // },
    // {
    //   name: "Actions",
    //   cell: row =>    row.has_joined!=1? <Button variant="danger" onClick={()=>{if(window.confirm('Delete the promo?')){this.handaleDelete(row.referral_code)}}}>Delete</Button> : ''
    //                  ,
    //   sortable: true,
    //   width: '110px'


    // },
    //     {
    //       name: "Details",
    //       cell: row =>  {
    //         console.log("row code",row.gs_users_userID)
    //         return(
    //                 <div>
    //                 <Button  variant="primary" onClick={()=>this.handlemodal(row.referral_code,row.gs_users_userID)}>View</Button>
    //                 </div>
    //             )
    //             } 
    //            ,
    // sortable: true
    //     },
    {
      name: "Action",
      cell: row => {
        // console.log("row code", row.gs_users_userID)
        return (
          


          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {row.has_joined != 1 ? <CopyToClipboard text={(JSON.parse(localStorage.getItem("gs_user")).roleID == 5) ? BASE_URL + '#/becomejobseeker/' + row.referral_code : BASE_URL + '#/sponser/' + row.referral_code}>
                  <Dropdown.Item>
                    <Button variant="danger"><i className="fa fa-clone" aria-hidden="true"></i> Copy</Button>
                  </Dropdown.Item>
                </CopyToClipboard> : ''}
                <Dropdown.Item >
                  <a href='javascript:'><Button variant="primary mr-1" onClick={() => this.handlemodal(row.referral_code, row.gs_users_userID)}> <i className="fa fa-eye"></i> View</Button></a>
                </Dropdown.Item>
                {row.is_shared != 1 ? (
                  <Dropdown.Item >
                    <Button variant="danger" onClick={() => this.handleClose(row.referral_code,false,{name:false,email:false})}> <i className="fa fa-share" aria-hidden="true"></i> Share Code</Button>
                  </Dropdown.Item>
                ) :
                  (<>
                    <Dropdown.Item >
                      <Button variant="danger" onClick={() => this.handleClose(row.referral_code, true,{name : row?.shared_to_name ?? false,email : row?.shared_to_email ?? false})}> <i className="fa fa-bell" aria-hidden="true"></i> Send Reminder</Button>
                    </Dropdown.Item>
                  </>)}
                {row.has_joined != 1 ?
                  <Dropdown.Item >
                    <Button variant="danger" onClick={() => { if (window.confirm('Delete the promo?')) { this.handaleDelete(row.referral_code) } }}> <i className="fa fa-trash"></i> Delete</Button>
                  </Dropdown.Item>
                  : ''}
              </Dropdown.Menu>
            </Dropdown>
          </div>



        )
      }
      ,
      // sortable: true
    },

  ];

  columns2 = [
    {
      name: "Code",
      selector: "referral_code",
      sortable: true,
      width: '150px',

    },

    {
      name: "Generated At",
      selector: "created_at",
      sortable: true,
      wrap: true
    },
    {
      // name: "Has Joined",
      name: "Joining Status",
      selector: "has_joined",
      cell: row => row.has_joined == 1 ? <span className="badge badge-info">Joined</span> : <span className="badge badge-danger">Not Joined</span>,
      sortable: true
    },
    {
      name: "Jobseeker Name ",
      // cell: row => (row.jobseeker.firstname|| '') +' '+(row.jobseeker.lastname|| '') ,
      selector: row => (row.has_joined == 1) ? (row.jobseeker.firstname || '') + ' ' + (row.jobseeker.lastname || '') : (row.shared_to_name ? row.shared_to_name : <span className="badge badge-danger">Not Joined</span>),
      sortable: true,
      wrap: true,

    },
    {
      name: "Jobseeker Email ",
      selector: "jobseeker.email",
      selector: row => (row.has_joined == 1) ? row.jobseeker.email  : ( row.shared_to_email ? row.shared_to_email :<span className="badge badge-danger">Not Joined</span>),
      sortable: true,
      wrap: true

    },
    {
      name: "Redeemed On ",
      selector: "jobseeker.created_at",
      sortable: true,
      wrap: true
    },
    // {
    //   name: "Copy",
    //   cell: row =>  row.has_joined!=1? <CopyToClipboard text={(JSON.parse(localStorage.getItem("gs_user")).roleID == 5 )? BASE_URL+'#/becomejobseeker/'+row.referral_code : BASE_URL+'#/sponser/'+row.referral_code }>
    //                   <Button variant="info">Copy</Button>
    //                 </CopyToClipboard> : ''
    //                  ,
    //   sortable: true,

    // },
    // {
    //   name: "Share",
    //   cell: row =>  row.has_joined!=1?
    //   (JSON.parse(localStorage.getItem("gs_user")).roleID == 5 )? <button className="btn btn-primary" onClick={()=>{this.handleClose(BASE_URL+'#/becomejobseeker/'+row.referral_code)}}>Share</button>  : 
    //   <button className="btn btn-primary" onClick={()=>{this.handleClose(BASE_URL+'#/sponser/'+row.referral_code)}}>Share</button> 
    //     : ''
    //                  ,
    //   sortable: true,

    // },
    // {
    //   name: "Actions",
    //   cell: row =>    row.has_joined!=1? <Button variant="danger" onClick={()=>{if(window.confirm('Delete the promo?')){this.handaleDelete(row.referral_code)}}}>Delete</Button> : ''
    //                  ,
    //   sortable: true,
    //   width: '110px'


    // },
    //     {
    //       name: "Details",
    //       cell: row =>  {
    //         console.log("row code",row.gs_users_userID)
    //         return(
    //                 <div>
    //                 <Button  variant="primary" onClick={()=>this.handlemodal(row.referral_code,row.gs_users_userID)}>View</Button>
    //                 </div>
    //             )
    //             } 
    //            ,
    // sortable: true
    //     },
    {
      name: "Action",
      cell: row => {
        // console.log("row code", row.gs_users_userID)
        return (
          //   <Dropdown size="sm" >

          //     <Dropdown.Toggle variant="primary" id="dropdown-basic" >View</Dropdown.Toggle>
          //       <Dropdown.Menu  drop='up' >
          //       {row.has_joined!=1? <CopyToClipboard text={(JSON.parse(localStorage.getItem("gs_user")).roleID == 5 )? BASE_URL+'#/becomejobseeker/'+row.referral_code : BASE_URL+'#/sponser/'+row.referral_code }>
          //             <Dropdown.Item>Copy</Dropdown.Item>
          //             </CopyToClipboard> : ''}
          //             {row.has_joined!=1? <Dropdown.Item  onClick={()=>{if(window.confirm('Delete the promo?')){this.handaleDelete(row.referral_code)}}}>Delete</Dropdown.Item> : ''}
          //             <Dropdown.Item onClick={()=>this.handlemodal(row.referral_code,row.gs_users_userID)}>View</Dropdown.Item>
          //       </Dropdown.Menu>
          // </Dropdown>
          <DropdownButton drop="up" title="View" >
            {row.has_joined != 1 ? <CopyToClipboard text={(JSON.parse(localStorage.getItem("gs_user")).roleID == 5) ? BASE_URL + '#/becomejobseeker/' + row.referral_code : BASE_URL + '#/sponser/' + row.referral_code}>
              <Dropdown.Item>Copy</Dropdown.Item>
            </CopyToClipboard> : ''}
            {row.has_joined != 1 ? <Dropdown.Item onClick={() => { if (window.confirm('Delete the promo?')) { this.handaleDelete(row.referral_code) } }}>Delete</Dropdown.Item> : ''}
            <Dropdown.Item onClick={() => this.handlemodal(row.referral_code, row.gs_users_userID)}>View</Dropdown.Item>

            {
              row.has_joined != 1   && <Dropdown.Item >
              <span variant="danger" onClick={() => this.handleClose(row.referral_code, true,{name : row?.shared_to_name ?? false,email : row?.shared_to_email ?? false})}> <i className="fa fa-bell" aria-hidden="true"></i> Send Reminder</span>
            </Dropdown.Item>
            }
            
          </DropdownButton>








        )
      }
      ,
      // sortable: true
    },

  ];

  conditionalRowStyles = [
    {
      when: row => row.has_joined != 2,
      style: row => ({
        backgroundColor: row.has_joined == 1 ? '#ccc' : 'white',
        color: row.has_joined == 1 ? 'white' : 'black',
      }),



    }];

  handaleDelete(id) {
    let sta = this

    var formdata = new FormData();
    formdata.append("referral_code", id);

    let request = {
      end_point: '/delete-referral-code',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };




    POST_API(request).then((resp) => {

      if (resp.status == 'success') {


        sta.setState({ Msg: 'Deleted Successfully!', Msgtype: 'succcess' })

        setTimeout(() => {
          window.location.reload()

        }, 3000);





      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: resp.message.email.join(), MsgType: 'danger' })
      }

    }).catch(() => {

    })

  }

  handlePageChange = async (page) => {
    let endpoint = `/list-referral-code?offset=${page}&limit=${this.state.limit}`;
    //this.setState({ offset: page });
    console.log(page);
    if (this.state.has_joined != '') {
      endpoint = endpoint + `&has_joined=${this.state.has_joined}`
    }
    let request = {
      end_point: endpoint,
      formdata: {},
      token: localStorage.getItem("gs_token")
    };

    this.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {
      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        //this.setState({ totalRows: resp.row_count });
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };


  handlePerRowsChange = async (page) => {
    let endpoint = `/list-referral-code?offset=${this.state.offset}&limit=${page}`;
    //this.setState({ offset: page });
    console.log(page);
    if (this.state.has_joined != '') {
      endpoint = endpoint + `&has_joined=${this.state.has_joined}`
    }

    let request = {
      end_point: endpoint,
      formdata: {},
      token: localStorage.getItem("gs_token")
    };

    this.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {

      this.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        let FromData = resp.data;
        this.setState({ limit: page });
        //this.setState({ totalRows: resp.row_count });
        this.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      this.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  };

  fetchList = () => {
    let sta = this;
    let FromData = this.state.FromData

    let FromDataError = this.state.FromDataError
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }
    let endpoint = `/list-referral-code?offset=${this.state.offset}&limit=${this.state.limit}`;

    if (this.state.has_joined != '') {
      endpoint = endpoint + `&has_joined=${this.state.has_joined}`
    }

    let request = {
      end_point: endpoint,
      formdata: {},
      token: localStorage.getItem("gs_token")

    };


    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        FromData = resp.data
        sta.setState({ totalRows: resp.row_count });
        sta.setState({ FromData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });
  }


  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError



    //for redirectin message show
    this.fetchList()


    //help api
    let requestHelp = {
      end_point: '/help-details?key=redeem_code_list_help',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })



    console.log(localStorage.getItem("roleID"));

  }

  handleReedemClear() {
    this.setState({ reddemCode: "" })
    this.fetchList()
  }

  handleClose(refcode = '', remind = false,sendObj = false) {
    let t_key = 'sh_link'
    if (JSON.parse(localStorage.getItem("gs_user")).roleID == 16) {
      t_key = 'jobassist_sh_link'
      if (remind) {
        t_key = "jobassist_sh_link_remind"
      }
    }
    else {
      t_key = 'sh_link'
      if (remind) {
        t_key = "sh_link_remind"
      }
    }

    if (this.state.ShareModal) {
      this.setState({ ShareModal: false, reddemCode: refcode, t_key })
    }
    else {
      this.setState({ ShareModal: true, reddemCode: refcode, t_key }, () => {



      })
    }

    if(sendObj){
      this.setState({ shared_to_email: sendObj.email ?? false , shared_to_name : sendObj?.name ?? false });
    }

  }
  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.referralcodeSearch();
    });
  };

  referralcodeSearch = () => {
    let { searchInput, FromData } = this.state;
    let filteredData = FromData.filter((value) => {

      if (searchInput != null) {
        console.log("value", value)
        let Search =
          value?.referral_code?.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.jobseeker?.firstname?.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.jobseeker?.lastname?.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.employee?.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
          value?.jobseeker?.email?.toLowerCase().includes(searchInput.toLowerCase())

        return Search

      }
      // return true;
    });
    this.setState({ filteredData });

  };
  filterchange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.fetchList()
    })

  }
  genarateCode(e) {
    e.preventDefault();
    let sta = this;

    let request = {
      end_point: '/create-referral-code',
      formdata: {},
      token: localStorage.getItem("gs_token")

    };
    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {
        sta.setState({ Text: resp.message, TextType: 'text-success' })

        sta.setState({ reddemCode: resp.data.referral_code })

        setTimeout(() => {
          this.setState({ Msg: '' })

        }, 3000);
      }
      else {


        sta.setState({ Text: resp.message, TextType: 'text-danger' })
      }


    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off


      sta.setState({ Text: 'Something Went Wrong,Please try again later.', TextType: 'text-danger' })
      console.log('/codeGEN_ERR', ee)


    });


  }
  // click and code copy 
  handleCopy() {
    console.log("copytest finction")
  }

  codeModalClick = () => {
    if (this.state.codeModal) {
      this.setState({ codeModal: false })
    } else {
      this.setState({ codeModal: true })
    }
  }
  handleReedemClear() {
    this.setState({ reddemCode: "" })
    this.setState({ Text: '' })
  }


  handleClose_share() {
    this.setState({ codeModal: false })
    if (this.state.ShareModal) {
      this.setState({ ShareModal: false })
    }
    else {
      this.setState({ ShareModal: true }, () => {



      })
    }



  }

  render() {
    let FromData = this.state.FromData
    let filteredData = this.state.filteredData
    // console.log(".....FromData", FromData)
    // console.log(".....filteredData", this.state.filteredData)
    return (
      <div>
        {FromData != null ? (<>


          <Container fluid className="dash_container">
            <Row className='mt-3 mb-3'>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}

              {/* <Col md={9} className="d-flex">
                <div className="d-flex w-100 flex-column mr-3 gen_code_block">
                  <b className={"mb-2 tdiv w-100 " + this.state.TextType}>{this.state.Text}</b>
                  <div className="r_code test2">{this.state.reddemCode}</div>
                  <div>&nbsp;</div>
                </div>
                <div className='copy_Code_block d-flex flex-column'>
                  {(this.state.reddemCode != '') ? (<>
                    <b className="mb-2 tdiv w-100"></b>
                    <CopyToClipboard text={this.state.reddemCode}
                      onCopy={() => this.setState({ copied: true })}>
                      <Button className="btn btn-primary ghost_button" onClick={() => { this.handleCopy() }}>Copy to Clipboard</Button>
                    </CopyToClipboard>
                    <strong className='text-center'>{this.state.copied ? <span style={{ color: '#28a745' }}>Copied.</span> : null}</strong>
                  </>) : (<></>)}

                </div>
              </Col> */}
              <Col md={9}></Col>
              <Col md={3} className="flex-column d-flex mob_up">
                <b className='mb-2 tdiv w-100'></b>
                {/* <Button className="btn btn-primary ghost_button" onClick={this.codeModalClick}>Generate Code</Button> */}

              </Col>

            </Row>

            <Row>

              <Col md={12} className="pt-4 pb-4">



                {/* <Button className="btn btn-primary ghost_button" disabled={this.state.loader != 'hidden' ? true : false} onClick={this.genarateCode}>Generate Code</Button>
                      <div className="r_code test2">{this.state.reddemCode}</div>
                      <p className={this.state.TextType}>{this.state.Text}</p>
                      {(this.state.reddemCode != '') ? (<>
                        <CopyToClipboard text={this.state.reddemCode}
                            onCopy={() => this.setState({copied: true})}>
                            <Button className="btn btn-primary ghost_button" onClick={() => { this.handleCopy() }}>Copy to Clipboard</Button>
                        </CopyToClipboard>
                        <div>{this.state.copied ? <span style={{color: '#28a745'}}>Copied.</span> : null}</div>
                      </>) : (<></>)} */}

                {/* {this.state.reddemCode && (
                  <ShareLink {...this.props} code={this.state.reddemCode}
                    handleReedemClear={() => this.handleReedemClear()}
                    refcode={(JSON.parse(localStorage.getItem("gs_user")).roleID == 16) ?
                      `<a href='` + BASE_URL + '#/joboutplacement/' + this.state.reddemCode + "'>Click here</a>" :
                      `<a href='` + BASE_URL + '#/becomejobseeker/' + this.state.reddemCode + "'>Click here</a>"}

                    show={this.state.ShareModal} close={() => this.handleClose()} t_key={this.state.t_key} />
                )} */}
                <Loader loadmsg="please wait" visibility={this.state.loader} />
                <div className="tabpanelOneForm pt-3">
                  {(this.state.gs_userID != 0) ? (<>
                    <RedeemDashboardViewModal {...this.props} referral_code={this.state.referral} gs_userID={this.state.gs_userID} show={this.state.redeemDashModal} close={() => this.handlemodal()} />
                  </>) : (<></>)}  </div>
                <div className="tabpanelOneForm">
                  <Row>
                    <Col md={12}>
                      {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                    </Col>
                    <Col md={9}>   <h3>Sponsorship Codes</h3>
                      {/* <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.help
                    }}></p> */}
                      {localStorage.getItem("roleID") != undefined && localStorage.getItem("roleID") == 16 ?
                        (<>
                          {/* <p>Check out all the sponsorship codes and joining statuses of all the outplaced candidates listed in your Job Assist profile. You can also know the time and date of the codes redeemed.</p> */}
                          <p>Review the status of the sponsorship codes you have generated for your organization.</p>

                        </>) :
                        localStorage.getItem("roleID") != undefined && localStorage.getItem("roleID") == 7 ? (<><p>You can access here the sponsorship referral codes of the outplaced jobseekers along with names, email ids. You can also check their joining status and code generation time.</p></>) :
                          (<>
                            {/* <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.help
                              }}></p> */}
                          </>)
                        // (<></>)
                      }
                    </Col>
                    <Col md={3}>
                      <Button className="btn btn-primary" onClick={this.codeModalClick}>Generate Sponsor Code</Button>

                    </Col>
                  </Row>




                  <Row className="justify-content-between">
                    <Col md={6}>
                      <div className="mr-2 tabform mb-3 table_search" style={{ width: "150px" }}>
                        <select name="has_joined" onChange={this.filterchange} className="form-control">
                          <option value="">Filter Status</option>
                          <option value="1">Joined</option>
                          <option value="0">Not Joined</option>
                          <option value="">Both</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={6} className="d-flex justify-content-end">
                      <div className="table_search mb-3" style={{ float: "right", margin: '10px' }}>
                        Search : <input type='text' placeholder='Search Sponsorship Code' name="searchInput"
                          value={this.state.searchInput || ""} onChange={this.handleSearch} /></div>
                    </Col>

                  </Row>

                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}



                  <div className='table-responsive'>
                    <DataTable
                      title=""
                      columns={(JSON.parse(localStorage.getItem("gs_user")).roleID == 5) ? this.columns2 : this.columns}
                      data={filteredData == "" ? FromData : filteredData}
                      defaultSortField="title"
                      //conditionalRowStyles={this.conditionalRowStyles}
                      pagination={true}
                      responsive={false}
                      className="textCenter"
                      noHeader={true}
                      paginationServer
                      paginationTotalRows={this.state.totalRows}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                    />
                  </div>

                </div>



              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}

        {localStorage.getItem("roleID") != undefined && localStorage.getItem("roleID") == 16 ? (<>
          <ShareLink {...this.props} handleReedemClear={() => this.handleReedemClear()} code={this.state.reddemCode} shared_to_name={this.state.shared_to_name} shared_to_email={this.state.shared_to_email}  refcode={`<a href='` + BASE_URL + '#/joboutplacement/' + this.state.reddemCode + "'>Click here</a>"} show={this.state.ShareModal} close={() => this.handleClose_share()} t_key={'jobassist_sh_link'} />
        </>) : (<>
          <ShareLink {...this.props} handleReedemClear={() => this.handleReedemClear()} code={this.state.reddemCode} shared_to_name={this.state.shared_to_name} shared_to_email={this.state.shared_to_email}  refcode={`<a href='` + BASE_URL + '#/becomejobseeker/' + this.state.reddemCode + "'>Click here</a>"} show={this.state.ShareModal} close={() => this.handleClose_share()} t_key={'sh_link'} />
        </>)}


        <Modal
          show={this.state.codeModal}
          onHide={() => { this.codeModalClick() }}
          dialogClassName=""
          aria-labelledby="example-custom-modal-styling-title"
          className='jobseeker_modal'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Generate Sponsor Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                className="tabform">
                <Row className="m-0 mt-4 mb-4">
                  <Col>

                    <Form.Row>
                      <Form.Group controlId="formBasicEmail22" as={Col}>
                        <div className="upload_action row">
                          <div className="col pl-2">
                            <Button variant="primary" type="button" onClick={this.genarateCode} className="btn btn-primary tabformbutton btn-block">Generate Sponsor Code</Button>
                          </div>
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group controlId="formBasicEmail22" as={Col}>
                        <div className="r_code">{this.state.reddemCode}</div>
                        <p className={this.state.TextType}>{this.state.Text}</p>
                      </Form.Group>
                    </Form.Row>
                    {(this.state.reddemCode != '') ? (<>
                      <Form.Row>
                        <Form.Group controlId="formBasicEmail22" as={Col}>
                          <div className="upload_action row">
                            <div className="col pl-2">
                              <Button className="btn btn-primary ghost_button" onClick={() => { this.handleClose_share() }}>Share</Button>
                            </div>
                          </div>
                        </Form.Group>
                      </Form.Row>

                    </>) : (<></>)}
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal.Body>

        </Modal>









      </div>




    );
  }
}

export default RedemCode;