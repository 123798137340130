import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';

import DataTable from "react-data-table-component";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class MemberDashboardViewModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {        
                        referral_code:'',
                        firstname:'',
                        lastname:'',
                        email:'',
                        registered_at:'',
                        generated_at:'',
                        has_joined:'',
                        amount:'',
                        payment_date:'',
                        payment_status:'',
                        payment_from:'',
                        loader: "hidden",
                        stripPaymentLog:[], 
                        paypalPaymentLog:[],             
                        
                     };

    
        
        
            

    }


    getonepayment()
    {

        let payment_userID =this.props.payment_userID;
        console.log("payment details",);
        
   
        let request = {
            end_point : '/get-one-paymentDetails?payment_userID='+payment_userID,
            token: localStorage.getItem("gs_token")

        };

        this.setState({loader:'visible'})///loader on


            GET_API(request).then(resp=>{

                   
                           
                                console.log("payment modal",resp);
                                this.setState({amount:resp.amount.amount,payment_date:resp.payment_date,payment_status:resp.payment_status,payment_from:resp.payment_from,stripPaymentLog:resp.stripPaymentLog,paypalPaymentLog:resp.paypalPaymentLog})
                                    this.setState({loader:'hidden'})
                            
                    }).catch((ee)=>{

                        console.log('/member view list ERR',ee)
                    });


           
        

    }


  
 
 
  render() {
    let  ContactList = this.state.ContactList
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getonepayment()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         size="md"
         >
        
          <Modal.Header closeButton>
            <Modal.Title>Sponsor Details</Modal.Title>
            {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
          </Modal.Header>
          <Modal.Body>
 <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />
<Row className="m-0">
          
            <Col md={6}>
             <strong> 
             Payment Date : 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.payment_date}
                </p>
            </Col>
          
          </Row>
       
          <Row className="m-0">
          <Col>
           <strong> 
           Amount : 
            </strong> 
          </Col>
          <Col>
              <p className="textwrap">
                   ${this.state.amount} 
              </p>
          </Col>
        </Row>

        <Row className="m-0">
          <Col md={6}>
           <strong> 
           Payment Status : 
            </strong> 
          </Col>
          <Col md={6}>
              <p className="textwrap">
                    {this.state.payment_status == 1 ?(<span className="badge badge-success">Success</span>) : (<span className="badge badge-danger">Failed</span>)}
              </p>
          </Col>
        </Row>
        <Row className="m-0">
          {/* <Col md={6}>
           <strong> 
           Payment From : 
            </strong> 
          </Col>
          <Col md={6}>
              <p className="textwrap">
                    {this.state.payment_from}
              </p>
          </Col> */}
          {this.state.payment_from == 'stripe' &&(
            <>
          <Col md={6}>
             <strong> 
             Payment Method Type : 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.stripPaymentLog.method_type}
                </p>
            </Col>
            <Col md={6}>
             <strong> 
             Payment Card : 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.stripPaymentLog.card_number}
                </p>
            </Col>
            <Col md={6}>
             <strong> 
             Payment Card Name: 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.stripPaymentLog.biling_card_name}
                </p>
            </Col>
            <Col md={6}>
             <strong> 
             Transaction ID: 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.stripPaymentLog.balance_transaction}
                </p>
            </Col>
            </>)}

            {this.state.payment_from == 'paypal' &&(
            <>
          <Col md={6}>
             <strong> 
             Payment Method Type : 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.paypalPaymentLog.payment_email}
                </p>
            </Col>
            <Col md={6}>
             <strong> 
             Payment Name : 
              </strong> 
            </Col>
            <Col md={6}>
                <p className="textwrap">
                    {this.state.paypalPaymentLog.payment_name}
                </p>
            </Col>
            </>)}

        </Row>
        
          
       

    

            </Modal.Body>
          <Modal.Footer>
         
            
          </Modal.Footer>

         

        </Modal>
      </div>
    );
  }
}
