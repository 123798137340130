import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import './css/login.css';
import Loginrightimage from '../images/login_right_panel.png';
import {API_URL,CaptchaKey} from '../Config';
import validation from '../Validate/validator';
import L from 'react-loader-spinner'
import  TokenVerify from "../Helpers/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";

// import service images
import logo1 from "../images/resume_scoring_blue.png"; // RS
import logo2 from "../images/Resume_Certified.png"; // RC
import logo3 from "../images/JAlerts.png"; // JA
import logo4 from "../images/Resume_Sending.png"; // RSD
import logo5 from "../images/hri_direct_blue@9x.png"; // HRC
import logo6 from "../images/career_master.png"; // CRM
import logo7 from "../images/career_book.png"; // CB
import logo8 from "../images/recruiter_direct.png"; // RL
import logo9 from "../images/coach_master.png"; // CM
import logo14 from "../images/logo14.png"; // JAS
import logo11 from "../images/social_profile_scoring_blue.png"; // SPV
import logo13 from "../images/jobseeker_horizontal9x.png"; // JNH
import logo10 from "../images/getback_work.png"; // GBW

// import EOR from "../images/EOR-Main-logo.png"; //EOR
//import GWIAF from "../images/GWI_Affiliates_LOGO_dark.png"; //GWIAF

import arrower from "../images/arrower.png"




import logo_1 from "../images/logo1.png";
import logo_2 from "../images/logo2.png";
import logo_3 from "../images/logo3.png";
import logo_4 from "../images/logo4.png";
import logo_5 from "../images/logo5.png";
import logo_6 from "../images/logo6.png";
import logo_7 from "../images/logo7.png";
import logo_8 from "../images/logo8.png";
import logo_9 from "../images/logo9.png";
import logo_10 from "../images/logo10.png";
import logo_11 from "../images/logo11.png";
import logo_12 from "../images/logo12.png";


import EOR from "../images/EOR-Main-logo.png"; //EOR
import GWIAF from "../images/GWI_Affiliates_LOGO.png"; //GWIAF
import GWI from "../images/global-logov2.png"; //GWI
import Jobas from "../images/Job-Assist-logo.png"; // JA




//
import  { FlashMess,GET_API,POST_API,Loader} from "../Helpers/Helpers";
import { wait } from '@testing-library/react';


const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );






class Login extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                        FromData: {
                                        email : '',
                                        password : '',
                                        recapcha:""
                                    },
                        FromDataError :
                                        {
                                            email : 'false',
                                            password : 'false',
                                            recapcha:"false"
                                        },
                        isValid : false,
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        inputType:'password',
                        currentUrl: "",
                        pagesource: "",
                        servicetype: ""

                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        this.toggleEye=this.toggleEye.bind(this)
        

    }


//     validateForm  () {

     
//        let valid = true;
//        let sta = this;
//        let {FromDataError,FromData} = this.state;
      
//        let input_list = document.getElementsByTagName('input');
//        let ll = input_list.length
//        let i =1;

      
       
      

      
//    for (const [key,value] of Object.entries(input_list))
//       {

//         let name = value.getAttribute('name');
//         let rule = value.getAttribute('validaterule');
//         let validateMsg = value.getAttribute("validatemsg");

        

//           var valid_obj = {
//             value:FromData[name],
//             rules:rule,
//             message:validateMsg
//           }


          
//         validation(valid_obj).then((error)=>{

//           if(error != '')
//           {
//             valid = false
            
//           }

//           FromDataError[name] = error
//           this.setState({FromDataError})
          

//             if(i==ll)
//           {
//             alert(valid)
//             return valid;
//           }
//         i++

//         }) 

        

        
//       }

     

     
// };

      validateForm  (errors) {
            let valid = true;
            let sta = this;
            let FromDataError = this.state.FromDataError;

            for (const [key, value] of Object.entries(errors)) {
              if(value.length > 0 )
              {

                if(value=='false')
                {
                  FromDataError[key] = 'This field is required';
                  sta.setState({FromDataError})
                }
                
                valid = false;
              }
            }
          
            return valid;
    };
  

    

    handaleChange(data)
    {
        let sta = this;
        let FromDataError = this.state.FromDataError;
        let isValid = this.state.isValid;

        let name = data.target.name;
        let value = data.target.value;
        let FromData = this.state.FromData;
        FromData[name] = value;

        

        var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }


      
        validation(valid_obj).then((error)=>{

         

          FromDataError[name] = error
          this.setState({FromDataError})
          this.setState({isValid})

          this.setState({FromData})
        })
         

          //setTimeout(function(){ console.log(sta.state) }, 100);
        
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
        let formBody = [];


        

        if(this.validateForm(FromDataError))
        {

          sta.setState({loader:'visible'})///loader on

            var formdata = new FormData();
                formdata.append("email", FromData.email);
                formdata.append("password", FromData.password);


                let request = {
                                end_point : '/login',
                                formdata:formdata

                              };

                              console.log('......request',formdata)

                
                

                  POST_API(request).then(async resp=>{
                    //return false;
                    sta.setState({loader:'hidden'})///loader off

                      //console.log(resp) 
                          if(resp.status == 'success')
                          {
                              localStorage.setItem('gs_token',resp.user.token)
                              localStorage.setItem('roleID',resp.user.roleID)
                              
                              if(resp.user.resume_score != null){
                                localStorage.setItem('resumeScore',resp.user.resume_score)
                              }    
                             
                              localStorage.setItem('gs_user',JSON.stringify(resp.user))
                            
          
                              if(localStorage.getItem("gs_token") != null)
                              {
                                localStorage.setItem('profile_image',resp.image)
                                  if(resp.user.roleID == 3)
                                  {
                                    localStorage.setItem('dashboardLink','#/employe-dash')
                                    TokenVerify()
                                      this.props.history.push('/employe-dash')

                                  }
                                  else if(resp.user.roleID == 4)
                                  {
                                    localStorage.setItem('dashboardLink','#/jobseeker-dash')
                                   await TokenVerify()

                                    localStorage.setItem('gs_auth_code',resp.user.auth_code)
                                    localStorage.setItem('roleID',resp.user.roleID)
                                    localStorage.setItem('get_category',resp.user.job_category)
                                    localStorage.setItem('profile_image',resp.image)
                                      this.props.history.push('/jobseeker-dash')
                                  }
                                  else if(resp.user.roleID == 8 || resp.user.roleID == 9 || resp.user.roleID == 10)
                                  {
                                    localStorage.setItem('dashboardLink','#/affiliate-dash')
                                    TokenVerify()

                                    localStorage.setItem('gs_auth_code',resp.user.auth_code)
                                      this.props.history.push('/affiliate-dash')
                                  }else if(resp.user.roleID == 11 || resp.user.roleID == 12)
                                  {
                                    localStorage.setItem('dashboardLink','#/affiliate-member-dash')
                                    TokenVerify()

                                    localStorage.setItem('gs_auth_code',resp.user.auth_code)
                                      this.props.history.push('/affiliate-member-dash')
                                  }
                                  else{
                                    localStorage.setItem('dashboardLink','#/sponser-dash')
                                    TokenVerify()
                                      this.props.history.push('/sponser-dash')
                                  }
                                  
                                  
                              }
                              
                          }
                          else{
                              
                              if(resp.message !=undefined)
                              {
                                
                                sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
                                  sta.setState({MsgType:'danger'})
                              }else
                              {
                                sta.setState({Msg:resp.messages,MsgType:'danger'})
                                  sta.setState({MsgType:'danger'})
                              }
                                  
                          
                            
          
                          }
                  

                }).catch((ee)=>{

                  console.log('/loginERR',ee)


                });

                
              

                       
                




        }else
        {
            sta.setState({Msg:'Enter Email ID and Password'})
            sta.setState({MsgType:'danger'})
        }

       
    }


    componentDidMount()
    {
     

      
      let sta = this;
      if(sta.props.location.state!=undefined)
      {
        sta.setState({Msg:sta.props.location.state.Msg})
        sta.setState({MsgType:sta.props.location.state.MsgType})
      }

        try {
            if(localStorage.getItem("gs_token") != null && localStorage.getItem("gs_user") != null && localStorage.getItem("gs_user") != undefined)
            {
               let user = JSON.parse(localStorage.getItem("gs_user"))

                
                if(user.roleID == 4)
                {
                   this.props.history.push('/jobseeker-dash')
                }
                else if(user.roleID == 8 || user.roleID == 9 || user.roleID == 10)
                {
                    this.props.history.push('/affiliate-dash')
                }
               
                else if(user.roleID == 3)
                {
                  this.props.history.push('/employe-dash')
                }
                else if(user.roleID == 11 || user.roleID == 12)
               {
                this.props.history.push('/affiliate-member-dash')
               }
                else 
                {
                   this.props.history.push('/sponser-dash')
                }
                
            }
           
        } catch (error) {
            
        }
        const { match, location, history } = this.props;
        // console.log("location",location)
        this.setState({currentUrl: location.search})

        const queryParams = new URLSearchParams(location.search)
        this.setState({pagesource: queryParams.get("pagesource")? queryParams.get("pagesource") : ""})
        this.setState({servicetype: queryParams.get("servicetype")? queryParams.get("servicetype") : ""})
        if(queryParams.get("pagesource") && queryParams.get("servicetype"))
        {
          localStorage.setItem('pagesource',queryParams.get("pagesource"))
          localStorage.setItem('servicetype',queryParams.get("servicetype"))
        }
        else{
          localStorage.removeItem('pagesource')
          localStorage.removeItem('servicetype')
        }
        
        console.log(queryParams.get("pagesource"))


    }

     toggleEye()
     {
      var temp = document.getElementById("password");
            if (temp.type === "password") {
                this.setState({inputType:'text'});
               
            }
            else 
            {
              this.setState({inputType:'password'});
                
            }
     }

// pagesourceWiseImg () {

//     switch (this.state.pagesource) {
//         case "RS": 
//               return logo1;break;
//         case "RC": 
//               return logo2;break;
//         case "RSD": 
//               return logo4;break;
//         case "JA": 
//               return logo3;break;
//         case "SPV": 
//               return logo11;break;
//         case "CM": 
//               return logo9;break;
//         case "CRM": 
//               return logo6;break;
//         case "CB": 
//               return logo7;break;
//         case "RL": 
//               return logo8;break;
//         case "HRC": 
//               return logo5;break;
//         case "JNH": 
//               return logo13;break;
//         case "JAS":
//               return logo14;break;    
//         case "EOR":
//               return EOR;break;
//         case "GWIAF":
//                 return GWIAF;break; 
//         default:
//             break;
//     }
// }

capchaChange=(value)=>{
  console.log("Captcha value:", value);
  let { FromData ,FromDataError} = this.state;
  FromData.recapcha=value
  FromDataError.recapcha=""
  this.setState({FromData,FromDataError},()=>{
    this.validateForm(FromDataError)
  })

 
}


    render() {
      const eye = <FontAwesomeIcon icon={faEye} />;
      const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
        return (

            
 <>          



<div className='login_page'>
    <div className='login-top-bar'>
        <Container fluid>
          <Row className='justify-content-around'>
              <div className='top_logos'>
                <img src={EOR}/>
                <div className='tooltips'>For the recruiter to access their unique referral link, view referral activity and commission reporting</div>
              </div>
              <div className='top_logos'>
                <img src={GWIAF}/>
                <div className='tooltips'>For the affiliate to access thei unique referral link, view refer al activity and commission reporting</div>
                </div>
              <div className='top_logos'>
                <img src={logo_12}/>
                <div className='tooltips'>For the jobseeker to access all the services listed below to help them Get Back To Work</div>
              </div>
              <div className='top_logos'>
                <img src={GWI}/>
                <div className='tooltips'>For the sponsor to generate sponsor codes and access sponsored jobseeker activity</div>
              </div>
              <div className='top_logos'>
                <img src={Jobas}/>
                <div className='tooltips'>For a company's HR department to generate and send sponsor codes and access sponsored employee activity</div>
              </div>
          </Row>
        </Container>
    </div>

    <Container>
        <Row className="justify-content-center ml-0 mr-0">
          <div className="main d-flex">

              <div className="container-login a-container" id="a-container">
            
                  
                  <Form onSubmit={this.handaleSubmit} className="form" id="a-form" >
                  {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                    <Loader loadmsg="please wait" visibility={this.state.loader}/>
                    {/* <span className='d-flex justify-content-center align-items-center'>
                      <img src={this.pagesourceWiseImg()} className="fix_logo_align" />
                      {this.state.pagesource && this.state.pagesource !== "GBW" && (<img src={arrower} className="arrow_fix mx-4"/>)}
                      <img src={logo10} className="fix_logo_align" />
                    </span> */}


                  {/* <p className="switch__description description">For the jobseeker to access all the services <br/>listed below to help them Get Back To Work</p> */}

                  <h2 className="form_title title_form">Login</h2>
                  


                          <Form.Group controlId="formBasicEmail" className='mb-0'>
                            <Form.Control  
                            type="text"
                                className="form__input"
                                    name="email"
                                    onChange={this.handaleChange}
                                    placeholder="Email" 
                                    validaterule={['required','isEmail']}
                                    validatemsg={['This field is required','input a Valid Email']}
                                    />
                        {/* <p style={{ color: 'red' }}>{this.state.FromDataError.email=='false'? '':this.state.FromDataError.email}</p> */}
                          </Form.Group>

                          <Form.Group controlId="formBasicPassword" className='mb-0'>
                            <Form.Control  
                                    type={this.state.inputType}
                                    className="form__input"
                                    id="password"
                                    name="password"
                                    onChange={this.handaleChange}
                                    placeholder="Password" 
                                    validaterule={['required','password']}
                                    validatemsg={['This field is required','password should be 6 charecter']}

                                    />
                                <i  style={{  float: 'right', marginTop: '-38px', marginRight:'8px', position: 'relative', zIndex: 1, cursor:'pointer'}} onClick={this.toggleEye}>{this.state.inputType=='text'?eyeSlash:eye}</i>     
                        {/* <p style={{ color: 'red' }}>{this.state.FromDataError.password=='false'?'': this.state.FromDataError.password}</p> */}
                          </Form.Group>
                          <Form.Row>
                          <Form.Group controlId="formBasicEmail" as={Col} className='mt-2'>
                            <ReCAPTCHA
                              sitekey={CaptchaKey}
                              onChange={this.capchaChange}
                            />
                          
                            
                              <p style={{ color: "red" }}>
                                  {this.state.FromDataError.recapcha == "false"
                                    ? ""
                                    : this.state.FromDataError.recapcha}
                                </p>
                        
                      
                            </Form.Group>
                      
                            </Form.Row>

                          <Row>
                            <Col md={12} className="d-flex keep_login_forgot_pass">
                              {/* <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Keep me logged in" />                    
                              </Form.Group> */}
                              <a href="#/forget-password" className="ml-auto forgotPass">Forgot password</a>
                            </Col>
                          </Row>

                          <Form.Group controlId="formBasicPassword" className='mb-0'>
                            <Button type="submit" className="btn btn-main btn-block login_button">
                              Log in
                            </Button>
                          </Form.Group>

                  </Form>

              </div>

           </div>
        </Row>

    </Container>

    <div className='flogos'>
    <Container fluid>
        <Row>
          <div className='items'>
            <img src={logo_1}/>
          </div>
          <div className='items'>
            <img src={logo_2}/>
          </div>
          <div className='items'>
            <img src={logo_3}/>
          </div>
          <div className='items'>
            <img src={logo_4}/>
          </div>
          <div className='items'>
            <img src={logo_5}/>
          </div>
          <div className='items'>
            <img src={logo_6}/>
          </div>
          <div className='items'>
            <img src={logo_7}/>
          </div>
          <div className='items'>
            <img src={logo_8}/>
          </div>
          <div className='items'>
            <img src={logo_9}/>
          </div>
          <div className='items'>
            <img src={logo_10}/>
          </div>
          <div className='items'>
            <img src={logo_11}/>
          </div>
          <div className='items'>
          <img src={logo_12}/>
          </div>
        </Row>
    </Container>
    </div>
</div>


</>
        );
    }
}

export default withRouter(Login);