import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';

import DataTable from "react-data-table-component";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class MemberDashboardViewModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {        
                        referral_code:'',
                        firstname:'',
                        lastname:'',
                        email:'',
                        profileImage:'',
                        registered_at:'',
                        middlelayer_id:'',
                        loader: "hidden",
                        select_all_web_tab:{}              
                        
                     };

    
        
        
            

    }


    getoneRecord()
    {

        let member_id =this.props.member_id;
        
   
        let request = {
            end_point : '/get-one-jobseeker?userID='+member_id,
            token: localStorage.getItem("gs_token")

        };

        this.setState({loader:'visible'})///loader on


            GET_API(request).then(resp=>{

                   
                            if(resp.status == 'success')
                            {
                                console.log("modal",resp);
                                this.setState({referral_code:resp.redeem_code,profileImage:resp.profileImage,firstname:resp.firstname,lastname:resp.lastname,email:resp.email,registered_at:resp.registered_at,middlelayer_id:resp.middlelayer_id
                                  ,select_all_web_tab:resp.select_all_web_tab})
                                   this.setState({loader:'hidden'}) 
                               }
                                else{
                                    
                                    
                                        
                                }
                    }).catch((ee)=>{

                        console.log('/member view list ERR',ee)
                    });


           
        

    }


  
 
 
  render() {
    let  ContactList = this.state.ContactList
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         size="lg"
         centered
         >
        
          <Modal.Header closeButton>
            <Modal.Title>Jobseeker Details</Modal.Title>
            {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
          </Modal.Header>
          <Modal.Body>
    <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />
        <Row className="m-0">
          
          <Col md={3}>
           <img src={this.state.profileImage} className="img-fluid"/> 
          
          </Col>
         
        
        </Row>
          <Row className="m-0">
          
            <Col md={4}>
             <strong> 
             Name : 
              </strong> 
            </Col>
            <Col md={8}>
                <p className="textwrap">
                    {(this.state.firstname)+" "+(this.state.lastname)}
                </p>
            </Col>
          
          </Row>
          <Row className="m-0">
          <Col  md={4}>
           <strong> 
           Email : 
            </strong> 
          </Col>
          <Col  md={8}>
              <p className="textwrap">
                    {this.state.email} 
              </p>
          </Col>
        </Row>

        <Row className="m-0">
          <Col md={4}>
           <strong> 
           Referral code : 
            </strong> 
          </Col>
          <Col md={8}>
              <p className="textwrap">
                    {this.state.referral_code}
              </p>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={4}>
           <strong> 
           Register at : 
            </strong> 
          </Col>
          <Col md={8}>
              <p className="textwrap">
                    {this.state.registered_at}
              </p>
          </Col>
        </Row>
        {this.state.middlelayer_id !="" && this.state.middlelayer_id !=null?
        (<Row className="m-0">
          <Col md={4}>
           <strong> 
           Site registered : 
            </strong> 
          </Col>
          <Col md={8}>
              <div className="sponsor_list_inline">
                  {/* <span className="badge badge-success mr-2">Resume Sending</span>
                  <span className="badge badge-success mr-2">Resume Certified</span> */}
                      {this.state.select_all_web_tab?.resume_sending=='1'?
                        <span className="badge badge-success mr-2">Resume Sending</span>:
                        <span className="badge badge-secondary mr-2">Resume Sending</span>
                      }
                      {this.state.select_all_web_tab?.resume_certified=='1'?
                        <span className="badge badge-success mr-2">Resume Certified</span>:
                        <span className="badge badge-secondary mr-2">Resume Certified</span>
                      }
                      {this.state.select_all_web_tab?.resume_scoring=='1'?
                        <span className="badge badge-success mr-2">Resume Scoring</span>:
                        <span className="badge badge-secondary mr-2">Resume Scoring</span>
                      }
                      {this.state.select_all_web_tab?.job_alerts=='1'?
                        <span className="badge badge-success mr-2">Job Alerts</span>:
                        <span className="badge badge-secondary mr-2">Job Alerts</span>
                      }
                      {this.state.select_all_web_tab?.hr_contacts=='1'?
                        <span className="badge badge-success mr-2">HR Direct</span>:
                        <span className="badge badge-secondary mr-2">HR Direct</span>
                      }
                      {this.state.select_all_web_tab?.lifeguru_coach=='1'?
                        <span className="badge badge-success mr-2">Coach Master</span>:
                        <span className="badge badge-secondary mr-2">Coach Master</span>
                      }
                      {this.state.select_all_web_tab?.free_ebooks=='1'?
                        <span className="badge badge-success mr-2">Career Books</span>:
                        <span className="badge badge-secondary mr-2">Career Books</span>
                      }
                      {this.state.select_all_web_tab?.social_profile_view=='1'?
                        <span className="badge badge-success mr-2">Social Profile Scoring</span>:
                        <span className="badge badge-secondary mr-2">Social Profile Scoring</span>
                      }
                      {this.state.select_all_web_tab?.reqruiter_list=='1'?
                        <span className="badge badge-success mr-2">Recruiter Direct</span>:
                        <span className="badge badge-secondary mr-2">Recruiter Direct</span>
                      }
                      {this.state.select_all_web_tab?.career_master=='1'?
                        <span className="badge badge-success mr-2">Career Master</span>:
                        <span className="badge badge-secondary mr-2">Career Master</span>
                      }
                      {this.state.select_all_web_tab?.job_seeker_news=='1'?
                        <span className="badge badge-success mr-2">Jobseeker News Hub</span>:
                        <span className="badge badge-secondary mr-2">Jobseeker News Hub</span>
                      }
                      {this.state.select_all_web_tab?.job_assist=='1'?
                        <span className="badge badge-success mr-2">Job Assist</span>:
                        <span className="badge badge-secondary mr-2">Job Assist</span>
                      }
                      <span className="badge badge-success mr-2">RESUME WRITER</span>
                      <span className="badge badge-success mr-2">COVER LETTER WRITER</span>
                      <span className="badge badge-success mr-2">EMAIL WRITER</span>
                      <span className="badge badge-success mr-2">RESUME TEMPLATES</span>
    
              </div>
                <div className='box_container'>
                        <div className='left_box'></div><p className="text_p">Inactive</p>
                        <div className='right_box'></div><p className="text_p">Active</p>
                   </div>
          </Col>
        </Row>):(<p>&nbsp;</p>)
        }
          
       

    

            </Modal.Body>
         

         

        </Modal>
      </div>
    );
  }
}
