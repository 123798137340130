import React, {useRef, useState, useEffect } from 'react';
import { Form, Button, Col, Row, Table,Card,Modal } from 'react-bootstrap';
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import "react-credit-cards/es/styles-compiled.css";
import { Elements,useStripe, useElements,CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from '../../Config';

import logo_1 from "../../images/logo1.png";
import logo_2 from "../../images/logo2.png";
import logo_3 from "../../images/logo3.png";
import logo_4 from "../../images/logo4.png";
import logo_5 from "../../images/logo5.png";
import logo_6 from "../../images/logo6.png";
import logo_7 from "../../images/logo7.png";
import logo_8 from "../../images/logo8.png";
import logo_9 from "../../images/logo9.png";
import logo_10 from "../../images/logo10.png";
import logo_11 from "../../images/logo11.png";
import logo_12 from "../../images/logo12.png";

const promise = loadStripe(STRIPE_KEY);


function CheckoutForm(props) {
  
  const [disabled, setDisabled] = useState(true);
  const [Msg, setMsg] = useState("");
  const [MsgType, setMsgType] = useState("");
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setDisabled(true);
    setMsg("Hold on we are processing your request");
    setMsgType('info');

    stripe.createToken(elements.getElement(CardElement)).then((result) => {
      console.log("result",result);
      if(result.error){
        setDisabled(false);
        setMsg(result.error.message);
        setMsgType("danger");
        return;
      }
      if(result.token){
        var formdata = new FormData();
        formdata.append("amount", props.Amount);
        formdata.append("duration", props.Duration);
        formdata.append("package_id", props.PackageId);
        formdata.append("stripe_token", JSON.stringify(result.token));
        formdata.append("description", "Jobseeker Payment for Renew package by new Credit card");
        
        let request = {
          end_point: '/renew-subscription',
          formdata: formdata,
          token: localStorage.getItem("gs_token"),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
        };

        POST_API(request)
          .then((resp) => {
            setDisabled(false);
            if (resp.status == 'success') {
              setMsg(resp.message);
              setMsgType('success');
              setTimeout(() => {
                window.location.href="/jobseeker-dash"
              }, 2000);
            } else {
              setMsg(resp.message);
              setMsgType('danger');
            }
          })
          .catch((ee) => {
            setDisabled(false);
            setMsg("Something Went Wrong, Please try again later.");
            setMsgType('danger');
            console.log('/paymentERR', ee);
          });
      }
    });
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
    {Msg !== '' ? (<><FlashMess m={Msg} mtype={MsgType ? MsgType : 'success'} /></>) : (<></>)}
      <Form.Group controlId="formBasicCardNo" className="pr-0" as={Col}>
        <Form.Label>Amount Payable: (<strong>${props.Amount}</strong>)</Form.Label>
        <CardElement
          className="col-md-8"
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
      </Form.Group>
    <Row>
      <Col md={8}>
      <Row>
      <Col md={6}>
        <Button variant="primary" onClick={() => { window.location.reload() }} className="btn-main tabformbutton mr-2">Cancel</Button>
      </Col>
      <Col md={6}>
          <Button variant="primary" disabled={disabled} style={{ cursor: disabled ? 'none' : 'inherit' }} type="submit" className="btn-main tabformbutton">Pay</Button>
      </Col>
      </Row>
      </Col>
      </Row>
    </form>
    </>
  );
}

const RenewSubscription = (props) => {
  const ref = useRef(null);
  const [Description,setDescription] = useState("");
  const [modal,setModal] = useState(false);
  const [message, setMessage] = useState({});
  const [currentPackage, setCurrentPackage] = useState({});
  const [Duration,setDuration] = useState(0);
  const [PackageId,setPackageId] = useState(0);
  const [Amount,setAmount] = useState(0);
  const [Services,setServices] = useState([]);
  const getLogo = (id)=>{
    switch(id){
      case 1:
        return logo_6;
    
    case 2:
      return logo_1;
  case 3:
    return logo_2;
break;
case 4:
    return logo_5;
break;
case 5:
    return logo_7;
break;
case 6:
    return logo_3;
break;
case 7:
    return logo_4;
break;
case 8:
    return logo_8;
break;
case 9:
    return logo_10;
break;
case 10:
    return logo_11;
break;
case 11:
    return logo_9;
break;
case 13:
    return logo_12;
break;


    }
  }
  const [state, setState] = useState({
    key: "strip",
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    amount: '',
    Msg: '',
    MsgType: '',
    loader: 'hidden',
    show: '',
    save_card: '',
    FromData: {
        cvc: '',
        expiry: '',
        name: "",
        number: "",
        amount: '',
    },
    card_details: [],
    card_id_attr: '',
    button_text: 'Show My Cards',
    card_exits: false,
    FromDataError: {
      cvc: 'false',
      expiry: 'false',
      name: "false",
      number: "false",
      amount: '',
    },
    paydisabled: false,
    packageName: {}
  });

  

  const getAllServices = () => {
    setState({ ...state, loader: '' });
    let requestCard = {
      end_point: '/list-sites',
      token: localStorage.getItem("gs_token"),
    };
    GET_API(requestCard)
      .then((resp) => {
        setState({ ...state, loader: 'hidden' }); ///loader off
        if (resp.data.length > 0) {
          console.log("ajay",resp.data);
         setServices(resp.data);
        }
      })
      .catch(() => {
        setState({ ...state, loader: 'hidden' }); ///loader off
      });
  };

  const scrollToPayment = () => {
    const lastChildElement = ref.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {

    let subscription = JSON.parse(
        localStorage.getItem("mysubscriptions")
      )
      if(!subscription) return

      subscription = subscription[0];
      if(subscription){
        setAmount(subscription.pack.offerPrice);
        setPackageId(subscription.package_id);
        setDuration(subscription.pack.duration);

        setCurrentPackage(subscription.pack);
        // console.log("ajaaaa",subscription.pack)
      }
    
    
      
    getAllServices();
    // savedCardDetails();
  }, []);


  return (
    <div id="PaymentForm" className='mt-5'>

<Modal
					show={modal}
					onHide={()=>setModal(false)}
					dialogClassName=""
					aria-labelledby="example-custom-modal-styling-title"
					className='jobseeker_modal tabform'
					size='md'
					centered
				>

					<Modal.Header closeButton>
						<Modal.Title>Package Description</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{Description}
						</div>
					</Modal.Body>

				</Modal>

      <h3>Renew Subscription</h3>
      <p style={{ fontSize: "10px", margin: "0" }}>We use stripe for payments</p>
      {currentPackage.package_name  ?
      <ul>
        <li>Current Package:</li>
        <li>Package Name : <b>{currentPackage.package_name}</b></li>
        <li>Price : <b>${currentPackage.offerPrice}</b></li>
        <li>Duration : <b>{currentPackage.duration} Months</b></li>
      </ul>
      :
      <><h4 className='text-danger'>Your current status indicates no active subscription.</h4><br /></>
      }
      <div style={{ minHeight: "250px" }}>
        <Loader loadmsg="please wait" visibility={state.loader} />

        <Row>
        {
        Services.length > 0 && Services.map((item,key)=>{
          return <Col md="4"  key={key}><Card className='p-3 body_card mb-3' >
              <div className='text-center'><img style={{"width":"120px","height":"80px"}} src={getLogo(item.id)}/></div>
              <p><b>{item.package_name}</b></p>
              <p>Price: <strong> ${item.offerPrice}</strong></p>
              <p>Duration : <strong> {item.duration} Months</strong></p>
              
              <p className='text-center'><button className="btn btn-primary mr-3" onClick={()=>{
               setDescription(item.description)
               setModal(true);
              }}>View</button>

              <button className="btn btn-primary" onClick={()=>{
                setAmount(item.offerPrice);
                setPackageId(item.package_id);
                setDuration(item.duration);
                scrollToPayment()
              }}>Buy</button>
              </p>
            </Card></Col>
        })}
        </Row>
        <div className="tabpanelOneForm" style={{ lineHeight: '33px' }}>
          <p ref={ref} className="text-center" id="paymentDescription" style={{ color: '#108193' }}><b>You are about to make a payment of ${Amount} for Renew the Subscription.</b></p>
          <Elements stripe={promise}>
              <CheckoutForm Amount={Amount} Duration={Duration} PackageId={PackageId}/>
          </Elements>
        </div>

        
      </div>
      <br /><br /><br />
    </div>
  );
};

export default RenewSubscription;