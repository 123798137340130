import React, { useEffect, useState } from 'react'
import { Card,Container, Row, Col, Pagination } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import moment from "moment";

const JobseekerKnowledgebase = (props) => {

    const [records, setRecords] = useState([]);
    const [recordsCount, setRecordsCount] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [isCategoryChanged, setIsCategoryChanged] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [category, setCategories] = useState([]);
    const [url, setURL] = useState("/knowledgebase-videos");
    const [loading, setLoading] = useState(false);
    const [categoryId, setCategoryId] = useState(0);
    
    useEffect(() => {
        setLoading(true)
            if(isFirst){
                GET_API({
                    end_point: `/knowledgebase-category`,
                    token: localStorage.getItem("gs_token")
                }).then((resp) => {
                    setLoading(false)
                    if (resp.status == 200) {
                        setCategories(resp.data);
                        setIsFirst(false);
                    }else{
                        console.log(resp.message);
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    console.log("creditTransactions", err)
                })
            }
            let newURL = `${url}?page=${pageNo}&category_id=${categoryId}`;

            if(isCategoryChanged){
              newURL = `${url}?page=1&category_id=${categoryId}`;
            }

            GET_API({
                end_point: newURL,
                token: localStorage.getItem("gs_token"),
                
            }).then((resp) => {
                setLoading(false)
                
                if (resp.status == 200) {
                    setRecords(resp.data.data);
                    console.log("resp.data",resp.data);
                    setRecordsCount(resp.data.total);
                    setIsCategoryChanged(false);
                }else{
                    console.log(resp.message);
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("creditTransactions", err)
            })

    }, [categoryId,pageNo])

    const handlePageChange = (pageNumber) => {
      setPageNo(pageNumber)
    };

    return (
    <div className='pt-5 pb-5 data_table_gwi'>
         <Container fluid className="dash_container text-center">

          <Row>
              <Col md={12}>
              <Loader loadmsg="please wait" visibility={loading ? "visible" : "hidden"} />
                  
              </Col>
                <Col md={6}>
                <h2 className='jobseeker_heading_hr'> Knowledge base Videos ({recordsCount})</h2>  
                </Col>
                <Col md={2}></Col>
                <Col md={4}>
                    <div className='mb-2 mt-2'>
                        <select className="form-control" defaultvalue={categoryId} onChange={(e)=>{
                            setIsCategoryChanged(true);
                            setCategoryId(e.target.value);
                        }} >
                            <option value ="0"> All </option>
                            {category.length > 0 && 
                            category.map((item,i)=>{
                               return <option value={item.id} key={i}> {item.name}</option>
                            }) 
                            }
                        </select>
                    </div>
               </Col>
            </Row>

                <Row>

                 {records.length > 0 && 
                            records.map((item,i)=>{
                            return  <>
                              <Col md={6} className="p-3">
                                <Card>
                                     <Card.Title>{item.title}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">{item.category_name}</Card.Subtitle>
                                        <iframe title="vimeo-player" src={item.link} width="100%" height="400" frameborder="0" allowfullscreen></iframe>                                  
                                </Card>
                              </Col>
                              </>
                   })
                    }

           
                  
                 </Row>

                 <Row>
              <Col md={12}>
                <Pagination style={{"marginTop":"2rem"}}>
                      {Array.from({ length: Math.ceil(recordsCount / 10) }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={ index + 1 === pageNo}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
              </Col>
              </Row>
          </Container>  
           </div>
    )
}

export default JobseekerKnowledgebase;