import React, { Component } from "react";
import "./css/payment.css";
import { Container, Row, Col, Tab, Nav, Form, Button, Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import Payment from "./Section/Payment";
import validation from '../Validate/validator';

import PLATINUM from "../images/platinum_badge.png";
import SILVER from "../images/silver_badge.png";
import GOLD from "../images/gold_badge.png";

import Patron from "../images/Group-98.png";
import Platinum from "../images/Group-98-1.png";
import Gold from "../images/Group-98-2.png";
import Silver from "../images/Group-98-3.png";
import Bronze from "../images/Group-98-4.png";
import Basic from "../images/Group-98-5.png";

import Platinumbadge from "../images/PLATINUM.png";
import Silverbadge from "../images/SILVER.png";
import Goldbadge from "../images/GOLD.png";
import basicbadge from "../images/basic_badge.png";
import bronzebadge from "../images/bonze_badge.png";
import patronbadge from "../images/patron_badge.png";

export default class PaymentPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priceList: "",
      userDetails: "",
      PaymentModal: false,
      price: null,
      pricePlan: "",
      Msg: "",
      MsgType: "",
      tab: "",
      priceplan: "",
      plan: "",
      planimage: "",
      badges: "",
      personCount: 0,
      FromDataError: {
        price: 'false',
        personCount: 'false'
      }
    };
  }
  handleClose(price = 0) {
    if (this.state.PaymentModal) {
      this.setState({ PaymentModal: false });
    } else {
      this.setState({ price: price, PaymentModal: true });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;
    let userDetails = "";
    let badges = "";

    let tx = new URLSearchParams(this.props.location.search).get("tx");

    if (tx != undefined) {
      sta.setState({ tab: "paypal", PaymentModal: true, price: 0 });
    }

    try {
      if (localStorage.getItem("gs_user") != null) {
        let userDetails = JSON.parse(localStorage.getItem("gs_user"));
        if (userDetails.priceplan != "") {
          this.setState({ priceplan: userDetails.priceplan });
        }
      }
    } catch (error) { }

    try {
      if (localStorage.getItem("gs_user") != null) {
        let userDetails = JSON.parse(localStorage.getItem("gs_user"));
        let userplan = localStorage.getItem("plan");
        console.log("plan name", localStorage.getItem("plan"));

        console.log("badge", userDetails);
        switch (userplan) {
          case "PLATINUM":
            badges = Platinumbadge;
            break;
          case "GOLD":
            badges = Goldbadge;
            break;
          case "SILVER":
            badges = Silverbadge;
            break;
          case "BASIC":
            badges = basicbadge;
            break;
          case "PATRON":
            badges = patronbadge;
            break;
          case "BRONZE":
            badges = bronzebadge;
            break;
        }
        this.setState({ badges: badges });

        this.setState({ userDetails }, () => {
          //console.log('..onloadState',this.state)
        });
      }
    } catch (error) {
      console.log("badge error", error);
    }

    let request1 = {
      end_point: "/list-plans",

      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on
    GET_API(request1)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          this.setState({ priceList: resp.data });
        }
      })
      .catch(() => {
        this.setState({ loader: "hidden" }); ///loader off
      });

    let request = {
      end_point: "/get-company-dashboard",

      token: localStorage.getItem("gs_token"),
    };
    this.setState({ loader: "visible" }); ///loader on
    GET_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          this.setState({
            totalsponsor: resp.data.total,
            plan: resp.data.priceplan,
          });
          this.setState({ planimage: resp.data.planimage });
          this.setState({ priceplan: resp.data.priceplan });
          localStorage.setItem("plan", resp.data.priceplan);
        }
      })
      .catch(() => {
        this.setState({ loader: "hidden" }); ///loader off
      });
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };


  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let FromDataError = this.state.FromDataError;



    var valid_obj = {
      value: value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }



    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError })
    })

    this.setState({ [name]: value }, () => {

      if (name == "personCount") {
        if (value >= 1 && value <= 100) {
          FromDataError['price'] = ''
          this.setState({ price: Number(value * 99), FromDataError })
        }
        else {
          FromDataError['price'] = 'false'
          this.setState({ price: '', FromDataError })
        }


      }
      //console.log(this.state)
    });





  };


  handalePay = () => {
    let { FromDataError } = this.state
    if (this.validateForm(FromDataError)) {
      this.handleClose(this.state.price)
    }
  }


  render() {
    let { priceList } = this.state;
    let userDetails = this.state.userDetails;
    // console.log(",,,,,,state", this.state.priceplan)
    // console.log(",,,,,,badge", this.state.planimage)

    return (
      <div className="tetse">

        <Card className="p-5">

          {this.state.price != null ? (
            <>
              <Payment
                {...this.props}
                price={this.state.price}
                tab={this.state.tab}
                show={this.state.PaymentModal}
                close={() => this.handleClose()}
              />
            </>
          ) : (
            <></>
          )}

          {
            this.state.userDetails.roleID != 16 ? (<>

              <Paymentheader />
              <Loader loadmsg="please wait" visibility={this.state.loader} />
              <div>
                <h4 style={{ color: "#00BCD4", paddingBottom: "10px" }}>Existing Plan</h4>

                {/* {userDetails.pricePlan != null */}
                {this.state.planimage != null ? (
                  <div className='patron_existing_plan'>
                    <div className='patron_logo'>
                      <img src={this.state.planimage} style={{"width":"120px"}}/>
                    </div>
                    <div className='patron_text'>
                      <h4>Benefits of Sponsorship</h4>
                       <ul>
                            <li>Featured on Home Page</li>
                            <li>Mutual Press Release</li>
                            {
                              (localStorage.getItem('plan') == "BASIC" ) &&
                              <>
                                <li>Basic GBTW Sponsor Badge</li>

                              </>  
                            }
                            {
                              (localStorage.getItem('plan') == "BRONZE" ) &&
                              <>
                                <li>Bronze GBTW Sponsor Badge</li>

                              </>  
                            }
                             {
                              (localStorage.getItem('plan') == "SILVER" ) &&
                              <>
                                <li>Silver GBTW Sponsor Badge</li>

                              </>  
                            }
                            {
                              (localStorage.getItem('plan') == "GOLD" ) &&
                              <>
                                <li>Gold GBTW Sponsor Badge</li>
                                <li>1 Meeting/Leisure Booking on a yacht</li>

                              </>  
                            }
                            {
                              (localStorage.getItem('plan') == "PLATINUM" ) &&
                              <>
                                <li>Platinum GBTW Sponsor Badge</li>
                                <li>One Event (Virtual Hosting & Promotion)</li>
                                <li>2 Meeting/Leisure Bookings on a yacht</li>
                              </>  
                            }
                            {
                              (localStorage.getItem('plan') == "PATRON" ) &&
                              <>
                                <li>Patron GBTW Sponsor Badge</li>
                                <li>Two Events (Virtual Hosting & Promotion)</li>
                                <li>6 Meeting/Leisure Bookings on a yacht</li>
                              </>  
                            }
                            <li>Featured in Weekly Newsletters</li>
                            
                          </ul>
                      <div className='view_det_but d-flex justify-content-end w-100'>
                        <a href='#'>View Details</a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <h6 style={{ color: "gray", fontSize: "17px", paddingBottom: "50px" }}>
                      You don't have any existing plan. You can select a
                      sponsorship plan you like and start sponsoring today.
                    </h6>
                  </>
                )}
              </div>

              <h4 style={{ color: "#00BCD4", paddingTop: "35px" }}>Update Plan</h4>
              <div className="tariflist py-5">
                <div className="container-fluid">
                  <Row className="justify-content-center">
                    {this.state.Msg !== "" ? (
                      <>
                        <FlashMess
                          m={this.state.Msg}
                          mtype={this.state.MsgType ? this.state.MsgType : "success"}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {priceList != "" ? (
                      <>
                        {priceList.map((data, key) => {
                          return (
                            <Col key={key} md={4} sm={6} className="pl__grid mb-4">
                              <div className="flip-box-inner-wrapper">
                                <div
                                  className="flip-box-front pl__grid__card text-center d-flex align-items-center flex-wrap"
                                  style={{ backgroundColor: "#ffffff" }}
                                >
                                  <img src={data.plan_badge} className="img-flip" />
                                  <div style={{ flex: "0 0 100%" }}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: data.plan_description,
                                      }}
                                    ></p>
                                    <div className="pl__sep"></div>
                                    <a
                                      href="javascript:"
                                      onClick={() =>
                                        this.handleClose(data.plan_amount)
                                      }
                                    >
                                      Select Plan
                                    </a>
                                  </div>
                                </div>

                                <div className="flip-box-back pl__grid__card d-flex align-items-center flex-wrap">
                                  <div className="text-center">
                                    <img src={data.plan_badge} className="img-flip" />
                                  </div>
                                  <div style={{ flex: "0 0 100%" }}>
                                    <p>
                                      <strong>Benefits of Sponsorship</strong>
                                    </p>
                                    <div className="scElm">
                                      <ul>
                                      <li>Featured on Home Page</li>
                                      <li>Mutual Press Release</li>
                                      {
                                        (data.plan_type == "BASIC" ) &&
                                        <>
                                          <li>Basic GBTW Sponsor Badge</li>

                                        </>  
                                      }
                                      {
                                        (data.plan_type == "BRONZE" ) &&
                                        <>
                                          <li>Bronze GBTW Sponsor Badge</li>

                                        </>  
                                      }
                                      {
                                        (data.plan_type == "SILVER" ) &&
                                        <>
                                          <li>Silver GBTW Sponsor Badge</li>

                                        </>  
                                      }
                                      {
                                        (data.plan_type == "GOLD" ) &&
                                        <>
                                          <li>Gold GBTW Sponsor Badge</li>
                                          <li>1 Meeting/Leisure Booking on a yacht</li>

                                        </>  
                                      }
                                      {
                                        (data.plan_type == "PLATINUM" ) &&
                                        <>
                                          <li>Platinum GBTW Sponsor Badge</li>
                                          <li>One Event (Virtual Hosting & Promotion)</li>
                                          <li>2 Meeting/Leisure Bookings on a yacht</li>
                                        </>  
                                      }
                                      {
                                        (data.plan_type == "PATRON" ) &&
                                        <>
                                          <li>Patron GBTW Sponsor Badge</li>
                                          <li>Two Events (Virtual Hosting & Promotion)</li>
                                          <li>6 Meeting/Leisure Bookings on a yacht</li>
                                        </>  
                                      }
                                      <li>Featured in Weekly Newsletters</li>
                                        
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-center w-100">
                                    <a
                                      href="javascript:"
                                      onClick={() =>
                                        this.handleClose(data.plan_amount)
                                      }
                                    >
                                      Select Plan
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}

                    {/* {(this.state.price != '')? (<> */}
                    <Col md={12} className="p-3 text-center">
                      <a
                        href="javascript:"
                        onClick={() => this.handleClose(0)}
                        className="btn btn-primary mt-3"
                      >
                        Custom Amount
                      </a>
                    </Col>

                    {/* </>) : (<></>)} */}
                  </Row>
                </div>
              </div>
            </>) : (<>

              <Paymentheader2 />
              <Loader loadmsg="please wait" visibility={this.state.loader} />
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="formBasicCardNo">
                    <Form.Label>Number of employees you would like to sponsor</Form.Label>
                    <Form.Control
                      type="number"
                      name="personCount"
                      onWheel={(e) => e.target.blur()}
                      min={0}
                      validaterule={['required', 'numeric', 'priceValue|1', 'maxpriceValue|100']}
                      validatemsg={['This field is required', 'Invalid Number', 'Minimum value 1', 'Maximum 100']}
                      // placeholder="Sponsor Amount"
                      placeholder=""
                      onChange={this.handleInputChange}
                      onFocus={this.handleInputFocus}
                    />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.personCount == 'false' ? '' : this.state.FromDataError.personCount}</p>

                  </Form.Group>
                </Col>
                <Col md={6}>

                  <Form.Group controlId="formBasicCardNo">
                    <Form.Label>Total Sponsorship Amount ($)</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="number"
                      name="price"
                      validaterule={['required', 'numeric', 'priceValue|100', 'maxpriceValue|100']}
                      validatemsg={['Amount required', 'Invalid Number', 'Minimum 100 $ need to sponsor', 'Maximum 100']}
                      value={this.state.price}

                    />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.price == 'false' ? '' : this.state.FromDataError.price}</p>

                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3 justify-content-center">
                <Col md={6}>
                  <Button onClick={this.handalePay} variant="primary" type="submit" className="btn-main tabformbutton w-100">Pay</Button>
                </Col>
              </Row>

            </>)}
        </Card>
      </div>
    );
  }
}

class Paymentheader extends Component {
  render() {
    return (
      <div className="Paymentheader">
        <Container>
          <h3>Select the plan you like</h3>
          {/* <p>Choose your plan and make payment </p> */}
          <p>Please choose your preferred sponsorship plan</p>
        </Container>
      </div>
    );
  }
}

class Paymentheader2 extends Component {
  render() {
    return (
      <div className="Paymentheader">
        <Container>
          {/* <h3>Are you willing to sponsor more jobseeker? Please enter the number of outplaced candidates you would like to sponsor.</h3> */}
          <h3>Please enter the number of outplaced employees you would like to sponsor.</h3>

          {/* <p>Choose your plan and make payment </p> */}
          {/* <p>Each sponsor code is worth $99. Your amount will be calculated based on the number of jobseekers you are entering.</p> */}
          <p>Each sponsor code is worth $99. Total sponsorship amount will be calculated based on the number of employees you enter.</p>

        </Container>
      </div>
    );
  }
}
