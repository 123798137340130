import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import DataTable from "react-data-table-component";
import { BASE_URL } from '../Config';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Papaparse from "papaparse";
import moment from "moment";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import cvgIcon from '../images/csv.png'



export default class AffiliateCommisionReport extends Component {

  constructor(props) {
    super(props)
    this.state = {

      FromData: {

        start_date: '',
        end_date: '',
        role: '',

      },
      FromDataError:
      {
        start_date: '',
        end_date: '',
        role: ""
      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/commission-report',
      help: '',
      commission_table_data: {},
      dataCount: "",
      start_date: "",
      end_date: "",
      startDateErr: "",
      endDateErr: "",

    };

    this.searchCommission = this.searchCommission.bind(this)
    this.handaleChange = this.handaleChange.bind(this)

    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    //Binding




  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
        console.log('loop', valid)
      }

    }


    console.log('funct', valid)

    return valid;
  };


  handaleChange(data) {

    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;


    value = data.target.value;
    FromData[name] = value;
    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }
  handleValidateForm() {
    let startDateErr = "";
    let endDateErr = "";

    if (!this.state.start_date) {
      startDateErr = "required";
    } else {
      startDateErr = ""
    }
    if (!this.state.end_date) {
      endDateErr = "required";
    }
    else if (this.state.start_date > this.state.end_date) {
      endDateErr = "To date must be greater than from date";
    }
    if (startDateErr || endDateErr) {
      this.setState({ startDateErr, endDateErr });
      return false;
    } else {
      this.setState({ startDateErr: "", endDateErr: "" });
      return true;
    }
  }


  handleStartDateChange(startD) {
    this.setState({
      start_date: startD,

    })
  }
  handleEndDateChange(endD) {
    this.setState({
      end_date: endD,

    })
  }


  searchCommission(e) {

    e.preventDefault();
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let commission_table_data = this.state.commission_table_data
    if (this.handleValidateForm()) {
      var formdata = new FormData();
      let start_date = this.state.start_date
      let end_date = this.state.end_date
      let role = FromData.role

      sta.setState({ loader: 'visible' })///loader on

      let requestsearch = {
        end_point: '/view-commission-details?to_date=' + moment(start_date).format("YYYY-MM-DD") + '&from_date=' + moment(end_date).format("YYYY-MM-DD") + '&role=' + role,
        token: localStorage.getItem("gs_token")

      };

      GET_API(requestsearch).then(resp3 => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp3.status == 'success') {

          commission_table_data = resp3.data

          sta.setState({ commission_table_data })


        }
      }).catch((ee) => {

        sta.setState({ loader: 'hidden' })///loader off

        console.log('/affiliatecountERR', ee)
      });




    }


  }



  columns = [
    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
      wrap: true,
    },
    {
      name: "Name",
      selector: row => (row.firstname || '') + ' ' + (row.lastname || ''),
      width: '180px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => { return <a href={`mailto:${row.email}`}>{row.email}</a> },
      width: '200px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Created at",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: '120px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Role",
      width: '100px',
      selector: row => row.roleID == "indivisual" ? "Individual" : row.roleID,
      sortable: true
    },
    {
      name: "Commission Amount",
      selector: row => <div>$ {row.commission_amount}</div>,
      sortable: true
    },
    {
      name: "Payment Status",
      selector: row => {
        return (
          <div>{row.has_paid == 1 ? 'Paid' : 'Pending'}</div>

        )
      }
      ,
      sortable: true
    },



  ];





  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError
    let commission_table_data = this.state.commission_table_data
    let dataCount = this.state.dataCount



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }



    let request = {
      end_point: '/view-commission-details',
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        commission_table_data = resp.data
        dataCount = resp.data.length

        sta.setState({ commission_table_data })
        sta.setState({ dataCount })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/code list ERR', ee)
    });

    //help api
    let requestHelp = {
      end_point: '/help-details?key=affiliate_commission_list',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })



  }

  downloadCsv = () => {
    let FromData = this.state.FromData;
    let start_date = FromData.start_date
    let end_date = FromData.end_date
    let role = FromData.role

    let requestCSV = {
      end_point: '/view-commission-details?to_date=' + start_date + '&from_date=' + end_date + '&role=' + role,
      token: localStorage.getItem("gs_token")
    };
    GET_API(requestCSV)
      .then((resp) => {
        let csv = Papaparse.unparse(
          resp.data.map((val, index) => {

            return {
              "Name": val.aff_firstname + " " + val.aff_lastname,
              "Email": val.email,
              "Date": val.created_at,
              "Role": val.roleID,
              "Commission Amount": "$" + " " + val.commission_amount,
              "Status": val.has_paid == 1 ? 'Paid' : 'Pending',
            }
          })
        );

        let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        let csvURL = null;
        if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, "commissionReport.csv");
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "commissionReport.csv");
        tempLink.click();
      });
  };


  render() {
    let FromData = this.state.FromData
    let commission_table_data = this.state.commission_table_data

    return (
      <div>
        {commission_table_data != null ? (<>




          <Container fluid className="dash_container">

            <div className="tabpanelOneForm pt-3">

              <Row className='align-items-center'>
                <Col lg={8} md={12} sm={12}>
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                  <h3>Commission List</h3>
                  {/* <p>{this.state.help}</p> */}
                  <p>You can view a comprehensive report that outlines the commission you have earned for each {localStorage.getItem("roleID") != 8 ? 'sponsor and' : ''} jobseeker. This report also allows you to track the status of your commission payments, including whether they are still pending or have already been received.</p>
                  {/* <p>Get a detailed report on the commission amount you are entitled to receive so far{localStorage.getItem("roleID") != 8 ? 'per sponsor and' : ''}  per jobseeker. You can also check the status (pending, received) of your commission payments here.</p> */}
                </Col>
                <Col lg={4} md={7} sm={12} className="d-flex justify-content-end align-items-start">
                  {this.state.dataCount ? <Button onClick={() => this.downloadCsv()}
                    style={{}}
                    className="btn btn-light download_btn_size"><img src={cvgIcon} style={{ width: '30px' }} />Download Commission Report</Button> : <></>}
                </Col>
              </Row>


              <Form
                className="tabform"
                onSubmit={this.searchCommission}
              >
                <Row className='justify-content-end'>
                  <Col md="3">From Date
                    {/* <Form.Control
                      type="date"
                      name="start_date"
                      onChange={this.handaleChange}
                      validaterule={['required']}
                      validatemsg={['This field is required']}
                      className="w-100"
                      placeholder='mm-dd-yyyy'
                    />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.start_date == 'false' ? '' : this.state.FromDataError.start_date}</p> */}
                    <DatePicker
                      className='pl-2'
                      selected={this.state.start_date}
                      name="start_date"
                      placeholderText="mm-dd-yyyy"
                      dateFormat="MM-dd-yyyy"
                      onChange={this.handleStartDateChange}
                      autoComplete="off"
                    />
                    <p style={{ color: 'red' }}>{this.state.startDateErr}</p>
                  </Col>
                  <Col md="3"> To Date
                    {/* <Form.Control
                      type="date"
                      name="end_date"
                      onChange={this.handaleChange}
                      validaterule={['required', 'lessThenFrom|' + this.state.FromData.start_date,]}
                      validatemsg={['This field is required', 'To date must be greater than from date']}
                      className="w-100"
                    />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.end_date == 'false' ? '' : this.state.FromDataError.end_date}</p> */}
                    <DatePicker
                      className='pl-2'
                      selected={this.state.end_date}
                      name="end_date"
                      placeholderText="mm-dd-yyyy"
                      dateFormat="MM-dd-yyyy"
                      onChange={this.handleEndDateChange}
                      autoComplete="off"
                    />
                    <p style={{ color: 'red' }}>{this.state.endDateErr}</p>
                  </Col>
                  <Col md="3"> Role
                    {localStorage.getItem("roleID") == 9 ?
                      <Form.Control as="select" name="role" onChange={this.handaleChange} className="w-100">
                        <option value="">Select</option>
                        <option value="sponsor">Sponsor</option>
                        <option value="jobseeker">Jobseeker</option>
                      </Form.Control> : <Form.Control as="select" name="role" onChange={this.handaleChange}
                        className="w-100"><option value="jobseeker">Jobseeker</option></Form.Control>}
                    <p style={{ color: 'red' }}>{this.state.FromDataError.role == 'false' ? '' : this.state.FromDataError.role}</p>
                  </Col>
                  <Col md="2" className="mt-4" >
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn btn-primary btn-md">
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>


              <DataTable
                title=""
                columns={this.columns}
                data={commission_table_data}
                defaultSortField="title"
                pagination={true}
                NoDataComponent={"No data"}
                noHeader={true}
              />


            </div>




          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

