import React from "react";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";

const JobseekerCareerMaster = ()=>{
  return (
  <>
   <Container fluid className="dash_container text-center">
            <Row>
              <Col md={12} className="pt-5 pb-5">
              <h3>Coming Soon</h3>
              </Col>
             </Row>
      </Container>      

  </>);
}

export default JobseekerCareerMaster;
