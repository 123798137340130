import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';

import DataTable from "react-data-table-component";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class MemberDashboardViewModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      referral_code: '',
      firstname: '',
      lastname: '',
      email: '',
      registered_at: '',
      generated_at: '',
      has_joined: '',
      loader: "hidden",
      trakinguserEmail: '',
      trakinguserFname: '',
      trakinguserLname: '',


    };






  }


  getoneRedeem() {

    let referral_code = this.props.referral_code;
    let gs_userID = this.props.gs_userID;
    console.log("redeem code details", gs_userID);


    let request = {
      end_point: '/get-one-redeemDetails?referral_code=' + referral_code + '&gs_userID=' + gs_userID,
      token: localStorage.getItem("gs_token")

    };

    this.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {



      console.log("modal", resp);
      this.setState({ referral_code: resp.referral_code, firstname: resp.firstname, lastname: resp.lastname, email: resp.email, registered_at: resp.registered_at, middlelayer_id: resp.middlelayer_id, generated_at: resp.generated_at, trakinguserEmail: resp.details.trakinguserEmail, has_joined: resp.has_joined, trakinguserFname: resp.details.trakinguserFname, trakinguserLname: resp.details.trakinguserLname })
      this.setState({ loader: 'hidden' })

    }).catch((ee) => {

      console.log('/member view list ERR', ee)
    });





  }





  render() {
    let ContactList = this.state.ContactList

    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          onShow={() => this.getoneRedeem()}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
          size="md"
        >

          <Modal.Header closeButton>
            <Modal.Title>Redeem Details</Modal.Title>
            {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
          </Modal.Header>
          <Modal.Body>
            <Loader
              loadmsg="please wait"
              visibility={this.state.loader}
            />
            {this.state.firstname != null ? (<Row className="m-0">

              <Col md={6}>
                <strong>
                  Employee Name :
                </strong>
              </Col>
              <Col md={6}>
                <p className="textwrap">
                  {(this.state.firstname) + " " + (this.state.lastname)}
                </p>
              </Col>

            </Row>)
              : (<h5>&nbsp;</h5>)}
            <Row className="m-0">
              <Col>
                <strong>
                  Employee Email :
                </strong>
              </Col>
              <Col>
                <p className="textwrap">
                  {this.state.email}
                </p>
              </Col>
            </Row>

            <Row className="m-0">
              <Col md={6}>
                <strong>
                  Referral code :
                </strong>
              </Col>
              <Col md={6}>
                <p className="textwrap">
                  {this.state.referral_code}
                </p>
              </Col>
            </Row>
            {this.state.has_joined == 1 ?
              (<Row className="m-0">
                <Col md={6}>
                  <strong>
                    Generated at :
                  </strong>
                </Col>
                <Col md={6}>
                  <p className="textwrap">
                    {this.state.generated_at}
                  </p>
                </Col>
              </Row>) : ('')}
            {this.state.has_joined == 1 ?
              (<Row className="m-0">
                <Col md={6}>
                  <strong>
                    Code Used :
                  </strong>
                </Col>
                <Col md={6}>
                  <p className="textwrap">
                    {this.state.generated_at}
                  </p>
                </Col>
              </Row>) : ('')}
            {this.state.has_joined == 1 ?
              (<Row className="m-0">
                <Col md={6}>
                  <strong>
                    JobSeeker Name :
                  </strong>
                </Col>
                <Col md={6}>
                  <p className="textwrap">
                    {(this.state.trakinguserFname) + " " + (this.state.trakinguserLname)}
                  </p>
                </Col>
              </Row>) : ('')}
            {this.state.has_joined == 1 ?
              (<Row className="m-0">
                <Col md={6}>
                  <strong>
                    JobSeeker Email :
                  </strong>
                </Col>
                <Col md={6}>
                  <p className="textwrap">
                    {this.state.trakinguserEmail}
                  </p>
                </Col>
              </Row>) : ('')}
            <Row className="m-0">
              <Col md={6}>
                <strong>
                  Shared :
                </strong>
              </Col>
              <Col md={6}>
                <p className="textwrap">
                  {this.state.has_joined == 1 ? 'Shared' : 'Not Shared'}
                </p>
              </Col>
            </Row>
            <Row className="m-0">
              <Col md={6}>
                <strong>
                  Joining status :
                </strong>
              </Col>
              <Col md={6}>
                <p className="textwrap">
                  {this.state.has_joined == 1 ? <span className="badge badge-info">Joined</span> : <span className="badge badge-danger">Not Joined</span>}
                </p>
              </Col>
            </Row>






          </Modal.Body>
          <Modal.Footer>


          </Modal.Footer>



        </Modal>
      </div>
    );
  }
}
