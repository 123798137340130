import React, { Component, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import csvfilename from './files/add-contact.csv';
import './css/dashboard.css';
import DataTable from "react-data-table-component";





export default class addCampaign extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        campaign_name : '',
                                        description : '',
                                        allcontactID : [],
                                        templete_id:'',
                                        schedule_at:'',
                                        save_time:'',
                                        group_id:'',
                                        templete_type:'',
                                        group_type:'',
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                          campaign_name : 'false',
                                          description : 'false',
                                          allcontactID : '',
                                          templete_id:'false',
                                          group_id:'false',
                                         
                                         
                                        
                                        },
                                        FromDataError1 :
                                        {
                                          
                                          contact_no:'',
                                          save_time:'false',
                                          templete_type:'false',
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/campaign-details-sponsor',
                        ContactData: [],
                        ContactDataError :[],

                        TemplateData: [],
                        SMSTemplateData: [],
                        EmailemplateData: [],
                        GroupData:[],
                        GroupData1:[],
                        templatePre:[],
                       
                                      
                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        this.handaleChangeTemplate = this.handaleChangeTemplate.bind(this)
       
        
        
        
        

    }

    

    

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {
          
          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  
    setContactValue(data)
    {
      
       
         let FromData = this.state.FromData;
         let FromDataError1 = this.state.FromDataError1;

         let check= FromData.allcontactID.includes(data)
        if(!check){

          FromData.allcontactID.push(data);
        }else{
          FromData.allcontactID.pop(data)
        }  
        FromDataError1.contact_no = 'false';
        this.setState({FromDataError1})

        //this.setState(FromData.allcontactID)
        console.log(FromData.allcontactID)
        console.log(check)

    }
    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromDataError1 = this.state.FromDataError1;
        let FromData = this.state.FromData;
        let gs_user = '';

      
    

       //console.log(FromData)

        if(this.validateForm(FromDataError)  && FromData.save_time && FromData.templete_type!='')
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("campaign_name", FromData.campaign_name);
                formdata.append("description", FromData.description);
                formdata.append("templete_id", FromData.templete_id);
                formdata.append("schedule_at", FromData.schedule_at);
                formdata.append("save_time", FromData.save_time);
                formdata.append("group_id", FromData.group_id);
                formdata.append("templete_type", FromData.templete_type);
                    
                let request = {
                  end_point : '/create-campaign',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off

                  console.log('......response',resp)

                        if(resp.status == 'success')
                        {
                          //sta.setState({Msg:'Contact add Successfully',MsgType:'success'})
                          
                          
                          this.props.history.push({
                            pathname: '/campaign-details-sponsor',
                            state:{Msg:'Campaign added Successfully',MsgType:'success'}
                  
                          })

                            // setTimeout(() => {
                            //   window.location.reload()
                              
                            // }, 3000);

                          

                        }
                        else{
                            
                            sta.setState({Msg:resp.message.campaign_name.join(),MsgType:'danger'})
                                //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/addcontactERR',ee)
                        });
                      

           

        }else
        { 

         

          if(FromData.templete_type=='')
          {
           
            FromDataError1.templete_type = 'This field is required';
            this.setState({FromDataError1})

          }
           
           if(!FromData.save_time){
            
             FromDataError1.save_time = 'This field is required';
             this.setState({FromDataError1})
           }
           
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
           
        }

       
    }

    searchChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
       
      
       let sta = this;
       let ContactData =this.state.ContactData
       let ContactDataError =this.state.ContactDataError
       let request = {
          end_point : '/list-contact?search_key='+value,
          token: localStorage.getItem("gs_token")
  
      };
  
    sta.setState({loader:'visible'})///loader on
  
  
        GET_API(request).then(resp=>{
  
                 sta.setState({loader:'hidden'})///loader off
  
  
                        if(resp.status == 'success')
                        {
                                
                            ContactData = resp.data
  
                                sta.setState({ContactData})
                                
                           }
                            else{
                                
                                
                                    
                            }
                }).catch((ee)=>{
                  sta.setState({loader:'hidden'})///loader off
  
                    console.log('/code list ERR',ee)
                });
  
      }
  
      handleCloseDatetime(param='')
      {
        let FromData = this.state.FromData;
        let FromDataError1 = this.state.FromDataError1;
        FromData.save_time=param

        if(param=='schedule'){
            document.getElementById('showDatetime').style.display = 'block';
        }else{
            document.getElementById('showDatetime').style.display = 'none';
        }

        if(param)
        {
          FromDataError1.save_time = 'false';
          this.setState({FromDataError1})
        }

           
      }


  
    componentDidMount()
    {

        let sta = this;
        let ContactData =this.state.ContactData
        let ContactDataError =this.state.ContactDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
      


                let request = {
                    end_point : '/list-contact',
                    token: localStorage.getItem("gs_token")

                };

                sta.setState({loader:'visible'})///loader on


                    GET_API(request).then(resp=>{

                             sta.setState({loader:'hidden'})///loader off


                                    if(resp.status == 'success')
                                    {
                                            
                                        ContactData = resp.data

                                            sta.setState({ContactData})
                                            
                                       }
                                        else{
                                            
                                            
                                                
                                        }
                            }).catch((ee)=>{
                              sta.setState({loader:'hidden'})///loader off

                                console.log('/code list ERR',ee)
                            });

                            this.getTemplate()

                            // let TemplateData =this.state.TemplateData
                          
                    
                  
                            //         let requestTemp = {
                            //             end_point : '/list-campaign-templete',
                            //             token: localStorage.getItem("gs_token")
                    
                            //         };
                    
                            //         sta.setState({loader:'visible'})///loader on
                    
                    
                            //             GET_API(requestTemp).then(resp=>{
                    
                            //                      sta.setState({loader:'hidden'})///loader off
                    
                    
                            //                             if(resp.status == 'success')
                            //                             {
                                                                
                            //                               TemplateData = resp.data
                    
                            //                                     sta.setState({TemplateData})
                                                                
                            //                                }
                            //                                 else{
                                                                
                                                                
                                                                    
                            //                                 }
                            //                     }).catch((ee)=>{
                            //                       sta.setState({loader:'hidden'})///loader off
                    
                            //                         console.log('/TemplateData list ERR',ee)
                            //                     });



                                                let GroupData =this.state.GroupData
                          
                    
                  
                                                let requestGroup = {
                                                    end_point : '/list-contact-group',
                                                    token: localStorage.getItem("gs_token")
                                
                                                };
                                
                                                sta.setState({loader:'visible'})///loader on
                                
                                
                                                    GET_API(requestGroup).then(respGroup=>{
                                
                                                             sta.setState({loader:'hidden'})///loader off
                                
                                
                                              if(respGroup.status == 'success')
                                                {
                                                                            
                                                      GroupData = respGroup.data
                                
                                                      sta.setState({GroupData})
                                                      this.handleSelectGroup(GroupData)                      
                                                }
                                        else{
                                                                            
                                                                            
                                                                                
                                          }
                           }).catch((ee)=>{
                                      sta.setState({loader:'hidden'})///loader off
                                
                                       console.log('/group list ERR',ee)
                          });
                             
                 

      
    }
    getTemplate()
    {

      let sta = this;
      let ContactData =this.state.ContactData
      let ContactDataError =this.state.ContactDataError

    let TemplateData =this.state.TemplateData
                          
                    
                  
    let requestTemp = {
        end_point : '/list-campaign-templete',
        token: localStorage.getItem("gs_token")

    };

    sta.setState({loader:'visible'})///loader on


        GET_API(requestTemp).then(resp=>{

                 sta.setState({loader:'hidden'})///loader off


                        if(resp.status == 'success')
                        {
                                
                          TemplateData = resp.data

                                sta.setState({TemplateData})
                                
                           }
                            else{
                                
                                
                                    
                            }
                }).catch((ee)=>{
                  sta.setState({loader:'hidden'})///loader off

                    console.log('/TemplateData list ERR',ee)
                });
    }              

    // updateState = state => {
     
    //   let FromDataError1 = this.state.FromDataError1;
    //     let allData=[]
    //     state.selectedRows.map( (item, key) => {
        
    //       allData.push(item.id);
    //       //console.log(item);
    //     })
    //     let FromData=this.state.FromData
    //     FromData.allcontactID=allData
    //     this.setState({FromData})

    //     FromDataError1.contact_no = 'false';
    //     this.setState({FromDataError1})
     
      
    //   }

      handaleChangeTemplate(data)
      {
         
           let sta = this;
          
   
         
      if(data){

        let value = '';
        let EmailemplateData=[]

          if(data=='SMS' || data=='EMAIL')
          {
            value = data;
          }else{
            value = data.target.value;
          }
          
           this.state.TemplateData.map( (items, key2) => {
             if(items.template_type==value)
             {
              EmailemplateData.push(items);
             }
          })
          
          this.setState({EmailemplateData})

        let FromData=this.state.FromData
        FromData.templete_type=value
        this.setState({FromData})

          let FromDataError1 = this.state.FromDataError1;
          FromDataError1.templete_type = 'false';
          this.setState({FromDataError1})

        }   
         
      }

      handleSelectGroup(param='')
      {
        let FromData = this.state.FromData;
        let FromDataError1 = this.state.FromDataError1;
        
        let GroupData1=[];
        
            if(param=='sponsor' || param=='jobseeker'){

              this.state.GroupData.map( (items, key2) => {
                if(items.group_type==param)
                {
                  GroupData1.push(items);
                }
              })
             
            
              
          }else{

            GroupData1=param;
           // this.setState({GroupData1})
          }

          this.setState({GroupData1})

           
      }
      handleSelectTemplateGroup(param='')
      {
        let sta = this;
        let FromData = this.state.FromData;
        let FromDataError1 = this.state.FromDataError1;

        
       
        let TemplateData =this.state.TemplateData
          if(param=='predefined')                
          {          
                  
               let requestTemp = {
                           end_point : '/list-predefine-templete',
                            token: localStorage.getItem("gs_token")
                    
                 };
                    
                sta.setState({loader:'visible'})///loader on
                    
                    GET_API(requestTemp).then(resp=>{
                    
                      console.log(resp)
                       sta.setState({loader:'hidden'})///loader off
                    
                     if(resp.status == 'success')
                      {
                                                                
                           //let  EmailemplateData = resp.data
                           let  TemplateData = resp.data

                           
                    
                              sta.setState({TemplateData})
                             // this.setState({EmailemplateData})
                             this.handaleChangeTemplate(FromData.templete_type)                                  
                      }
                        else{
                                                                
                                                                
                                                                    
                        }
                    }).catch((ee)=>{
                         sta.setState({loader:'hidden'})///loader off
                    
                         console.log('/TemplateData list ERR',ee)
                    });

          }else{
           
            let requestTemp = {
              end_point : '/list-campaign-templete',
              token: localStorage.getItem("gs_token")
      
          };
      
          sta.setState({loader:'visible'})///loader on
      
      
              GET_API(requestTemp).then(resp=>{
      
                       sta.setState({loader:'hidden'})///loader off
      
      
                              if(resp.status == 'success')
                              {
                                let customTem=[]
                                resp.data.map( (items, key2) => {
                                  if(items.is_admin!=1)
                                  {
                                    customTem.push(items);
                                  }
                               })
                               
                               
                                      
                                TemplateData = customTem
      
                                      sta.setState({TemplateData})
                                      this.handaleChangeTemplate(FromData.templete_type)

                                 }
                                  else{
                                      
                                      
                                          
                                  }
                      }).catch((ee)=>{
                        sta.setState({loader:'hidden'})///loader off
      
                          console.log('/TemplateData list ERR',ee)
                      });
            
                     }

      }



    render() {
        let  FromData = this.state.FromData
        let  ContactData = this.state.ContactData
        return (
            <div>
                 {FromData.hasOwnProperty("campaign_name")? (<>
            

            <Container fluid className="dash_container">
          <Row>
            <Leftpanel urlPath={this.state.urlPath} {...this.props} />
            <Col md={9} className="pt-4 pb-4">
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

                  <div className="tabpanelOneForm card p-5">
                  <Card className="p-5">
                
                      <h3>Add Campaign</h3>
                      <Form
                        className="tabform"
                        onSubmit={this.handaleSubmit}
                      >
                        <Row className="m-0 mt-4 mb-4">

                      
                          <Col md={12}>
                              {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
            {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
            
            
          
                            
                    
                            <Form.Row>
                              <Form.Group controlId="formBasicEmail4" as={Col}>
                                <Form.Label>Name
                                <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="campaign_name"
                                  onChange={this.handaleChange}
                                  placeholder="Enter your campaign name"
                                
                                  validaterule={['required']}
                                  validatemsg={['This field is required']}
                                />
                                  <p style={{ color: 'red' }}>{this.state.FromDataError.campaign_name=='false'?'': this.state.FromDataError.campaign_name}</p>
                              </Form.Group>
                              
                        <Form.Group controlId="formBasicEmail22" as={Col}>
                              <Form.Label>Template Type<span className="text-danger"> *</span></Form.Label>
                              <Form.Control as="select" name="templete_type" onChange={this.handaleChange ,this.handaleChangeTemplate}  defaultValue={''}>
                              <option value="">--Select Type--</option>
                              <option  value="SMS">SMS Template</option>
                              <option  value="EMAIL">Email Tamplate</option>
                              
                              </Form.Control>
                              <p style={{ color: 'red' }}>{this.state.FromDataError1.templete_type=='false'?'': this.state.FromDataError1.templete_type}</p>
                          </Form.Group>

                            
                            </Form.Row>
                            <Form.Row> 
                            <Form.Group controlId="formBasicEmail22" as={Col}>
                                <Form.Label>Select Template<span className="text-danger"> *</span></Form.Label>
                                <Form.Label><Form.Check   name="template_group" type="radio" value="predefined" onClick={()=>this.handleSelectTemplateGroup('predefined')} label="Predefined"/></Form.Label>
                                <Form.Label><Form.Check   name="template_group" type="radio" value="custom" onClick={()=>this.handleSelectTemplateGroup('custom')} label="Custom"/></Form.Label>
                                
                                <Form.Control as="select" name="templete_id" onChange={this.handaleChange}>
                                  <option value="">--Select Template--</option>

                                  {this.state.EmailemplateData.map( (items, key2) => {
                                    return (    
                                  <option value={items.id}>{items.templete_name}</option>
                                    )
                                })} 
                                </Form.Control>
                                  <p style={{ color: 'red' }}>{this.state.FromDataError.templete_id=='false'?'': this.state.FromDataError.templete_id}</p>
                              </Form.Group>

                            <Form.Group controlId="formBasicEmail22" as={Col}>
                                <Form.Label>Select Contact Group<span className="text-danger"> *</span></Form.Label> <Form.Label><Form.Check   name="group_type" type="radio" value="jobseeker" onClick={()=>this.handleSelectGroup('jobseeker')} label="Jobseeker"/></Form.Label>
                                <Form.Label><Form.Check   name="group_type" type="radio" value="sponsor" onClick={()=>this.handleSelectGroup('sponsor')} label="Sponsor"/></Form.Label>
                                
                                <Form.Control as="select" name="group_id" onChange={this.handaleChange}>
                                  <option value="">--Select Contact Group--</option>

                                  {this.state.GroupData1.map( (itemsg, key1) => {
                                    return (    
                                        <option value={itemsg.id}>{itemsg.group_name}</option>
                                    )
                                })} 
                                </Form.Control>
                                  <p style={{ color: 'red' }}>{this.state.FromDataError.group_id=='false'?'': this.state.FromDataError.group_id}</p>
                              </Form.Group>

                            </Form.Row>

                            <Form.Row>
                            <Form.Group controlId="formGridAddress1" as={Col}>
                                <Form.Label>Description
                                <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name='description'
                                  placeholder="Enter Campaign Description"
                                  onChange={this.handaleChange}
                                  validaterule={['required']}
                                  validatemsg={['This field is required','Enter Description']}
                                />
                                  <p style={{ color: 'red' }}>{this.state.FromDataError.description=='false'?'': this.state.FromDataError.description}</p>

                              </Form.Group>
                            </Form.Row>   
                    
                          <Row>
                            <Col md="3">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check   name="save_time" type="radio" value="now" onClick={()=>this.handleCloseDatetime('now')} label="Send Now"/>                     
                            </Form.Group>
                            </Col>
                            <Col md="3">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check   name="save_time" type="radio" value="schedule" onClick={()=>this.handleCloseDatetime('schedule')} label="Send Schedule"/>                     
                            </Form.Group>
                            </Col>
                            <Col md="12">
                            <p style={{ color: 'red' }}>{this.state.FromDataError1.save_time=='false'?'': this.state.FromDataError1.save_time}</p>
                            </Col>
                          </Row> 
                          

                            <Form.Row id="showDatetime" style={{ display: 'none' }}>      
                            <Form.Group controlId="formBasicdate" as={Col}>
                                <Form.Label>Send time
                                <span className="text-danger"> *</span>
                                </Form.Label>
                                <Form.Control
                                  type="datetime-local"
                                  name="schedule_at"
                                  
                                  value={FromData.schedule_at}
                                  onChange={this.handaleChange}
                                  validaterule={['required']}
                                  validatemsg={['This field is required']}
                                />
                                  <p style={{ color: 'red' }}>{this.state.FromDataError.schedule_at=='false'?'': this.state.FromDataError.schedule_at}</p>
                              </Form.Group>

                          </Form.Row>  

                          </Col>
                          <Col md={8} className="pl-1 tabformRight">
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn btn-primary btn-md tabformbutton"
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
    
                      </Form>
                            
                  </Card>
                  </div>
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}


  