import React, { Component } from 'react';
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import Leftpanel from '../Section/LeftPanel';


  
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

class CreateCard extends Component {

    state = {
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
        Msg: '',
        MsgType:'',
        loader:'hidden',
        show:'',
        FromDataError :
                        {
                          cvc : 'false',
                          expiry : 'false',
                          name: "false",
                          number: "false",
                        },
     
      };


      validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
    
        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {
    
            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
    };
    
    
      handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
      };
    
      handleInputChange = (e) => {
        const { name, value } = e.target;
        let FromDataError = this.state.FromDataError;
    
    
            var valid_obj = {
              value:value,
              rules:e.target.getAttribute("validaterule"),
              message:e.target.getAttribute("validatemsg")
            }
    
    
      
                  validation(valid_obj).then((error)=>{
                    FromDataError[name] = error
                    this.setState({FromDataError})
                   })
    
        this.setState({ [name]: value });
      };
    
      handaleSubmit= (e) =>
      {
        e.preventDefault();
        let sta = this;
        let FromDataError = this.state.FromDataError;
    
    
    
    
        if(this.validateForm(FromDataError))
        {
              sta.setState({loader:'visible'})///loader on
        
              var formdata = new FormData();
              formdata.append("card_name", this.state.name);
                  formdata.append("card_number", this.state.number);
                  formdata.append("card_cvc", this.state.cvc);
                  formdata.append("card_exp_month", this.state.expiry.substring(0, 2));
                  formdata.append("card_exp_year", this.state.expiry.slice(-2));
    
    
                 
    
    
                    let request = {
                                    end_point : '/save-card',
                                    formdata:formdata,
                                    token: localStorage.getItem("gs_token")
    
                                  };
    
                                  console.log('......request',formdata)
    
                    
                    
    
                      POST_API(request).then(resp=>{
    
                        sta.setState({loader:'hidden'})///loader off
    
    
                              if(resp.status == 'success')
                              {
                                sta.props.history.push({
                                    pathname: '/cards',
                                    state: { Msg:'Card Added Successfully!',Msgtype:'succcess'}
                                })
                                sta.setState({Msg:'Card Added',MsgType:'success'})
    
                              
                                
              
                                 
                                  
                              }
                              else{
                                  
                                  
                                      sta.setState({Msg:resp.message,MsgType:'danger'})
                                      
                              
                                
              
                              }
                      
    
                    }).catch((ee)=>{
                      
                        sta.setState({loader:'hidden'})///loader off
                        
    
                      sta.setState({Msg:'Something Went Wrong,Please try again later.',MsgType:'danger'})
                      console.log('/CardADD ERR',ee)
    
    
                    });
          }
          else{
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
          }
    
      }
      


    render() {
        return (
            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12} className="pt-4 pb-4">
            <Loader loadmsg="please wait" visibility={this.state.loader}/>
           
            <div className="tabpanelOneForm card p-5">
            <h3>Create Card</h3>
           
                
                <Row className="m-0 mt-4 mb-4">
              <Col md={4} className=" pl-0">
                <Cards
                  cvc={this.state.cvc}
                  expiry={this.state.expiry}
                  focused={this.state.focus}
                  name={this.state.name}
                  number={this.state.number}
                />
              </Col>
              <Col md={8}>
                   {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
               {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
              
              
                <form onSubmit={this.handaleSubmit}>
                  <Form.Row>
                    <Form.Group controlId="formBasicCardNo" className="pr-0" as={Col}>
                      <Form.Label>Name on Card
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name on Card"
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        validaterule={['required']}
                        validatemsg={['Card Name required']}
                      />
                <p style={{ color: 'red' }}>{this.state.FromDataError.name=='false'?'': this.state.FromDataError.name}</p>

                    </Form.Group>
                  </Form.Row>
                  <Row>
                    <Col md={7} className="">
                      <Form.Group controlId="formBasicCardNo">
                      <Form.Label>Card Number
                        <span className="text-danger"> *</span>
                      </Form.Label>
                        <Form.Control
                          type="tel"
                          name="number"
                          placeholder="Card Number"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          maxLength="16"
                          validaterule={['required','max|16','min|14']}
                          validatemsg={['Card no required','Card number must be 14-16 character long','Card number must be 14-16 character long']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.number=='false'?'': this.state.FromDataError.number}</p>
                      </Form.Group>
                    </Col>
                    <Col md={3} className="pl-1 ">
                      <Form.Group controlId="formBasicCardNo">
                      <Form.Label>Expiry
                        <span className="text-danger"> *</span>
                      </Form.Label>
                        <Form.Control
                          type="text"
                          name="expiry"
                          placeholder="MMYY"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          maxLength="4"
                          validaterule={['required','min|4','expire','numeric']}
                          validatemsg={['Card expire required','Card Expiry field must contain 4 digit','Expiry month and year should be a future date.','Invalid Number']}
                        />

                      <p style={{ color: 'red' }}>{this.state.FromDataError.expiry=='false'?'': this.state.FromDataError.expiry}</p>
                      </Form.Group>
                    </Col>
                    <Col md={2} className=" pl-1 pr-0 ">
                      <Form.Group controlId="formBasicCardNo">
                      <Form.Label>CVC
                        <span className="text-danger"> *</span>
                      </Form.Label>
                        <Form.Control
                          type="text"
                          name="cvc"
                          placeholder="CVC"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          maxLength="3"
                          validaterule={['required','min|3','max|3','numeric']}
                          validatemsg={['Card expire required','CVC field must contain 3 digit','CVC field must contain 3 digit','Invalid Number']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.cvc=='false'?'': this.state.FromDataError.cvc}</p>
                      </Form.Group>
                    </Col>
                  </Row>

                 <Row className='justify-content-center'>
                  <Col md={6}>
                      <Button
                          variant="primary"
                          type="submit"
                          className="btn btn-primary btn-md tabformbutton"
                          disabled={this.state.loader!='hidden'? true : false}
                        
                      >
                        Save
                      </Button>
                  </Col>
                 </Row>
                 
                  
                </form>
              </Col>
            </Row>
                </div>
            </Col>     
          </Row>
        </Container>
        );
    }
}

export default CreateCard;