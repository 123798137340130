import React, { Component } from 'react';
//import "./styles.css";
import {Link} from "react-router-dom";
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table } from 'react-bootstrap';
import './purchase.css';
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";

import {
    Scoring_Img,
    Purchase_Iconn,
    Pur_right_Img,
    Logo_1,
    Logo_2,
    Logo_3,
    Logo_4,
    Logo_5,
    Logo_6,
    Logo_7,
    Logo_8,
    Logo_9,
    Logo_10,
    RSending
}from "../../images";
export default class Purchase extends Component {
    constructor(props)
    {
        super(props)
    }
    componentDidMount()
    {
        console.log("Purchase")
    }

    logoDrop(key)
    {
        switch (key) {
            case "logo_1.png": return Logo_1;break;
            case "logo_2.png": return Logo_2;break;
            case "logo_3.png": return Logo_3;break;
            case "logo_4.png": return Logo_4;break;
            case "logo_5.png": return Logo_5;break;
            case "logo_6.png": return Logo_6;break;
            case "logo_7.png": return Logo_7;break;
            case "logo_8.png": return Logo_8;break;
            case "logo_9.png": return Logo_9;break;
            case "logo_10.png": return Logo_10;break;
            case "RSending.png": return RSending;break;
        
            default:
                break;
        }
    }
    render() {
        return (
          <div className="home_new">
               <StepAway {...this.props} logoDrop = {this.logoDrop}/>
               <LimitedTime {...this.props}/>
               <ServBundle {...this.props} />
          </div>
        );
      }
}


class StepAway extends Component {
    constructor(props)
    {
        super(props)
    }
    setPackageData(dataValue){
        this.setState({dataValue})
    }
    render() {
        
        return (
            <div className='home_partts'>
                <Container>
                    <div className='step_away'>
                        <h2>You are one step away from purchasing the service, click on the purchase 
button to buy this package or explorae our bundle service below.</h2>
                        <div className='resume_scoring'>
                               {/* Website Details */}
                           

                             
                                <SelectedSite logoDrop={this.props.logoDrop} setPackageData={this.setPackageData}/>
                               
                              
                           
                              {/* Wbsite details end */}
                            <div className='scoring_right'>
                                <Link to='/jobseeker-purchase-cart' className='purchase_now'>Purchase Now <span><img src={Purchase_Iconn} /></span></Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
  }


  class LimitedTime extends Component {
    constructor(props)
    {
        super(props)
    }
    render() {
        return (
            <div className='limited_time_offer'>
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="12">
                            <div className='limited_time_inner'>
                                <h2>Limited-Time Offer for Jobseekers!</h2>
                                <p>Buy our All-in-One package at just <span>$99.00</span> and get everything under one roof.</p>
                                <p>So, sign up now and make your journey of job search easy with our Global Work Initiative.</p>
                                <div className='purchase_packages'>
                                <Link to='/jobseeker-purchase-cart'>Purchase All Package</Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="12">
                            <div className='purchase_right'>
                                <img src={Pur_right_Img} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            );
    }
  }
  class ServBundle extends Component {
    constructor(props)
    {
        super(props)
    }
    render() {
        return (

            <div className='srv_bundle'>
                <Container>
                    <div className='serv_bundle_inner'>
                        <h3>Here's a great opportunity for job seekers to use all our services and pay only $99.00. Buy our bundle service package and enjoy the exclusive benefits of using all the services at one flat price. Why pay for each service separately and spend $1999 when you can have them all together at a much lesser cost? </h3>
                        <h2>Hurry up! and purchase our bundled service now. </h2>
                    </div>
                    
                </Container>
                <div className='survice_bundle_listing'>
                    <div className='surv_bundle_inner_listing'>
                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_1} />
                            </div>
                            <p>Your Sponsored jobseeker will receive 
a resume score and suggestions to 
strengthen their resume
</p>
                            <label><strike>$99</strike>  <span>$29.99</span></label>
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>

                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_2} />
                            </div>
                            <p>Your sponsorship will give a jobseeker’s resume a competitive edge and show employers that they are the perfect candidate for the job!
</p>
                            <label><strike>$199</strike>  <span>$9.99</span></label> 
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>


                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={RSending} />
                            </div>
                            <p>Your sponsored jobseeker will be able to register with over 175 job boards to get hired fast

</p>
                            <label><strike>$99</strike>  <span>$59.99</span></label>
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>


                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_3} />
                            </div>
                            <p>Your sponsorship will enable jobseekers to find thousands of local jobs with local companies with a simple click</p>
                            <label><strike>$99</strike>  <span>$0</span>(Free Service)</label>
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>


                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_4} />
                            </div>
                            <p>Designed by top recruiters, our AI-powered platform instantly gives you tailored feedback on your resume and LinkedIn profile. Land 5x more interviews, opportunities, and job offers with us.</p>
                            <label><strike>$199</strike>  <span>$9.99</span></label> 
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>



                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_5} />
                            </div>
                            <p>Your sponsored jobseeker will have access to webinars by career coaches & life-enhancing resources</p>
                            <label><strike>$599</strike>  <span>$399.99</span></label>  
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>

                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_6} />
                            </div>
                            <p>With Career Master, you can research and understand what jobs suit you, learn new skills, and connect with mentors and other professionals.</p>
                            <label><strike>$299</strike>  <span>$199.99</span></label>   
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>

                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_7} />
                            </div>
                            <p>Your sponsored jobseeker will have access to valuable career related ebooks such as Art of Interview, Job Comparison, Interview Prep, and more</p>
                            <label><strike>$99</strike>  <span>$39.99</span></label>    
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>


                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_8} />
                            </div>
                            <p>Your Sponsored Jobseeker will have easy access to a list of top recruiters to get hired in the desired job role.</p>
                            <label><strike>$199</strike>  <span>$9.99</span></label>     
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>


                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_9} />
                            </div>
                            <p>Your sponsored jobseeker will receive targeted HR contacts to assist them in their job search</p>
                            <label><strike>$199</strike>  <span>$9.99</span></label>     
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>

                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            <div className='surv_center'>
                                <img src={Logo_10} />
                            </div>
                            <p>Your sponsored jobseeker will have access to webinars by career coaches & life-enhancing resources.</p>
                            <label><strike>$99</strike>  <span>$0</span>(Free Service)</label>      
                            <div className='learn_more'>
                                <a href='#'>LEARN MORE</a>
                            </div>
                            </div>
                        </div>


                        <div className='surv_bund_inner_inner_listing'>
                        <div className='surv_build_innr_partts'>
                            {/* <div className='surv_center'>
                                <img src={Logo_10} />
                            </div> */}
                            <h2>Buy Bundle Package</h2>
                            <p>Buy our All-in-One package and 
get everything under one roof.
</p>
                            <label><span>$99.00</span></label>      
                            <div className='learn_more pur_pack'>
                            <Link to='/jobseeker-purchase-cart'>Purchase Package</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
      }

class SelectedSite extends Component {
        constructor(props)
        {
            super(props)
            this.state = {
                siteDetails:[],
                userData:{}
            }
        }
        componentDidMount()
        {
            let user = localStorage.getItem("gs_user")
                user = JSON.parse(user)
                this.setState({userData:user})
                  let request = {
                                end_point: '/list-sites',
                                token: localStorage.getItem("gs_token")

                            };

       


        GET_API(request).then(resp => {

            this.setState({siteDetails:resp.data},()=>{
                console.log(this.state)
            })
            // resp.data.forEach(element => {
            //     if(this.state.userData.jobseeker_from_site == element.id)
            //     {
            //         this.props.setPackageData(element)
            //     }
            // });
           

        }).catch((error)=>{
            console.log(error)

        })
        }
        render() {
            return (
                <div className='scoring_left'>
                    {/* resumescoring */}
                    {this.state.siteDetails.map((d)=>{
                        if(this.state.userData.jobseeker_from_site == d.id)
                        {
                           // console.log('ddddddddddddddddd',d)
                            return(<>
                            
                                <div className='scoring_left_icon'>
                                      <img src={Scoring_Img} />
                                      {/* <img src={this.props.logoDrop(d.logo)} /> */}
                                </div>
                                <div className='scoring_right_text'>
                                    <h3>{d.url}</h3>
                                    <p>{d.description}</p>
                                    {/* <p>We analyzed thousands of high quality resumes and cover letters to gather insights on exactly what makes them effective. Get an immediate analysis of your resume and cover letter.</p> */}
                                </div>
                            </>)
                        }
                        

                    })}
                                {/* <div className='scoring_left_icon'>
                                    <img src={Scoring_Img} />
                                </div>
                                <div className='scoring_right_text'>
                                    <h3>RESUMESCORING.COM</h3>
                                    <p>Your sponsored jobseeker will receive a resume score and suggestions to strengthen their resume</p>
                                    <p>We analyzed thousands of high quality resumes and cover letters to gather insights on exactly what makes them effective. Get an immediate analysis of your resume and cover letter.</p>
                                </div> */}
                </div>
                );
        }
      }
