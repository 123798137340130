import React, { Component, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import Leftpanel from "./Section/LeftPanel";
import csvfilename from "./files/add-contact.csv";
import "./css/dashboard.css";
import DataTable from "react-data-table-component";
import AddContactModal from "./Section/AddContactModal";
import AddContactcsvModal from "./Section/AddContactcsvModal";
import EditContactModal from "./Section/EditContactModal";
import { BASE_URL } from "../Config";
import moment from "moment";

export default class SponsorReferalContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContactData: {},
      ContactDataError: {},
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/referal-contact-details",
      ContactModal: false,
      ContactcsvModal: false,
      ContactEditModal: false,
      id: 0,
      group_id: 0,
      group_name: "",
      contactUrl: "",

      FromData: {
        group_id: "",
        import_code: "",
      },
      totalRows:0,
      offset: 1,
      limit: 10,
    };

    //Binding
  }
  handlebulkReferal() {
    let group_id = this.props.match.params.id;
    let sta = this;
    var formdata = new FormData();
    formdata.append("group_id", group_id);
    formdata.append("link", BASE_URL);
    let request = {
      end_point: '/bulk-refer-friend',
      formdata:formdata,
      token: localStorage.getItem("gs_token")
  
    };
    
    sta.setState({ loader: 'visible' })///loader on
  
    POST_API(request).then(resp => {
  
      sta.setState({ loader: 'hidden' })///loader off
  
  
      if (resp.status == 'success') {
       
        sta.setState({ Msg: resp.message, Msgtype: "text-success" });
  
        this.getContactList();
        setTimeout(() => {
          this.setState({ Msg: '' })
  
        }, 4000);
      }
      else {
  
  
       
        sta.setState({ Msg: resp.message, Msgtype: "text-danger" });
      }
  
  
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off
  
      sta.setState({ Msg: "Something Went Wrong,Please try again later.", Msgtype: "text-danger" });
      
      console.log('/codeGEN_ERR', ee)
  
  
    });
  }

 
  columns = [
 
    {
      name: "Name",
      selector: (row) => (row.firstname || "") + " " + (row.lastname || ""),
      sortable: true,
      width:'150px',
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width:'150px',
      sortable: true,
      wrap:true,
    },

    {
      name: "Phone",
      selector: (row) => row.contact_no,
      width:'135px',
      sortable: true,
      wrap:true,
    },
    {
      name: "Refer Code",
      selector: (row) => row.referal_code,
      width:'100px',
      sortable: true,
      wrap:true,
    },
    {
      name: "Code Sent",
      selector: (row) => row.has_send,
      sortable: true,
      wrap:true,
    },
    {
      name: "Code Used",
      selector: (row) => row.has_seen,
      sortable: true,
      wrap:true,
    },
    {
      name: "Created At",
      // selector: (row) => row.created_at,
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width:'150px',
      sortable: true,
      wrap:true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div>
            

            <Button
              disabled={false}
              variant="danger"
              onClick={() => {
                if (window.confirm("Delete the Contact?")) {
                  this.handaleDelete(row.id);
                }
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
      
      width: "150px",
    },
  ];

  componentDidMount() {
    let sta = this;
    

    let group_id = this.props.match.params.code;
    this.setState({ group_id });
    localStorage.setItem("group_id", group_id);
    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }



    this.getContactList();
  }

  handleSearchChange = (searchValue) => {
    
    let group_id = this.props.match.params.id;

    let sta = this;
    let ContactData = this.state.ContactData;
    let ContactDataError = this.state.ContactDataError;
    let request = {
      end_point: `/referal-group-contact-list?group_id=${group_id}&offset=${this.state.offset}&limit=${this.state.limit}&searchValue=${searchValue}`,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = {};
          sta.setState({ ContactData });
          ContactData = resp.data;

          sta.setState({ ContactData });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };

  handaleDelete(id) {
    let sta = this;

    var formdata = new FormData();
    formdata.append("id", id);

    let request = {
      end_point: "/delete-referal-contact",
      formdata: formdata,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    POST_API(request)
      .then((resp) => {
        if (resp.status == "success") {
          sta.setState({ loader: "hidden" }); ///loader off

          sta.setState({ Msg: "Contact deleted successfully!", Msgtype: "success" });
          this.getContactList();

        } else {
         
          sta.setState({ Msg: "faild", MsgType: "danger" });
        }
        setTimeout(() => {
          this.setState({ Msg: '' })
  
        }, 4000);
      })
      .catch(() => {});
  }

  handlePerRowsChange = async (page) => {
        
    let group_id = this.props.match.params.id;
    let request = {
      end_point: `/referal-group-contact-list?group_id=${group_id}&offset=${this.state.offset}&limit=${page}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let ContactData = resp.data;
         
          this.setState({ limit: page });
          this.setState({ ContactData });
        } else {
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };
  
  handlePageChange = async (page) => {
    let group_id = this.props.match.params.id;
    let request = {
      end_point: `/referal-group-contact-list?group_id=${group_id}&offset=${page}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    this.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let ContactData = resp.data;
          
         
          this.setState({ ContactData });
        } else {
        }
      })
      .catch((ee) => {
        this.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };

  getContactList() {
    let sta = this;
    let ContactData = this.state.ContactData;
    let group_id = this.props.match.params.id;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    let request = {
      end_point: `/referal-group-contact-list?group_id=${group_id}&offset=${this.state.offset}&limit=${this.state.limit}`,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = resp.data;

          sta.setState({ ContactData ,totalRows:resp.total_count});

          let group_name = resp.group_name.group_name;
          sta.setState({ group_name });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });

  }

  render() {
    let ContactData = this.state.ContactData;

    return (
      <div>
        {ContactData != null ? (
          <>
            <Container fluid className="dash_container">
              <Row>
               
                <Col md={12}>
                  <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />

                  <div className="tabpanelOneForm pt-3">
                    {/* {this.state.group_id != 0 ? (
                      <>
                        <AddContactModal
                          {...this.props}
                          group_id={this.state.group_id}
                          show={this.state.ContactModal}
                          close={() => this.handleClose()}
                        />
                      </>
                    ) : (
                      <></>
                    )} */}

                    

                    {/* {this.state.id != 0 ? (
                      <>
                        <EditContactModal
                          {...this.props}
                          id={this.state.id}
                          show={this.state.ContactEditModal}
                          close={() => this.handleCloseEdit()}
                        />
                      </>
                    ) : (
                      <></>
                    )} */}

                   
                    <h3>Contact List Of {this.state.group_name}</h3>

                    
                      <a
                        href="javascript:"
                        onClick={()=>{if(window.confirm('Are you sure want to send bulk referal?')){this.handlebulkReferal()}}}
                        className="btn btn-primary ghost_button float-right"
                      >
                        Send bulk referal
                      </a>
                   

                    <div style={{marginTop:'10px'}}>
                    {this.state.Msg !== "" ? (
                      <>
                        <FlashMess
                          m={this.state.Msg}
                          mtype={
                            this.state.MsgType ? this.state.MsgType : "success"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    </div>
                    <div  style={{marginTop:"70px"}}>
                    <input
                        className="form-control float-right"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => this.handleSearchChange(e.target.value)}
                        style={{maxWidth: '15rem'}}
                    />
                  </div>
                    <DataTable
                      title=""
                      columns={this.columns}
                      data={ContactData}
                      defaultSortField="title"
                      pagination={true}
                      NoDataComponent={"No data"}
                      noHeader={true}
                      paginationServer
                      paginationTotalRows={this.state.totalRows}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                    />
                  </div>
                </Col>
              </Row>
                    <a
                        href={'#/referal-group-details'}
                        className="btn btn-primary ghost_button float-left"
                      >
                        Back
                      </a>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}
