import React, { Component } from 'react'
import Mytimer from "../Mytimer/Mytimer";
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import moment from 'moment';

import logo_1 from "../images/logo1.png";
import logo_2 from "../images/logo2.png";
import logo_3 from "../images/logo3.png";
import logo_4 from "../images/logo4.png";
import logo_5 from "../images/logo5.png";
import logo_6 from "../images/logo6.png";
import logo_7 from "../images/logo7.png";
import logo_8 from "../images/logo8.png";
import logo_9 from "../images/logo9.png";
import logo_10 from "../images/logo10.png";
import logo_11 from "../images/logo11.png";
import logo_12 from "../images/logo12.png";




import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export default class JobseekerSubscriptionBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mysubscriptions: [],
            packageIdArr: []
        };
    }
    async componentDidMount() {
        if (localStorage.getItem("gs_user")) {
            let user = JSON.parse(localStorage.getItem("gs_user"))
            if (user.roleID == 4)
                await this.getMysubscriptions()
        }

    }
    logoManage = (packageId) => {
        switch (packageId) {
            case 1: return logo_12;
            case 2: return logo_6;
            case 3: return logo_1;
            case 4: return logo_2;
            case 5: return logo_5;
            case 6: return logo_7;
            case 7: return logo_3;
            case 8: return logo_4;
            case 9: return logo_8;
            case 10: return logo_10;
            case 11: return logo_11;


                break;

            default:
                break;
        }
    }
    getMysubscriptions = () => {
        let thhis = this
        return new Promise((resolve, reject) => {


            let request = {
                end_point: "/get-subscriptions",
                token: localStorage.getItem("gs_token"),
            };

            // console.log("......request", request);

            GET_API(request)
                .then((resp) => {
                    // this.setState({ loader: "hidden" }); ///loader off

                    if (resp.status == "success") {
                        // console.log("Update")
                        thhis.setState({ mysubscriptions: resp.data }, () => {

                            let packageIdArr = thhis.state.mysubscriptions.map(e => e.package_id);
                            thhis.setState({ packageIdArr })
                        })

                    }

                    resolve()

                }).catch((ee) => {

                    // console.log('/getMySubscription error', ee)
                    resolve()


                });

        })
    }
    checkTrialPeriod = (gs_user)=>{
        if(gs_user.is_trial == "Y"){
          let givenTimestamp = gs_user.trial_end;
          let currentDateTime = moment();
          let givenDateTime = moment(givenTimestamp, 'YYYY-MM-DD HH:mm:ss');
          let isGreater = givenDateTime.isAfter(currentDateTime);
          return isGreater
        }
        return false;
    }

    checkTrialPeriodDays = (sub)=>{
        const givenTimestamp = sub;
        const currentDateTime = moment();
        const givenDateTime = moment(givenTimestamp, 'YYYY-MM-DD HH:mm:ss');
        return givenDateTime.diff(currentDateTime, 'days') + 1;
    }
    render() {
        let { mysubscriptions } = this.state

        return (
            <>

            
                {mysubscriptions.length > 0 && (<>

                    


                    <h5 className='mr-3'>Active Packages:</h5>


                    {mysubscriptions.map((sub) => {
                        let date1 = new Date();
                        let date2 = new Date(sub.end);
                        let time_difference = date2.getTime() - date1.getTime();
                        let days_difference = time_difference / (1000 * 60 * 60 * 24);

                        let subEnd = new Date(sub.end);
                        let SubStart = new Date(sub.start);
                        let time_differenceToDate = subEnd.getTime() - SubStart.getTime();
                        let days_differenceToDate = time_differenceToDate / (1000 * 60 * 60 * 24);
                        let calculateDiff = (~~days_differenceToDate - ~~days_difference);

                        return (<>
                            <div className='active_package_item'>
                                <img src={this.logoManage(sub.pack.id)} className="img-fluid" />

                                <div className='active_package_item_details d-flex flex-column'>
                                    <img src={this.logoManage(sub.pack.id)} className="img-fluid" />
                                    
                                    { this.checkTrialPeriod(sub) ? 
                                        <> <span className="mt-3">Trial Remaining</span>
                                        <strong><CircularProgressbar value={~~this.checkTrialPeriodDays(sub.trial_end)} maxValue="7" text={`${~~this.checkTrialPeriodDays(sub.trial_end)} days`} styles={buildStyles({ textSize: '14px', textColor: '#213c53', textWeight: 600 })} /></strong>
                                         Days
                                        </>
                                    :
                                    sub.end != null ? (
                                        <>
                                            <span className="mt-3">Time Remaining</span>
                                            {/* <ProgressBar now={144} /> */}
                                            <strong><CircularProgressbar value={~~calculateDiff} maxValue={~~days_differenceToDate} text={`${~~days_difference} days`} styles={buildStyles({ textSize: '14px', textColor: '#213c53', textWeight: 600 })} /></strong>
                                            {/* <Mytimer expiryTimestamp={sub.end} /> */}
                                        </>
                                    ) : (
                                        <>
                                            <span className="mt-3">Time Remaining</span>
                                            <span className="mt-3">Lifetime service</span>
                                        </>
                                    )
                                }
                                </div>
                            </div>



                        </>)
                    })}

                </>

                )}</>
        )
    }
}
