import React from 'react'
import { Card,Container, Row, Col, Pagination } from "react-bootstrap";

const SponsorKnowledgeBase = (props) => {
    return (
    <div className='pt-5 pb-5 data_table_gwi'>
         <Container fluid className="dash_container text-center">

            <h1>Sponsor Knowledgebase</h1>
                <Row>
                      <Col md={6} className="p-3">
                        <Card>
                                <Card.Title>Become a Sponsor</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">GWI Tutorials</Card.Subtitle>
                                <iframe title="vimeo-player" src={"https://www.youtube.com/embed/rtlEAIsMHNk"} width="100%" height="400" frameborder="0" allowfullscreen></iframe>                                  
                        </Card>
                      </Col>
                     
                 </Row>

          </Container>  
           </div>
    )
}

export default SponsorKnowledgeBase;