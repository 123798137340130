import React from "react";
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coordinate,
  Label,
  Legend,
  Interval,
} from "bizcharts";
import DataSet from "@antv/data-set";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

export default class BarChart extends React.Component {
  constructor() {
    super();
    this.state = {
      graphData: [],
      loader: "hidden",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let sta = this;

    let request2 = {
        end_point: '/graph-employee-List',
  
        token: localStorage.getItem("gs_token")
      }
  
      sta.setState({ loader: 'visible' })///loader on
  
      POST_API(request2).then((resp) => {
        sta.setState({ loader: 'hidden' })///loader off
  
        if (resp.status == 'Success') {
          // console.log('emp graph data', resp.data);
          sta.setState({ graphData: resp.data })
        }
  
      }).catch(() => {
        sta.setState({ loader: 'hidden' })///loader off
  
  
      })

  }
  
  render() {
    let graphVal = this.state.graphData.map(i=>(
      {
        label: i.employee,
        Total_Joined: i.join,
        Total_Referred: i.referral_codes_count,
      }
    ))
 
    const ds = new DataSet();
    const dv = ds.createView().source(graphVal);
    dv.transform({
      type: "fold",
      fields: ["Total_Referred", "Total_Joined" ],
      key: "type",
      value: "value", // value 
    });
    return (
      <>
      <h4>Performance Statistics of top 5 members</h4>
      <Chart height={400} data={dv.rows} autoFit>
        <Legend />
        <Coordinate actions={[["scale", 1, -1], ["transpose"]]} />
        <Axis
          name="label"
          label={{
            offset: 12,
          }}
        /> 
         <Axis name="value" position={"right"} />
        <Tooltip />
        <Interval
          position="label*value"
          color={"type"}
          adjust={[
            {
              type: "dodge",
              marginRatio: 1 / 32,
            },
          ]}
        />
      </Chart>
      <br />
        <span>
          <strong style={{color:"gray"}}><i className="fa-solid fa-arrow-up"></i>  X-Axis represents number of jobseekers on boarded,<i className="fa-solid fa-arrow-right ml-2"></i> Y-Axis represents top 5 members from now</strong>
        </span>
      </>
    );
  }
}

