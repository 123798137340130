import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";

export default class SampleResume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleResume : false
    };
  }

  downloadSampleResume = ()=>{
    let sampleResume = this.state.sampleResume
    this.setState({sampleResume: !sampleResume});
  }
  

  render() {
    return (
        <>
        <a className="badge badge-success"  target="_blank" href="/sample-resume.docx" download onClick={this.downloadSampleResume} > Download Sample Resume</a>
        
        <Modal
					show={this.state.sampleResume}
					onHide={() => { this.downloadSampleResume() }}
					dialogClassName=""
					aria-labelledby="example-custom-modal-styling-title"
					className='jobseeker_modal tabform'
					size='md'
					centered
				>

					<Modal.Header closeButton>
						<Modal.Title>Sample Resume</Modal.Title>
					</Modal.Header>


					<Modal.Body>
						<div>
							<Row >
								<Col md="12" >
									<p>The sample resume will be automatically downloaded, and for improved outcomes, kindly modify the text and convert the DOC file to a PDF format.</p>
								</Col>
							</Row>
						</div>
					</Modal.Body>

				</Modal>

      
      </>

    )
  }
}
